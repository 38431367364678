<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutGap="12px">
      <mat-card-title>
        <h2>Credit Note Balance</h2>
      </mat-card-title>
      <mat-card-subtitle>
        Credits may be applied to your account for various reasons. Your credit balance will be applied to your
        invoice total at the beginning of each month. The table below depicts the history of your credit balances.
      </mat-card-subtitle>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="loadingInvoiceCredits">
        <mat-spinner></mat-spinner>
      </div>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getFormatedCreatedDate(row)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="credit">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Credit Amount</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="mat-table-cell-text">
            ${{row.amount < 0 ? -1 * (row.amount/100) : 0}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="debit">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Debit Amount</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.amount >0 ? -1 * (row.amount/100) : 0}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ending_balance">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ending Balance</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.ending_balance/100}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="invoice">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.clientInvoice">
            <mat-icon [style.cursor]="'pointer'" fontSet="fas" fontIcon="fa-file-invoice-dollar" color="accent" [matMenuTriggerFor]="invoiceMenu">
            </mat-icon>
            <mat-menu #invoiceMenu="matMenu" class="info-menu">
              <div (click)="$event.stopPropagation()" fxLayout="column" fxLayoutAlign="start center">
                <div><b>Date: ${{row.clientInvoice?.invoicedDate.toDate() | date: 'shortDate'}}</b></div>
                <div><b>Status: ${{row.clientInvoice?.status}}</b></div>
                <div><b>Total: ${{row.clientInvoice?.amountDue}}</b></div>
                <div><b>Amount Paid: ${{row.clientInvoice?.amountPaid}}</b></div>
                <div><b>Amount Remaining: ${{row.clientInvoice?.amountRemaining}}</b></div>
                <div><b>Due On: ${{row.clientInvoice?.dueDate.toDate() | date: 'shortDate'}}</b></div>
              </div>
            </mat-menu>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Event Type</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.type}}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
