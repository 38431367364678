<div fxLayout="row">
  <h4>
    {{document && document.id ? 'Edit ' + document.name : 'Create Document'}}
  </h4>
</div>
<form [formGroup]="documentForm" fxLayout="column" fxLayoutGap="10px">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>
    <mat-form-field  appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-evenly start">
      <mat-form-field appearance="outline">
        <mat-label>Project</mat-label>
        <mat-select formControlName="project" placeholder="status">
          <mat-option *ngFor="let project of projects" [value]="project.id">
            {{project.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <div fxLayout="row">
    <button mat-raised-button color="primary"
            (click)="saveDocument()">
      Save
      <mat-icon>save</mat-icon>
    </button>
    <button mat-raised-button color="warn" *ngIf="document.creator === user.id"
            (click)="deleteDocument()">
      Delete
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</form>
