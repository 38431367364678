import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeCardComponent } from './stripe-card.component';

@NgModule({
  declarations: [StripeCardComponent],
  exports: [StripeCardComponent],
  imports: [
    CommonModule
  ]
})
export class StripeCardModule { }
