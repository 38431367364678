import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabGroupComponent} from './tab-group.component';
import {MaterialModule} from "../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [TabGroupComponent],
  exports: [
    TabGroupComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    DragDropModule,
  ]
})
export class TabGroupModule {
}
