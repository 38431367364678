<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="client">
      <h2>Your {{client.name}} Projects</h2>
    </mat-card-title>
    <mat-card-subtitle>
      <h6>View and edit projects.</h6>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button color="primary" [routerLink]="'./new'">Create an project</button>
  </div>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Projects">
  </mat-form-field>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a [routerLink]="row.id">{{row.name}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.description}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="department">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Department</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{getDepartmentName(row)}}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
