import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { tap, finalize, map } from 'rxjs/operators';
import { LoadingDialogService } from './loading-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService {

  constructor(private storage: AngularFireStorage,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) { }

  uploadSingle(file, path, progress?) {
    return new Promise(resolve => {
      const ref = this.storage.ref(`${path}`);
      const task = this.storage.upload(path, file);
      if (progress) {
        progress = task.percentageChanges();
      }
      this.loadingService.isLoading(true, 'Uploading Document...', { progress: progress });
      const uploadResult = {};
      const snapshot = task.snapshotChanges().pipe(
        tap(console.log),
        // The file's download URL
        finalize(async () => {
          // downloadUrl = await ref.getDownloadURL().toPromise();
        })).subscribe(result => {
          if (snapshot['bytesTransferred'] === snapshot['totalBytes']) {
            uploadResult['path'] = result.ref.fullPath;
            uploadResult['size'] = result.totalBytes;
            this.loadingService.isLoading(false);
            resolve(uploadResult);
          }
        }, () => {
          this.loadingService.isLoading(false);
        }, () => {
          this.loadingService.isLoading(false);
        })
    })
  }

}
