<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card class="dialog-card">
  <mat-card-header>
    <mat-card-title>
      <h2>{{data.statusText}} Transactions at {{data.locationId}}</h2>
    </mat-card-title>
    <div>
      <button mat-button (click)="exportReport()" color="accent">
        Export
        <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
      </button>
      <mat-form-field appearance="outline" class="report-drill-down-filter">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutGap="10px">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows class="mat-elevation-z5">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.date.toDate() |date:'shortDate'}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sale">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Sale</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.sale ? row.sale : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="saleCorrection">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Sale Adjustment</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.saleCorrection ? row.saleCorrection : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tax">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tax </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.tax ? row.tax : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="taxCorrection">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tax Adjustment</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.taxCorrection ? row.taxCorrection : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tip">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tip</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.tip ? row.tip : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="otherCharges">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Other Charges</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.otherCharges ? row.otherCharges : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="taxRemitted">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tax Remitted</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.taxRemitted ? row.taxRemitted : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="promoFee">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Promo Fee</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.promoFee ? row.promoFee : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deliveryFeeRate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Fee Rate</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.deliveryFeeRate ? row.deliveryFeeRate : 0}}%
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="pickupFeeTotal">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Pickup Fee</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.pickupFeeTotal ? row.pickupFeeTotal : 0}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cateringFee">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Catering Fee</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.cateringFee ? row.cateringFee : 0}}
          </mat-cell>
        </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
