<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Audit Trail Checklist Templates</h2>
      </mat-card-title>
      <mat-card-subtitle>
        Below is a list of your audit trail checklist templates. Checklist templates allow you to save and reuse a set
        of tasks specific to your
        team's process when reviewing and completing audit trails.
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
      <mat-form-field appearance="outline">
        <mat-label>Select Project</mat-label>
        <mat-select [formControl]="selectedProject">
          <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Checklists">
      </mat-form-field>
      <div>
        <button mat-raised-button color="primary" (click)="createAtChecklistTemplate()">Create New Template</button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="mat-elevation-z8 report-table">
        <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="content-cell">
                <ng-container *ngIf="row.user === uiState.authUser.id; else viewOnly">
                  <a (click)="editAtChecklistTemplate(row)" [matTooltip]="row.name">
                    <u>
                      {{row.name}}
                    </u>
                  </a>
                </ng-container>
                <ng-template #viewOnly>
                  <div [matTooltip]="row.name">
                    {{row.name}}
                  </div>
                </ng-template>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.description}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Creator</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{getCreatorName(row)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="updated">
            <mat-header-cell *matHeaderCellDef> Last Update</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.dateUpdated ? (row.dateUpdated.toDate() | date:'shortDate') : ''}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="copy">
            <mat-header-cell *matHeaderCellDef> Copy</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button color="primary" (click)="copyTemplateToOtherProject(row)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandTrigger">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Show Items</b></mat-header-cell>
            <mat-cell *matCellDef="let row" [style.color]="row.color">
              <button mat-icon-button color='primary' (click)="expandedElement = expandedElement === row ? null : row">
                <mat-icon>expand</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef> Delete</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button color="warn" (click)="deleteAtChecklistTemplate(row)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="expanded-cell-data">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <h3>Items:</h3>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start start" [style.height.px]="250" MalihuScrollbar
                    [scrollbarOptions]="scrollbarOptions">
                    <mat-list *ngIf="expandedElement">
                      <mat-list-item *ngFor="let item of expandedElement.items" fxLayoutAlign="start center">
                        <mat-icon matListIcon>check_box</mat-icon>
                        <div matLine [matTooltip]="item.name">
                          <span>{{item.name}}</span>
                        </div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element">
          </mat-row>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="auditTrailDocumentChecklistTemplates.length">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
