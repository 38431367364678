import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AuditTrailDocument,
  Client, Project,
  User, UserRoles,
} from "@deliver-sense-librarian/data-schema";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {takeUntil} from "rxjs/operators";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import * as moment from 'moment';

@Component({
  selector: 'app-audit-trail-document',
  templateUrl: './audit-trail-document.component.html',
  styleUrls: ['./audit-trail-document.component.scss']
})
export class AuditTrailDocumentComponent implements OnInit, OnDestroy {
  @Input() document: AuditTrailDocument;
  @Output() saved = new EventEmitter();
  public documentForm: FormGroup;
  public user: User;
  private client: Client;
  private destroy$ = new Subject();
  private uiState: UiState;
  projects: Project[] = [];

  constructor(private store: Store<any>,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private loadingService: LoadingDialogService,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && (this.user !== uiState$.authUser && this.client !== uiState$.client)) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.fetchAvailableProjects();
          this.setupDocumentForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async saveDocument() {
    if (this.documentForm.valid) {
      try {
        const formValues = this.documentForm.value;
        const timeStamp = moment().toDate();
        if (this.document.id) {
          await this.afs.doc(`auditTrailDocuments/${this.document.id}`)
            .update({
              project: formValues.project,
              creator: formValues.creator,
              description: formValues.description,
              name: formValues.name,
              dateUpdated: timeStamp
            });
        } else {
          await this.afs.collection(`auditTrailDocuments`)
            .add({
              project: formValues.project,
              creator: formValues.creator,
              description: formValues.description,
              name: formValues.name,
              dateCreated: timeStamp,
              dateUpdated: timeStamp
            })
        }
        this.snackBar.open(`Document ${this.document.id ? 'Updated' : 'Created'}`, 'Dismiss', {
          duration: 5000
        });
        this.saved.emit(true);
      } catch (e) {
        console.log(e);
        this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please fill out required fields.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  async deleteDocument() {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this document? All sections, comments, and messages associated with this document will be deleted as well.',
        action: 'Yes, delete.'
      }
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`auditTrailDocuments/${this.document.id}`).delete();
        this.snackBar.open('Document deleted successfully!', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }

  private setupDocumentForm() {
    this.documentForm = this.fb.group({
      project: new FormControl(this.document.project, Validators.required),
      creator: new FormControl(this.document.creator),
      description: new FormControl(this.document.description),
      name: new FormControl(this.document.name, Validators.required)
    })
  }

  private fetchAvailableProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType(
      'projects',
      this.afs,
      this.uiState.projects,
      [UserRoles.admin, UserRoles.contributor, UserRoles.viewer],
      this.client.id
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(projects$ => {
        this.projects = projects$ as Project[];
      });
  }
}
