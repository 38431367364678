  <div fxLayout="column" fxLayoutAlign="center center">
    <h1>Dashboard</h1>
    <div fxLayout="row wrap" fxLayoutGap="12px" fxLayoutAlign="space-between center">
      <ng-container *ngIf="isModuleActive(auditTrailsModule)">
        <!-- <app-audit-trails-recent-files></app-audit-trails-recent-files>
        <app-audit-trails-assigned-comments></app-audit-trails-assigned-comments>
        <app-audit-trails-created-comments></app-audit-trails-created-comments> -->
      </ng-container>
      <ng-container *ngIf="isModuleActive(thirdPartyReportingModule)">
        <app-third-party-delivery-latest-reports ></app-third-party-delivery-latest-reports>
      </ng-container>
      <app-billing-recent-day-charges></app-billing-recent-day-charges>
      <app-billing-recent-invoices></app-billing-recent-invoices>
    </div>
