import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditTrailDocumentChecklistTemplate } from '@deliver-sense-librarian/data-schema';

@Component({
  selector: 'app-audit-trail-checklist-template-dialog',
  templateUrl: './audit-trail-checklist-template-dialog.component.html',
  styleUrls: ['./audit-trail-checklist-template-dialog.component.scss']
})
export class AuditTrailChecklistTemplateDialogComponent implements OnInit {
  public auditTrailDocumentChecklistTemplate: AuditTrailDocumentChecklistTemplate;
  copy = false;
  constructor(public dialogRef: MatDialogRef<AuditTrailChecklistTemplateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.auditTrailDocumentChecklistTemplate = data.auditTrailDocumentChecklistTemplate ? data.auditTrailDocumentChecklistTemplate : new AuditTrailDocumentChecklistTemplate()
                this.copy = data.copy;
               }

  ngOnInit() {
  }

}
