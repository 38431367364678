<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Upload Transaction Data</h2>
    </mat-card-title>
  </mat-card-header>
</mat-card>
<mat-card>
  <mat-card-content>
    <mat-checkbox [formControl]="testMode">
      Test Mode?
      <mat-icon fontSet="fas" fontIcon="fa-info-circle" matTooltip="Checking this box will enable you to validate a set of data for uploading without saving it to the database.
      It is recommended to test uploads that are from a new source, may have significant data structure changes, or potential for numerous errors.">
      </mat-icon>
    </mat-checkbox>

    <div fxLayout="row wrap">
      <div fxLayout="row">
        <mat-form-field appearance="outline">
          <mat-label>Transaction Type</mat-label>
          <mat-select [formControl]="selectedTransactionType">
            <mat-option *ngFor="let transactionType of transactionTypes" [value]="transactionType">
              {{transactionType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" *ngIf="selectedTransactionType.valid && selectedTransactionType.value === 'POS'">
        <mat-form-field appearance="outline">
          <mat-label>POS System</mat-label>
          <mat-select [formControl]="selectedPos">
            <mat-option *ngFor="let posType of posSystems" [value]="posType.code">
              {{posType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" *ngIf="selectedTransactionType.valid && selectedTransactionType.value === '3PD'">
        <mat-form-field appearance="outline">
          <mat-label>Account Type</mat-label>
          <mat-select [formControl]="selectedThirdParty">
            <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty.code">
              {{thirdParty.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="selectedTransactionType.valid && selectedPos.valid && selectedThirdParty.valid">

    </ng-container>
    <div fxLayout="column" *ngIf="transactionConversions && transactionConversions.length > 0; else noConversions">
      <h3>Expected Fields</h3>
      <h5>
        Below are the required columns that must be present in your csv file upload in order to upload transaction
        data.
      </h5>
      <div fxLayout="row" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
        <ng-container *ngFor="let field of expectedFields">
          <div class="expected-field-cell">
            {{field}}
            <ng-container *ngIf="isRequiredField(field)">
              *
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #noConversions>
      <b>
        <h4 *ngIf="this.transactionConversions && this.transactionConversions.length < 1">Your client account doesn't
          have any conversions available for that type of transactions.</h4>
      </b>
    </ng-template>
  </mat-card-content>
  <mat-card-actions *ngIf="transactionConversions && transactionConversions.length > 0">
    <input type="file" id="location-form-upload-button-partial" fxHide (change)="detectFiles($event)"
      [multiple]="false">
    <label for="location-form-upload-button-partial" #imageUploadLabel>
      <button mat-raised-button color="primary" (click)="imageUploadLabel.click()">
        <mat-icon>attachment</mat-icon> Upload Transactions
      </button>
    </label>
  </mat-card-actions>
</mat-card>
