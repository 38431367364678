<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <div class="text-center mb-4">
            <h4 class="text-uppercase mt-0 mb-3">Reset Password</h4>
            <p class="text-muted mb-0 font-13">Enter your email address and we'll send you an email with instructions
              to reset your password. </p>
          </div>


          <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>

            <mat-form-field appearance="outline">

              <mat-label>Email</mat-label>

              <input matInput type="email" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Email" />
            </mat-form-field>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
            <div class="form-group mb-0 text-center" *ngIf="!success">
              <button mat-raised-button color="accent" class="btn btn-primary btn-block" type="submit">Submit</button>
            </div>
          </form>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">Back to <a routerLink="/login" class="text-dark ml-1"><b>Log In</b></a>
              </p>
            </div> <!-- end col -->
          </div>
          <!-- end row -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
