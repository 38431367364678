import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuditTrailDocument, AuditTrailDocumentSection, AuditTrailDocumentSectionComment, UserView } from "@deliver-sense-librarian/data-schema";
import { MatSort } from "@angular/material/sort";
import { AuditTrailDocumentCommentComponent } from "../../components/ds-modules/audit-trails/audit-trail-document-comment/audit-trail-document-comment.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-audit-trail-document-comment-dialog',
  templateUrl: './audit-trail-document-comment-dialog.component.html',
  styleUrls: ['./audit-trail-document-comment-dialog.component.scss']
})
export class AuditTrailDocumentCommentDialogComponent implements OnInit {
  @ViewChild(AuditTrailDocumentCommentComponent, { static: true }) auditTrailDocumentCommentComponent: AuditTrailDocumentCommentComponent;
  public comment: AuditTrailDocumentSectionComment;
  public documentId: string;
  public sectionId: string;
  public projectId: string;

  constructor(private afs: AngularFirestore,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AuditTrailDocumentCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.comment = data.comment;
    this.documentId = data.documentId;
    this.sectionId = data.sectionId;
    this.projectId = data.projectId;
  }

  ngOnInit() {
  }

  closeDialog() {
    if (this.auditTrailDocumentCommentComponent.commentForm.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Unsaved Changes',
          message: 'You have unsaved changes. Are you sure you want to leave?',
          action: 'Yes, Close'
        }
      });
      confirmDialog.afterClosed().subscribe(async (confirmed) => {
        if (confirmed) {
          this.dialogRef.close()
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
}
