import {Component, Inject, OnInit} from '@angular/core';
import {Client} from "@deliver-sense-librarian/data-schema";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-create-entity-dialog',
    templateUrl: './create-entity.dialog.component.html',
    styleUrls: ['./create-entity.dialog.component.scss']
})
export class CreateEntityDialogComponent implements OnInit {
    public client: Client;

    constructor(public dialogRef: MatDialogRef<CreateEntityDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.client = data ? data.client : undefined;
    }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
