<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutGap="12px">
      <mat-card-title>
        <h2>Day Charges</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6></h6>
      </mat-card-subtitle>
      <div fxLayout="row" fxLayoutAlign="center center">
        <h3><b>Search Day Charges</b></h3>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="2px" fxLayoutAlign="space-between center">
        <div fxLayout="row wrap" fxLayoutGap="2px">
          <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
            <mat-label>Start Date</mat-label>
            <input matInput [formControl]="startDate" [matDatepicker]="startPicker" placeholder="Start date">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" id="select-end-date" class="report-parameter-form-field">
            <mat-label>End Date</mat-label>
            <input matInput [formControl]="endDate" [disabled]="startDate.invalid" [min]="startDate.value"
              [max]="getMaxEndDate()" [matDatepicker]="endPicker" placeholder="End date">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div [style.margin-right.px]="20">
          <button mat-raised-button color="primary" (click)="runSearch()">
            Search
          </button>
        </div>
      </div>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.id}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="chargeDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Charge Date</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="mat-table-cell-text">
            {{row.chargeDate ? (row.chargeDate.toDate() | date:'shortDate') : ''}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="module">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Module</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getDeliverSenseModule(row.clientModule)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subTotal">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal</mat-header-cell>
        <mat-cell *matCellDef="let row">
            ${{row.subTotal}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="unit">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Unit</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div [matTooltip]="row.unit">{{row.unit}}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="unitQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.unitQuantity}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="promo">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Promo Discount</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.clientModule.promoRate ? row.clientModule.promoRate + '%' : '0%'}}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
