<mat-toolbar class="main-header" color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <button (click)="toggleSidenav.emit()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-form-field [style.width.px]="160" id="header-client-selector">
      <mat-select [formControl]="selectedClient">
        <mat-select-trigger>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="80" class="inner-text">
              {{selectedClient.value && getClient(selectedClient.value) ? getClient(selectedClient.value).name : ''}}
            </div>
            <div fxFlex>
              <img [style.width.px]="20"
                *ngIf="selectedClient && getClient(selectedClient.value) && getClient(selectedClient.value).logo"
                [src]="getClient(selectedClient.value).logo" />
            </div>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let client of availableClients" [value]="client.id">
          {{client.name}}
          <img [style.width.px]="20" *ngIf="client && client.logo" [src]="client.logo" />
        </mat-option>
        <mat-option (click)="$event.stopPropagation(); clearClientSelection()">Clear Selection</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div id="header-client-brand" fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="center center">
    <div class="header-logo" id="header-logo">
      <img src="assets/images/logo-light-vert.png" />
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button (click)="openDataUploadWindow()">
      <mat-icon matBadgePosition="before"  [matBadge]="uploadCompletions().total > 0 ? uploadCompletions().total : null"
        [matBadgeColor]="uploadCompletions().errorsExist ? 'warn' : 'accent'">cloud_upload</mat-icon>
    </button>
    <span fxFlex></span>
    <!-- <button (click)="toggleNotificationSidenav.emit()"
            mat-icon-button class="ml-xs overflow-visible"
            id="header-notification-button"
            fxShow="false"
            fxShow.gt-sm="true">
      <mat-icon matBadgeColor="warn"
                matBadgePosition="above before"
                [matBadge]="countUnreadNotifications() > 0 ? countUnreadNotifications() : ''">
        notifications
      </mat-icon>
    </button> -->
    <!--    <button id="header-user-actions-button" [matMenuTriggerFor]="userMenu" mat-button class="ml-xs">-->
    <!--      <b fxHide.lt-md="true" fxShow="true">Hello, {{user ? user.firstName + ' ' + user.lastName : ''}}</b>-->
    <app-avatar *ngIf="user" [userView]="user" [matMenuTriggerFor]="userMenu" [style.cursor]="'pointer'"></app-avatar>
    <!--      <mat-icon>person</mat-icon>-->
    <!--    </button>-->
    <mat-menu #userMenu="matMenu" x-position="before">
      <!-- <button (click)="toggleNotificationSidenav.emit()"
              mat-menu-item
              fxShow="true"
              fxShow.gt-sm="false">
        <mat-icon matBadgeColor="warn"
                  [matBadge]="countUnreadNotifications() > 0 ? countUnreadNotifications() : ''">
          notifications
        </mat-icon>
        Notifications
      </button> -->
      <button mat-menu-item [routerLink]="'profile'">
        <mat-icon>account_box</mat-icon>
        Profile
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        Sign Out
      </button>
    </mat-menu>
  </div>

</mat-toolbar>
