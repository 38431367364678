import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute } from "@angular/router";
import { first, flatMap, map, take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  UserRoles,
  Location,
  ThirdParty,
  ThirdPartyReport,
  Client3pdConfiguration,
  ThirdPartyReconciliationLocationData,
  Client3pdTransactionStatusConfiguration,
  ThirdPartyTransaction,
  ThirdPartyTransactionStatus,
  ThirdPartyReportStatuses,
  ThirdPartyReportFragment
} from '@deliver-sense-librarian/data-schema';
import * as moment from "moment";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { LocationReport, ThirdPartyDeliveryAnalyticsEngine } from "../third-party-delivery-analytics-engine";
import { UiState } from '../../../../redux/custom-states/uiState/ui-state';
import * as _ from "lodash";
import { MatExpansionPanel } from "@angular/material/expansion";
import * as bluebird from 'bluebird'
import { ReconciliationTableComponent } from '../reconciliation-table/reconciliation-table.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-create-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
  @ViewChild(ElementRef, { static: true }) reportHeaderCard: ElementRef;
  @ViewChild(MatExpansionPanel, { static: true }) parameterPanel: MatExpansionPanel;
  @ViewChild(ReconciliationTableComponent) reconciliationTable: ReconciliationTableComponent;
  public loadingParameters = true;
  public reportName = new FormControl('', Validators.required);
  public parametersForm: FormGroup;
  public reportData: any[];
  public thirdParties: ThirdParty[] = [];
  public locations: Location[] = [];
  public reportAvailable: boolean;
  public analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  public existingReport: ThirdPartyReport;
  public editingName = false;
  public client3pdConfiguration: Client3pdConfiguration;
  public client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] = [];
  public taxRateErrorTotal = 0;
  public salesErrorTotal = 0;
  public taxErrorTotal = 0;
  public remittanceErrorTotal = 0;
  public defaultSelectedFields = [
    'Location Id',
    '3PD',
    'State/Province',
    'Sales Variance',
    'Tax Variance',
    'Suggested Tax Adjustment',
    'Total Fees',
    'Remittance Variance',
  ];
  private allThirdPartiesSelected: boolean;
  private thirdPartyReportId: string;
  public uiState$: UiState;
  private destroy$ = new Subject();
  totalErrors = 0;
  thirdPartyTransactionStatuses: ThirdPartyTransactionStatus[];
  reportRunningInBackground = false;
  reportFragments: ThirdPartyReportFragment[] = [];
  completeFragments: ThirdPartyReportFragment[] = [];
  loadingReportFragments = true;
  errorFragments: ThirdPartyReportFragment[] = [];
  errorReconcilingData = false;
  constructor(private store: Store<any>,
    private http: HttpClient,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private afAuth: AngularFireAuth) {
    this.analyticsEngine = new ThirdPartyDeliveryAnalyticsEngine();
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.initializeReport();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async initializeReport() {
    await this.get3pdConfigurations();
    this.getLocations();
    this.thirdParties = this.uiState$.clientThirdParties.map(clientThirdParty => clientThirdParty.thirdParty).filter(tp => !!tp);
    this.activatedRoute.params.subscribe(params$ => {
      this.thirdPartyReportId = params$['id'];
      if (this.thirdPartyReportId) {
        this.getExistingReport();
      }
    })
  }

  private async get3pdConfigurations() {
    const result$ = await Promise.all([
      this.afs.collection('client3pdConfigurations', ref => ref
        .where('client', '==', this.uiState$.client.id))
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs.collection('client3pdTransactionStatusConfigurations', ref => ref
        .where('client', '==', this.uiState$.client.id))
        .snapshotChanges()
        .pipe(first())
        .toPromise(),
      this.afs.collection('thirdPartyTransactionStatuses').snapshotChanges().pipe(first()).toPromise()
    ]);
    this.client3pdConfiguration = FirestoreUtilities.mapToType(result$[0])[0] as Client3pdConfiguration;
    this.client3pdTransactionStatusConfigurations = FirestoreUtilities.mapToType(result$[1]) as Client3pdTransactionStatusConfiguration[];
    this.thirdPartyTransactionStatuses = FirestoreUtilities.mapToType(result$[2]) as ThirdPartyTransactionStatus[];
  }

  private async fullQuery(locationId: any, thirdPartyId: any, start: Date, end: Date, isPos) {
    const tpdFilterTypeName = isPos ? 'account' : 'thirdParty';
    const collection = isPos ? 'posTransactions' : 'thirdPartyTransactions';
    return this.afs.collection(collection, ref => ref
      .where('date', '>=', start)
      .where('date', '<=', end)
      .where(tpdFilterTypeName, '==', thirdPartyId)
      .where('location', '==', locationId)
      .where('client', '==', this.uiState$.client.id)
      .orderBy('date')
    ).snapshotChanges()
      .pipe(first())
      .toPromise();
  }
  private getToken(token): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);

  }
  private async fetchParameterizedReportData(newParameters) {
    const loadingValue = { progress: 0 };
    const selectedLocationIds = newParameters.selectedLocations;
    const selectedThirdPartyIds = newParameters.selectedThirdParties;
    const start = moment(newParameters.startDate).startOf('day').toDate();
    const end = moment(newParameters.endDate).endOf('day').toDate();
    const allTransactions = {
      posTransactions: [],
      tpdTransactions: []
    };
    const locationsChunks = _.chunk(selectedLocationIds, 4);
    const totalRequestPairs = locationsChunks.length * selectedThirdPartyIds.length
    const progressStep = 100 / (totalRequestPairs);
    const estimatedTime = +(totalRequestPairs * 2 / 60).toFixed(0) > 0 ? +(totalRequestPairs * 2 / 60).toFixed(0) : 1;
    this.loadingService.isLoading(true, `Pulling Report Data. This may take up to ${estimatedTime} minutes`, loadingValue);
    await bluebird.mapSeries(locationsChunks, async (locationIds) => {
      return await Promise.all(selectedThirdPartyIds.map(async (thirdPartyId) => {
        const posTransactionsQuery = await this.afs.collection('posTransactions', ref => ref
          .where('client', '==', this.uiState$.client.id)
          .where('date', '>=', start)
          .where('date', '<=', end)
          .where('location', 'in', locationIds)
          .where('account', '==', thirdPartyId)
          .orderBy('date')
        ).snapshotChanges().pipe(first()).toPromise();
        const thirdPartyTransactionsQuery = await this.afs.collection('thirdPartyTransactions', ref => ref
          .where('client', '==', this.uiState$.client.id)
          .where('date', '>=', start)
          .where('date', '<=', end)
          .where('location', 'in', locationIds)
          .where('thirdParty', '==', thirdPartyId)
          .orderBy('date')
        ).snapshotChanges().pipe(first()).toPromise();
        allTransactions.posTransactions = [...allTransactions.posTransactions, ...FirestoreUtilities.mapToType(posTransactionsQuery)]
        allTransactions.tpdTransactions = [...allTransactions.tpdTransactions, ...FirestoreUtilities.mapToType(thirdPartyTransactionsQuery)]
        loadingValue.progress += progressStep;
      }))
    })

    // allTransactions.posTransactions = FirestoreUtilities.mapToType(posTransactionsResult);
    // // @TODO need to improve performance by grouping more requests together
    // const locationThirdPartyPairs = selectedLocationIds.reduce((arr, locationId) => {
    //   const locationThirdParties = selectedThirdPartyIds.map(thirdPartyId => {
    //     return { location: locationId, thirdParty: thirdPartyId };
    //   });
    //   return arr ? [...arr, ...locationThirdParties] : [...locationThirdParties];
    // }, []);
    // const progressStep = 100 / (locationThirdPartyPairs.length);
    // const estimatedTime = +(locationThirdPartyPairs.length * 2 / 60).toFixed(0) > 0 ? +(locationThirdPartyPairs.length * 2 / 60).toFixed(0) : 1;
    // this.loadingService.isLoading(true, `Pulling Report Data. This may take up to ${estimatedTime} minutes`, loadingValue);
    // const transactionRequests = await bluebird.mapSeries(locationThirdPartyPairs, async (locationThirdPartyPair: any) => {
    //   const transactionPair = await Promise.all([
    //     this.fullQuery(locationThirdPartyPair.location, locationThirdPartyPair.thirdParty, start, end, true),
    //     this.fullQuery(locationThirdPartyPair.location, locationThirdPartyPair.thirdParty, start, end, false)
    //   ]);
    //   loadingValue.progress += progressStep;
    //   return { posTransaction: transactionPair[0], thirdPartyTransaction: transactionPair[1] };
    // });
    // allTransactions.posTransactions = _.flatten(transactionRequests.map(pair => pair.posTransaction));
    // allTransactions.tpdTransactions = _.flatten(transactionRequests.map(pair => pair.thirdPartyTransaction));
    // allTransactions.posTransactions = allTransactions.posTransactions.map(transaction => FirestoreUtilities.objectToType(transaction))
    // allTransactions.tpdTransactions = allTransactions.tpdTransactions.map(transaction => FirestoreUtilities.objectToType(transaction))
    this.filterTpdTransactionsByStatusConfigs(allTransactions);
    return allTransactions;
  }

  private filterTpdTransactionsByStatusConfigs(allTransaction: { posTransactions, tpdTransactions }) {
    allTransaction.tpdTransactions = allTransaction.tpdTransactions.filter((tpdTransaction: ThirdPartyTransaction) => {
      // get the global third party transaction status that is equal to the text value in status of the transaction
      const thirdPartyTransactionStatus = this.thirdPartyTransactionStatuses.find(tpts => tpts.status === tpdTransaction.status && tpts.thirdParty === tpdTransaction.thirdParty);
      if (thirdPartyTransactionStatus) {
        // if a global transaction status is found check if the client has a setting for the transaction status
        const matching3pdTransactionsConfiguration = this.client3pdTransactionStatusConfigurations.find(config => config.thirdParty === tpdTransaction.thirdParty && config.status === thirdPartyTransactionStatus.id);
        if (matching3pdTransactionsConfiguration) {
          // if a matching client transaction status configuration is found return the boolean value of "inPos" to filter
          return matching3pdTransactionsConfiguration.inPos === false ? false : true;
        }
      }
      // if both status levels are not found return the transaction to be included in the reconciliation
      return true;
    });
  }
  private async clearExistingFragments() {
    const fragmentChunks = _.chunk(this.reportFragments, 500)
    await Promise.all(fragmentChunks.map(fragmentChunk => {
      const deleteBatch = this.afs.firestore.batch();
      fragmentChunk.forEach(fragment => {
        deleteBatch.delete(this.afs.doc(`thirdPartyReportFragments/${fragment.id}`).ref)
      });
      return deleteBatch.commit();
    }))
  }
  async runReport() {
    this.reportData = null;
    setTimeout(async () => {
      if (this.parametersForm.valid) {
        this.loadingService.isLoading(true, 'Creating report job...');
        const newParameters = this.parametersForm.value;
        if (this.reportFragments.length > 0) {
          await this.clearExistingFragments();
        }
        await this.saveReport();
        const selectedLocationIds = newParameters.selectedLocations;
        const selectedThirdPartyIds = newParameters.selectedThirdParties;
        const start = moment(newParameters.startDate).startOf('day').toDate();
        const end = moment(newParameters.endDate).endOf('day').toDate();
        const locationsChunks = _.chunk(selectedLocationIds, 10);
        await Promise.all(locationsChunks.map(async (locationsChunk: string[]) => {
          await Promise.all(selectedThirdPartyIds.map(async thirdPartyId => {
            const newFragment = new ThirdPartyReportFragment()
            newFragment.client = this.uiState$.client.id;
            newFragment.endDate = end;
            newFragment.startDate = start;
            newFragment.locations = locationsChunk;
            newFragment.thirdParty = thirdPartyId;
            newFragment.thirdPartyReport = this.existingReport.id;
            return this.afs.collection('thirdPartyReportFragments').add(newFragment.toJSONObject())
          }))
        }));
        this.loadingService.isLoading(false);
        this.reportRunningInBackground = true;
        //   try {
        //     const allTransactions = await this.fetchParameterizedReportData(newParameters);
        //     this.loadingService.isLoading(false);
        //     this.loadingService.isLoading(true, 'Running Reconciliation...');
        //     const reportLocations = this.locations.filter(location => this.parametersForm.get('selectedLocations').value.find(locationId => locationId === location.locationId));
        //     const reportThirdParties = this.thirdParties.filter(thirdParty => this.parametersForm.get('selectedThirdParties').value.find(tpId => tpId === thirdParty.id));
        //     const reportThirdPartyLocations = reportThirdParties.reduce((rows: LocationReport[], thirdParty: ThirdParty) => {
        //       const thirdPartyLocationReports: LocationReport[] = [];
        //       reportLocations.forEach(location => {
        //         const locationReport = Object.assign(new LocationReport(), location);
        //         locationReport.posTransactions = allTransactions.posTransactions
        //           .filter(posTransaction => {
        //             return posTransaction['location'] === location.locationId && posTransaction['account'] === thirdParty.id;
        //           });
        //         locationReport.thirdPartyTransactions = allTransactions.tpdTransactions
        //           .filter(thirdPartyTransaction => {
        //             return thirdPartyTransaction.location === location.locationId && thirdPartyTransaction.thirdParty === thirdParty.id;
        //           });
        //         locationReport.thirdParty = thirdParty.id;
        //         locationReport.startDate = this.parametersForm.get('startDate').value;
        //         locationReport.endDate = this.parametersForm.get('endDate').value;
        //         thirdPartyLocationReports.push(locationReport);
        //       });
        //       return [...rows, ...thirdPartyLocationReports]
        //     }, []);
        //     reportThirdPartyLocations.forEach(row => {
        //       row.reportId = this.afs.createId();
        //     });
        //     await this.analyticsEngine.setLocationReportData(reportThirdPartyLocations);
        //     const summaryData = this.flattenReportData(reportThirdPartyLocations);
        //     await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`).update({
        //       reportData: summaryData,
        //       lastRun: moment().toDate()
        //     });
        //     this.snackBar.open('Report run complete', 'Dismiss', { duration: 5000 })
        //     setTimeout(() => {
        //       console.log('activating rec table')
        //       this.reportData = summaryData;
        //     });
        //     this.loadingService.isLoading(false);
        //   } catch (e) {
        //     console.log('error: ', e.message)
        //     this.snackBar.open('Error running report. Please validate your selection and try again.', 'Dismiss', {
        //       duration: 5000
        //     });
        //     this.loadingService.isLoading(false)
        //   }
        // } else {
        //   this.loadingService.isLoading(false);
        //   this.snackBar.open('Please select one or more locations, one or more third parties, a start date, and an end date', 'Dismiss', {
        //     duration: 5000
        //   })
      }

    })
  }
  public isUserAccessRestricted() {
    return this.existingReport.creator === this.uiState$?.authUser.id || this.uiState$.clientRole < 2
  }
  private flattenReportData(locationReportData: LocationReport[]) {
    return locationReportData.map((row: LocationReport) => {
      const location = this.locations.find(_l => _l.locationId === row.locationId);
      const tpdReportData = new ThirdPartyReconciliationLocationData();
      tpdReportData.reportId = row.reportId;
      // LOCATION INFORMATION
      tpdReportData.locationId = row.locationId;
      tpdReportData.thirdPartyName = this.getThirdPartyName(row.thirdParty);
      tpdReportData.thirdParty = row.thirdParty;
      tpdReportData.locationName = row.name;
      tpdReportData.stateProvince = location.addressState;
      tpdReportData.mfTaxApplicable = this.isMfTaxApplicable(location, row.thirdParty);
      tpdReportData.locationTaxRate = row.locationTaxRate;

      //POS SALES
      tpdReportData.posGrossSales = row.posGrossSales;
      tpdReportData.posSalesAdjustments = row.posSalesAdjustments;
      tpdReportData.posNetSales = row.posNetSales;
      tpdReportData.posOtherRevenue = row.posOtherRevenue;
      //3PD Sales
      tpdReportData.thirdPartyGrossSales = row.thirdPartyGrossSales;
      tpdReportData.thirdPartySalesAdjustments = row.thirdPartySalesAdjustments;
      tpdReportData.thirdPartyNetSales = row.thirdPartyNetSales;
      tpdReportData.thirdPartyOtherRevenue = row.thirdPartyOtherRevenue;

      tpdReportData.salesVariance = this.getVariance(row.posNetSales, row.thirdPartyNetSales);
      //TIPS
      tpdReportData.posTips = row.posTips;
      tpdReportData.thirdPartyTips = row.thirdPartyTips;
      //OTHER CHARGES
      tpdReportData.posOtherCharges = row.posOtherCharges;
      tpdReportData.thirdPartyOtherCharges = row.thirdPartyOtherCharges;
      //POS TAX
      tpdReportData.posGrossTax = row.posGrossTax;
      tpdReportData.posTaxAdjustments = row.posTaxAdjustments;
      tpdReportData.posNetTax = row.posNetTax;
      //3PD Tax
      tpdReportData.thirdPartyGrossTax = row.thirdPartyGrossTax;
      tpdReportData.thirdPartyTaxAdjustments = row.thirdPartyTaxAdjustments;
      tpdReportData.thirdPartyNetTax = row.thirdPartyNetTax;
      tpdReportData.marketFacilitatorTax = row.marketFacilitatorTax;
      tpdReportData.effectiveMfRate = row.effectiveMfRate;
      tpdReportData.thirdPartyEffectiveTaxRate = row.thirdPartyEffectiveTaxRate;
      //
      tpdReportData.taxVariance = this.getVariance(row.posNetTax, row.thirdPartyNetTax);
      tpdReportData.taxRateVariance = this.getVariance(row.thirdPartyEffectiveTaxRate, row.locationTaxRate, 4);
      //
      // Delivery Fees
      tpdReportData.deliveryFees = row.deliveryFees;
      tpdReportData.effectiveDeliveryFeeRate = row.effectiveDeliveryFeeRate;
      tpdReportData.otherFees = row.otherFees;
      //Estimations
      tpdReportData.expectedMfTax = row.expectedMfTax;
      tpdReportData.expectedDeliveryFee = row.expectedDeliveryFee
      tpdReportData.clientTaxResponsibility = row.taxResponsibility;
      tpdReportData.effectiveClientTaxResponsibilityRate = row.effectiveClientTaxResponsibilityRate;
      tpdReportData.suggestedTaxAdjustment = row.taxAdjustment;
      //Remittance
      tpdReportData.expectedRemittance = row.expectedRemittance;
      tpdReportData.actualRemittance = row.actualRemittance;
      tpdReportData.remittanceVariance = this.getVariance(row.expectedRemittance, row.actualRemittance);
      return tpdReportData.toJSONObject();
    });
  }
  getSize(obj) {
    const size = new TextEncoder().encode(JSON.stringify(obj)).length
    const kiloBytes = size / 1024;
  }

  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState$.locations, [UserRoles.admin])
      .subscribe(locations$ => {
        this.locations = locations$.filter(location => !!location.active).sort((a, b) => a.locationId < b.locationId ? -1 : 1);
        this.loadingParameters = false;
      });
  }

  public getReportProgressPercentage() {
    if (this.completeFragments.length > 0) {
      return +((this.completeFragments.length / this.reportFragments.length) * 100).toFixed(0)
    }
    return 0;
  }
  selectAllThirdParties() {
    if (!this.allThirdPartiesSelected) {
      this.allThirdPartiesSelected = true;
      this.parametersForm.get('selectedThirdParties').patchValue([0, ...this.thirdParties.map(tp => tp.id)])
    } else {
      this.allThirdPartiesSelected = false;
      this.parametersForm.get('selectedThirdParties').patchValue([]);
    }
    this.parametersForm.get('selectedThirdParties').updateValueAndValidity()
  }

  async saveReport() {
    const selectedFields = this.reconciliationTable ? this.reconciliationTable.selectedFields : this.defaultSelectedFields;
    await this.updateReport(selectedFields);
  }

  private async updateReport(selectedFields) {
    if (this.parametersForm.valid) {
      const formValues = this.parametersForm.value;
      await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`)
        .update({
          locations: formValues.selectedLocations,
          thirdParties: formValues.selectedThirdParties,
          startDate: moment(formValues.startDate).toDate(),
          endDate: moment(formValues.endDate).toDate(),
          selectedFields,
          status: ThirdPartyReportStatuses.running
        });
      this.snackBar.open('Report updated successfully.', 'Dismiss', {
        duration: 5000
      });
    } else {
      this.snackBar.open('Please complete all required report parameters.')
    }
  }
  async saveReportName() {
    if (this.reportName.valid) {
      await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`)
        .update({
          name: this.reportName.value
        });
      this.snackBar.open('Report name updated successfully.', 'Dismiss', { duration: 5000 });
    } else {
      this.snackBar.open('Please provide a name for the report.', 'Dismiss', { duration: 5000 });
    }
  }

  getThirdPartyName(thirdPartyId: string) {
    if (thirdPartyId) {
      const tp = this.thirdParties.find(_tp => !!_tp && _tp.id === thirdPartyId);
      return tp ? tp.name : '';
    }
    return '';
  }

  getLocationName(locationId: string) {
    const location = this.locations.find(l => l.locationId === locationId);
    return location ? location.name : ''
  }

  private getExistingReport() {
    this.afs.doc(`thirdPartyReports/${this.thirdPartyReportId}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(report$ => {
        this.existingReport = <ThirdPartyReport>FirestoreUtilities.objectToType(report$);
        this.reportName.patchValue(this.existingReport.name);
        this.reportName.updateValueAndValidity();
        if (this.existingReport) {
          if (this.existingReport.reportData) {
            this.reportData = this.existingReport.reportData;
            this.reportAvailable = true;
          }
          this.setDefaultFields();
          this.setupParametersForm();
          this.getReportFragments();
        }
      }, () => {
        this.snackBar.open('Oops... something went wrong loading your report. Please refresh to try again.', 'Dismiss', {
          duration: 5000
        })
      }, () => {
      });
  }
  private getReportFragments() {
    this.afs.collection('thirdPartyReportFragments', ref => ref
      .where('thirdPartyReport', '==', this.existingReport.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$)).subscribe(result$ => {
        this.reportFragments = <ThirdPartyReportFragment[]>FirestoreUtilities.mapToType(result$);
        this.completeFragments = this.reportFragments.filter(fragment => fragment.status === 'done');
        this.errorFragments = this.reportFragments.filter(fragment => fragment.status == 'error');
        this.loadingReportFragments = false;
        if (this.errorFragments.length > 0) {
          debugger;
          this.errorReconcilingData = true;
        } else if (this.completeFragments.length > 0 && (this.completeFragments.length === this.reportFragments.length)) {
          const fullReconciliation = _.flatten(this.completeFragments.map(fragment => fragment['reconciliation']));
          // this.reportData = this.flattenReportData(fullReconciliation);
          this.reportData = fullReconciliation;
          this.determineErrors();
        }
      })
  }
  private async compileDataForReconciliation(allTransactions: { posTransactions: any[], tpdTransactions: any[] }) {
    const reportLocations = this.locations.filter(location => this.parametersForm.get('selectedLocations').value.find(locationId => locationId === location.locationId));
    const reportThirdParties = this.thirdParties.filter(thirdParty => this.parametersForm.get('selectedThirdParties').value.find(tpId => tpId === thirdParty.id));
    const reportThirdPartyLocations = reportThirdParties.reduce((rows: LocationReport[], thirdParty: ThirdParty) => {
      const thirdPartyLocationReports: LocationReport[] = [];
      reportLocations.forEach(location => {
        const locationReport = Object.assign(new LocationReport(), location);
        locationReport.posTransactions = allTransactions.posTransactions
          .filter(posTransaction => {
            return posTransaction['location'] === location.locationId && posTransaction['account'] === thirdParty.id;
          });
        locationReport.thirdPartyTransactions = allTransactions.tpdTransactions
          .filter(thirdPartyTransaction => {
            return thirdPartyTransaction.location === location.locationId && thirdPartyTransaction.thirdParty === thirdParty.id;
          });
        locationReport.thirdParty = thirdParty.id;
        locationReport.startDate = this.parametersForm.get('startDate').value;
        locationReport.endDate = this.parametersForm.get('endDate').value;
        thirdPartyLocationReports.push(locationReport);
      });
      return [...rows, ...thirdPartyLocationReports]
    }, []);
    reportThirdPartyLocations.forEach(row => {
      row.reportId = this.afs.createId();
    });
    await this.analyticsEngine.setLocationReportData(reportThirdPartyLocations);
    const summaryData = this.flattenReportData(reportThirdPartyLocations);
    await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`).update({
      reportData: summaryData,
      lastRun: moment().toDate()
    });
    this.snackBar.open('Report run complete', 'Dismiss', { duration: 5000 })
    setTimeout(() => {
      console.log('activating rec table')
      this.reportData = summaryData;
    });
    this.loadingService.isLoading(false);

  }
  private determineErrors() {
    if (this.reportData) {
      this.clearErrorTotals();
      if (this.client3pdConfiguration) {
        this.reportData.forEach((locationData: ThirdPartyReconciliationLocationData) => {
          locationData['taxErrors'] = this.client3pdConfiguration.taxVarianceThreshold > 0 && Math.abs(locationData.taxVariance) >= this.client3pdConfiguration.taxVarianceThreshold
          locationData['salesErrors'] = this.client3pdConfiguration.salesVarianceThreshold > 0 && Math.abs(locationData.salesVariance) >= this.client3pdConfiguration.salesVarianceThreshold
          locationData['remittanceErrors'] = this.client3pdConfiguration.remittanceVarianceThreshold > 0 && Math.abs(locationData.remittanceVariance) >= this.client3pdConfiguration.remittanceVarianceThreshold
          locationData['taxRateErrors'] = this.client3pdConfiguration.taxRateVarianceThreshold > 0 && Math.abs(locationData.taxRateVariance) >= this.client3pdConfiguration.taxRateVarianceThreshold
          if (locationData['salesErrors']) { this.salesErrorTotal++ }
          if (locationData['remittanceErrors']) { this.remittanceErrorTotal++ }
          if (locationData['taxErrors']) { this.taxErrorTotal++ }
          if (locationData['taxRateErrors']) { this.taxRateErrorTotal++ }
        });
        this.totalErrors = this.salesErrorTotal + this.taxErrorTotal + this.taxRateErrorTotal + this.remittanceErrorTotal;
      }

    }
  }
  private clearErrorTotals() {
    this.reportData.forEach((locationData: ThirdPartyReconciliationLocationData) => {
      locationData['taxErrors'] = 0
      locationData['salesErrors'] = 0
      locationData['remittanceErrors'] = 0
      locationData['taxRateErrors'] = 0
    });
    this.salesErrorTotal = 0;
    this.taxErrorTotal = 0;
    this.remittanceErrorTotal = 0;
    this.taxRateErrorTotal = 0;
  }
  private setupParametersForm() {
    this.parametersForm = this.fb.group({
      selectedThirdParties: new FormControl(this.existingReport.thirdParties ? this.existingReport.thirdParties : [], Validators.required),
      selectedLocations: new FormControl(this.existingReport.locations ? this.existingReport.locations : [], Validators.required),
      startDate: new FormControl(this.existingReport.startDate ? this.existingReport.startDate.toDate() : [], Validators.required),
      endDate: new FormControl(this.existingReport.endDate ? this.existingReport.endDate.toDate() : [], Validators.required)
    })
  }
  private setDefaultFields() {
    if (!!this.existingReport.selectedFields && this.existingReport.selectedFields.length > 0) {
      this.defaultSelectedFields = this.existingReport.selectedFields;
    } else if (this.client3pdConfiguration && this.client3pdConfiguration.defaultFields) {
      this.defaultSelectedFields = this.client3pdConfiguration.defaultFields;
    }
  }

  getReportLocations() {
    return this.locations.filter(location => this.parametersForm.get('selectedLocations').value.find(locationId => locationId === location.locationId));
  }

  getReportThirdParties() {
    return this.thirdParties.filter(tp => this.parametersForm.get('selectedThirdParties').value.find(tpid => tpid === tp.id));
  }
  getReportMinDate() {
    return moment(this.parametersForm.get('startDate').value).toDate();
  }

  getReportMaxDate() {
    return moment(this.parametersForm.get('endDate').value).toDate();
  }

  getMaxEndDate() {
    if (this.parametersForm.get('startDate').valid) {
      return moment(this.parametersForm.get('startDate').value).add(2, 'month').toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(this.parametersForm.get('startDate').value);
      if (!controlDate.isValid()) {
        return null;
      }
      const validationDate = moment(date);
      return controlDate.isSameOrBefore(validationDate) ? null : {
        'date-minimum': {
          'date-minimum': validationDate.toDate(),
          'actual': controlDate.toDate()
        }
      };
    };
  }


  //Flattening functions
  private isMfTaxApplicable(location: Location, thirdPartyId: string) {
    const thirdParty = location['thirdParties'].find(tp => tp.thirdParty === thirdPartyId);
    return !!thirdParty ? !!thirdParty.mfApplicable : false;
  }
  getVariance(a: number, b: number, decimals = 2) {
    return +(a - b).toFixed(decimals);
  }
  isReportOutOfDate() {
    let outOfDate = false;
    const lastRunMoment = moment(this.existingReport.lastRun.toDate());
    if (this.existingReport.lastRun && this.client3pdConfiguration && this.client3pdConfiguration.dateUpdated) {
      outOfDate = lastRunMoment.isBefore(this.client3pdConfiguration.dateUpdated.toDate())
      if (!!outOfDate) {
        return outOfDate;
      }
    }
    if (this.existingReport.lastRun && this.client3pdTransactionStatusConfigurations) {
      for (let statusConfig of this.client3pdTransactionStatusConfigurations) {
        if (statusConfig && statusConfig.dateUpdated) {
          outOfDate = lastRunMoment.isBefore(moment(statusConfig.dateUpdated.toDate()))
          if (!!outOfDate) {
            break;
          }
        }
      }
      return outOfDate;
    }
  }
}
