import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { Client, UserRoles, ThirdParty, User, PosSystem, Location } from '@deliver-sense-librarian/data-schema';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { Title } from '@angular/platform-browser';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { HttpClient } from '@angular/common/http';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { Store } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { Conversion } from '@deliver-sense-librarian/data-schema/dist/models/interfaces';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import * as _ from 'lodash';
import { scrollbarOptions, tablescrollbarOptions } from '../../shared/ds-constant';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { Papa } from 'ngx-papaparse';

export class DataUploadDialogResponse {
  fileName: string;
  fileSize: number;
  thirdParty?: any;
  posSystem?: any;
  data: any;
  testMode?: boolean;
}
@Component({
  selector: 'app-data-upload-dialog',
  templateUrl: './data-upload-dialog.component.html',
  styleUrls: ['./data-upload-dialog.component.scss']
})
export class DataUploadDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  selectedTransactionType = new FormControl('', Validators.required);
  selectedThirdParty = new FormControl('', Validators.required);
  selectedPos = new FormControl('', Validators.required);
  testMode = new FormControl(false);
  scrollbarOptions = tablescrollbarOptions;
  transactionTypes = [
    'POS',
    '3PD',
    'Remittance'
  ];
  user: User;
  client: Client;
  uiState: UiState;
  expectedFields = [];
  posSystems: PosSystem[] = [];
  thirdParties: ThirdParty[] = [];
  locations: Location[] = [];
  requiredFields: any[];
  transactionConversions: Conversion[] = [];
  dataToUpload: any;
  fileToUpload: any;
  constructor(
    public dialogRef: MatDialogRef<DataUploadDialogComponent>,
    private store: Store<any>,
    private afs: AngularFirestore,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private papa: Papa,
    private loadingService: LoadingDialogService,
    private http: HttpClient,
    public auth: FirebaseAuthService) { }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getRelevantResources();
          this.setupListeners();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private getRelevantResources() {
    combineLatest([
      this.afs.collection('posSystems').snapshotChanges(),
      this.afs.collection('thirdParties').snapshotChanges(),
      FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState.locations, [UserRoles.admin, UserRoles.contributor])
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([pos$, thirdParties$, locations]) => {
        this.locations = locations;
        this.posSystems = FirestoreUtilities.mapToType(pos$);
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
      })
  }
  private setupListeners() {
    this.selectedTransactionType.valueChanges.subscribe(() => {
      this.selectedPos.patchValue(null);
      this.selectedPos.updateValueAndValidity();
      this.selectedThirdParty.patchValue(null);
      this.selectedThirdParty.updateValueAndValidity();
      this.transactionConversions = [];
    });
    this.selectedThirdParty.valueChanges.subscribe(selectedThirdParty$ => {
      if (selectedThirdParty$) {
        this.getThirdPartyTransactionConversionValues();
      }
    });
    this.selectedPos.valueChanges.subscribe(selectedPos$ => {
      if (selectedPos$) {
        this.getPosTransactionConversionValues();
      }
    });
  }

  private triggerUpload() {
    const uploadResponse = new DataUploadDialogResponse();
    uploadResponse.data = this.dataToUpload;
    if (this.selectedPos.value) {
      uploadResponse.posSystem = this.selectedPos.value;
    }
    if (this.selectedThirdParty.value) {
      uploadResponse.thirdParty = this.selectedThirdParty.value;
    }
    if (this.testMode.value) {
      uploadResponse.testMode = this.testMode.value;
    }
    uploadResponse.fileName = this.fileToUpload.name;
    uploadResponse.fileSize = this.fileToUpload.size;
    this.dialogRef.close(uploadResponse);
  }
  async detectFiles(event) {
    this.titleService.setTitle('DeliverSense')
    if (event.target.files && event.target.files[0]) {
      this.loadingService.isLoading(true, 'Preparing for upload...');
      const csvData = event.target.files[0];
      this.fileToUpload = csvData;
      const lastDot = csvData.name.lastIndexOf('.');
      const ext = csvData.name.substring(lastDot + 1);
      if (csvData && (ext === 'csv')) {
        await this.parseData(csvData);
        this.loadingService.isLoading(false);
        this.triggerUpload();
      } else {
        this.loadingService.isLoading(false);
        this.transactionConversions = [];
        this.dialog.open(InformationDialogComponent, {
          panelClass: 'invisible-panel-dialog',
          data: {
            title: 'ERROR: Incorrect File Format',
            message: 'The transaction file must be a .csv file'
          }
        })
      }
    }
  }
  private async parseData(csvData) {
    return new Promise(resolve => {
      this.papa.parse(csvData, {
        header: true,
        worker: true,
        skipEmptyLines: true,
        complete: async (result) => {
          const parsedData = result.data;
          this.dataToUpload = this.reducePostingData(parsedData);
          resolve(true);
        }
      });
    })
  }
  private getPosTransactionConversionValues() {
    const posSystem = this.posSystems.find(pos => pos.code === this.selectedPos.value);
    this.afs.collection('clientPosTransactionConversions', ref => ref
      .where('client', '==', this.client.id)
      .where('posSystem', '==', posSystem.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientPosTransactionConversions$ => {
        this.transactionConversions = FirestoreUtilities.mapToType(clientPosTransactionConversions$);
        this.mapExpectedFields();
      })
  }
  private getThirdPartyTransactionConversionValues() {
    const thirdParty = this.thirdParties.find(tp => tp.code === this.selectedThirdParty.value) as ThirdParty;
        this.transactionConversions = thirdParty.reportConversionMappings;
        this.mapExpectedFields();
  }
  public isRequiredField(field) {
    return _.includes(this.requiredFields, field);
  }

  private mapExpectedFields() {
    this.expectedFields = [];
    this.requiredFields = [];
    const requiredKeyOuts = this.selectedTransactionType.value === 'POS' ? ['location', 'date', 'id', 'account'] : ['location, date, id'];
    this.transactionConversions.forEach((conversion: Conversion) => {
      const isRequiredConversion = _.includes(requiredKeyOuts, conversion.keyOut);
      if (conversion.algorithm && conversion.algorithm[0]) {
        conversion.algorithm[0].fields.forEach(field => {
          if (!this.expectedFields.find(existingField => existingField === field)) {
            this.expectedFields.push(field);
            if (isRequiredConversion) {
              this.requiredFields.push(field);
            }
          }
        })
      } else {
        if (!this.expectedFields.find(existingField => existingField === conversion.keyIn)) {
          this.expectedFields.push(conversion.keyIn);
          if (isRequiredConversion) {
            this.requiredFields.push(conversion.keyIn);
          }
        }
      }
    });
  }
  private reducePostingData(parsedData: any) {
    let rowCount = 2; // start @ 2 to account for headers row. should represent the exact row of the data in csv
    const reducedData = parsedData.map(record => {
      const reducedObject = {};
      Object.keys(record).forEach(key => {
        const keyExpected = this.expectedFields.find(fieldKey => fieldKey === key);
        if (keyExpected) {
          reducedObject[key] = record[key] ? record[key] : null;
        }
      });
      reducedObject['batchRow'] = rowCount;
      rowCount++;
      return reducedObject;
    }).filter(item => {
      const empty = this.isItemEmpty(item);
      return !empty;
    });
    return reducedData;
  }


  private isItemEmpty(item) {
    const keys = Object.keys(item);
    const emptyValues = keys.map(key => item[key] === undefined || item[key] === null).filter(isEmpty => !!isEmpty);
    // the item is empty if the number of empty values is equal to the number of keys in the object
    return emptyValues.length === keys.length
  }
}
