import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineAll, map, takeUntil, first } from "rxjs/operators";
import { combineLatest, from, Subject } from "rxjs";
import { PosTransaction, User, ExemptionCertificate, Client, Location, PosSystem, ThirdParty, UserRoles } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from '@ngrx/store';
import { tableExpandAnimation } from "../../../shared/ds-constant";
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { LoadingDialogService } from '../../../services/loading-dialog.service';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { transition } from '@angular/animations';
import { UiState } from '../../../redux/custom-states/uiState/ui-state';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { report } from 'process';

@Component({
  selector: 'app-transaction-lookup',
  templateUrl: './transaction-lookup.component.html',
  styleUrls: ['./transaction-lookup.component.scss'],
  animations: tableExpandAnimation
})
export class TransactionLookupComponent implements OnInit, OnDestroy {
  public locations: Location[];
  private user: User;
  private destroy$ = new Subject();
  public transactions: PosTransaction[];
  public selectedTransactionType = new FormControl('', Validators.required);
  public selectedThirdParty = new FormControl('', Validators.required);
  public selectedPos = new FormControl('', Validators.required);
  public selectedLocation = new FormControl([], Validators.required);
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public storagePath: any;
  public noTransactions = false;
  public posSystems: PosSystem[] = [];
  public thirdParties: ThirdParty[] = [];
  transactionTypes = [
    'POS',
    '3PD',
    'Remittance'
  ];
  private client: Client;
  private uiState$: UiState;
  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState$ = uiState$;
          this.setupParametersForm();
          this.getLocations();
          this.getRelevantResources();
          this.setupListeners();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private setupParametersForm() {
    // this.parametersForm = this.fb.group({

    // })
  }
  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState$.locations, [3])
      .subscribe(locations$ => {
        this.locations = locations$.sort((a, b) => a.locationId < b.locationId ? -1 : 1);
      });
  }
  private getRelevantResources() {
    combineLatest([
      this.afs.collection('posSystems').snapshotChanges(),
      this.afs.collection('thirdParties').snapshotChanges(),
      FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState$.locations, [UserRoles.admin, UserRoles.contributor])
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([pos$, thirdParties$, locations]) => {
        this.locations = locations;
        this.posSystems = FirestoreUtilities.mapToType(pos$);
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
      })
  }

  private setupListeners() {
    this.selectedTransactionType.valueChanges.subscribe(() => {
      this.selectedPos.patchValue(null);
      this.selectedPos.updateValueAndValidity();
      this.selectedThirdParty.patchValue(null);
      this.selectedThirdParty.updateValueAndValidity();
    });

  }

  runSearch() {
    this.transactions = null;
    setTimeout(() => {
      const reportLocationId = this.selectedLocation.value
      const start = this.startDate.value;
      const end = this.endDate.value;
      const thirdParty = this.selectedThirdParty.value.id;
      const posSystem = this.selectedPos.value.id;
      const clientId = this.client.id;
      this.loadingService.isLoading(true, 'Pulling transactions...');
      this.afs.collection('posTransactions', ref => ref
        .where('location', '==', reportLocationId)
        .where('account', '==', thirdParty)
        .where('client', '==', clientId)
        .where('posSystem', '==', posSystem)
        .where('date', '<=', end)
        .where('date', '>=', start))
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe(transactions$ => {
          this.loadingService.isLoading(false);
          this.transactions = FirestoreUtilities.mapToType(transactions$);
          if (this.transactions.length < 1) {
            this.noTransactions = true;
          }
        }, (e) => {
          console.error(e);
          this.loadingService.isLoading(false);
          this.snackBar.open('Oops... something went wrong! Please refresh and try again.', 'Dismiss', { duration: 5000 });
        })
    })
  }



  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(3, 'month').toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(this.startDate.value);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate) ? null : {
        'date-minimum': {
          'date-minimum': validationDate.toDate(),
          'actual': controlDate.toDate()
        }
      };
    };
  }
}
