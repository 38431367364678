import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AuditTrailDocumentSectionComment, AuditTrailUserSectionCommentFlag, AuditTrailDocumentSection, AuditTrailDocument, AuditTrailDocumentSectionCommentStatuses, UserView } from '@deliver-sense-librarian/data-schema';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { Subject, from, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil, combineAll } from 'rxjs/operators';
import { AuditTrailDocumentCommentDialogComponent } from 'app/dialogs/audit-trail-document-comment-dialog/audit-trail-document-comment-dialog.component';
import moment from 'moment';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';

@Component({
  selector: 'app-audit-trail-viewer-messages',
  templateUrl: './audit-trail-viewer-messages.component.html',
  styleUrls: ['./audit-trail-viewer-messages.component.scss']
})
export class AuditTrailViewerMessagesComponent implements OnInit, OnDestroy {
  @Input() auditTrailDocumentSectionCommentId: string;
  @Input() auditTrailDocumentSectionId: string;
  @Input() auditTrailDocumentId: string;
  @Output() onCommentSelect = new EventEmitter();
  public uiState: UiState;
  public selectedComment: AuditTrailDocumentSectionComment;
  public selectedSection: AuditTrailDocumentSection;
  public auditTrailDocument: AuditTrailDocument;
  private destroy$ = new Subject();
  private clientUsers: UserView[] = [];

  constructor(private store: Store<any>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.uiState = uiState$;
        }
      });
    this.fetchResources();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private fetchResources() {
    combineLatest([
      this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}/comments/${this.auditTrailDocumentSectionCommentId}`).snapshotChanges(),
      this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}`).snapshotChanges(),
      this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}`).snapshotChanges(),
      this.afs.collection('userViews', ref => ref
        .where('clients', 'array-contains', this.uiState.client.id))
        .snapshotChanges()
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([comment$, section$, auditTrailDocument$, clientUsers$]) => {
        this.selectedComment = FirestoreUtilities.objectToType(comment$);
        this.selectedSection = FirestoreUtilities.objectToType(section$);
        this.auditTrailDocument = FirestoreUtilities.objectToType(auditTrailDocument$);
        this.clientUsers = FirestoreUtilities.mapToType(clientUsers$);
        this.setCommentMetadata();
      });
  }

  private setCommentMetadata() {
    if (this.selectedComment) {
      this.selectedComment['assigneeName'] = this.getCommentAssigneeName();
      this.selectedComment['creatorName'] = this.getCommentCreatorName();
      this.selectedComment['approvalInfo'] = this.getCommentApprovalInfo();
      this.selectedComment['statusText'] = this.getCommentStatus();
    }
  }

  private getCommentAssigneeName() {
    const userMatch = this.selectedComment ? this.clientUsers.find(userView => userView.id === this.selectedComment.assignee) : null;
    return userMatch ? `${userMatch.firstName} ${userMatch.lastName}` : '';
  }

  private getCommentApprovalInfo() {
    const userMatch = this.selectedComment ? this.clientUsers.find(userView => userView.id === this.selectedComment.approvedBy) : null;
    return userMatch ?
      `${userMatch.firstName} ${userMatch.lastName} on ${this.selectedComment.approvedOn ? moment(this.selectedComment.approvedOn.toDate()).format('M/D/YY') : ''}`
      : '';
  }

  private getCommentCreatorName() {
    const userMatch = this.selectedComment ? this.clientUsers.find(userView => userView.id === this.selectedComment.creator) : null;
    return userMatch ? `${userMatch.firstName} ${userMatch.lastName}` : '';
  }
  private getCommentStatus() {
    if (this.selectedComment) {
      switch (this.selectedComment.status) {
        case AuditTrailDocumentSectionCommentStatuses.closed:
          return 'Closed';
        case AuditTrailDocumentSectionCommentStatuses.open:
          return 'Open';
        case AuditTrailDocumentSectionCommentStatuses.cancelled:
          return 'Cancelled';
        case AuditTrailDocumentSectionCommentStatuses.ready:
          return 'Ready for Review';
      }
    }
  }
  editComment() {
    this.dialog.open(AuditTrailDocumentCommentDialogComponent, {
      data: {
        comment: this.selectedComment,
        documentId: this.auditTrailDocument.id,
        sectionId: this.selectedSection.id,
        projectId: this.auditTrailDocument.project
      }
    });
  }

}
