import {Component, Inject, OnInit} from '@angular/core';
import {AuditTrailDocument, AuditTrailDocumentSection, AuditTrailDocumentSectionComment} from "@deliver-sense-librarian/data-schema";
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-audit-trail-document-dialog',
  templateUrl: './audit-trail-document-dialog.component.html',
  styleUrls: ['./audit-trail-document-dialog.component.scss']
})
export class AuditTrailDocumentDialogComponent implements OnInit {
  public document: AuditTrailDocument;

  constructor(private afs: AngularFirestore,
              public dialogRef: MatDialogRef<AuditTrailDocumentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.document = data.document;
  }

  ngOnInit() {
  }

}
