<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="client">
      <h2>{{client.name}}</h2>
    </mat-card-title>
    <mat-card-subtitle>
      <h6>Manage client account settings below.</h6>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
<mat-card>
  <mat-tab-group>
    <mat-tab [label]="'Basic Information'">
      <form [formGroup]="clientForm">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" *ngIf="clientForm">
          <div fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="space-evenly start">
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>Company Name</mat-label>
                <input matInput placeholder="Client Name" formControlName="name">
              </mat-form-field>
            </div>
            <h4><b>Company Logo</b></h4>
            <div class="header-logo">
              <img *ngIf="client && client.logo" [src]="client.logo" />
            </div>
            Set your logo:
            <app-document-upload [storagePath]="storagePath" [compressFile]="false"
              (imageUploadComplete)="logoUploaded($event)"></app-document-upload>
          </div>
          <div fxFlex="70">
            <div fxLayout="row" fxLayoutAlign="center center">
              <h3>Address</h3>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>Street Address</mat-label>
                <input matInput placeholder="Address Line 1" formControlName="addressLine1">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>Street Address Line 2</mat-label>
                <input matInput placeholder="Address Line 2" formControlName="addressLine2">
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput placeholder="City" formControlName="addressCity">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <mat-select formControlName="addressState">
                  <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Postal Code</mat-label>
              <input matInput placeholder="Postal Code" formControlName="addressPostalCode">
            </mat-form-field>
            <div fxLayout="row">
              <button mat-raised-button color="primary" (click)="save()">Save</button>
            </div>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab [label]="'Modules and Billing'">
      <ng-template matTabContent>
        <app-client-billing [client]="client" [user]="user"></app-client-billing>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Security & Dev'">
      <div fxLayout="column" fxLayoutGap="5px">
        <h2><b>Security Settings</b></h2>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <mat-checkbox [matTooltip]="!client.sfaEnabled ? 'Second factor authentication is an add-on feature. Please contact your DeliverSense account manager to learn more.' : null " [disabled]="!client.sfaEnabled" [checked]="client.sfaRequired" (change)="updateSfaRequired($event)">
              Require Second Factor Authentication
            </mat-checkbox>
          </div>
          <div>
            <mat-icon fontSet="fas" fontIcon="fa-info-circle" matTooltip="When this is checked all users will be required to complete a second factor authentication challenge after login and before accessing your client's DeliverSense page.">
            </mat-icon>
          </div>
          <!-- <div *ngIf="client.sfaRequired">
            <mat-form-field appearance="outline">
              <mat-label>SFA Expiration</mat-label>
              <mat-select [(ngModel)]="client.sfaExpiration">
                <mat-option [value]="undefined">No Expiration</mat-option>
                <mat-option *ngFor="expiration"
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>
        <h2><b>API Keys</b></h2>
        <div fxLayout="column">
          <h4>User your key and secret to access the DeliverSense Open API.</h4>
          <a href="https://deliversense.com/docs/api" target="_blank">
            API Documentation
            <mat-icon fontSet="fas" fontIcon="fa-book"></mat-icon>
          </a>
        </div>
        <div fxLayout="column">
          <mat-form-field appearance="outline" class="developer-client-credential">
            <mat-label>Client ID</mat-label>
            <input matInput disabled [value]="client.clientId">
          </mat-form-field>
          <mat-form-field appearance="outline" class="developer-client-credential">
            <mat-label>Client Secret</mat-label>
            <input matInput disabled [type]="secretType" [value]="client.clientSecret">
            <ng-container *ngIf="secretType === 'password'; else eyeSlash">
              <button mat-icon-button matSuffix (click)="secretType = 'text'">
                <mat-icon fontSet="fas" fontIcon="fa-eye"></mat-icon>
              </button>
            </ng-container>
            <ng-template #eyeSlash>
              <button *ngIf="secretType === 'text'" mat-icon-button matSuffix (click)="secretType = 'password'">
                <mat-icon fontSet="fas" fontIcon="fa-eye-slash"></mat-icon>
              </button>
            </ng-template>
          </mat-form-field>
          <div fxLayout="row">
            <mat-icon fontSet="fas" fontIcon="fa-info-circle" matTooltip="Include this as 'liveKey' parameter when pushing data via the DeliverSense api"></mat-icon>
            <mat-form-field appearance="outline" class="developer-client-credential">
              <mat-label>Client Live Key </mat-label>
              <input matInput disabled [type]="liveKeyType" [value]="client.clientLiveKey">
              <ng-container *ngIf="liveKeyType === 'password'; else eyeSlash">
                <button mat-icon-button matSuffix (click)="liveKeyType = 'text'">
                  <mat-icon fontSet="fas" fontIcon="fa-eye"></mat-icon>
                </button>
              </ng-container>
              <ng-template #eyeSlash>
                <button *ngIf="liveKeyType === 'text'" mat-icon-button matSuffix (click)="liveKeyType = 'password'">
                  <mat-icon fontSet="fas" fontIcon="fa-eye-slash"></mat-icon>
                </button>
              </ng-template>
            </mat-form-field>
          </div>
        </div>
        <!-- <h3>Redirect URLs</h3>
        <h4>If your company is using the single sign on service please add your redirect urls here.</h4>
        <div *ngFor="let uri of client.redirectUris" fxLayout="row">
          {{uri}}
          <button mat-mini-fab (click)="removeUri(uri)" color="warn">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <button mat-raised-button (click)="addingUri = true" color="primary" *ngIf="!addingUri">Add URL</button>
        <ng-container *ngIf="addingUri">
          <mat-form-field appearance="outline">
            <mat-label>New URI</mat-label>
            <input matInput [formControl]="newUri" />
          </mat-form-field>
          <div fxLayout="row">
            <button mat-raised-button (click)="saveNewUri()" color="accent">Save</button>
            <button mat-raised-button (click)="addingUri = false" color="accent">Cancel</button>
          </div>
        </ng-container> -->
      </div>
    </mat-tab>
  </mat-tab-group>

</mat-card>
