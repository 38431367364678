import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import {loadStripe} from '@stripe/stripe-js';
@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss']
})
export class StripeCardComponent implements OnInit, AfterViewChecked, OnDestroy {
  stripeStyle = {
    base: {
      color: '#32325d',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };
  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  stripe: any;
  elements: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  private cardInfoInitialized = false;
  public defaultSource: any | any;

  constructor(private cd: ChangeDetectorRef) {
    this.initStripe();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  ngAfterViewChecked() {
    if (!this.cardInfoInitialized && this.cardInfo && this.elements) {
      this.card = this.elements.create('card');
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
      this.cardInfoInitialized = true;
    }
  }
  async initStripe() {
    this.stripe = await loadStripe(environment.stripePublicKey);
    this.elements = this.stripe.elements();
  }
  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  getCard() {
    return this.card;
  }

}
