import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuditTrailDocumentSection, AuditTrailDocumentSectionCommentStatuses, User } from '@deliver-sense-librarian/data-schema';
import { Store } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AuditTrailDocumentSectionDialogComponent } from 'app/dialogs/audit-trail-document-section-dialog/audit-trail-document-section-dialog.component';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';

@Component({
  selector: 'app-audit-trail-viewer-sections',
  templateUrl: './audit-trail-viewer-sections.component.html',
  styleUrls: ['./audit-trail-viewer-sections.component.scss']
})
export class AuditTrailViewerSectionsComponent implements OnInit, OnDestroy {
  @Input() auditTrailDocumentId: string;
  @Input() sectionsFilter: FormControl;
  @Output() onSectionSelected = new EventEmitter();
  @Output() onCountTotalsUpdated = new EventEmitter();
  private destroy$ = new Subject();
  public sections: AuditTrailDocumentSection[] = [];
  public filteredSections: AuditTrailDocumentSection[] = [];
  public loadingSections = false;
  public statues = [
    { id: AuditTrailDocumentSectionCommentStatuses.closed, value: 'Closed' },
    { id: AuditTrailDocumentSectionCommentStatuses.ready, value: 'Ready For Review' },
    { id: AuditTrailDocumentSectionCommentStatuses.open, value: 'Open' }
  ]
  public uiState: UiState;
  public sectionIdSelection = [];
  public loadingSectionsError = false;
  public selectedSection: AuditTrailDocumentSection;
  ;
  constructor(private store: Store<any>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.uiState = uiState$;
        }
      });
    this.fetchDocumentSections();
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private mapSectionForComparison(section: AuditTrailDocumentSection) {
    return {
      auditTrailDocument: section.auditTrailDocument,
      name: section.name,
      notes: section.notes,
      order: section.order,
      totalOpen: section.totalOpen,
      totalClosed: section.totalClosed,
      totalReady: section.totalReady,
      totalCancelled: section.totalCancelled
    };
  }
  private fetchDocumentSections() {
    this.loadingSections = true;
    this.afs.collection(`auditTrailDocuments/${this.auditTrailDocumentId}/sections`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$)
      ).subscribe(sections$ => {
        const sections = <AuditTrailDocumentSection[]>FirestoreUtilities.mapToType(sections$).sort((a, b) => {
          return !a.order ? -1 : !b.order ? 1 : a.order < b.order ? -1 : a.order === b.order ? 0 : 1;
        });
        if (this.sections.length === 0 ||
          (JSON.stringify(sections.map((section) => {
            return this.mapSectionForComparison(section);
          })) !==
            JSON.stringify(this.sections.map(section => {
              return this.mapSectionForComparison(section);
            })))) {
          this.sections = sections;
          this.filteredSections = Object.assign([], this.sections);
          this.listenForSectionStatusFilter();
          this.loadingSections = false;
          this.loadingSectionsError = false;
          this.emitTotalStatusCounts();
        } else {
          console.log('same sections');
        }
      }, () => {
        this.loadingSections = false;
        this.loadingSectionsError = true;
        this.snackBar.open('Error loading document sections. Please refresh and try again.');

      })
  }

  setSelectedSection(sectionId) {
    this.selectedSection = sectionId;
    this.onSectionSelected.emit(sectionId);
  }
  listenForSectionStatusFilter() {
    this.sectionsFilter.valueChanges.subscribe(statusFilters => {
      this.filteredSections = Object.assign([], this.sections).filter(section => {
        let sectionPassesFilter = false;
        for (const status of statusFilters) {
          switch (status) {
            case AuditTrailDocumentSectionCommentStatuses.closed:
              sectionPassesFilter = section.totalClosed > 0;
              break;
            case AuditTrailDocumentSectionCommentStatuses.ready:
              sectionPassesFilter = section.totalReady > 0;
              break;
            case AuditTrailDocumentSectionCommentStatuses.open:
              sectionPassesFilter = section.totalOpen > 0;
              break;
          }
          if (statusFilters.find(statusFilter => statusFilter === 'untouched') &&
            !sectionPassesFilter &&
            !section.totalClosed &&
            !section.totalReady &&
            !section.totalOpen) {
            sectionPassesFilter = true;
          }
          if (sectionPassesFilter) {
            break;
          }
        }
        return sectionPassesFilter;
      }).sort((a, b) => {
        return !a.order ? -1 : !b.order ? 1 : a.order < b.order ? -1 : a.order === b.order ? 0 : 1;
      });
    })
  }
  emitTotalStatusCounts() {
    if (this.sections) {
      const countTotals = {};
      countTotals[AuditTrailDocumentSectionCommentStatuses.cancelled] = this.getTotalCountOfStatus(AuditTrailDocumentSectionCommentStatuses.cancelled);
      countTotals[AuditTrailDocumentSectionCommentStatuses.closed] = this.getTotalCountOfStatus(AuditTrailDocumentSectionCommentStatuses.closed);
      countTotals[AuditTrailDocumentSectionCommentStatuses.open] = this.getTotalCountOfStatus(AuditTrailDocumentSectionCommentStatuses.open);
      countTotals[AuditTrailDocumentSectionCommentStatuses.ready] = this.getTotalCountOfStatus(AuditTrailDocumentSectionCommentStatuses.ready);
      this.onCountTotalsUpdated.emit(countTotals);
    }
  }
  getTotalCountOfStatus(status: AuditTrailDocumentSectionCommentStatuses) {
    if (this.sections) {
      return this.sections.reduce((total, section) => {
        switch (status) {
          case AuditTrailDocumentSectionCommentStatuses.cancelled:
            return section.totalCancelled ? total += section.totalCancelled : total;
          case AuditTrailDocumentSectionCommentStatuses.closed:
            return section.totalClosed ? total += section.totalClosed : total;
          case AuditTrailDocumentSectionCommentStatuses.open:
            return section.totalOpen ? total += section.totalOpen : total;
          case AuditTrailDocumentSectionCommentStatuses.ready:
            return section.totalReady ? total += section.totalReady : total;
        }
      }, 0);
    }
  }


  toggleSelectedSection(event: MatCheckboxChange, sectionId: string) {
    if (event.checked) {
      this.sectionIdSelection.push(sectionId);
    } else {
      this.sectionIdSelection.splice(this.sectionIdSelection.indexOf(sectionId), 1);
    }
  }
  async dropSection(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      try {
        this.loadingSections = true;
        moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
        const batchUpdate = this.afs.firestore.batch();
        this.sections.forEach(section => {
          batchUpdate.update(this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${section.id}`).ref, {
            order: this.sections.indexOf(section),
            dateUpdated: moment().toDate()
          })
        });
        await batchUpdate.commit();
        this.loadingSections = false;
        this.snackBar.open('Successfully updated section order', 'Dismiss', {
          duration: 5000
        });
      } catch {
        this.loadingSections = false;
        this.snackBar.open('Opps... something went wrong. Please refresh the page and try again.', 'Dismiss', {
          duration: 5000
        })
      }
    }
  }
  addSection() {
    const newSection = new AuditTrailDocumentSection();
    newSection.auditTrailDocument = this.auditTrailDocumentId;
    newSection.order = this.sections.length; // inherently +1 of the last index
    this.dialog.open(AuditTrailDocumentSectionDialogComponent, {
      data: {
        section: newSection,
        documentId: this.auditTrailDocumentId
      }
    });
  }


  // async deleteSection() {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     data: {
  //       title: 'Confirm Delete Section',
  //       message: 'Are you sure you want delete this section? All comments and messages for this section will be deleted as well.',
  //       action: 'Yes, Delete.'
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(async (confirmed) => {
  //     if (confirmed) {
  //       await this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.selectedSection.id}`).delete();
  //       this.snackBar.open('Successfully deleted the section.', 'Dismiss', {
  //         duration: 5000
  //       })
  //     }
  //   });
  // }
  async deleteMultipleSections(selectedSections: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Multiple Section',
        message: 'Are you sure you want delete these sections? All comments and comment messages for this section will be deleted as well.',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const deleteBatch = this.afs.firestore.batch();
        selectedSections.forEach(sectionId => {
          deleteBatch.delete(this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${sectionId}`).ref);
        })
        try {
          await deleteBatch.commit();
          this.snackBar.open('Successfully deleted the sections.', 'Dismiss', {
            duration: 5000
          });
          this.sectionIdSelection = []
        } catch (e) {
          this.snackBar.open('Ooops something went wrong please refresh and try again.', 'Dismiss', {
            duration: 5000
          });
        }
      }
    });
  }

  trackSections(index: number, section: AuditTrailDocumentSection) {
    return section ? section.id : null
  }
}
