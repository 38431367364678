import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Client, Entity, User, UserRoles } from "@deliver-sense-librarian/data-schema";
import { from, Subject } from "rxjs";
import { FirebaseApp } from "@angular/fire";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineAll, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<Entity>;
  public displayedColumns: string[] = ['name', 'address'];
  private user: User;
  private destroy$ = new Subject();
  public client: Client;
  private entities: Entity[] = [];
  uiState: UiState;
  constructor(private firebaseApp: FirebaseApp,
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getEntities();
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private getEntities() {
    if (this.uiState.authUser.internalRole) {
      this.getAllClientEntities();
    } else {
      this.getUserAccessibleEntites();
    }

  }
  getAllClientEntities() {
    this.afs.collection('entities', ref => ref
      .where('client', '==', this.uiState.client.id)).snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(entitiesResult$ => {
        this.entities = FirestoreUtilities.mapToType(entitiesResult$);
        this.tableData = new MatTableDataSource(this.entities);
      })
  }
  getUserAccessibleEntites() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin, UserRoles.contributor, UserRoles.viewer])
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(entities$ => {
        this.entities = entities$ as Entity[];
        if (this.entities && this.entities.length > 0) {
          this.entities = this.entities.filter(entity => !!entity && entity.client === this.client.id);
          this.tableData = new MatTableDataSource(this.entities);
        }
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
