import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WindowHeightMatcherDirective} from "./directives/window-height-matcher";
import {MaterialModule} from "../material.module";
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar';
import {FlexLayoutModule} from "@angular/flex-layout";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ElementDynamicHeightDirective} from "./directives/element-dynamic-height.directive";
import {SanitizeHtmlPipe} from "./pipes/sanitize-html.pipe";
import {KeysPipe} from "./pipes/keys.pipe";
import {TitleCasePipe} from "./pipes/titleCasePipe";
import {PrettyPrintPipe} from "./pipes/prettyPrintPipe";
import {PreloaderComponent} from "./preloader/preloader.component";
import {NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {AccountingFormat} from "./pipes/accounting-format.pipe";

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    MalihuScrollbarModule.forRoot(),
    FlexLayoutModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule
  ],
  declarations: [
    PreloaderComponent,
    TitleCasePipe,
    SanitizeHtmlPipe,
    WindowHeightMatcherDirective,
    ElementDynamicHeightDirective,
    PrettyPrintPipe,
    KeysPipe,
    AccountingFormat
  ],
  exports: [
    PreloaderComponent,
    TitleCasePipe,
    SanitizeHtmlPipe,
    ElementDynamicHeightDirective,
    WindowHeightMatcherDirective,
    PrettyPrintPipe,
    KeysPipe,
    AccountingFormat
  ]
})
export class DsSharedModule {
}
