<div fxLayout="column" class="comment-messages-container" appWindowHeightMatcher heightCompensation="240">
  <mat-toolbar fxFlex class="section-comment-list-toolbar">
    <div>
      <b>Sections</b>
    </div>
    <span fxFlex></span>
    <ng-container>
      <button mat-icon-button (click)="addSection()">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!(sectionIdSelection.length > 0)" color="warn"
        (click)="deleteMultipleSections(sectionIdSelection)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </ng-container>
  </mat-toolbar>
  <div [style.overflow-y]="'scroll'" cdkScrollable appWindowHeightMatcher heightCompensation="304">
    <mat-list class="section-comment-list example-list" cdkDropList cdkScrollable
      (cdkDropListDropped)="dropSection($event)">
      <mat-list-item *ngFor="let section of filteredSections; let i = index; trackBy: trackSections" cdkDrag
        cdkDragLockAxis="y" class="pointer" (click)="setSelectedSection(section.id)"
        [class.active]="selectedSection?.id === section.id">
        <div matLine [matTooltip]="section.name">
          <div fxLayout="row">
            <div fxFlex="90" class="inner-text">
              {{section.name}}
            </div>
            <mat-checkbox (change)="toggleSelectedSection($event, section.id)"></mat-checkbox>
          </div>
        </div>
        <div matLine fxLayout="row" fxLayoutAlign="space-between start">
          <small class="mat-text-warn font-weight-bold" fxFlex="33">
            <ng-container *ngIf="section.totalOpen > 0">
              {{'Open: ' + section.totalOpen}}
            </ng-container>
          </small>
          <small class="mat-text-primary font-weight-bold" fxFlex="33">
            <ng-container *ngIf="section.totalReady > 0">
              {{'Ready: ' + section.totalReady}}
            </ng-container>
          </small>
          <small class="mat-text-accent font-weight-bold" fxFlex="33">
            <ng-container *ngIf="section.totalClosed > 0">
              {{'Closed: ' + section.totalClosed}}
            </ng-container>
          </small>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
