import { UploadDocumentDialogComponent } from './../../../../dialogs/upload-document-dialog/upload-document-dialog.component';
import { FirestoreUtilities } from './../../../../utilities/firestore-utilities';
import { takeUntil, first } from 'rxjs/operators';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AuditTrailDocument, User, Client, AuditTrailDocumentFile, AuditTrailDocumentSection } from '@deliver-sense-librarian/data-schema';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogComponent } from '../../../../dialogs/confirm-dialog/confirm-dialog.component';
import { AttachAuditTrailFileDialogComponent } from '../../../../dialogs/attach-audit-trail-file-dialog/attach-audit-trail-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-audit-trail-document-files',
  templateUrl: './audit-trail-document-files.component.html',
  styleUrls: ['./audit-trail-document-files.component.scss']
})
export class AuditTrailDocumentFilesComponent implements OnInit {
  @Input() auditTrailDocument: AuditTrailDocument;
  @Input() sections: AuditTrailDocumentSection[];
  @Input() client: Client;
  @Input() user: User
  files: AuditTrailDocumentFile[];
  private destroy$ = new Subject();

  constructor(
    private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) { }

  ngOnInit() {
    if (this.auditTrailDocument) {
      this.fetchAuditTrailDocumentFiles();
    }
  }
  fetchAuditTrailDocumentFiles() {
    this.afs.collection(`auditTrailDocuments/${this.auditTrailDocument.id}/files`, ref => ref
      .orderBy('dateCreated', 'desc'))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(files$ => {
        this.files = <AuditTrailDocumentFile[]>FirestoreUtilities.mapToType(files$);
      });
  }
  attachFile() {
    const dialogRef = this.dialog.open(AttachAuditTrailFileDialogComponent, {
      data: {
        path: `clients/${this.client.id}/audit-trail-documents`,
        sections: this.sections
      }
    });
    dialogRef.afterClosed().subscribe(async (uploadResult) => {
      if (uploadResult) {
        await this.afs.collection(`auditTrailDocuments/${this.auditTrailDocument.id}/files`).add({
          filePath: uploadResult['path'],
          fileSize: uploadResult['size'],
          fileName: uploadResult['fileName'],
          dateUpdated: moment().toDate(),
          dateCreated: moment().toDate()
        });
        if (uploadResult['newSections'] && uploadResult['newSections'].length > 0) {
          let currentOrderNumber = this.sections.length - 1;
          await Promise.all(uploadResult['newSections'].map(async (sectionName) => {
            const newSection = new AuditTrailDocumentSection();
            newSection.name = sectionName;
            newSection.auditTrailDocument = this.auditTrailDocument.id;
            newSection.order = currentOrderNumber;
            currentOrderNumber++;
            return this.afs
              .collection(`auditTrailDocuments/${this.auditTrailDocument.id}/sections`)
              .add(newSection.toJSONObject())
          }));
        }
        this.snackBar.open(`Successfully attached document`, 'Dismiss', {
          duration: 5000
        })
      }
    })
  }
  deleteAttachment(file: AuditTrailDocumentFile) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Attachment',
        message: 'Are you sure you want delete the attached document version?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        FirestoreUtilities.deleteStorageFile(file.filePath, this.storage);
        await this.afs.doc(`auditTrailDocuments/${this.auditTrailDocument.id}/files/${file.id}`).delete();
        this.snackBar.open('File deleted successfully.', 'Dismiss', {
          duration: 5000
        });
      }
    });
  }
  async downloadFile(file: AuditTrailDocumentFile) {
    const fullUrlPath = await this.storage.ref(file.filePath).getDownloadURL().pipe(first()).toPromise();
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = name;
    a.href = fullUrlPath;
    a.click();
  }
}
