import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuditTrailDocument, AuditTrailDocumentSection, Client, User } from "@deliver-sense-librarian/data-schema";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil } from "rxjs/operators";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import * as moment from 'moment';

@Component({
  selector: 'app-audit-trail-document-section',
  templateUrl: './audit-trail-document-section.component.html',
  styleUrls: ['./audit-trail-document-section.component.scss']
})
export class AuditTrailDocumentSectionComponent implements OnInit, OnDestroy {
  @Input() section: AuditTrailDocumentSection;
  @Input() documentId: string;
  @Output() saved = new EventEmitter();
  public sectionForm: FormGroup;
  public user: User;
  private client: Client;
  private destroy$ = new Subject();

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && (this.user !== uiState$.authUser && this.client !== uiState$.client)) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.setupSectionForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async saveDocument() {
    if (this.sectionForm.valid) {
      try {
        const formValues = this.sectionForm.value;
        this.loadingService.isLoading(true, 'Saving Section');
        if (this.section.id) {
          await this.afs.doc(`auditTrailDocuments/${this.documentId}/sections/${this.section.id}`)
            .update({
              name: formValues.name,
              notes: formValues.notes ? formValues.notes : null,
              dateUpdated: moment().toDate()
            });
        } else {
          await this.afs.collection(`auditTrailDocuments/${this.documentId}/sections/`)
            .add({
              name: formValues.name,
              notes: formValues.notes,
              order: formValues.order,
              dateUpdated: moment().toDate(),
              dateCreated: moment().toDate()
            })
        }
        this.loadingService.isLoading(false);
        this.snackBar.open(`Section ${this.section.id ? 'Updated' : 'Created'}`, 'Dismiss', {
          duration: 5000
        });
        this.saved.emit(true);
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open('Oops... something went wrong. Please refresh and try again.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please fill out required fields.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private setupSectionForm() {
    this.sectionForm = this.fb.group({
      name: new FormControl(this.section.name, Validators.required),
      notes: new FormControl(this.section.notes),
      order: new FormControl(this.section.order, Validators.required)
    })
  }

}
