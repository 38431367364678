import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FirebaseAuthService } from "../../../../auth/services/firebase-auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Client, ClientModule, DeliverSenseModule, User, usStates } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { Store } from '@ngrx/store';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import * as moment from "moment";
import { auditTrailsModule, exemptSalesManagementModule, thirdPartyReportingModule } from "../../../../shared/ds-constant";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-create-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {
  @Input() client: Client = new Client();
  @Output() formSavedSuccess = new EventEmitter();
  clientForm: FormGroup;
  physicalSameAsShipping = new FormControl();
  states = usStates;
  storagePath: string;
  addingUri: boolean;
  secretType = 'password';
  liveKeyType = 'password';
  newUri = new FormControl('', Validators.required);
  billingEmailInEdit = false;
  clientBillingEmailFormControl = new FormControl('', [Validators.required, Validators.email]);
  public user: User;
  private destroy$ = new Subject();
  private clientModules: ClientModule[] = [];
  public availableModules: DeliverSenseModule[] = [];

  constructor(private auth: FirebaseAuthService,
    private store: Store<any>,
    private activedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private storage: AngularFireStorage,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.getClientModules();
          this.getAvailableModules();
          this.setupClientForm();
          this.listenForClientChanges();
          this.storagePath = `clients/${this.client.id}/logo/`
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private listenForClientChanges() {
    this.afs.doc(`clients/${this.client.id}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(client$ => {
        this.client = FirestoreUtilities.objectToType(client$);
        if (this.client.logoPath) {
          this.storage.ref(this.client.logoPath).getDownloadURL().subscribe(url => {
            this.client.logo = url
          })
        }
      })
  }

  public updateSfaRequired($event: MatCheckboxChange) {
    this.afs.doc(`clients/${this.client.id}`).update({
      sfaRequired: $event.checked
    }).then(() => {
      const message = $event.checked ? 'SFA now required for authentication.' : 'SFA has been disabled.';
      this.snackBar.open(message, 'Dismiss', {
        duration: 5000
      })
    }).catch((e) => {
      this.snackBar.open('Oops... something went wrong. Please refresh and check your authorizations with this client.', 'Dismiss', {
        duration: 5000
      })
    });
  }

  private setupClientForm() {
    this.clientForm = this.fb.group({
      name: new FormControl(this.client.name ? this.client.name : '', Validators.required),
      addressLine1: new FormControl(this.client.addressLine1 ? this.client.addressLine1 : '', Validators.required),
      addressLine2: new FormControl(this.client.addressLine2 ? this.client.addressLine2 : ''),
      addressCity: new FormControl(this.client.addressCity ? this.client.addressCity : '', Validators.required),
      addressState: new FormControl(this.client.addressState ? this.client.addressState : '', Validators.required),
      addressPostalCode: new FormControl(this.client.addressPostalCode ? this.client.addressPostalCode : '', Validators.required),
    });
    this.physicalSameAsShipping.valueChanges.subscribe(physicalSameAsShipping$ => {
      this.clientForm.get('billingAddressLine1').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressLine1').updateValueAndValidity();
      this.clientForm.get('billingAddressCity').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressCity').updateValueAndValidity();
      this.clientForm.get('billingAddressState').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressState').updateValueAndValidity();
      this.clientForm.get('billingAddressPostalCode').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressPostalCode').updateValueAndValidity();
      this.clientForm.updateValueAndValidity();
    });
  }

  public save() {
    this.clientForm.updateValueAndValidity();
    if (this.clientForm.valid) {
      const _client = this.mapFormToClient();
      this.afs.doc(`clients/${this.client.id}`)
        .update(_client.toJSONObject()).then(() => {
          this.snackBar.open('Successfully updated the client', 'Dismiss', {
            duration: 5000,
          });
          this.formSavedSuccess.emit(true);
        })
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000,
      });
    }
  }

  private mapFormToClient(): Client {
    const client = new Client();
    const form = this.clientForm.value;
    client.name = form.name;
    client.primaryContact = this.user.id;
    client.addressLine1 = form.addressLine1;
    client.addressLine2 = form.addressLine2;
    client.addressCity = form.addressCity;
    client.addressState = form.addressState;
    client.addressPostalCode = form.addressPostalCode;
    client.billingAddressLine1 = form.billingAddressLine1;
    client.billingAddressLine2 = form.billingAddressLine2;
    client.billingAddressCity = form.billingAddressCity;
    client.billingAddressState = form.billingAddressState;
    client.billingAddressPostalCode = form.billingAddressPostalCode;
    return client;
  }

  private getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientModules$ => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
      })
  }

  private getAvailableModules() {
    this.afs.collection('deliverSenseModules')
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dsModules$ => {
        this.availableModules = FirestoreUtilities.mapToType(dsModules$)
      });
  }

  isClientModuleActive(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule.active : false;
  }

  nextBillDate() {
    return moment().startOf('month').add(1, 'month').format('LL');
  }

  async logoUploaded($event) {
    if ($event.path) {
      if (this.client.logoPath) {
        try {
          await FirestoreUtilities.deleteStorageFile(this.client.logoPath, this.storage);
        } catch (e) {
          this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
            duration: 5000
          });
        }
      }
      await this.afs.doc(`clients/${this.client.id}`)
        .update({
          logoPath: $event.path
        });
      this.snackBar.open('Logo updated successfully!', 'Dismiss', {
        duration: 5000
      })
    }
  }

  getCurrentMonth() {
    return `${moment().startOf('month').format('M/D/YY')} through ${moment().format('M/D/YY')}`
  }

  removeUri(uri: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        // @ts-ignore
        const index = this.client.redirectUris.indexOf(uri);
        const reducedRedirectUris = this.client.redirectUris.splice(index, 1);
        this.afs.doc(`clients/${this.client.id}`).update({
          redirectUris: reducedRedirectUris
        })
      }
    });
  }

  async saveNewUri() {
    if (this.newUri.valid) {
      const clientUris = this.client.redirectUris && this.client.redirectUris instanceof Array ? this.client.redirectUris : [];
      if (clientUris.indexOf(this.newUri.value) > -1) {
        this.snackBar.open('That redirect uri already exists.', 'Dismiss', {
          duration: 5000
        })
      } else {
        clientUris.push(this.newUri.value);
        await this.afs.doc(`clients/${this.client.id}`).update({
          redirectUris: clientUris
        });
        this.newUri.reset();
        this.addingUri = false;
        this.snackBar.open('Redirect uri added successfully.', 'Dismiss', {
          duration: 5000
        });
      }
    }
  }

  async saveBillingEmailChange() {
    if (this.clientBillingEmailFormControl.valid) {
      await this.afs.doc(`clients/${this.client.id}`).update({
        billingEmail: this.clientBillingEmailFormControl.value
      });
      this.snackBar.open('Billing email updated successfully!', 'Dismiss', {
        duration: 5000
      })
      this.cancelBillingEdit();
    } else {
      this.snackBar.open('Please enter a valid email.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  cancelBillingEdit() {
    this.billingEmailInEdit = false;
    this.clientBillingEmailFormControl.reset();
    this.clientBillingEmailFormControl.patchValue(this.client.billingEmail);
    this.clientBillingEmailFormControl.updateValueAndValidity();
  }
}
