import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {LoadingDialogService} from "./loading-dialog.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {flatMap, map, take} from "rxjs/operators";
import {getErrorCodeMessage} from "../shared/ds-constant";

@Injectable()
export class FunctionsService {

    constructor(private http: HttpClient,
                private afAuth: AngularFireAuth,
                private loadingService: LoadingDialogService) {
    }

    private getToken(token): HttpHeaders {
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);
    }

}
