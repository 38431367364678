<mat-nav-list appAccordion class="navigation">
  <mat-list-item appAccordionLink *ngFor="let menuitem of menuitems">
    <a appAccordionToggle [id]="menuitem.id" class="relative" [routerLink]="['/app', menuitem.state]"
      *ngIf="menuitem.type === 'link'">
      <mat-icon fontSet="fas" [fontIcon]="'fa-'+ menuitem.icon"></mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle [id]="menuitem.id" class="relative" href="{{menuitem.state}}"
      *ngIf="menuitem.type === 'extLink'">
      <mat-icon fontSet="fas" [fontIcon]="'fa-'+ menuitem.icon"></mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle [id]="menuitem.id" class="relative" href="{{menuitem.state}}" target="_blank"
      *ngIf="menuitem.type === 'extTabLink'">
      <mat-icon fontSet="fas" [fontIcon]="'fa-'+ menuitem.icon"></mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
    <a appAccordionToggle [id]="menuitem.id" class="relative" href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <mat-icon fontSet="fas" [fontIcon]="'fa-'+ menuitem.icon"></mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a>
    <mat-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
      <mat-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
        <a [routerLink]="['/app', menuitem.state, childitem.state ]" class="relative"
          [class.departmentsNavLink]="childitem.name === 'DEPARTMENTS' || childitem.name === 'LOCATIONS'"
          [class.projectsNavLink]="childitem.name === 'PROJECTS'">
          <mat-icon *ngIf="childitem.icon" fontSet="fas" [fontIcon]="'fa-'+ childitem.icon"></mat-icon>
          {{ childitem.name }}
        </a>
      </mat-list-item>
    </mat-nav-list>
  </mat-list-item>

  <div class="bottom-list-items">
    <div fxLayout="row">
      <mat-divider fxFlex>
      </mat-divider>
    </div>
    <!-- <mat-list-item appAccordionLink *ngIf="user && client && isUserClientAdmin()">
      <a id="nav-client-settings" appAccordionToggle class="relative" [routerLink]="['/app/document-upload-center']">
        <mat-icon fontSet="fas" [fontIcon]="'fa-file-upload'"></mat-icon>
        <span> DOCUMENT CENTER </span>
        <span fxFlex></span>
      </a>
    </mat-list-item> -->
    <mat-list-item appAccordionLink>
      <a id="nav-client-settings" appAccordionToggle class="relative" target="_blank"
        href="https://deliversense.atlassian.net/wiki/external/98461/NDY0MTM2OTBiOGFiNGRkYTk4NDMxNWZhMTNlYjNhNzY">
        <mat-icon fontSet="fas" [fontIcon]="'fa-question-circle'"></mat-icon>
        <span> HELP </span>
        <span fxFlex></span>
      </a>
    </mat-list-item>
    <mat-list-item appAccordionLink>
      <a id="nav-client-settings" appAccordionToggle class="relative" (click)="showSupportDialog()">
        <mat-icon fontSet="fas" [fontIcon]="'fa-envelope'"></mat-icon>
        <span> CONTACT </span>
        <span fxFlex></span>
      </a>
    </mat-list-item>
    <mat-list-item appAccordionLink *ngIf="user && client && isUserClientAdmin()">
      <a id="nav-client-settings" appAccordionToggle class="relative" [routerLink]="['/app/client']">
        <mat-icon fontSet="fas" [fontIcon]="'fa-cogs'"></mat-icon>
        <span> CLIENT ACCOUNT </span>
        <span fxFlex></span>
      </a>
    </mat-list-item>
  </div>
</mat-nav-list>
