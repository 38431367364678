import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuditTrailDocumentChecklistTemplate, AuditTrailDocument, UserView } from '@deliver-sense-librarian/data-schema';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil, distinctUntilChanged, combineAll } from 'rxjs/operators';
import { Subject, from } from 'rxjs';
import { UiState } from '../../redux/custom-states/uiState/ui-state';
import { FirestoreUtilities } from '../../utilities/firestore-utilities';
import * as _ from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { scrollbarOptions } from '../../shared/ds-constant';

@Component({
  selector: 'app-audit-trail-checklist-viewer-dialog',
  templateUrl: './audit-trail-checklist-viewer-dialog.component.html',
  styleUrls: ['./audit-trail-checklist-viewer-dialog.component.scss']
})
export class AuditTrailChecklistViewerDialogComponent implements OnInit {
  public auditTrailDocument: AuditTrailDocument;
  public addingChecklistItem = false;
  public addingFromTemplate = false;
  public newChecklistItem = new FormControl('');
  public selectedChecklistTemplate = new FormControl('');
  public onlyShowUnchecked = new FormControl(false);
  public checkListItems = [];
  public filteredChecklistItems = [];
  destroy$ = new Subject();
  scrollbarOptions = scrollbarOptions;
  uiState: UiState;
  auditTrailDocumentChecklistTemplates: AuditTrailDocumentChecklistTemplate[] = [];
  checklistItemNames: string[] = [];
  userViews: UserView[] = [];
  constructor(public dialogRef: MatDialogRef<AuditTrailChecklistViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.getAuditTrailDocument();
          this.getProjectChecklistTemplates();
        }
      });
  }
  private getAuditTrailDocument() {
    this.afs.doc(`auditTrailDocuments/${this.data.auditTrailDocument.id}`).snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(auditTrailDocument$ => {
        this.auditTrailDocument = FirestoreUtilities.objectToType(auditTrailDocument$);
        this.checkListItems = this.auditTrailDocument.checklistItems ? this.auditTrailDocument.checklistItems : [];
        this.getChecklistItemUsers();
        this.filteredChecklistItems = Object.assign(this.checkListItems);
        this.onlyShowUnchecked.valueChanges.subscribe(onlyUnChecked => {
          if (onlyUnChecked) {
            this.filteredChecklistItems = Object.assign(this.checkListItems);
            this.filteredChecklistItems = this.filteredChecklistItems.filter(item => {
              return !item.complete;
            })
          } else {
            this.filteredChecklistItems = Object.assign(this.checkListItems);
          }
        })
      })
  }
  private getChecklistItemUsers() {
    const userViewRequests = [];
    this.checkListItems.forEach(checkListItem => {
      if (checkListItem.completedBy) {
        userViewRequests.push(this.afs.doc(`userViews/${checkListItem.completedBy}`).snapshotChanges());
      }
    });
    from(userViewRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(userViews$ => {
        const userViews = FirestoreUtilities.mergeToType(userViews$);
        this.userViews = _.uniqBy(userViews, 'id');
      })
  }
  public getCompletedByText(checkListItem) {
    const userView = this.userViews.find(uv => uv.id === checkListItem.completedBy);
    if (userView && checkListItem.completedOn) {
      return `Completed By ${userView.firstName} ${userView.lastName} on ${moment(checkListItem.completedOn.toDate()).format('L')}`
    } else {
      return ''
    }
  }
  private getProjectChecklistTemplates() {
    this.afs.collection('auditTrailDocumentChecklistTemplates', ref => ref
      .where('project', '==', this.data.auditTrailDocument.project)
      .where('client', '==', this.uiState.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(auditTrailDocumentChecklistTemplates$ => {
        this.auditTrailDocumentChecklistTemplates = FirestoreUtilities.mapToType(auditTrailDocumentChecklistTemplates$);
      })
  }
  async addTemplateChecklistItems() {
    if (this.selectedChecklistTemplate.valid) {
      const templateChecklist = []
      this.selectedChecklistTemplate.value.items.forEach(item => {
        templateChecklist.push({
          name: item.name,
          complete: false
        })
      });
      await this.saveNewItems(templateChecklist);
      this.snackBar.open('Successfully added template items to the checklist.', 'Dismiss', { duration: 5000 });
      this.resetAddingForm();
    } else {
      this.snackBar.open('You must select a template.', 'Dismiss', { duration: 5000 });
    }
  }
  async addNewItem() {
    const newItem = []
    newItem.push({ name: this.newChecklistItem.value, complete: false });
    this.saveNewItems(newItem);
    this.addingChecklistItem = false;
    this.newChecklistItem.reset();
  }

  async saveNewItems(newItems: any[]) {
    const updatedChecklist = [...this.checkListItems, ...newItems];
    await this.afs.doc(`auditTrailDocuments/${this.data.auditTrailDocument.id}`).update({
      checklistItems: updatedChecklist
    });
    this.snackBar.open(`Added items to ${this.auditTrailDocument.name} checklist`, 'Dismiss', {
      duration: 5000
    });
  }

  async updateChecklistItemStatus(item, $event: MatCheckboxChange) {
    const checklistItem = this.checkListItems[this.checkListItems.indexOf(item)];
    checklistItem.complete = $event.checked;
    if ($event.checked) {
      checklistItem.completedBy = this.uiState.authUser.id;
      checklistItem.completedOn = moment().toDate();
    } else {
      checklistItem.completedBy = null
      checklistItem.completedOn = null;
    }
    await this.afs.doc(`auditTrailDocuments/${this.data.auditTrailDocument.id}`).update({
      checklistItems: this.checkListItems
    });
    this.snackBar.open(`Updated ${this.auditTrailDocument.name} checklist item status.`, 'Dismiss', {
      duration: 5000
    });
    this.resetAddingForm();
  }
  async removeItem(item) {
    this.checkListItems.splice(this.checkListItems.indexOf(item), 1);
    await this.afs.doc(`auditTrailDocuments/${this.data.auditTrailDocument.id}`).update({
      checklistItems: this.checkListItems
    });
    this.snackBar.open(`Removed checklist item.`, 'Dismiss', {
      duration: 5000
    });
    this.resetAddingForm();
  }
  public resetAddingForm() {
    this.addingChecklistItem = false;
    this.addingFromTemplate = false;
    this.selectedChecklistTemplate.reset();
    this.newChecklistItem.reset();
  }
  async dropChecklistItem(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      try {
        this.loadingService.isLoading(true, 'Updating Checklist Order...');
        moveItemInArray(this.checkListItems, event.previousIndex, event.currentIndex);
        await this.afs.doc(`auditTrailDocuments/${this.data.auditTrailDocument.id}`).update({
          checklistItems: this.checkListItems
        });
        this.loadingService.isLoading(false);
        this.snackBar.open('Successfully updated checklist order', 'Dismiss', {
          duration: 5000
        });
      } catch {
        this.loadingService.isLoading(false);
        this.snackBar.open('Opps... something went wrong. Please refresh the page and try again.', 'Dismiss', {
          duration: 5000
        })
      }
    }
  }
}
