<div cdkScrollable heightCompensation="240" fxFlex fxLayout="column">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between start" class="comment-toolbar">
    <ng-container *ngIf="selectedComment">
      <div fxFlex="10">
      </div>
      <div fxLayout="column" fxFlex="80" class="comment-toolbar-info">
        <perfect-scrollbar>
          <div class="comment-toolbar-comment-container">
            {{selectedComment?.comment}}
          </div>
        </perfect-scrollbar>
        <perfect-scrollbar>
          <div fxLayout="row" *ngIf="selectedComment" [style.min-width.px]="450" fxLayoutAlign="space-between center"
            fxLayoutGap="10px">
            <small class="ma-0 mat-text-muted text-sm">
              <b>Status: </b>
              {{selectedComment['statusText']}}
              <ng-container *ngIf="selectedComment['statusText'] === 'Closed'">
                <mat-icon [style.cursor]="'pointer'" color="accent" fontSet="fas"
                  fontIcon="fa-info-circle"
                  [matTooltip]="'Approved By: ' + selectedComment['approvalInfo']">
                </mat-icon>
              </ng-container>
            </small>
            <small class="ma-0 mat-text-muted text-sm">
              <b>Assigned To: </b>
              {{selectedComment['assigneeName']}}
            </small>
            <small class="ma-0 mat-text-muted text-sm">
              <b>Created By: </b>
              {{selectedComment['creatorName']}}
            </small>
          </div>
        </perfect-scrollbar>
      </div>
      <div fxFlex="10" fxLayoutAlign="end center">
        <button mat-icon-button (click)="editComment()">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-menu #them="matMenu" x-position="before">
          <button mat-menu-item (click)="editComment()">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item>
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </mat-toolbar>
  <ng-container *ngIf="!!uiState.authUser && !!selectedComment && !!selectedSection && !!auditTrailDocument">
    <app-messages-window [user]="uiState.authUser" [section]="selectedSection"
      [comment]="selectedComment" [document]="auditTrailDocument">
    </app-messages-window>
  </ng-container>
  <hr>
</div>
