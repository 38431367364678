import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { UploadDocumentService } from '../../services/upload-document.service';

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './upload-document-dialog.component.html',
  styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {
  path: string;
  constructor(private afs: AngularFirestore,
    private dialog: MatDialog,
    private uploadDocumentService: UploadDocumentService,
    public dialogRef: MatDialogRef<UploadDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.path = data.path
     }

  ngOnInit() {
  }
  async handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];
    const fileName = file.name.substr(0, file.name.lastIndexOf('.'));
    const lastDot = file.name.lastIndexOf('.');
    const fileExtension = file.name.substring(lastDot + 1);
    const filePath = `${this.path}/${fileName.replace(/ /g, "_")}_${moment().unix().toString()}.${fileExtension}`;
    const uploadResult = await this.uploadDocumentService.uploadSingle(file, filePath);
    uploadResult['fileName'] = `${file.name}`;
    this.dialogRef.close(uploadResult);
  }
}
