<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row">
    <div>
      <app-location-search [multiple]="true" [selectedLocations]="selectedLocations" [locations]="locations">
      </app-location-search>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Variance Type</mat-label>
      <mat-select [formControl]="selectedVarianceType">
        <mat-option [value]="varianceType" *ngFor="let varianceType of varianceTypes">
          {{varianceType}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Third Party</mat-label>
      <mat-select [formControl]="selectedThirdParty">
        <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty.id">{{thirdParty.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" (click)="updateChartData()">
        Run Report
      </button>
    </div>
  </div>
  <div style="position: relative; height:50vh; width:80vw; display: block" *ngIf="showChart">
    <canvas baseChart [datasets]="bubbleChartData" [options]="bubbleChartOptions" [colors]="bubbleChartColors"
      [legend]="bubbleChartLegend" [chartType]="bubbleChartType">
    </canvas>
  </div>
</div>
