import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { downloadDataAsFile } from 'app/shared/ds-constant';
import { DataUploadErrorDialogComponent } from '../data-upload-error-dialog/data-upload-error-dialog.component';
import { Papa } from 'ngx-papaparse';
import * as moment from "moment";

@Component({
  selector: 'app-location-transactions-by-status-dialog',
  templateUrl: './location-transactions-by-status-dialog.component.html',
  styleUrls: ['./location-transactions-by-status-dialog.component.scss']
})
export class LocationTransactionsByStatusDialogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = []
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'date',
    'sale',
    'saleCorrection',
    'tax',
    'taxCorrection',
    'tip',
    'otherCharges',
    'taxRemitted',
    'promoFee',
    'deliveryFeeRate',
    'pickupFeeTotal',
    'cateringFee'
  ];
  constructor(private papa: Papa,
    public dialogRef: MatDialogRef<DataUploadErrorDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.tableData = new MatTableDataSource(this.data.transactions);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  exportReport() {
    const data = this.tableData.data.map(row => {
      const mutRow = Object.assign({}, row);
      if (row.date) {
        mutRow.date = mutRow.date.toDate();
      }
      return mutRow;
    })
    const results = this.papa.unparse(data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `${this.data.statusText}_Transactions_for_${this.data.locationId}_${moment().format('M/D/YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
}
