<mat-form-field appearance="outline" id="select-locations" class="report-parameter-form-field">
  <mat-label>{{multiple ? 'Locations' : 'Location'}}</mat-label>
  <mat-select [formControl]="selectedLocations" placeholder="Banks" [multiple]="multiple" #multiSelect>
    <mat-select-trigger *ngIf="multiple">
      <ng-container
        *ngIf="selectedLocations.value && selectedLocations.value[0] === 0; else locationsSelectedText">
        All Locations
      </ng-container>
      <ng-template #locationsSelectedText>
        {{selectedLocations.value ? getLocationName(selectedLocations.value[0]) : ''}}
        <div *ngIf="selectedLocations.value?.length > 1" class="example-additional-selection">
          (+{{selectedLocations.value.length - 1}} others)
        </div>
      </ng-template>
    </mat-select-trigger>
    <ngx-mat-select-search placeholderLabel="Search Locations..."
                           i18n-placeholderLabel
                           noEntriesFoundLabel="'No Matching Location'"
                           i18n-noEntriesFoundLabel
                           [showToggleAllCheckbox]="true"
                           (toggleAll)="toggleSelectAll($event)"
                           [formControl]="locationsFilterCtrl">
    </ngx-mat-select-search>
    <mat-option *ngFor="let location of filteredLocations | async" [value]="location.locationId">
      {{location.locationId}} - {{location.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
