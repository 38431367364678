<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutGap="12px">
      <mat-card-title>
        <h2>Exemption Certificates</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6>Manage the exemption certificates for all the exempt transactions from your POS system.</h6>
      </mat-card-subtitle>
      <div fxLayout="row" fxLayoutAlign="center center">
        <h3><b>Search Exempt Transactions</b></h3>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="2px" fxLayoutAlign="space-between center">
        <app-location-search [locations]="locations" [selectedLocations]="selectedLocations" *ngIf="locations && locations.length > 0" [multiple]="true">
        </app-location-search>
        <mat-checkbox [formControl]="onlyUndocumentedTransactions">Only Show Undocumented</mat-checkbox>
        <div fxLayout="row wrap" fxLayoutGap="2px">
          <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
            <mat-label>Start Date</mat-label>
            <input matInput [formControl]="startDate" [matDatepicker]="startPicker" placeholder="Start date">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" id="select-end-date" class="report-parameter-form-field">
            <mat-label>End Date</mat-label>
            <input matInput [formControl]="endDate" [disabled]="startDate.invalid" [min]="startDate.value"
              [max]="getMaxEndDate()" [matDatepicker]="endPicker" placeholder="End date">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div [style.margin-right.px]="20">
          <button mat-raised-button color="primary" (click)="runSearch()">
            Search
          </button>
        </div>
      </div>
    </mat-card-header>
  </mat-card>
  <mat-card *ngIf="transactions && !noTransactions">
    <app-exempt-transactions [transactions]="transactions" [storagePath]="storagePath"></app-exempt-transactions>
  </mat-card>
  <mat-card *ngIf="noTransactions">
    <div fxLayout="row" fxLayoutAlign="center center">
      <h3><b>No Tax Exempt Transactions Found.</b></h3>
    </div>
  </mat-card>
</div>
