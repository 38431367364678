import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PosTransaction, ThirdParty, ThirdPartyTransaction} from "@deliver-sense-librarian/data-schema";
import {AngularFirestore} from '@angular/fire/firestore';
import {map, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-change-transaction-third-party-dialog',
  templateUrl: './change-transaction-third-party-dialog.component.html',
  styleUrls: ['./change-transaction-third-party-dialog.component.scss']
})
export class ChangeTransactionThirdPartyDialogComponent implements OnInit {
  public posTransaction: PosTransaction;
  public accounts: Observable<any[]>;
  public selectedAccount = new FormControl();

  constructor(private afs: AngularFirestore,
              public dialogRef: MatDialogRef<ChangeTransactionThirdPartyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.posTransaction = data.transaction as PosTransaction;
    this.selectedAccount.patchValue(this.posTransaction.account);
    this.selectedAccount.updateValueAndValidity();
  }

  ngOnInit() {
    this.accounts = this.afs.collection('thirdParties').snapshotChanges()
      .pipe(map(thirdParties$ => [{id: 'ex', name: 'Tax Exempt Sale'}, ...FirestoreUtilities.mapToType(thirdParties$)]));
  }
  change() {
    this.dialogRef.close(this.selectedAccount.value);
  }
  cancel() {
    this.dialogRef.close();
  }
}
