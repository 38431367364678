<div class="session">
  <div class="session-content text-xs-center">
    <div>
      <div class="error-title">404</div>
      <div class="error-subtitle">Page not found!</div>
      <p class="mat-text-muted">Sorry, but the page you were trying to view does not exist. <a (click)="showSupportDialog()">Report
          this error?</a></p>
    </div>
  </div>
</div>
