import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { User } from 'firebase';
import { Client, ClientDayCharge, ClientModule } from '@deliver-sense-librarian/data-schema';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { Subject, from } from 'rxjs';
import { takeUntil, combineAll } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { scrollbarOptions } from '../../../../shared/ds-constant';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-billing-recent-day-charges',
  templateUrl: './billing-recent-day-charges.component.html',
  styleUrls: ['./billing-recent-day-charges.component.scss']
})
export class BillingRecentDayChargesComponent implements OnInit, AfterViewInit, OnDestroy {
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  clientDayCharges: ClientDayCharge[];
  loadingWidgetData = false;
  scrollbarOptions = scrollbarOptions;
  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private el: ElementRef) {

  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getRecentDayCharges();
        }
      });
  }
  getRecentDayCharges() {
    this.loadingWidgetData = true;
    this.afs.collection('clientDayCharges', ref => ref
      .where('client', '==', this.client.id)
      .orderBy('chargeDate', 'desc')
      .limit(5))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientDayCharges$ => {
        this.clientDayCharges = <ClientDayCharge[]>FirestoreUtilities.mapToType(clientDayCharges$);
        const clientModuleRequests = this.clientDayCharges.map(clientDayCharge => {
          return this.afs.doc(`clientModules/${clientDayCharge.clientModule}`).snapshotChanges();
        })
        from(clientModuleRequests)
          .pipe(combineAll(), takeUntil(this.destroy$))
          .subscribe(clientModules$ => {
            const clientModules = FirestoreUtilities.mergeToType(clientModules$);
            this.clientDayCharges.forEach(clientDayCharge => {
              const clientModule = clientModules.find(cm => cm.id === clientDayCharge.clientModule);
              clientDayCharge['moduleName'] = this.getClientModuleName(clientModule);
              clientDayCharge.subTotal = +(clientDayCharge.subTotal.toFixed(2));
            })
          })
        this.loadingWidgetData = false;
      })
  }
  getClientModuleName(clientModule: ClientModule): any {
    switch (clientModule.module) {
      case "WHTZNRSd7qRMiz2nTO84":
        return "Exempt Sales Management";
      case "ru8bwRnug0X6Q6bLZwHX":
        return "Audit Trials";
      case "vlBJZeknUiMFjkSqj6F6":
        return "3PD Reporting";
      case "yqsnspZEfxxQJ1ibLNGD":
        return "Property Tax Manager";
      case "whHbvtGPfxd79diFGclc":
        return "Tax Brackets";
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {

  }

}
