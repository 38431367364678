import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  typeConfirmInput = new FormControl('');
  typingConfirmed = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.typeConfirm) {
      this.typingConfirmed = false;
      this.typeConfirmInput.valueChanges.subscribe(confirmation => {
        if (confirmation === data.typeConfirm) {
          this.typingConfirmed = true;
        } else {
          this.typingConfirmed = false;
        }
      })
    }
  }


  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
