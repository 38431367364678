import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { combineAll, map, takeUntil } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { Client, User, UserRoles } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from '@angular/fire/firestore';
import { from, of, Subject } from "rxjs";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-login',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  public clients: Client[] = [];
  public noClients = false;
  public user: User;
  public clientRoles: any[] = [];
  private destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private store: Store<any>,
    private storage: AngularFireStorage,
    private router: Router,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(state => state.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser) {
          if (uiState$.client) {
            this.router.navigateByUrl('/app');
          } else {
            this.user = uiState$.authUser;
            this.getUserClients();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
  }

  private getUserClients() {
    this.afs.collection(`users/${this.user.id}/clientRoles`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientRoles$ => {
        this.clientRoles = FirestoreUtilities.mapToType(clientRoles$);
        if (this.clientRoles && clientRoles$.length > 0) {
          const clientRequest = this.clientRoles.map(clientRole => {
            return this.afs.doc(`clients/${clientRole.resource}`).snapshotChanges();
          });
          from(clientRequest)
            .pipe(combineAll(), takeUntil(this.destroy$))
            .subscribe(clients$ => {
              this.clients = FirestoreUtilities.mergeToType(clients$).filter(client => !!client);
              if (this.clients.length < 1) {
                this.noClients = true;
              } else {
                const clientLogos = this.clients.map(client => {
                  if (client.logoPath) {
                    return this.storage.ref(client.logoPath).getDownloadURL().pipe(map(url => {
                      client.logo = url
                    }))
                  } else {
                    return of();
                  }
                });
                from(clientLogos)
                  .pipe(takeUntil(this.destroy$), combineAll())
                  .subscribe(clientLogoMappings$ => {

                  })
              }
            })
        } else {
          this.noClients = true;
        }
      });
  }

  public async setClient(client: Client) {
    if (client.sfaRequired) {
      await this.router.navigateByUrl(`/sfa/${client.id}`);
    } else {
      await this.auth.setSelectedClient(client.id, this.user.id);
      this.router.navigate(['/app']);
    }
  }

  async signout() {
    await this.auth.signOut();
    this.router.navigate(['']);
  }

  getUserClientRole(client: Client) {
    if (this.user) {
      const clientRole = this.clientRoles.find(_cr => _cr.resource === client.id);
      if (clientRole) {
        switch (clientRole.role) {
          case UserRoles.admin:
            return 'admin';
          case UserRoles.contributor:
            return 'contributor';
          case UserRoles.viewer:
            return 'viewer';
        }
      }
    }
  }
}
