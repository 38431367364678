import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, from, Observable, Subject, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { combineAll, first, map, take, takeUntil, catchError } from 'rxjs/operators';
import { Client, User, UserRoles } from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { FirestoreUtilities } from '../../utilities/firestore-utilities';
import { environment } from "../../../environments/environment";
import { Store } from '@ngrx/store';
import { UnsetSelectedClientAction, SetAuthorizedClientsAction, SfaSuccessAction } from '../../redux/custom-states/uiState/ui-state-actions/authentication-actions';
import {
  SetAccountClientAction, SetAccountRolesAction, SetClientPosSystemsAction, SetClientThirdPartiesAction,
  UnauthenticateUserAction,
  UserAuthenticationSuccessAction,
  SetClientLocationObjectsAction
} from "../../redux/custom-states/uiState/ui-state-actions";
import { auth } from 'firebase/app';
import { UiState } from '../../redux/custom-states/uiState/ui-state';

@Injectable()
export class FirebaseAuthService {
  private user: User;
  private userSet = false;
  private inSignup = false;
  public authUser = new BehaviorSubject(null);
  public locationRoles: Observable<any>;
  public entityRoles: Observable<any>;
  public departmentRoles: Observable<any>;
  public projectRoles: Observable<any>;
  private userSubscription;
  private loggedIn = true;
  private loggingOut = new Subject();

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    protected http: HttpClient) {
    this.initializeAuthState();
  }

  private async initializeAuthState() {
    const appVersion = JSON.parse(localStorage.getItem('applicationVersion'));
    if (!appVersion || appVersion !== environment.applicationVersion) {
      this.signOut();
    } else {
      const user = await this.checkForAuthUser();
      if (user && JSON.parse(localStorage.getItem('client'))) {
        this.setSelectedClient(JSON.parse(localStorage.getItem('client')).id, user.id);
      }
    }
  }
  public async getAuthHeader() {
    const token = await this.afAuth.idToken.pipe(first()).toPromise();
    const uiState = (await this.store.select((state) => state.uiState).pipe(first()).toPromise()) as UiState;
    const sfaToken = uiState.sfaToken;
    const headerToken = new HttpHeaders().set('Authorization', `Bearer ${token}${sfaToken ? `,Bearer ${sfaToken}` : ''}`);
    return { headers: headerToken };
  }
  public async login(email, password, invitationId?) {
    const url = `${environment.apiUrl}login`;
    this.loadingService.isLoading(true, 'Authenticating...');
    try {
      const customToken = await this.http.post(url, { email, password, invitationId }).pipe(first(), map(response => response['token'])).toPromise();
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      this.snackBar.open('Login successful. Welcome back!', 'Dismiss', {
        duration: 5000
      });
      return authUser;
    } catch (e) {
      this.loadingService.isLoading(false);
      throw new Error(e.message);
    }
  }
  /**
   *
   * @param provider
   * @param invitationId
   */
  public async authWithProvider(provider: 'microsoft' | 'google', client?, invitationId?, referralCode?, teamMemberInvites?) {
    const providerInstance = provider === 'microsoft' ? new auth.OAuthProvider('microsoft.com') : new auth.GoogleAuthProvider;
    const authResult = await this.afAuth.signInWithPopup(providerInstance);
    const authHeader = await this.getAuthHeader();
    if (authResult.additionalUserInfo.isNewUser && !client && !invitationId) {
      const removeUnregisteredProviderUserUrl = `${environment.apiUrl}credentials/${authResult.user.uid}`;
      await this.http.delete(removeUnregisteredProviderUserUrl).pipe(first()).toPromise()
      this.snackBar.open('No user exists with that authentication provider. Please register to continue.', 'Dismiss', { duration: 5000 });
      this.loadingService.isLoading(false);
      return;
    } else if (authResult.additionalUserInfo.isNewUser) {
      this.loadingService.isLoading(true, `Creating your account... Please don't navigate away.`);
      const registerUrl = `${environment.apiUrl}provider-user-registration`;
      const response$ = await this.http.post(registerUrl, { client, invitationId, referralCode, teamMemberInvites }, authHeader).pipe(first()).toPromise()
      const customToken = response$['token']
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      return authUser;
    } else if (!authResult.additionalUserInfo.isNewUser) {
      this.loadingService.isLoading(true, 'Authenticating...');
      const url = `${environment.apiUrl}provider-authentication`;
      const response$ = await this.http.post(url, { invitationId }, authHeader).pipe(first()).toPromise()
      const customToken = response$['token']
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      return authUser;
    }
  }

  public register(user, password, client?, invitationId?, referralCode?, teamMemberInvites?) {
    const url = `${environment.apiUrl}signup`;
    this.loadingService.isLoading(true, `Creating your account... Please don't navigate away.`);
    return this.http.post(url, { user, password, client, invitationId, referralCode, teamMemberInvites }).pipe(map(async response$ => {
      const customToken = response$['token']
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      return authUser;
    }));
  }
  public async requestSfa(clientId) {
    const url = `${environment.apiUrl}sfa?clientId=${clientId}`;
    const authHeader = await this.getAuthHeader();
    return await this.http.get(url, authHeader).pipe(first()).toPromise();
  }
  public async requestNewSfa(clientId) {
    const url = `${environment.apiUrl}sfa?clientId=${clientId}&resetCode=true`;
    const authHeader = await this.getAuthHeader();
    return await this.http.get(url, authHeader).pipe(first()).toPromise();
  }
  public async verifySfa(sfaCode, client: Client) {
    this.loadingService.isLoading(true, 'Verifying...');
    const url = `${environment.apiUrl}sfa/${sfaCode}`;
    const authHeader = await this.getAuthHeader();
    return await this.http.patch(url, {}, authHeader).pipe(first(), map(async response$ => {
      this.loadingService.isLoading(false);
      return response$;
    }), catchError(e => {
      this.loadingService.isLoading(false);
      return of(e);
    })).toPromise();
  }

  public verifySso(authCode, tokenId) {
    this.loadingService.isLoading(true, 'Authenticating...');
    const url = `${environment.apiUrl}sso/${tokenId}`
    return this.http.patch(url, { authCode }).pipe(map(async (response$: any) => {
      if (response$.token && response$.client && response$.user) {
        const authUser = await this.setAuthenticationState(response$['token'])
        await this.store.dispatch(new SetAccountClientAction(response$.client));
        this.setClientAndOrgRoles(response$.client, response$.user);
        this.setClientPosSystemsAndThirdParties(response$.client);
        this.loadingService.isLoading(false);
        return authUser
      } else {
        this.loadingService.isLoading(false);
        return false;
      }
    }), catchError(e => {
      this.loadingService.isLoading(false);
      this.snackBar.open('Invalid code', 'Dismiss', { duration: 5000 });
      return of(e);
    }))
  }

  public async setAuthenticationState(customToken) {
    try {
      const credential = await this.afAuth.signInWithCustomToken(customToken);
      return await this.setApplicationCredentials(credential);
    } catch (e) {
      throw new Error(e.message);
    }
  }
  private async setApplicationCredentials(credential) {
    try {
      const user = await this.setAuthUserState(credential.user.uid);
      this.loadingService.isLoading(false);
      return user;
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Error loading your account. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }
  public async setAuthUserState(userId) {
    const user$ = await this.afs.doc(`users/${userId}`).snapshotChanges().pipe(first()).toPromise();
    const user = FirestoreUtilities.objectToType(user$);
    this.setUserAvailableClients(user);
    this.store.dispatch(new UserAuthenticationSuccessAction(user));
    return user;
  }

  public async updateEmail(user: User, newEmail?, password?) {
    if (newEmail.value && user.email && password.value) {
      this.loadingService.isLoading(true, 'Updating your email...');
      try {
        // verify the user email and password are valid
        const credential = await this.afAuth.signInWithEmailAndPassword(user.email, password.value);
        // send the update email request
        await credential.user.updateEmail(newEmail.value);
        await this.afs.doc(`users/${user.id}`).update({ email: newEmail.value });
        await this.signOut()
        this.snackBar.open('Successfully updated your account email. Please sign back in with your new email address.', 'Dismiss', {
          duration: 5000
        });
        this.loadingService.isLoading(false);
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open('Error updating your email please try again later.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please enter your current email, new email, and confirm your password to complete the change.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  async resetPassword(email) {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      this.snackBar.open('Please check your account email for a link to reset your password.', 'Dismiss', {
        duration: 5000
      });
    } catch (e) {
      this.snackBar.open('Error resetting password please try again later.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private setUserAvailableClients(user: User) {
    this.afs.collection(`users/${user.id}/clientRoles`).snapshotChanges()
      .subscribe(authorizedClients$ => {
        const authorizedClients = FirestoreUtilities.mapToType(authorizedClients$);
        this.store.dispatch(new SetAuthorizedClientsAction(authorizedClients));
        this.verifyCurrentSelectedClientAccess(authorizedClients);
      })
  }
  private async verifyCurrentSelectedClientAccess(authorizedClients) {
    const currentSelectedClient = localStorage.getItem('client');
    if (currentSelectedClient) {
      const accessToCurrentSelection = authorizedClients.find(clientRole => clientRole.resource === JSON.parse(currentSelectedClient).id);
      if (!accessToCurrentSelection) {
        await this.store.dispatch(new UnsetSelectedClientAction());
        setTimeout(async () => {
          await this.router.navigateByUrl('/client-selection')
        }, 1000)
        this.snackBar.open('You no longer have access to the selected client.', 'Dismiss', {
          duration: 5000
        })
      }
    }
  }
  public async setSelectedClient(clientId: string, userId: string) {
    const client$ = await this.afs.doc(`clients/${clientId}`)
      .snapshotChanges()
      .pipe(first()).toPromise();
    const client = FirestoreUtilities.objectToType(client$);
    this.store.dispatch(new SetAccountClientAction(client));
    this.setClientAndOrgRoles(clientId, userId);
    this.setClientPosSystemsAndThirdParties(clientId);
  }

  private setClientAndOrgRoles(clientId, userId) {
    combineLatest([
      this.afs.doc(`users/${userId}/clientRoles/${clientId}`)
        .snapshotChanges(),
      this.afs.collection(`users/${userId}/clientRoles/${clientId}/organizationRoles`)
        .snapshotChanges()
    ])
      .pipe(takeUntil(this.loggingOut))
      .subscribe(async ([clientRole$, clientOrgRoles$]) => {
        const clientRole = FirestoreUtilities.objectToType(clientRole$);
        const clientOrgRoles = FirestoreUtilities.mapToType(clientOrgRoles$);
        if (clientRole) {
          const accountRoles = {
            clientRole: clientRole.role,
            entities: clientOrgRoles.filter(orgRole => orgRole.type === 'entity'),
            locations: clientOrgRoles.filter(orgRole => orgRole.type === 'location'),
            departments: clientOrgRoles.filter(orgRole => orgRole.type === 'department'),
            projects: clientOrgRoles.filter(orgRole => orgRole.type === 'project')
          };
          // this.setClientLocations(accountRoles.locations);
          this.store.dispatch(new SetAccountRolesAction(accountRoles));
        }
      }, async (e) => {
        console.log(e);
      });
  }
  private setClientLocations(locations: any) {
    const locationRequests = locations.map(location => {
      return this.afs.doc(`locations/${location.resource}`).snapshotChanges();
    });
    from(locationRequests)
      .pipe(combineAll(), takeUntil(this.loggingOut))
      .subscribe(locationObjects$ => {
        const locationObjects = FirestoreUtilities.mergeToType(locationObjects$);
        this.store.dispatch(new SetClientLocationObjectsAction(locationObjects));
      })
  }

  private setClientPosSystemsAndThirdParties(clientId) {
    combineLatest([
      this.afs.collection('clientThirdParties', ref => ref
        .where('client', '==', clientId))
        .snapshotChanges(),
      this.afs.collection('clientPosSystems', ref => ref
        .where('client', '==', clientId))
        .snapshotChanges(),
    ])
      .pipe(takeUntil(this.loggingOut))
      .subscribe(([clientThirdParties$, clientPosSystems$]) => {
        const clientThirdParties = FirestoreUtilities.mapToType(clientThirdParties$).filter(ctp=> !!ctp);
        const clientPosSystems = FirestoreUtilities.mapToType(clientPosSystems$).filter(cpos=> !!cpos);;
        const globalPosSystemRequests = clientPosSystems.map(clientPosSystem => {
          return this.afs.doc(`posSystems/${clientPosSystem.posSystem}`).snapshotChanges();
        });
        const globalThirdPartyRequests = clientThirdParties.map(clientThirdParty => {
          return this.afs.doc(`thirdParties/${clientThirdParty.thirdParty}`).snapshotChanges();
        });
        combineLatest([
          from(globalThirdPartyRequests).pipe(combineAll()),
          from(globalPosSystemRequests).pipe(combineAll()),
        ]).subscribe(([thirdParties$, posSystems$]) => {
          const thirdParties = FirestoreUtilities.mergeToType(thirdParties$).filter(tp => !!tp);
          clientThirdParties.forEach(clientThirdParty => {
            const thirdParty = thirdParties.find(tp => tp.id === clientThirdParty.thirdParty);
            clientThirdParty.thirdParty = thirdParty;
          });
          const posSystems = FirestoreUtilities.mergeToType(posSystems$).filter(pos => !!pos);
          clientPosSystems.forEach(clientPosSystem => {
            const posSystem = posSystems.find(pos => pos.id === clientPosSystem.posSystem);
            clientPosSystem.posSystem = posSystem;
          });
          this.store.dispatch(new SetClientPosSystemsAction(clientPosSystems));
          this.store.dispatch(new SetClientThirdPartiesAction(clientThirdParties));
        });
      }, (e) => {
        console.log(e);
      });
  }

  public async emailRegistration(user: User, password: string) {
    return await this.afAuth.createUserWithEmailAndPassword(user.email, password);
  }

  private async checkForAuthUser() {
    if (!this.userSet) {
      const authUser = await this.afAuth.authState
        .pipe(map(user$ => user$), first())
        .toPromise();
      if (authUser) {
        const user = await this.setAuthUserState(authUser.uid);
        this.userSet = true;
        return user;
      } else {
        this.signOut();
      }
    }
  }

  public async signOut() {
    this.store.dispatch(new UnauthenticateUserAction());
    await this.afAuth.signOut();
    this.loggingOut.next(true);
    this.authUser = new BehaviorSubject<any>(null);
    this.user = null;
  }
  public async linkProviderAccount(provider: 'google' | 'microsoft') {
    const providerObj = provider === 'google' ? new auth.GoogleAuthProvider() : new auth.OAuthProvider('microsoft.com');
    const currentUser = await (this.afAuth.currentUser);
    currentUser.linkWithPopup(providerObj);
    const redirectResult = await this.afAuth.getRedirectResult();
    const authHeader = await this.getAuthHeader();
    this.loadingService.isLoading(true, 'Authenticating...');
    try {
      const url = `${environment.apiUrl}provider-authentication`;
      const response$ = await this.http.post(url, {}, authHeader).pipe(first()).toPromise()
      const customToken = response$['token']
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      return authUser;
    } catch (e) {
      this.loadingService.isLoading(false);
      throw e;
    }
  }

  public async unlinkAuthProvider(providerId: string) {
    const currentUser = await (this.afAuth.currentUser);
    currentUser.unlink(providerId);
    this.signOut();
    this.router.navigateByUrl('/login');
  }
}
