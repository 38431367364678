import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Location} from "@deliver-sense-librarian/data-schema";
import {take, takeUntil} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {
  @Input() locations: Location[];
  @Input() selectedLocations: FormControl;
  @Input() multiple = true;
  @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;
  public locationsFilterCtrl: FormControl = new FormControl();
  public filteredLocations: ReplaySubject<Location[]> = new ReplaySubject<Location[]>(1);
  private destroy$ = new Subject();
  constructor() { }

  ngOnInit() {
    this.filteredLocations.next(this.locations.slice());
    this.locationsFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
    this.filteredLocations.next(this.locations.slice());
    this.locationsFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
  }
  toggleSelectAll(selectAllValue: boolean) {
    this.filteredLocations.pipe(take(1), takeUntil(this.destroy$))
      .subscribe(val => {
        if (selectAllValue) {
          this.selectedLocations.patchValue(val.map(location => location.locationId));
          this.selectedLocations.updateValueAndValidity();
        } else {
          this.selectedLocations.patchValue([]);
          this.selectedLocations.updateValueAndValidity();
        }
      });
  }

  filterLocations() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationsFilterCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredLocations.next(
      this.locations.filter(location => location.name.toLowerCase().indexOf(search) > -1 || location.locationId.indexOf(search) > -1)
    );
  }

  getLocationName(locationId: string) {
    const location = this.locations.find(l => l.locationId === locationId);
    return location ? location.name : ''
  }
}
