<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="column" fxLayoutGap="10px" *ngIf="auditTrailDocument">
  <div>
    <h3>
      <ng-container *ngIf="data.setup; else editTitle">
        Setup {{auditTrailDocument.name}} Checklist
      </ng-container>
      <ng-template #editTitle>
        Edit {{auditTrailDocument.name}} Checklist
      </ng-template>
    </h3>
  </div>
  <div>
    <mat-slide-toggle [formControl]="onlyShowUnchecked">
      Only Show not checked
    </mat-slide-toggle>
    <br>
    <small *ngIf="onlyShowUnchecked.value">Cannot reorder when items are filtered <mat-icon>warn</mat-icon></small>
  </div>
  <div [style.max-height]="'40vh'" [style.overflow-y]="'scroll'" cdkScrollable>
    <mat-list cdkDropList [cdkDropListDisabled]="onlyShowUnchecked.value" cdkScrollable
      (cdkDropListDropped)="dropChecklistItem($event)" fxLayout="column">
      <mat-list-item fxFlex *ngFor="let item of filteredChecklistItems; let i = index" cdkDrag cdkDragLockAxis="y">
        <div matLine fxLayout="row" fxLayoutAlign="space-between center" class="checklist-item">
          <button mat-icon-button color="warn" (click)="removeItem(item)">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <div [matTooltip]="item.name" class="checklist-item-text">
            {{item.name}}
          </div>
          <mat-checkbox [disabled]="!!addingChecklistItem" [checked]="item.complete"
            (change)="updateChecklistItemStatus(item, $event)"
            [matTooltip]="item.completedBy && item.completedOn ? getCompletedByText(item) : null"></mat-checkbox>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div>
    <ng-container *ngIf="!addingChecklistItem && !addingFromTemplate; else addingChecklistItemTemplate">
      <div fxLayout="row">
        <button mat-raised-button color="primary" (click)="addingChecklistItem = true">
          Add New Item
        </button>
        <button mat-raised-button (click)="addingFromTemplate = true">
          Add Template
        </button>
      </div>
    </ng-container>
    <ng-template #addingChecklistItemTemplate>
      <ng-container *ngIf="addingChecklistItem">
        <mat-form-field appearance="outline">
          <mat-label>Checklist Item Name</mat-label>
          <input matInput [formControl]="newChecklistItem">
        </mat-form-field>
        <div>
          <button mat-raised-button color="accent" (click)="addNewItem()">Save</button>
          <button mat-raised-button color="warn" (click)="resetAddingForm()">Cancel</button>
        </div>
      </ng-container>
      <ng-container *ngIf="addingFromTemplate">
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Select Checklist Template</mat-label>
            <mat-select [formControl]="selectedChecklistTemplate">
              <mat-option *ngFor="let template of auditTrailDocumentChecklistTemplates" [value]="template">
                <div fxLayout="row">
                  {{template.name}}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayout="column" *ngIf="selectedChecklistTemplate.value" [style.max-height.px]="200" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
              <div fxLayout="row" *ngFor="let item of selectedChecklistTemplate.value.items">
                <div>
                  <mat-icon>check_box_outline</mat-icon>
                </div>{{item.name}}
              </div>
          </div>
        </div>
        <div>
          <button mat-raised-button color="accent" [disabled]="!selectedChecklistTemplate.value" (click)="addTemplateChecklistItems()">Add Template Items</button>
        </div>
        <div>
          <button mat-raised-button color="warn" (click)="resetAddingForm()">Cancel</button>
        </div>
      </ng-container>
    </ng-template>

  </div>
</div>
