
<form [formGroup]="commentForm" fxLayout="column" fxLayoutGap="10px" *ngIf="commentForm" [style.width]="'50vw'">
  <ng-container *ngIf="comment.creator === uiState.authUser.id; else staticCommentText">
    <mat-form-field id="comment-text" appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea matInput formControlName="comment"></textarea>
    </mat-form-field>
  </ng-container>
  <ng-template #staticCommentText>
    <div fxFlex>
      <b>Comment:</b> {{comment['comment']}}
    </div>
  </ng-template>
  <div fxLayout="column" fxLayoutGap="12px" fxLayoutAlign.gt-sm="space-evenly start">
    <ng-container *ngIf="comment.creator === uiState.authUser.id; else assigneeText">
      <mat-form-field id="assignee-selector" appearance="outline">
        <mat-label>Assignee</mat-label>
        <mat-select formControlName="assignee" placeholder="status">
          <mat-option *ngFor="let user of availableAssignees" [value]="user.id">
            {{user.firstName}} {{user.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #assigneeText>
      <div>
      <b>Assignee: </b>
        {{getCommentAssigneeName()}}
      </div>
    </ng-template>
    <ng-container *ngIf="comment.creator === uiState.authUser.id; else viewDueDate">
      <mat-form-field appearance="outline">
        <mat-label>Due Date</mat-label>
        <input matInput [matDatepicker]="dueDatePicker" formControlName="dueDate">
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <ng-template #viewDueDate>
      <div *ngIf="comment.dueDate">
        <b>Due Date: </b>
        {{getCommentDateFormatted(comment)}}
      </div>
    </ng-template>
    <ng-container
      *ngIf="(comment.status === 3 && comment.creator === uiState.authUser.id) || comment.assignee === uiState.authUser.id || comment.creator === uiState.authUser.id; else viewStatus">
      <mat-form-field id="status-selector"
                      appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" placeholder="status">
          <mat-option *ngFor="let commentStatus of commentStatuses" [disabled]="commentStatus.disabled"
                      [value]="commentStatus.value">
            {{commentStatus.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #viewStatus>
      <div *ngIf="comment.status">
        <b>Status: </b>{{comment.status}}
      </div>
    </ng-template>
  </div>
  <div fxLayout="row">
    <button mat-raised-button color="primary"
            (click)="saveSectionComment()">
      Save
      <mat-icon>save</mat-icon>
    </button>
    <button mat-raised-button color="warn" *ngIf="comment.id && comment.creator === uiState.authUser.id"
            (click)="deleteSectionComment()">
      Delete
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
</form>
