import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Tutorial, TutorialStep} from "../../shared/ds-constant";
import {Yarrow} from 'yarrow';
import {BehaviorSubject} from "rxjs";
import {ScrollToService} from "@nicky-lenaers/ngx-scroll-to";

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-bottom-sheet.component.html',
  styleUrls: ['./tutorial-bottom-sheet.component.scss']
})
export class TutorialBottomSheetComponent implements OnInit, OnDestroy {
  public tutorial: Tutorial;
  private currentStep: BehaviorSubject<TutorialStep> = new BehaviorSubject(null);
  private yarrow: Yarrow;
  private arrows: any[] = [];
  imageLoaded = false;
  mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );

  constructor(public bottomSheetRef: MatBottomSheetRef<TutorialBottomSheetComponent>,
              private scrollToService: ScrollToService,
              zone: NgZone,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.tutorial = data.tutorial as Tutorial;
    this.mediaMatcher.addListener(mql =>
      zone.run(() => {
        // @ts-ignore
        this.mediaMatcher = mql;
      })
    );
  }

  ngOnInit() {
    this.yarrow = new Yarrow();
    this.listenForStepChange();
  }

  ngOnDestroy(): void {
    this.removeArrows();
  }

  close() {
    this.removeArrows();
    if (this.currentStep.value.endAction) {
      const el = document.querySelector(this.currentStep.value.endAction);
      if (el) {
        el.dispatchEvent(new Event('click'));
      }
    }
    this.bottomSheetRef.dismiss();
  }

  get currentStepIndex() {
    return this.currentStep.value ? this.tutorial.steps.indexOf(this.currentStep.value) : 0;
  }

  previousStep() {
    if (this.currentStepIndex > 0) {
      if (this.currentStep.value.endAction) {
        const el = document.querySelector(this.currentStep.value.endAction);
        if (el) {
          el.dispatchEvent(new Event('click'));
        }
      }
      this.currentStep.next(this.tutorial.steps[this.currentStepIndex - 1]);
    }
  }

  nextStep() {
    if (this.currentStepIndex < this.tutorial.steps.length - 1) {
      if (this.currentStep.value.endAction) {
        const el = document.querySelector(this.currentStep.value.endAction);
        if (el) {
          el.dispatchEvent(new Event('click'));
        }
      }
      this.currentStep.next(this.tutorial.steps[this.currentStepIndex + 1]);
    }
  }

  start() {
    this.currentStep.next(this.tutorial.steps[0]);
  }

  private async listenForStepChange() {
    this.currentStep.subscribe(async (currentStep$: TutorialStep) => {
      this.removeArrows();
      if (currentStep$) {
        if (currentStep$.delay) {
          await new Promise(resolve => setTimeout(() => {
            resolve()
          }, currentStep$.delay))
        }
        if (currentStep$.scrollTo) {
          this.scrollToService.scrollTo({
            target: currentStep$.scrollTo
          })
        }
        if (currentStep$.startAction) {
          const el = document.querySelector(currentStep$.startAction);
          if (el) {
            el.dispatchEvent(new Event('click'));
          }
        }
        if (currentStep$.mainImage) {
          const imageLoadWaitInterval = setInterval(async () => {
            if (this.imageLoaded) {
              clearInterval(imageLoadWaitInterval);
              currentStep$.targets.forEach(target => {
                if (document.getElementById(target)) {
                  this.makeLine(currentStep$.source, target, currentStep$.arrowText);
                }
              });
              await Promise.all(this.arrows.map(arrow => arrow.render()));
              this.imageLoaded = false;
            }
          }, 500);
        } else {
          setTimeout(async () => {
            currentStep$.targets.forEach(target => {
              this.makeLine(currentStep$.source, target, currentStep$.arrowText);
            });
            await Promise.all(this.arrows.map(arrow => arrow.render()));
          }, 500)
        }
      }
    });
  }

  private async makeLine(source, target, arrowText) {
    if (!this.mediaMatcher.matches) {
      this.arrows.push(this.yarrow.arrow({
        source: document.getElementById(source),
        target: document.getElementById(target),
        x1: function (_, u) {
          // _ - this options object has already had explicit values for `source` and `target`
          return _.source.left + _.source.width / 3;
        },
        y1: function (_, u) {
          return _.source.top + _.source.height / 3;
        },
        x2: function (_, u) {
          return _.target.left + _.target.width / 2;
        },
        y2: function (_, u) {
          return _.target.top + _.target.height / 2;
        },
        text: arrowText,
        textStartOffset: 200,
        textReverseDirection: true,
        arrowStyles: {
          'stroke': '#00e9b0',
          'stroke-width': 6,
        },
        textStyles: {
          'fill': '#fff',
          'font-size': 20,
          'font-weight': 'bold'
        }
      }));
    }
  }

  private removeArrows() {
    if (this.arrows.length > 0) {
      this.arrows.forEach(arrow => {
        arrow.dispose(500, 0);
      });
      this.arrows = [];
    }
  }
}
