import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil, combineAll } from 'rxjs/operators';
import { AuditTrailDocumentSectionComment, AuditTrailUserSectionCommentFlag, AuditTrailDocumentSection, AuditTrailDocumentSectionCommentStatuses, AuditTrailDocument } from '@deliver-sense-librarian/data-schema';
import { Subject, from, combineLatest } from 'rxjs';
import moment from 'moment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuditTrailDocumentSectionDialogComponent } from 'app/dialogs/audit-trail-document-section-dialog/audit-trail-document-section-dialog.component';
import { AuditTrailDocumentCommentDialogComponent } from 'app/dialogs/audit-trail-document-comment-dialog/audit-trail-document-comment-dialog.component';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';

@Component({
  selector: 'app-audit-trail-viewer-comments',
  templateUrl: './audit-trail-viewer-comments.component.html',
  styleUrls: ['./audit-trail-viewer-comments.component.scss']
})
export class AuditTrailViewerCommentsComponent implements OnInit, OnDestroy {
  @Input() auditTrailDocumentSectionId: string;
  @Input() auditTrailDocumentId: string;
  @Output() onCommentSelect = new EventEmitter();
  public comments: AuditTrailDocumentSectionComment[] = [];
  public uiState: UiState;
  public userCommentFlags: AuditTrailUserSectionCommentFlag[] = [];
  public selectedSection: AuditTrailDocumentSection;
  public selectedComment: AuditTrailDocumentSectionComment;
  private destroy$ = new Subject();
  document: AuditTrailDocument;

  constructor(private store: Store<any>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.uiState = uiState$;
        }
      });
    this.fetchResources();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setSelectedComment(commentId) {
    this.selectedComment = commentId;
    this.onCommentSelect.emit(commentId);
  }
  editSection(section) {
    this.dialog.open(AuditTrailDocumentSectionDialogComponent, {
      data: {
        section: section,
        documentId: this.auditTrailDocumentId
      }
    });
  }



  async toggleCommentFlag(toggleOn: boolean, comment: AuditTrailDocumentSectionComment) {
    if (toggleOn) {
      const commentFlag = new AuditTrailUserSectionCommentFlag();
      commentFlag.user = this.uiState.authUser.id;
      commentFlag.comment = comment.id;
      commentFlag.active = true;
      await this.afs.collection('auditTrailUserSectionCommentFlags').add(commentFlag.toJSONObject());
    } else {
      const existingFlag = this.userCommentFlags.find(flag => flag.comment === comment.id);
      if (existingFlag) {
        await this.afs.doc(`auditTrailUserSectionCommentFlags/${existingFlag.id}`).delete();
      }
    }
  }

  async dropComment(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.comments, event.previousIndex, event.currentIndex);
      const batchUpdate = this.afs.firestore.batch();
      this.comments.forEach(comment => {
        batchUpdate.update(
          this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.selectedSection.id}/comments/${comment.id}`).ref,
          {
            order: this.comments.indexOf(comment),
            dateUpdated: moment().toDate()
          })
      });
      await batchUpdate.commit();
      this.snackBar.open('Successfully updated comment order', 'Dismiss', {
        duration: 5000
      })
    }
  }
  private fetchResources() {
    this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}`)
      .snapshotChanges().pipe(takeUntil(this.destroy$))
      .subscribe(document$ => {
        this.document = FirestoreUtilities.objectToType(document$) as AuditTrailDocument;
      })
    this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}`)
      .snapshotChanges().pipe(takeUntil(this.destroy$))
      .subscribe(section$ => {
        this.selectedSection = FirestoreUtilities.objectToType(section$) as AuditTrailDocumentSection;
      })
    this.afs.collection(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}/comments`)
      .snapshotChanges().pipe(takeUntil(this.destroy$))
      .subscribe(comments$ => {
        this.comments = FirestoreUtilities.mapToType(comments$).sort((a, b) => {
          return a.order < b.order ? -1 : 1;
        });
        this.fetchCommentFlags();
      })
    // combineLatest(
    //   [
    //     this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}`)
    //       .snapshotChanges(),
    //     this.afs.doc(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}`)
    //       .snapshotChanges(),
    //     this.afs.collection(`auditTrailDocuments/${this.auditTrailDocumentId}/sections/${this.auditTrailDocumentSectionId}/comments`)
    //       .snapshotChanges()
    //   ]
    // ).pipe(takeUntil(this.destroy$))
    //   .subscribe(([document$, section$, comments$]) => {
    //     console.log('comments list obsv event')
    //     this.selectedSection = FirestoreUtilities.objectToType(section$) as AuditTrailDocumentSection;
    //     this.document = FirestoreUtilities.objectToType(document$) as AuditTrailDocument;
    //     this.comments = FirestoreUtilities.mapToType(comments$);
    //     this.comments.sort((a, b) => {
    //       return a.order < b.order ? -1 : 1;
    //     });
    //     this.fetchCommentFlags();
    //   })
  }

  private setCommentsMetaData() {
    this.comments.forEach((comment: AuditTrailDocumentSectionComment) => {
      comment['flagged'] = this.isCommentFlagged(comment);
      comment['pastDue'] = this.isCommentPastDue(comment);
      comment['statusIcon'] = this.getCommentStatusIcon(comment);
    });
  }
  private getCommentStatusIcon(comment) {
    if (comment) {
      switch (comment.status) {
        case AuditTrailDocumentSectionCommentStatuses.closed:
          return `<i class="material-icons mat-text-accent">check_circle</i>`;
        case AuditTrailDocumentSectionCommentStatuses.cancelled:
          return `<i class="material-icons mat-text-disabled"">cancel</i>`;
        case AuditTrailDocumentSectionCommentStatuses.ready:
          return `<i class="material-icons mat-text-primary">rate_review</i>`;
        case AuditTrailDocumentSectionCommentStatuses.open:
          return `<i class="material-icons mat-text-warn">warning</i>`;
      }
    }
  }
  private isCommentPastDue(comment: AuditTrailDocumentSectionComment) {
    if (comment && comment.dueDate && comment.status !== AuditTrailDocumentSectionCommentStatuses.closed) {
      return comment.dueDate.seconds ? moment().isAfter(moment(comment.dueDate.toDate())) : moment().isAfter(moment(comment.dueDate.toDate()))
    }
    return false;
  }

  private isCommentFlagged(comment) {
    const existingFlag = this.userCommentFlags.find(flag => flag.comment === comment.id);
    if (existingFlag) {
      return true;
    }
    return false;
  }

  private fetchCommentFlags() {
    if (this.comments.length > 0) {
      const flagRequests = this.comments.map(comment => {
        return this.afs.collection('auditTrailUserSectionCommentFlags', ref => ref
          .where('user', '==', this.uiState.authUser.id)
          .where('comment', '==', comment.id))
          .snapshotChanges();
      });
      from(flagRequests)
        .pipe(combineAll())
        .subscribe(userCommentFlags$ => {
          this.userCommentFlags = FirestoreUtilities.mergeCollectionToType(userCommentFlags$);
          this.setCommentsMetaData();
        }, (e) => console.log(e))
    }
  }

  public addComment() {
    const newComment = new AuditTrailDocumentSectionComment();
    newComment.creator = this.uiState.authUser.id;
    newComment.auditTrailDocumentSection = this.selectedSection.id;
    newComment.order = this.comments ? this.comments.length /*inherently +1 of highest index*/ : 0;
    this.dialog.open(AuditTrailDocumentCommentDialogComponent, {
      disableClose: true,
      data: {
        comment: newComment,
        documentId: this.auditTrailDocumentId,
        sectionId: this.auditTrailDocumentSectionId,
        projectId: this.document.project,
      }
    });
  }

}
