<div fxLayout="column" class="comment-messages-container" appWindowHeightMatcher heightCompensation="240">
  <mat-toolbar fxLayout="row"  class="section-comment-list-toolbar">
    <div fxFlex="20">
      <button mat-icon-button [disabled]="!selectedSection" (click)="editSection(selectedSection)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div fxFlex class="inner-text">
      <b [matTooltip]="selectedSection ? selectedSection.name : null">
        {{selectedSection?.name}}</b>
    </div>
    <div fxFlex="10">
      <button mat-icon-button matTooltip="Create new comment" (click)="addComment()" [disabled]="!this.selectedSection">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div [style.overflow-y]="'scroll'" cdkScrollable appWindowHeightMatcher heightCompensation="304">
    <mat-nav-list *ngIf="selectedSection" class="section-comment-list" cdkDropList cdkScrollable
      (cdkDropListDropped)="dropComment($event)">
      <ng-container *ngFor="let comment of comments">
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <mat-list-item cdkDrag cdkDragLockAxis="y" (click)="setSelectedComment(comment.id)"
          [class.active]="selectedComment === comment">
          <app-avatar *ngIf="comment['statusIcon']" fxFlex="5" mat-list-avatar [innerHTML]="comment['statusIcon']">
          </app-avatar>
          <div matLine class="inner-text" [matTooltip]="comment.comment"> {{comment.comment}} </div>
          <small matLine *ngIf="comment.dateCreated">
            <b>Created:</b> {{comment.dateCreated.toDate() | date:'shortDate'}}
          </small>
          <small matLine [class.mat-text-warn]="comment['pastDue']" *ngIf="comment.dueDate">
            <b>Due:</b> {{comment.dueDate.toDate() | date:'shortDate'}}
          </small>
          <ng-container *ngIf="comment['flagged']; else noFlag">
            <mat-icon mat-list-icon (click)="$event.stopPropagation(); toggleCommentFlag(false, comment)" color="warn">
              flag
            </mat-icon>
          </ng-container>
          <ng-template #noFlag>
            <mat-icon mat-list-icon (click)="$event.stopPropagation(); toggleCommentFlag(true, comment)">
              outlined_flag
            </mat-icon>
          </ng-template>
        </mat-list-item>
      </ng-container>
    </mat-nav-list>
  </div>
</div>
