import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FirebaseApp } from "@angular/fire";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  Client,
  AuditTrailDocumentSectionCommentStatuses,
  AuditTrailDocument,
  AuditTrailDocumentSection,
  AuditTrailDocumentSectionComment,
  User,
  UserView, UserRoles, Project
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { from, Subject, BehaviorSubject, combineLatest } from "rxjs";
import { combineAll, takeUntil, first, skip, distinctUntilChanged } from 'rxjs/operators';
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { Store } from '@ngrx/store';
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { AuditTrailDocumentDialogComponent } from "../../../../dialogs/audit-trail-document-dialog/audit-trail-document-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { LoadingDialogService } from '../../../../services/loading-dialog.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-review-document-list',
  templateUrl: './audit-trail-document-list.component.html',
  styleUrls: ['./audit-trail-document-list.component.scss']
})
export class AuditTrailDocumentListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<AuditTrailDocument>;
  public displayedColumns: string[] = ['name', 'num_sections', 'creator', 'project', 'open_comments', 'approved_comments', 'completed', 'created'];
  public commentStatuses = AuditTrailDocumentSectionCommentStatuses;
  public creatingNewAuditTrailDoc = false;
  public projects: Project[] = [];
  private user: User;
  private destroy$ = new Subject();
  private auditTrailDocuments: AuditTrailDocument[] = [];
  private auditTrailDocumentSections: AuditTrailDocumentSection[] = [];
  private auditTrailDocumentSectionComments: AuditTrailDocumentSectionComment[] = [];
  private creators: UserView[] = [];
  private client: Client;
  private initialized = false;
  public uiState: UiState;
  public selectedProject = new FormControl('');
  pageEvent = new BehaviorSubject({ pageIndex: 0, pageSize: 10 });
  pageMarkers = [0];
  numberOfProjectDocuments: number;
  pageSizeOptions = [10];

  constructor(private store: Store<any>,
    private firebaseApp: FirebaseApp,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(uiState$ => {
        if (!this.initialized && uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getUserProjects();
          this.selectedProject.valueChanges.subscribe(project$ => {
            if (!!project$) {
              this.getInitialSet();
            }
          })
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private getUserProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.viewer, UserRoles.contributor])
      .pipe(takeUntil(this.destroy$))
      .subscribe(projects$ => {
        this.projects = (projects$) as Project[];
        this.projects.sort((a, b) => {
          return moment(a.dateUpdated.toDate()).isAfter(b.dateUpdated.toDate()) ? -1 : 1;
        })
        if (this.projects.length > 0 && !this.selectedProject.value) {
          this.selectedProject.patchValue(this.projects[0].id);
          this.selectedProject.updateValueAndValidity();
        }
      });
  }
  private async getTotalNumberOfDocuments() {
    const totalProjectDocuments = await this.afs.collection('auditTrailDocuments', ref => ref
      .where('project', '==', this.selectedProject.value))
      .valueChanges()
      .pipe(first())
      .toPromise();
    this.numberOfProjectDocuments = totalProjectDocuments.length;
  }
  private clearSelection() {
    this.auditTrailDocuments = [];
    this.tableData = new MatTableDataSource([]);
  }
  public async getInitialSet() {
    this.clearSelection();
    this.loadingService.isLoading(true, 'Fetching Audit Trail Documents...');
    this.getTotalNumberOfDocuments();
    this.afs.collection('auditTrailDocuments', ref => ref
      .where('project', '==', this.selectedProject.value)
      .orderBy('dateUpdated', 'desc')
      .limit(this.pageEvent.value.pageSize))
      .snapshotChanges().pipe(takeUntil(this.destroy$)).subscribe(auditTrailDocs$ => {
        const auditTrailDocs = FirestoreUtilities.mapToType(auditTrailDocs$);
        this.updateTableData(auditTrailDocs);
      }, () => this.loadingService.isLoading(false));
  }
  pageChange(event) {
    if (this.pageEvent.value.pageIndex > event.pageIndex) {
      this.getPreviousPage();
    } else if (this.pageEvent.value.pageIndex < event.pageIndex) {
      this.getNextPage();
    }
    this.pageEvent.next(event);
  }
  public getPreviousPage() {
    this.loadingService.isLoading(true, 'Loading previous page...');
    this.afs.collection('auditTrailDocuments', ref => ref
      .where('project', '==', this.selectedProject.value)
      .orderBy('dateUpdated', 'asc')
      .startAfter(this.auditTrailDocuments[0].dateCreated)
      .limit(this.pageEvent.value.pageSize))
      .snapshotChanges().pipe(takeUntil(this.destroy$)).subscribe(auditTrailDocs$ => {
        this.updateTableData(auditTrailDocs$);
      }, () => this.loadingService.isLoading(false));
  }
  public getNextPage() {
    this.loadingService.isLoading(true, 'Loading next page...');
    this.afs.collection('auditTrailDocuments', ref => ref
      .where('project', '==', this.selectedProject.value)
      .orderBy('dateUpdated', 'desc')
      .startAfter(this.auditTrailDocuments[this.auditTrailDocuments.length - 1].dateCreated)
      .limit(this.pageEvent.value.pageSize))
      .snapshotChanges().pipe(takeUntil(this.destroy$)).subscribe(auditTrailDocs$ => {
        this.updateTableData(auditTrailDocs$);
      }, () => this.loadingService.isLoading(false));
  }
  private updateTableData(auditTrailDocs) {
    if (auditTrailDocs.length > 0 && this.selectedProject.value === auditTrailDocs[0].project) {
      this.auditTrailDocuments = auditTrailDocs.sort((a: AuditTrailDocument, b: AuditTrailDocument) => {
        return moment(a.dateUpdated.toDate()).isBefore(moment(b.dateUpdated.toDate())) ? 1 : -1;
      });
      this.getAuditTrailDocumentSections();
      this.getAuditTrailDocumentCreators();
    } else {
      this.loadingService.isLoading(false);
    }
    this.tableData = new MatTableDataSource(this.auditTrailDocuments);
  }
  private getAuditTrailDocumentSections() {
    const auditTrailDocumentSectionRequests = this.auditTrailDocuments.map(doc$ => {
      return this.afs.collection(`auditTrailDocuments/${doc$.id}/sections`)
        .snapshotChanges()
    });
    from(auditTrailDocumentSectionRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(auditTrailDocumentSections$ => {
        this.auditTrailDocumentSections = [];
        const collectionDocs = [].concat.apply([], auditTrailDocumentSections$);
        collectionDocs.forEach(section$ => {
          const docId = FirestoreUtilities.getSubcollectionParent('auditTrailDocuments', section$);
          const section = FirestoreUtilities.objectToType(section$);
          section.auditTrailDocument = docId;
          this.auditTrailDocumentSections.push(section);
        });
          this.loadingService.isLoading(false);
      }, () => this.loadingService.isLoading(false));
  }



  private getAuditTrailDocumentCreators() {
    const creatorRequests = this.auditTrailDocuments.map(doc$ => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(userViews$ => {
        this.creators = FirestoreUtilities.mergeToType(userViews$).filter(item => !!item);
      })
  }

  private mapDocumentMetaData() {
    this.auditTrailDocuments.forEach(doc$ => {
      doc$['sections'] = this.auditTrailDocumentSections.filter(section$ => section$.auditTrailDocument === doc$.id);
      doc$['sections'].forEach(section$ => {
        section$['comments'] = this.auditTrailDocumentSectionComments.filter(comment$ => comment$.auditTrailDocumentSection === section$.id);
      });
    });
  }
  getDocumentSections(auditTrailDocument: AuditTrailDocument) {
    return this.auditTrailDocumentSections.filter(section => section.auditTrailDocument === auditTrailDocument.id);
  }
  public getNumberOfComments(sections$: AuditTrailDocumentSection[], isOpen = true) {
    return sections$.reduce((prev, section$) => {
      if (isOpen) {
        return prev += (+section$.totalReady ? +section$.totalReady : 0) + (+section$.totalOpen ? +section$.totalOpen: 0);
      } else {
        return prev += (+section$.totalClosed ? +section$.totalClosed : 0);
      }
    }, 0);
  }

  public getCreatorInfo(doc$: AuditTrailDocument) {
    const creator = this.creators.find(user => user.id === doc$.creator);
    return creator ? creator.email ? creator.email : `${creator.firstName} ${creator.lastName}` : 'N/A';
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  isDocumentReviewCompleted(auditTrailDocument: AuditTrailDocument) {
    const numberOfOpenComments = this.getNumberOfComments(this.getDocumentSections(auditTrailDocument));
    if (numberOfOpenComments === 0) {
      return true;
    } else {
      return false;
    }
  }

  getProjectName(doc: AuditTrailDocument) {
    const project = this.projects.find(_project => _project.id === doc.project);
    return project ? project.name : '';
  }

  createNewDocument() {
    const newDocument = new AuditTrailDocument();
    newDocument.creator = this.user.id;
    this.dialog.open(AuditTrailDocumentDialogComponent, {
      data: {
        document: newDocument
      }
    })
  }
}
