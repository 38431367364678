<ng-container *ngIf="user">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>{{user.firstName}} {{user.lastName}}</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6>Manage your account and profile below!</h6>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-tab-group>
      <mat-tab [label]="'Basic Information'">
        <div fxLayout="column" fxLayoutGap="12px" *ngIf="userForm">
          <div fxLayout="column">
            <div>
              <app-avatar [userView]="user"></app-avatar>
            </div>
            <div>
              Set a picture:
              <app-document-upload [storagePath]="storagePath" [compressFile]="false"
                (imageUploadComplete)="pictureUploaded($event)"></app-document-upload>
            </div>
            <div *ngIf="user.picturePath">
              <button mat-raised-button color="red" (click)="clearPicture()">
                Clear Picture:
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <form [formGroup]="userForm" (ngSubmit)="saveUserForm()">
              <div fxLayout="row">
                <mat-form-field appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName">
                </mat-form-field>
              </div>
              <div fxLayout="row">
                <mat-form-field appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName">
                </mat-form-field>
              </div>
            </form>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <button mat-raised-button color="primary" (click)="saveUserForm()">Save</button>
            </div>
            <span flex></span>
            <div>
              <button mat-raised-button color="warn" (click)="deleteUserAccount()">Delete Account</button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Security">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <h3>Change Your Account Email</h3>
          <mat-icon fontSet="fas" fontIcon="fa-info-circle" matSuffix
            matTooltip="Your account email is what you use to login and where Vexed Professional emails will be sent.">
          </mat-icon>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
          <mat-form-field fxFlex="40%" appearance="outline">
            <mat-label>Current Email</mat-label>
            <input type="text" matInput [(ngModel)]="user.email" placeholder="Current Email" disabled>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
          <mat-form-field fxFlex="40%" appearance="outline">
            <mat-label>New Email</mat-label>
            <input type="text" matInput #newEmailInput placeholder="New Email">
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
          <mat-form-field fxFlex="40%" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput #passwordInput placeholder="ConfirmPassword">
            <mat-icon fontSet="fas" fontIcon="fa-info-circle" matSuffix
              matTooltip="For security purposes, you must provide your password in order to update your account email.">
            </mat-icon>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row">
          <button fxFlex="20%" mat-raised-button color="accent"
            (click)="authService.updateEmail(user, newEmailInput, passwordInput)">
            Update Email
          </button>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row">
          <h3>Change Your Password</h3>
          <mat-icon fontSet="fas" fontIcon="fa-info-circle" matSuffix
            matTooltip="Clicking a button below will send a link to your account email that will allow you to change your password.">
          </mat-icon>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
          <button fxFlex="20%" mat-raised-button color="warn" (click)="resetPassword()">Reset
            Password
          </button>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column" fxLayoutGap="12px">
          <h3><u>Linked Authentication</u></h3>
          <h5>You can link other methods of authentication to your profile. Click the provider link you wish to
            associate
            your account with and follow the authentication steps.</h5>
          <div>
            <ng-container *ngIf="microsoftProvider; else linkMicrosoft">
              <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png">
              Microsoft Linked
              <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
              <button mat-raised-button color="warn" (click)="unlinkAuthProvider(microsoftProvider)">Unlink</button>
            </ng-container>
            <ng-template #linkMicrosoft>
              <button mat-raised-button [disabled]="" (click)="linkProvider('microsoft')" [style.width.px]="200">
                <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png">Link Active Directory
              </button>
            </ng-template>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div>
            <ng-container *ngIf="googleProvider; else linkGoogle">
              <img [style.width.px]="25" src="/assets/images/google-login-symbol.png">
              Google Linked
              <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
              <button mat-raised-button color="warn" (click)="unlinkAuthProvider(googleProvider)">Unlink</button>
            </ng-container>
            <ng-template #linkGoogle>
              <button mat-raised-button (click)="linkProvider('google')" [style.width.px]="200">
                <img [style.height.px]="25" src="/assets/images/google-login-symbol.png">
                Link Google Account
              </button>
            </ng-template>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div>
            <ng-container *ngIf="passwordProvider; else linkEmail">
              <mat-icon fontSet="fas" fontIcon="fa-key"></mat-icon>
              Email Password Linked
              <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
            </ng-container>
            <ng-template #linkEmail>
              <form [formGroup]="linkPasswordForm">
                <mat-form-field appearance="outline" style="width: 100%">
                  <input matInput placeholder="Email address" type="email"
                    [formControl]="linkPasswordForm.controls['email']">
                </mat-form-field>
                <small
                  *ngIf="linkPasswordForm.controls['email'].hasError('required') && linkPasswordForm.controls['email'].touched"
                  class="mat-text-warn">You
                  must include an email address.
                </small>
                <small
                  *ngIf="linkPasswordForm.controls['email'].errors?.email && linkPasswordForm.controls['email'].touched"
                  class="mat-text-warn">You
                  must include a valid email address.
                </small>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput type="password" placeholder="Password"
                      [formControl]="linkPasswordForm.controls['password']">
                  </mat-form-field>
                  <small
                    *ngIf="linkPasswordForm.controls['password'].hasError('required') && linkPasswordForm.controls['password'].touched"
                    class="mat-text-warn">You must include password.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Confirm Password"
                      [formControl]="linkPasswordForm.controls['confirmPassword']" type="password">
                  </mat-form-field>
                  <small
                    *ngIf="linkPasswordForm.controls['confirmPassword'].hasError('required') && linkPasswordForm.controls['confirmPassword'].touched"
                    class="mat-text-warn">You must confirm your password.
                  </small>
                </div>
                <small *ngIf="linkPasswordForm.errors?.notSame" class="mat-text-warn">
                  Passwords do not match.
                </small>
                <button mat-raised-button color="primary" class="btn-block" (click)="showUserForm = false;"
                  [disabled]="!linkPasswordForm.valid">Next
                </button>

              </form>
            </ng-template>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="clients">
        <ng-template matTabContent>
          <app-user-clients></app-user-clients>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-container>
