<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card fxLayout="column" [style.width.px]="400" [style.height]="'60vh'" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
  <mat-card-header>
    <mat-card-title>
      <h2>{{title}}</h2>
    </mat-card-title>
    <mat-card-subtitle>
      <h3>{{tagLine}}</h3>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column">
      <h4>What Is It?</h4>
      <div>
        {{whatIsIt}}
      </div>
      <h4>Who Is It For?</h4>
      <div>
        {{whoIsItFor}}
      </div>
      <h4>How Does It Work?</h4>
      <div>
        {{howItWorks}}
      </div>
      <h4>How Much Does It Cost?</h4>
      <div>
        <div *ngFor="let billingRate of dsModule.billingRates">
          <mat-card class="pricing-plan">
            <mat-card-title>Developer</mat-card-title>
            <mat-card-subtitle class="mat-text-primary">For testing purposes</mat-card-subtitle>
            <mat-card-content>
              <h1><strong class="plan-price">FREE</strong></h1>
              <mat-list dense>
                <mat-list-item>Secure storage</mat-list-item>
                <mat-list-item>Limited to 1 user</mat-list-item>
                <mat-list-item class="plan-feature-inactive mat-text-muted">Data analytics</mat-list-item>
                <mat-list-item class="plan-feature-inactive mat-text-muted">Full search access</mat-list-item>
                <mat-list-item class="plan-feature-inactive mat-text-muted">Automatic backups</mat-list-item>
              </mat-list>
            </mat-card-content>
            <mat-card-actions class="pb-1">
              <button mat-raised-button color="accent">Choose Plan</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
