<ng-container *ngIf="!tableData; else transactionMatchTable">
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px" class="drill-down-report-tools">

  <form [formGroup]="parametersForm">
    <div [style.width.%]="100" fxLayout="row wrap" fxLayoutGap="12px">
      <div>
        <app-location-search [selectedLocations]="parametersForm.get('selectedLocation')" [multiple]="false"
          [locations]="locations">
        </app-location-search>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Third Party</mat-label>
        <mat-select formControlName="selectedThirdParty">
          <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty.id">
            {{thirdParty.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="column" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="center center">
          <h4><b>Acceptable Match Variance:</b></h4>
          <div class="acceptable-variance-value mat-elevation-z3" fxLayout="row" fxLayoutAlign="center center"
            [style.background]="analyticsEngine.perc2color(parametersForm.get('acceptableVariance').value)">
            {{parametersForm.get('acceptableVariance').value}}%
          </div>
          <mat-icon fontSet="fas" fontIcon="fa-info-circle"
            matTooltip="The acceptable match variance is used to determine how close a pos transaction should be to a third party transaction in order to be identified as a match.">
          </mat-icon>
        </div>
        <mat-slider [max]="100" [min]="40" [step]="1" [thumbLabel]="true" [tickInterval]="5"
          formControlName="acceptableVariance">
        </mat-slider>
      </div>
      <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
        <mat-label>Start Date</mat-label>
        <input matInput formControlName="startDate" [min]="minDate" [matDatepicker]="datePicker" placeholder="Day">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
        <mat-label>End Date</mat-label>
        <input matInput formControlName="endDate" [max]="maxDate" [matDatepicker]="endDatePicker" placeholder="Day">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button [disabled]="parametersForm.invalid" (click)="runAnalysis()">
        Run Analysis
      </button>
    </div>
  </form>
</div>
</ng-container>

<ng-template #transactionMatchTable>
    <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px" fxLayoutAlign="center center" class="drill-down-report-tools">
      <div>
        <button mat-raised-button color="warn" (click)="clearResults()">
          Clear Results
        </button>
        <button mat-button (click)="exportReport()" color="accent">
          Export
          <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
        </button>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div>
          <b>Location: </b>{{getSelectedLocationName()}}
        </div>
        <div>
          <b>Third Party: </b>{{getSelectedThirdPartyName()}}
        </div>
      </div>

      <mat-form-field appearance="outline" class="report-drill-down-filter">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
    </div>
  <div class="report-table transaction-drill-down-table" [style.height.px]="getHeight() - 50"
    [style.width]="'100% !important'" [style.margin-top.px]="10">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows class="mat-elevation-z5">

      <ng-container matColumnDef="Pos-Transaction-Date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let match">
          {{match['Pos-Transaction-Date']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Pos-Transaction-Sale">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Sale</mat-header-cell>
        <mat-cell *matCellDef="let match">
          ${{match['Pos-Transaction-Sale']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Pos-Transaction-Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tax</mat-header-cell>
        <mat-cell *matCellDef="let match">
          ${{match['Pos-Transaction-Tax']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Match%">
        <mat-header-cell *matHeaderCellDef mat-sort-header>MATCH %</mat-header-cell>
        <mat-cell *matCellDef="let match" id="match-level"
          [style.background]="analyticsEngine.perc2color((match['Match%']))">
          {{match['Match%'] > 0 ? match['Match%'] + '%' : 'No Match Found'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ThirdParty-Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD</mat-header-cell>
        <mat-cell *matCellDef="let match">
          <div class="content-cell">
            <!-- <button mat-icon-button color="primary" *ngIf="match['ThirdParty-Name']"
                  (click)="changePosHouseAccount(match['DeliverSense-Pos-Transaction-Id'])">
            <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
          </button> -->
            {{match['ThirdParty-Name']}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ThirdParty-Transaction-Date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let match">
          {{match['ThirdParty-Transaction-Date']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ThirdParty-Transaction-Sale">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Sale</mat-header-cell>
        <mat-cell *matCellDef="let match">
          ${{match['ThirdParty-Transaction-Sale']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ThirdParty-Transaction-Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tax</mat-header-cell>
        <mat-cell *matCellDef="let match">
          ${{match['ThirdParty-Transaction-Tax']}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="pos-group">
        <mat-header-cell *matHeaderCellDef [style.flex]="3"> POS Transactions</mat-header-cell>
      </ng-container>
      <ng-container matColumnDef="match-group">
        <mat-header-cell *matHeaderCellDef [style.flex]="1"></mat-header-cell>
      </ng-container>
      <ng-container matColumnDef="tpt-group">
        <mat-header-cell *matHeaderCellDef [style.flex]="4"> 3PD Transactions</mat-header-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['pos-group', 'match-group', 'tpt-group']; sticky: true">
      </mat-header-row>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</ng-template>
