<mat-list>
  <div mat-subheader>
    File Versions
    <span fxFlex></span>
    <button mat-raised-button color="primary" (click)="attachFile()">
      Upload New<mat-icon fontSet="fas" fontIcon="fa-paperclip"></mat-icon>
    </button></div>
  <mat-divider></mat-divider>
  <mat-list-item *ngFor="let file of files">
    <mat-icon mat-list-icon [style.cursor]="'pointer'" fontSet="fas" fontIcon="fa-download" (click)="downloadFile(file)">
    </mat-icon>
    <mat-icon mat-list-icon color="warn" (click)="deleteAttachment(file)">delete_forever</mat-icon>
    <div mat-line>
      <span class="inner-text" [matTooltip]="file.fileName">
        {{file.fileName}}
      </span>
    </div>
    <div mat-line>
      <span class="text-muted">
        Uploaded: {{file.dateCreated?.toDate() | date:'shortDate'}}
      </span>
    </div>
    <div mat-line>
      <mat-divider></mat-divider>
    </div>
  </mat-list-item>
</mat-list>
