import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import { LoadingDialogComponent } from "../dialogs/loading-dialog/loading-dialog.component";
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class LoadingDialogService {
    private loadingDialogRef: any;
    private loading = new BehaviorSubject(false);
    private signupLoading = new BehaviorSubject(false);
    private signup: boolean;
    private text: string;
    private determinateValue: number;
    private allowCancel: boolean;
    public cancelled = new Subject();
    constructor(private matDialog: MatDialog) {
        this.listenToLoading();
        this.listenToSignupLoading();
    }

    private listenToLoading() {
        this.loading.subscribe(loading => {
            if (loading) {
                this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
                    disableClose: true,
                    panelClass: 'invisible-panel-dialog',
                    data: { text: this.text, determinateValue: this.determinateValue, allowCancel: this.allowCancel, cancelled: this.cancelled }
                });
            } else {
                if (this.loadingDialogRef) {
                    this.loadingDialogRef.close();
                }
            }
        })
    }

    private listenToSignupLoading() {
        this.signupLoading.subscribe(loading => {
            if (loading) {
                this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
                    disableClose: true,
                    panelClass: 'invisible-panel-dialog',
                    data: { signup: true }
                });
            } else {
                if (this.loadingDialogRef) {
                    this.loadingDialogRef.close();
                }
            }
        })
    }

    public isLoading(is: boolean, text?: string, determinateValue?: any, allowCancel = false) {
        if (this.loading.getValue() !== is) {
            this.text = text;
            this.allowCancel = allowCancel;
            this.determinateValue = determinateValue;
            this.loading.next(is);
        }
    }

    public isSignupLoading(is: boolean) {
        if (this.signupLoading.getValue() !== is) {
            this.signupLoading.next(is);
        }
    }
}
