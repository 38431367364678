import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../material.module";
import { AuditTrailsRecentFilesComponent, ThirdPartyDeliveryLatestReportsComponent, BillingRecentDayChargesComponent, BillingRecentInvoicesComponent } from './widgets';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { AuditTrailsAssignedCommentsComponent } from './widgets/audit-trails-assigned-comments/audit-trails-assigned-comments.component';
import { AuditTrailsCreatedCommentsComponent } from './widgets/audit-trails-created-comments/audit-trails-created-comments.component';

@NgModule({
  declarations: [
    DashboardComponent,
    AuditTrailsRecentFilesComponent,
    ThirdPartyDeliveryLatestReportsComponent,
    BillingRecentDayChargesComponent,
    BillingRecentInvoicesComponent,
    DashboardWidgetComponent,
    AuditTrailsAssignedCommentsComponent,
    AuditTrailsCreatedCommentsComponent
  ],
  exports: [
    DashboardComponent,
    AuditTrailsRecentFilesComponent,
    ThirdPartyDeliveryLatestReportsComponent,
    BillingRecentDayChargesComponent,
    BillingRecentInvoicesComponent,
    DashboardWidgetComponent,
    AuditTrailsAssignedCommentsComponent,
    AuditTrailsCreatedCommentsComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MalihuScrollbarModule.forRoot()
  ]
})
export class DashboardModule { }
