<div fxLayout="column" fxLayoutGap="20px">
  <ng-container *ngIf="!activated; else uploadDoc">
    <button mat-raised-button color="primary" (click)="activated = true">Create Trail From Upload</button>
  </ng-container>
  <ng-template #uploadDoc>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <mat-form-field appearance="outline" *ngIf="projects && projects.length > 0; else noProjects">
        <mat-label>Select a Project</mat-label>
        <mat-select placeholder="Project" [formControl]="selectedProject">
          <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox [formControl]="includeFile">Attach file
      </mat-checkbox>
      <mat-icon fontSet="fas" fontIcon="fa-info-circle" color="accent" [matTooltip]="'Check this box if you would like to attach the file to this Audit Trail. Document storage rates of $1/GB apply.'">
      </mat-icon>
    </div>

    <ng-template #noProjects>
      <mat-error *ngIf="projects">You must have a project created in order to create an audit trail document.
      </mat-error>
    </ng-template>
    <ng-container *ngIf="selectedProject.valid">
      <input type="file"
             id="image-upload-button-partial"
             fxHide
             (change)="handleFileSelect($event)"/>
      <label for="image-upload-button-partial"
             #documentUploadLabel>
        <button mat-raised-button color="primary" (click)="documentUploadLabel.click()">
          Select File
          <mat-icon>attachment</mat-icon>
        </button>
      </label>
    </ng-container>
  </ng-template>
</div>
