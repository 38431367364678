<mat-card class="dialog-card" [style.maxHeight]="'80vh'">
  <mat-card-header
    [ngClass]="{'error-card-header': type === 'error', 'action-card-header': type === 'action', 'success-card-header': type === 'success'}"
    class="error-card-header">
    <mat-card-title>
      <h2>{{data.title}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutGap="10px" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex="90">{{data.message}}</div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <ng-container *ngIf="data.tableData && keys.length > 0">
        <app-information-dialog-table [style.width.%]="100" [errorData]="data.tableData"
         (updatedRowsToDelete)="setRowsToDelete($event)"></app-information-dialog-table>
      </ng-container>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <ng-container *ngIf="retry && rowsToRemove.length > 0">
      <button mat-raised-button color="primary" (click)="closeAndRetry()">Close and Retry</button>
    </ng-container>
    <button mat-raised-button color="warn" (click)="dialogRef.close()">Close{{retry ? ' and Cancel' : ''}}</button>
  </mat-card-actions>
</mat-card>
