import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, take, takeUntil, first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import { User, Client, TeamMemberInvitation, OrganizationRole } from "@deliver-sense-librarian/data-schema";
import { ActivatedRoute, Router } from "@angular/router";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";
import * as moment from "moment";
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomValidators } from "ng2-validation";
import { verifyPasswordMatch } from "../../shared/validators";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { UnauthenticateUserAction } from "../../redux/custom-states/uiState/ui-state-actions";
import { MatDialog } from '@angular/material/dialog';
import { PolicyDialogComponent } from 'app/dialogs/policy-dialog/policy-dialog.component';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import * as firebase from "firebase";

@Component({
  selector: 'app-team-member-signup',
  templateUrl: './team-member-signup.component.html',
  styleUrls: ['./team-member-signup.component.scss']
})
export class TeamMemberSignupComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public showSignupForm = true;
  public invitationExpired = false;
  public invalidInvitation = false;
  private destroy$ = new Subject();
  private user: User;
  private client: Client;
  private invitationId: string;
  private invitation: TeamMemberInvitation;

  constructor(private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private router: Router,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private auth: FirebaseAuthService,
    private activateRoute: ActivatedRoute,
    private store: Store<any>,
    private fbAuth: FirebaseAuthService) {
  }

  ngOnInit() {
    this.forceSignout().subscribe(async (confirmed) => {
      if (confirmed) {
      await this.auth.signOut();
      this.activateRoute.params.subscribe(params$ => {
        this.invitationId = params$['invitationId'];
        if (this.invitationId) {
          this.getInvitation().subscribe(() => {
            this.store.select(store => store.uiState)
              .pipe(takeUntil(this.destroy$))
              .subscribe(uiState$ => {
                if (uiState$.authUser && uiState$.client) {
                  this.showSignupForm = false;
                  this.user = uiState$.authUser;
                  this.client = uiState$.client;
                } else {
                  this.showSignupForm = true;
                }
              });
          });
        } else {
          this.invalidInvitation = true;
        }
      })
    } else {
      this.router.navigate(['/app'])
    }
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private forceSignout() {
    const userLoggedIn = localStorage.getItem('user');
    if (userLoggedIn) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Signout Required',
          message: 'In order to accept a team invitation you must be signed out of the application. Click, "Yes, Signout Now" if you wish to proceed, or click "Cancel" to return to the application.',
          action: 'Yes, Signout Now'
        }
      });
      return confirmDialog.afterClosed().pipe(map(confirmed => confirmed));
    } else {
      return of(true);
    }
  }
  private getInvitation() {
    return this.afs.doc(`teamMemberInvitations/${this.invitationId}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$), map(invitation$ => {
        this.invitation = FirestoreUtilities.objectToType(invitation$) as TeamMemberInvitation;
        if (this.invitation) {
          if (moment(this.invitation.expiration.toDate()).isSameOrBefore(moment())
            || this.invitation.status === 'complete') {
            this.invitationExpired = true;
          }
          this.setupLoginForm();
          this.setupSignupForm();
        } else {
          this.invalidInvitation = true;
        }
      }));
  }

  public async login() {
    if (this.loginForm.invalid) {
      this.snackBar.open('Please enter your email and password.', 'Dismiss', {
        duration: 5000
      });
      return;
    }
    try {
      this.loadingService.isLoading(true, 'Adding you to the client account.');
      const user$ = await this.fbAuth.login(this.loginForm.value.email, this.loginForm.value.password, this.invitation.id);
      this.loadingService.isLoading(false);
      this.snackBar.open('Login Successful!', 'Dismiss', {
        duration: 5000
      });
      await this.router.navigate(['client-selection']);
    } catch (e) {
      this.snackBar.open('Invalid email and/or password', 'Dismiss', {
        duration: 5000
      });
    }
  }
  async loginWithProvider(provider: 'microsoft' | 'google') {
    await this.fbAuth.authWithProvider(provider, null, this.invitationId);
    this.snackBar.open('Login Successful!', 'Dismiss', {
      duration: 5000
    });
    await this.router.navigate(['client-selection']);
  }

  public async signup() {
    if (this.signupForm.valid) {
      const form = this.signupForm.value;
      const user = new User();
      user.firstName = form.firstName;
      user.lastName = form.lastName;
      user.email = form.email;
      this.fbAuth.register(user, form.password, null, this.invitation.id).subscribe(authUser$ => {
        this.snackBar.open('Registration Successful!', 'Dismiss', {
          duration: 5000
        });
        this.router.navigate(['client-selection']);
      }, (e) => {
        this.loadingService.isLoading(false);
        if (e.error.error === "The email address is already in use by another account.") {
          this.snackBar.open(e.error.error, 'Dismiss', {
            duration: 5000
          });
        } else {
          this.snackBar.open('Error creating your account. Please refresh and try again.', 'Dismiss', {
            duration: 5000
          });
        }
      });
    } else {
      this.snackBar.open('Please complete the signup form.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  async registerWithProvider(provider: 'microsoft' | 'google') {
    await this.fbAuth.authWithProvider(provider, null, this.invitationId);
    this.snackBar.open('Registration Successful!', 'Dismiss', {
      duration: 5000
    });
    await this.router.navigate(['client-selection']);
  }
  private setupLoginForm() {
    this.loginForm = this.fb.group({
      email: new FormControl(this.invitation.email, [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      agreeToTerms: new FormControl('', Validators.required)
    });
  }

  private setupSignupForm() {
    this.signupForm = this.fb.group({
      email: new FormControl(this.invitation.email, [Validators.required, CustomValidators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, Validators.required),
      agreeToTerms: new FormControl('', Validators.required)
    }, { validator: verifyPasswordMatch });
  }



  openTermsOfServiceDialog() {
    this.afs.doc(`staticContents/terms-of-service`).snapshotChanges()
      .pipe(takeUntil(this.destroy$)).subscribe(policy$ => {
        const policy = FirestoreUtilities.objectToType(policy$);
        this.dialog.open(PolicyDialogComponent, {
          data: { policy: policy.value }
        })
      })
  }
  openPrivacyPolicyDialog() {
    this.afs.doc(`staticContents/privacy-policy`).snapshotChanges()
      .pipe(takeUntil(this.destroy$)).subscribe(policy$ => {
        const policy = FirestoreUtilities.objectToType(policy$);
        this.dialog.open(PolicyDialogComponent, {
          data: { policy: policy.value }
        })
      })
  }
}
