<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <button mat-raised-button color="accent" class="corner-button" (click)="backToClientSelection()">
        <mat-icon>arrow_back</mat-icon>
        Back to Client Selection
      </button>
        <mat-card>
          <mat-card-header fxLayout="row" fxLayoutAlign="center center">
            <mat-card-title>
              <h2>Email Verification</h2>
            </mat-card-title>
            <mat-card-subtitle>
              <p>Check your email for the second factor authentication code. Make sure to check your junk folder if you
                haven't received it after 1 minute.</p>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" class="sfa-code-input">
              <mat-label>Enter Code</mat-label>
              <input matInput maxlength="6" [formControl]="authCode" />
            </mat-form-field>
            <div fxLayoutAlign="center center">
              <button mat-raised-button color="accent" (click)="verifyAuth()">Verify</button>
            </div>
          </mat-card-content>
          <div fxLayout="row">
            <a (click)="requestNewSFA()">Request New Code</a>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
