<mat-card class="dialog-card" [style.height]="'80vh'">
  <mat-card-header
    [ngClass]="{'error-card-header': type === 'error', 'action-card-header': type === 'action', 'success-card-header': type === 'success'}"
    class="error-card-header">
    <mat-card-title>
      <h2>Data Upload Errors</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutGap="10px">
    <mat-tab-group>
      <ng-container *ngFor="let key of errorGroupKeys">
        <mat-tab *ngIf="errorGroups[key].length > 0">
          <ng-template matTabLabel>
            <span [matBadge]="errorGroups[key].length" matBadgeColor="warn"
              matBadgeOverlap="false">{{getTabLabel(key)}}</span>
          </ng-template>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxFlex="90"></div>
          </div>
          <ng-container *ngIf="key !== '0'; else missingFieldsDisplay">
            <div fxLayout="column" fxLayoutAlign="start start">
              <app-information-dialog-table [style.width.%]="100" [errorData]="errorGroups[key]"
                [rowsToRemove]="rowsToRemove" (removeRow)="addRemoveRow($event)" (undoRemoveRow)="undoRemoveRow($event)"
                (updatedRowsToDelete)="setRowsToDelete($event)"></app-information-dialog-table>
            </div>
          </ng-container>
          <ng-template #missingFieldsDisplay>
            <h3>Upload is missing the following required fields:</h3>
            <ul>
              <li *ngFor="let field of getMissingFieldsList(errorGroups[key])">
                {{field}}
              </li>
            </ul>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-card-content>
  <mat-card-actions>
      <button mat-raised-button color="primary" (click)="closeAndRetry()">Close and Retry</button>
    <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
  </mat-card-actions>
</mat-card>
