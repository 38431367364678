<div fxFlex>
  <mat-card class="widget-card">
    <mat-card-header class="mat-teal">
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle>{{description}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="loadingWidgetData; else widgetContent">
        <div fxLayout="row" fxLayoutAlign="center center" [style.margin.px]="12">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>
      <ng-template #widgetContent>
        <div MalihuScrollbar [scrollbarOptions]="scrollbarOptions" [style.height.%]="100">
          <mat-list class="my-xs" *ngIf="widgetData && widgetData.length > 0; else noData">
            <ng-template let-item let-last="last" left-first="first" ngFor [ngForOf]="widgetData" [ngForTemplate]="widgetTemplate"></ng-template>
          </mat-list>
          <ng-template #noData>
            <div fxLayout="row" fxLayoutAlign="center center" [style.margin.px]="14">
              <h3>{{noResultsText}}</h3>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </mat-card-content>

  </mat-card>
</div>
