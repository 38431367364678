import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExemptionCertificateManagementComponent} from './exemption-certificate-management.component';
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DocumentUploadModule} from "../../document-upload/document-upload.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LocationSearchModule } from '../../location-search/location-search.module';
import { ExemptTransactionsComponent } from './exempt-transactions/exempt-transactions.component';

@NgModule({
    declarations: [ExemptionCertificateManagementComponent, ExemptTransactionsComponent],
    exports: [ExemptTransactionsComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        DocumentUploadModule,
        LocationSearchModule
    ]
})
export class ExemptionCertificateManagementModule {
}
