<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<app-audit-trail-document-comment [comment]="comment"
                                  [documentId]="documentId"
                                  [sectionId]="sectionId"
                                  [projectId]="projectId"
                                  (saved)="dialogRef.close()">
</app-audit-trail-document-comment>
