import {FirebaseAuthService} from '../auth/services/firebase-auth.service';
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {map} from "rxjs/operators";
import {Store} from '@ngrx/store';

@Injectable()
export class ClientSelectedGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private store: Store<any>) {
  };

  canActivate() {
    const client_data = JSON.parse(localStorage.getItem('client'));
    if (client_data) {
      return true;
    } else {
      this.router.navigate(['client-selection']);
    }
  }
}
