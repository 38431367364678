import { FirebaseAuthService } from '../auth/services/firebase-auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take } from "rxjs/operators";
import { Store } from '@ngrx/store';

@Injectable()
export class NoSfaGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth,
    private store: Store<any>,
    private fbAuth: FirebaseAuthService,
    private router: Router) {
  };

  canActivate() {
    return this.store.select(state => state.uiState)
      .pipe(take(1), map(state => {
        if (state && state.sfaToken) {
          this.router.navigate(['/app']);
        } else {
          return true;
        }
      }));
  }
}
