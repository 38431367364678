<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Create New Report</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="newReportForm">
      <form [formGroup]="newReportForm" fxLayout="column" fxLayoutAlign="space-evenly start" >
        <mat-form-field appearance="outline">
          <mat-label>Report Name</mat-label>
          <input matInput formControlName="name"/>
        </mat-form-field>
        <button mat-raised-button color="accent" type="submit" (click)="save()">Save</button>
      </form>
    </ng-container>
  </mat-card-content>
</mat-card>
