import { Action } from '@ngrx/store';
import { Client, ClientPosSystem, ClientThirdParty, Location, ClientRole } from '@deliver-sense-librarian/data-schema';

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const UNAUTHENTICATION_SUCCESS = 'UNAUTHENTICATION_SUCCESS';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const SET_ACCOUNT_CLIENT = 'SET_ACCOUNT_CLIENT';
export const SET_ACCOUNT_ROLES = 'SET_ACCOUNT_ROLES';
export const SET_CLIENT_THIRD_PARTIES = 'SET_CLIENT_THIRD_PARTIES';
export const SET_CLIENT_POS_SYSTEMS = 'SET_CLIENT_POS_SYSTEMS';
export const SET_CLIENT_LOCATION_OBJECTS = 'SET_CLIENT_LOCATION_OBJECTS';
export const UNSET_SELECTED_CLIENT = 'UNSET_SELECTED_CLIENT';
export const SET_AUTHORIZED_CLIENTS = 'SET_AUTHORIZED_CLIENTS';
export const SFA_SUCCESS = 'SFA_SUCCESS';
export const CLEAR_SFA = 'CLEAR_SFA';

export class ClearSfaAction implements Action {
  readonly type = CLEAR_SFA;
  constructor(public payload: any) { }
}
export class SfaSuccessAction implements Action {
  readonly type = SFA_SUCCESS;
  constructor(public payload: { token, client }) { }
}
export class SetAuthorizedClientsAction implements Action {
  readonly type = SET_AUTHORIZED_CLIENTS;
  constructor(public payload?: ClientRole[]) { }
}
export class UnsetSelectedClientAction implements Action {
  readonly type = UNSET_SELECTED_CLIENT;

  constructor(public payload?: any) {

  }
}
export class AuthenticateUserAction implements Action {
  readonly type = AUTHENTICATE_USER;

  constructor(public payload?: any) {
  }
}

export class UnauthenticateUserAction implements Action {
  readonly type = UNAUTHENTICATE_USER;

  constructor() {
  }
}

export class SetAccountClientAction implements Action {
  readonly type = SET_ACCOUNT_CLIENT;

  constructor(public payload?: Client) {
  }
}

export class UserAuthenticationSuccessAction implements Action {
  readonly type = AUTHENTICATION_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class UserAuthenticationFailedAction implements Action {
  readonly type = AUTHENTICATION_FAILED;

  constructor(public payload?: any) {
  }
}

export class UnauthenticatedUserSuccessAction implements Action {
  readonly type = UNAUTHENTICATION_SUCCESS;

  constructor() {
  }
}

export class SetAccountRolesAction implements Action {
  readonly type = SET_ACCOUNT_ROLES;

  constructor(public payload: { clientRole, entities, locations, departments, projects }) {
  }
}


export class SetClientLocationObjectsAction implements Action {
  readonly type = SET_CLIENT_LOCATION_OBJECTS;

  constructor(public payload: Location[]) {
  }
}


export class SetClientPosSystemsAction implements Action {
  readonly type = SET_CLIENT_POS_SYSTEMS;

  constructor(public payload: ClientPosSystem[]) {
  }
}

export class SetClientThirdPartiesAction implements Action {
  readonly type = SET_CLIENT_THIRD_PARTIES;

  constructor(public payload: ClientThirdParty[]) {
  }
}


