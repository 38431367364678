import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HelpService {
    private tip = new BehaviorSubject(null);

    constructor() {
    }

    getTip() {
        return this.tip;
    }

    setTip(newTip) {
        this.tip.next(newTip)
    }

    clearTip() {
        this.tip.next(null);
    }
}
