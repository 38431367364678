import { User } from '@deliver-sense-librarian/data-schema';
import { Store } from '@ngrx/store';
import { tableExpandAnimation } from './../../../../shared/ds-constant';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { PosTransaction, ExemptionCertificate, Client } from '@deliver-sense-librarian/data-schema';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-exempt-transactions',
  templateUrl: './exempt-transactions.component.html',
  styleUrls: ['./exempt-transactions.component.scss'],
  animations: tableExpandAnimation
})
export class ExemptTransactionsComponent implements OnInit, AfterViewInit {
  @Input() transactions: PosTransaction[] = [];
  @Input() storagePath: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['documented', 'id', 'location', 'state', 'date', 'sale', 'tax', 'expandTrigger'];
  public expandedElement: any | null;
  private excNoteUpdateTimeout: any;
  private destroy$ = new Subject();
  private client: Client;
  private user: User;

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
        }
      });
    this.tableData = new MatTableDataSource(this.transactions);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {

  }
  applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  updateExemptionCertificateNotes(exemptionCertificate: ExemptionCertificate, value) {
    if (this.excNoteUpdateTimeout) {
      clearTimeout(this.excNoteUpdateTimeout);
    }
    this.excNoteUpdateTimeout = setTimeout(() => {
      this.afs.doc(`exemptionCertificates/${exemptionCertificate.id}`).update({
        notes: value
      }).then(() => {
        this.snackBar.open('Exemption Certificate notes updated successfully', 'Dismiss', {
          duration: 5000
        })
      })
    }, 300)
  }
  async addExemptionCert($event: any) {
    if (this.expandedElement) {
      await this.afs.collection('exemptionCertificates').add({
        posTransaction: this.expandedElement.id,
        client: this.client.id,
        document: $event.path,
        fileSize: $event.size,
        fileName: $event.fileName
      })
      this.snackBar.open('Exemption Certificate added successfully', 'Dismiss', {
        duration: 5000
      })
    } else {
      this.snackBar.open('Oops.. something went wrong. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      })
    }

  }

  async deleteExemptionCertificate(exemptionCertificate: ExemptionCertificate) {
    const path = exemptionCertificate.document;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to remove this exemption certificate?',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`exemptionCertificates/${exemptionCertificate.id}`).delete();
        await FirestoreUtilities.deleteStorageFile(path, this.storage);
        this.snackBar.open('Exemption Certificate removed successfully', 'Dismiss', {
          duration: 5000
        })
      }
    });

  }

  exportDocumentation() {

  }
}
