import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Client, User } from "@deliver-sense-librarian/data-schema";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';

@Component({
  selector: 'app-client-billing',
  templateUrl: './client-billing.component.html',
  styleUrls: ['./client-billing.component.scss']
})
export class ClientBillingComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @Input() user: User
  private destroy$ = new Subject();
  constructor(private afs: AngularFirestore) {
  }
  ngOnInit() {
    if (this.client) {
      this.fetchClient(this.client.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private fetchClient(clientId) {
    this.afs.doc(`clients/${clientId}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(client$ => {
        this.client = FirestoreUtilities.objectToType(client$);
      })
  }



}
