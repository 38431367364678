import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';import {AngularFirestore} from '@angular/fire/firestore';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Client, ClientRole, Department, Entity, Project, User, UserRoles} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';
import {takeUntil} from "rxjs/operators";
import {combineLatest, Subject} from "rxjs";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() selectedClient: Client;
  @Output() complete = new EventEmitter();
  public projectForm: FormGroup;
  public selectedEntity = new FormControl('', Validators.required);
  public entities: Entity[] = [];
  private user: User;
  private client: any;
  private destroy$ = new Subject();
  private departments: Department[] = [];
  public filteredDepartments: Department[] = [];
  private uiState: UiState;

  constructor(private store: Store<any>,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private router: Router,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          if (!this.project) {
            this.activedRoute.params.subscribe(params$ => {
              if (params$['id'] && params$['id'] !== 'new') {
                this.fetchProject(params$['id']);
              } else {
                this.project = new Project();
                this.setupProjectForm()
              }
            });
          } else {
            this.setupProjectForm();
          }
          this.fetchClientEntitiesAndDepartments();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private fetchProject(projectId) {
    this.afs.doc(`projects/${projectId}`)
      .snapshotChanges()
      .subscribe(project$ => {
        this.project = FirestoreUtilities.objectToType(project$);
        this.setupProjectForm();
      })
  }

  private fetchClientEntitiesAndDepartments() {
    combineLatest([
      FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin]),
      FirestoreUtilities.getUserAccessibleResourcesOfType('departments', this.afs, this.uiState.departments, [UserRoles.admin]),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([entities$, departments$]) => {
        this.entities = (entities$);
        this.departments = (departments$);
        if (this.project.department) {
          const selectedDepartment = this.departments.find(d => d.id === this.project.department);
          const selectedEntity = this.entities.find(entity => entity.id === selectedDepartment.entity);
          if (selectedEntity) {
            this.selectedEntity.patchValue(selectedEntity);
            this.selectedEntity.updateValueAndValidity();
            this.filterDepartments(selectedEntity);
          }
        }
      });
    this.selectedEntity.valueChanges.subscribe(selectedEntityId$ => {
      this.filterDepartments(selectedEntityId$);
    });
  }

  private setupProjectForm() {
    this.projectForm = this.fb.group({
      name: new FormControl(this.project.name ? this.project.name : '', Validators.required),
      department: new FormControl(this.project.department ? this.project.department : '', Validators.required),
      description: new FormControl(this.project.description ? this.project.description : ''),
    });
  }

  public async save() {
    if (this.projectForm.valid && this.selectedEntity.valid) {
      if (this.project && this.project.id) {
        const _project = this.mapFormToProject();
        await this.afs.doc(`projects/${this.project.id}`)
          .update(_project.toJSONObject());
      } else {
        const _project = this.mapFormToProject();
        const newProject$ = await this.afs.collection(`projects`).add(_project.toJSONObject());
        await this.afs.doc(`users/${this.user.id}/clientRoles/${this.client.id}/organizationRoles/${newProject$.id}`).set({
          resource: newProject$.id,
          type: 'project',
          role: UserRoles.admin
        });
      }
      this.snackBar.open(`Successfully ${this.project && this.project.id ? 'Updated' : 'Created'} the project`, 'Dismiss', {
        duration: 5000,
      });
      this.complete.emit(true);
      await this.router.navigate(['/app/organization/projects'])
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000,
      });
    }
  }

  private mapFormToProject() {
    const _project = new Project();
    const form = this.projectForm.value;
    _project.name = form.name;
    _project.client = this.client.id;
    _project.department = form.department;
    _project.description = form.description;
    return _project;
  }

  private filterDepartments(entity) {
    this.filteredDepartments = this.departments.filter(department => {
      return department.entity === entity.id;
    })
  }

  delete() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this project? All data associated with this project will also be deleted including Audit Trail documents.',
        action: 'Yes, Delete',
        typeConfirm: this.project.name
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`projects/${this.project.id}`).delete();
        this.loadingService.isLoading(true, 'Deleting project...');
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Project deleted successfully!', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['/app/organization/projects'])
        }, 1000)
      }
    });
  }
}
