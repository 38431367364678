<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <ng-container *ngIf="invalidInvitation">
            <h2>Invitation Is Invalid</h2>
          </ng-container>
          <ng-container *ngIf="invitationExpired">
            <h2>This invitation is expired. Please ask your admin to resend the invitation.</h2>
          </ng-container>
          <div *ngIf="invitationExpired || invalidInvitation">
            <button mat-raised-button color="accent" [routerLink]="'/'">
              Go to Login/Signup
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </div>
          <ng-container *ngIf="!invitationExpired && !invalidInvitation">
            <form [formGroup]="signupForm" *ngIf="signupForm && showSignupForm; else loginFormContainer">
              <div class="text-xs-center pb-1">
                <img src="assets/images/logo-dark-vert.png" alt="" width="30%"/>
                <p class="mat-text-muted">Create an account to accept the invitation.</p>
              </div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div>
                  <button mat-raised-button (click)="registerWithProvider('microsoft')" [style.width.px]="200">
                    <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png"> Add to my organization
                  </button>
                </div>
                <div>
                  <button mat-raised-button (click)="registerWithProvider('google')" [style.width.px]="200">
                    <img [style.height.px]="25" src="/assets/images/google-login-symbol.png"> Register with Google
                  </button>
                </div>
              </div>
              <div fxLayout="column" fxLayoutAlign="space-around">
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Email address" type="email"
                           [formControl]="signupForm.controls['email']">
                  </mat-form-field>
                  <small
                    *ngIf="signupForm.controls['email'].hasError('required') && signupForm.controls['email'].touched"
                    class="mat-text-warn">You
                    must include an email address.
                  </small>
                  <small *ngIf="signupForm.controls['email'].errors?.email && signupForm.controls['email'].touched"
                         class="mat-text-warn">You
                    must include a valid email address.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="First Name"
                           [formControl]="signupForm.controls['firstName']">
                  </mat-form-field>
                  <small
                    *ngIf="signupForm.controls['firstName'].hasError('required') && signupForm.controls['firstName'].touched"
                    class="mat-text-warn">
                    You must provide your first name.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Last Name"
                           [formControl]="signupForm.controls['lastName']">
                  </mat-form-field>
                  <small
                    *ngIf="signupForm.controls['lastName'].hasError('required') && signupForm.controls['lastName'].touched"
                    class="mat-text-warn">
                    You must provide your last name.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput type="password" placeholder="Password"
                           [formControl]="signupForm.controls['password']">
                  </mat-form-field>
                  <small
                    *ngIf="signupForm.controls['password'].hasError('required') && signupForm.controls['password'].touched"
                    class="mat-text-warn">You must include password.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Confirm Password"
                           [formControl]="signupForm.controls['confirmPassword']" type="password">
                  </mat-form-field>
                  <small
                    *ngIf="signupForm.controls['confirmPassword'].hasError('required') && signupForm.controls['confirmPassword'].touched"
                    class="mat-text-warn">You must confirm your password.
                  </small>
                </div>
                <small *ngIf="signupForm.errors?.notSame" class="mat-text-warn">
                  Passwords do not match.
                </small>
                <div class="pb-1">
                  <mat-checkbox formControlName="agreeToTerms"></mat-checkbox>
                   I have read and agree to the <a (click)="openTermsOfServiceDialog()">terms of service.</a> and the <a (click)="openPrivacyPolicyDialog()">privacy policy</a>
                </div>
                <button mat-raised-button color="primary" class="btn-block" (click)="signup()"
                        [disabled]="!signupForm.valid">
                  Register
                </button>
                <button mat-raised-button color="warn" class="btn-block" (click)="showSignupForm = false">
                  I Already Have an Account
                </button>
              </div>
            </form>
            <ng-template #loginFormContainer>
              <form *ngIf="loginForm" [formGroup]="loginForm">
                <div class="text-xs-center pb-1">
                  <img src="assets/images/logo-dark-vert.png" alt="" width="50%"/>
                  <p class="mat-text-muted">Sign in to accept the invitation.</p>
                </div>
                <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                  <div>
                    <button mat-raised-button (click)="loginWithProvider('microsoft')" [style.width.px]="200">
                      <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png"> Sign in with Microsoft
                    </button>
                  </div>
                  <div>
                    <button mat-raised-button (click)="loginWithProvider('google')" [style.width.px]="200">
                      <img [style.height.px]="25" src="/assets/images/google-login-symbol.png"> Sign in with Google
                    </button>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around">
                  <div class="pb-1">
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input matInput placeholder="Email" formControlName="email">
                    </mat-form-field>
                    <small
                      *ngIf="loginForm.controls['email'].hasError('required') && loginForm.controls['email'].touched"
                      class="mat-text-warn">Username
                      is required.</small>
                  </div>
                  <div class="pb-1">
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input matInput type="password" placeholder="Password" formControlName="password">
                    </mat-form-field>
                    <small
                      *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched"
                      class="mat-text-warn">Password is required.</small>
                  </div>
                  <div class="pb-1">
                    <mat-checkbox formControlName="agreeToTerms"></mat-checkbox>
                     I have read and agree to the <a (click)="openTermsOfServiceDialog()">terms of service.</a> and the <a (click)="openPrivacyPolicyDialog()">privacy policy</a>
                  </div>
                  <button mat-raised-button color="primary" (click)="login()" [disabled]="!loginForm.valid">Login
                  </button>
                  <button mat-raised-button color="warn" class="btn-block" (click)="showSignupForm = true">
                    Create an Account
                  </button>
                </div>
              </form>
            </ng-template>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
