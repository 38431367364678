import { MaterialModule } from '../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndServicesDialogComponent } from './terms-and-services-dialog/terms-and-services-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { TermsModule } from "../components/terms/terms.module";
import { PrivacyPolicyDialogComponent } from "./policy-policy-dialog/privacy-policy-dialog.component";
import { PolicyDialogComponent } from "./policy-dialog/policy-dialog.component";
import { HelpModule } from "../components/help/help.module";
import { ReasonPromptDialogComponent } from "./reason-prompt-dialog/reason-prompt-dialog.component";
import { ConfirmLeaveDialogComponent } from './unsaved-changes/confirm-leave-dialog.component';
import { DsSharedModule } from "../shared/ds-shared.module";
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { CreateClientDialogComponent } from './create-client/create-client.dialog.component';
import { ClientsModule } from "../components/organization/clients/clients.module";
import { CreateEntityDialogComponent } from "./create-entity/create-entity.dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { OrganizationModule } from "../components/organization/organization.module";
import { LocationUploadDialogComponent } from './location-upload-dialog/location-upload-dialog.component';
import { TutorialBottomSheetComponent } from './tutorial-bottom-sheet/tutorial-bottom-sheet.component';
import { ThirdPartyReportExportDialogComponent } from "./3pd-report-export-dialog/3pd-report-export-dialog.component";
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { ChangeTransactionThirdPartyDialogComponent } from './change-transaction-third-party-dialog/change-transaction-third-party-dialog.component';
import { EditFieldDialogComponent } from './edit-field-dialog/edit-field-dialog.component';
import { AuditTrailDocumentCommentDialogComponent } from './audit-trail-document-comment-dialog/audit-trail-document-comment-dialog.component';
import { AuditTrailModule } from "../components/ds-modules/audit-trails/audit-trail.module";
import { AuditTrailDocumentDialogComponent } from './audit-trail-document-dialog/audit-trail-document-dialog.component';
import { AuditTrailDocumentSectionDialogComponent } from './audit-trail-document-section-dialog/audit-trail-document-section-dialog.component';
import { TpdReportFieldSelectionDialogComponent } from './tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component';
import { UploadDocumentDialogComponent } from './upload-document-dialog/upload-document-dialog.component';
import { InformationDialogTableComponent } from './information-dialog/information-dialog-table/information-dialog-table.component';
import { AttachAuditTrailFileDialogComponent } from './attach-audit-trail-file-dialog/attach-audit-trail-file-dialog.component';
import { ModuleInformationDialogComponent } from './module-information-dialog/module-information-dialog.component';
import { AuditTrailPingDialogComponent } from './audit-trail-ping-dialog/audit-trail-ping-dialog.component';
import { DataUploadErrorDialogComponent } from './data-upload-error-dialog/data-upload-error-dialog.component';
import { DataUploadDialogComponent } from './data-upload-dialog/data-upload-dialog.component';
import { AuditTrailChecklistTemplateDialogComponent } from './audit-trail-checklist-template-dialog/audit-trail-checklist-template-dialog.component';
import { AuditTrailChecklistViewerDialogComponent } from './audit-trail-checklist-viewer-dialog/audit-trail-checklist-viewer-dialog.component';
import { NewTpdReportDialogComponent } from './new-tpd-report-dialog/new-tpd-report-dialog.component';
import { ThirdPartyDeliveryReportsModule } from 'app/components/ds-modules/3pd-reports/3pd-reports.module';
import { LocationTransactionsByStatusDialogComponent } from './location-transactions-by-status-dialog/location-transactions-by-status-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DsSharedModule,
    FlexLayoutModule,
    RecaptchaModule.forRoot(), // Keep in mind the "forRoot"-magic nuances!
    RecaptchaFormsModule,
    MalihuScrollbarModule.forRoot(),
    TermsModule,
    HelpModule,
    ClientsModule,
    OrganizationModule,
    AuditTrailModule,
    ThirdPartyDeliveryReportsModule,
    MaterialModule
  ],
  declarations: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    CreateClientDialogComponent,
    CreateEntityDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    TutorialBottomSheetComponent,
    ThirdPartyReportExportDialogComponent,
    InformationDialogComponent,
    ChangeTransactionThirdPartyDialogComponent,
    EditFieldDialogComponent,
    AuditTrailDocumentCommentDialogComponent,
    AuditTrailDocumentDialogComponent,
    AuditTrailDocumentSectionDialogComponent,
    TpdReportFieldSelectionDialogComponent,
    UploadDocumentDialogComponent,
    InformationDialogTableComponent,
    AttachAuditTrailFileDialogComponent,
    ModuleInformationDialogComponent,
    AuditTrailPingDialogComponent,
    DataUploadErrorDialogComponent,
    DataUploadDialogComponent,
    AuditTrailChecklistTemplateDialogComponent,
    AuditTrailChecklistViewerDialogComponent,
    NewTpdReportDialogComponent,
    LocationTransactionsByStatusDialogComponent
  ],
  entryComponents: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    CreateClientDialogComponent,
    CreateEntityDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    TutorialBottomSheetComponent,
    ThirdPartyReportExportDialogComponent,
    InformationDialogComponent,
    ChangeTransactionThirdPartyDialogComponent,
    EditFieldDialogComponent,
    AuditTrailDocumentCommentDialogComponent,
    AuditTrailDocumentDialogComponent,
    AuditTrailDocumentSectionDialogComponent,
    TpdReportFieldSelectionDialogComponent,
    UploadDocumentDialogComponent,
    AttachAuditTrailFileDialogComponent,
    ModuleInformationDialogComponent,
    AuditTrailPingDialogComponent,
    DataUploadErrorDialogComponent,
    DataUploadDialogComponent,
    AuditTrailChecklistViewerDialogComponent,
    NewTpdReportDialogComponent,
    LocationTransactionsByStatusDialogComponent
  ],
  exports: [
    InformationDialogTableComponent,
    MaterialModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcfNbkUAAAAAEz20Rw2LMS8NAjPXA9eG-SZ89DF',
      } as RecaptchaSettings,
    },
  ],
})
export class DialogsModule {
}
