<div fxLayout="row" fxLayoutAlign="center center">
  <div [style.margin.px]="12">
    <h2>Modules</h2>
  </div>
</div>
<mat-accordion>
  <ng-container *ngFor="let dsModule of availableModules">
    <ng-template #inactiveModule>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <b>{{dsModule.name}}:</b> <b class="mat-text-warn"> UNAVAILABLE</b>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-template>
    <ng-container *ngIf="!!dsModule && dsModule.active">
      <mat-expansion-panel [disabled]="!isClientModuleActive(dsModule)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <!-- <ng-container *ngIf="!isClientModuleActive(dsModule)">
              <button mat-icon-button color="primary" (click)="showModuleInfo(dsModule)"><mat-icon fontSet="fas" fontIcon="fa-info-circle"></mat-icon></button>
            </ng-container> -->
            <b>{{dsModule.name}}: </b><span fxFlex></span> <i>{{isClientModuleActive(dsModule) ? ' active' : '
              inactive'}}</i>
            <span fxFlex></span>
            <ng-container *ngIf="isClientModuleActive()"><b>Unit Price: </b> {{getClientModule(dsModule).unitRate}}
            </ng-container>
          </mat-panel-title>
          <mat-panel-description fxLayout="column">
            <ng-container *ngIf="isBilledByLocations(dsModule)">
              <div>
                Active Locations: {{clientLocations.length}}
              </div>
            </ng-container>
            <ng-container *ngIf="isBilledByThirdPartyDeliverPartners(dsModule)">
              <div>
                3PD Configurations: {{clientThirdParties.length}}
              </div>
            </ng-container>
            <!-- <ng-container
              *ngIf="isClientModuleActive(dsModule) && !!getClientModule(dsModule) && getClientModule(dsModule).inTrial">
              <span [style.margin]="'0 5px'"><b class="mat-text-accent">IN TRIAL</b></span>
            </ng-container>
            <ng-container
              *ngIf="isClientModuleActive(dsModule) && !!getClientModule(dsModule) && getClientModule(dsModule).inSetup">
              <span [style.margin]="'0 5px'"><b class="mat-text-primary">NEEDS SETUP</b></span>
            </ng-container> -->
            <!-- <span fxFlex></span> -->
            <!-- <ng-container *ngIf="!isClientModuleActive(dsModule); else discontinueModule">
              <button mat-raised-button color="primary"
                (click)="$event.stopPropagation(); activateModule(dsModule)">Activate Module</button>
            </ng-container>
            <ng-template #discontinueModule>
              <button mat-raised-button color="warn"
                (click)="$event.stopPropagation(); deactivateModule(dsModule)">Deactivate Module</button>
            </ng-template> -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column" *ngIf="isClientModuleActive(dsModule)">
          <!-- <div fxLayout="column"> -->
          <!-- <ng-container *ngIf="getClientModule(dsModule).inTrial">
              <div fxLayout="row" fxLayoutAlign="center center">
                <h2>Trial</h2>
              </div>
              <div fxLayout="row">
                <div fxLayout="row">
                  In Trial
                  <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
                </div>
                <mat-checkbox #trialRollOverCheckbox [checked]="getClientModule(dsModule).trialRollOver">
                  Rollover
                  <mat-icon fontSet="fas" fontIcon="fa-info-circle" color="primary"
                    matTooltip="Check this box if you would like to keep this module active after your trial is over. After the trial completes you will start accruing charges daily based on the billing rates below.">
                  </mat-icon>
                </mat-checkbox>
                <ng-container *ngIf="trialRollOverCheckbox.checked !== getClientModule(dsModule).trialRollOver">
                  <button mat-raised-button color="accent"
                    (click)="saveTrialRolloverChanges(dsModule, trialRollOverCheckbox.checked)">Save</button>
                </ng-container>
              </div>
              <div fxLayout="column">
                <h4><b>Trial Start: {{getClientModule(dsModule).trialStart?.toDate() | date:'shortDate'}}</b>
                </h4>
                <h4><b>Trial End: {{getClientModule(dsModule).trialEnd?.toDate() | date:'shortDate'}}</b></h4>
              </div>

              <div fxLayout="row" [style.margin]="'20px 0px'">
                <mat-divider></mat-divider>
              </div>
            </ng-container> -->
          <!-- </div> -->

          <div fxLayout="row" [style.margin]="'20px 0px'">
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <h2>Billing Info</h2>
          </div>
          <ng-container *ngIf="isClientModuleActive(dsModule) && getClientModule(dsModule)">
              <div fxLayout="column">
                <div><b>Unit Type: </b>{{getClientModule(dsModule).billingRate.unit}}</div>
                <div><b>Unit Monthly Rate: </b>{{getClientModule(dsModule).billingRate.unitMonthlyRate}}</div>
                <div><b>Discount</b> {{getClientModule(dsModule).billingRate.discount}}%</div>
                <div><b>Estimated Monthly Cost: ${{getEstimatedMonthlyCost(dsModule)}} </b></div>
                <!-- <ng-container [ngSwitch]="billingRate.unit">
                  <ng-container *ngSwitchCase="'Active Locations'">
                    Your Active Locations: {{totalActiveLocations}}
                  </ng-container>
                  <ng-container *ngSwitchCase="'GB of Storage'">
                    Your Current {{dsModule.name}} Storage Usage:
                  </ng-container>
                  <ng-container *ngSwitchCase="'Project Users'">
                    Total Project Users: {{totalProjectUsers}}
                  </ng-container>
                  <ng-container *ngSwitchCase="'Active 3PDs by Active Locations'">
                    Active 3PD's {{totalActive3pds}}<br><br>
                    Active Locations: {{totalActiveLocations}}
                  </ng-container>
                </ng-container> -->
              </div>
            <div fxLayout="row" [style.margin]="'10px 0'">
              <mat-divider></mat-divider>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</mat-accordion>
