import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ɵisDefaultChangeDetectionStrategy } from '@angular/core';
import { Client, ClientDayCharge, DeliverSenseModule, ClientModule } from '@deliver-sense-librarian/data-schema';
import { FormControl, Validators } from '@angular/forms';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { from, Subject } from 'rxjs';
import { combineAll, takeUntil, map } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-day-charges',
  templateUrl: './client-day-charges.component.html',
  styleUrls: ['./client-day-charges.component.scss']
})
export class ClientDayChargesComponent implements OnInit {
  @Input() client: Client;
  @Input() dsModules: DeliverSenseModule[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['id', 'chargeDate', 'module', 'subTotal', 'unit', 'unitQuantity', 'promo'];
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public dayCharges: ClientDayCharge[] = [];
  public editDayCharge: ClientDayCharge = null;
  private destroy$ = new Subject();
  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.store.select(store => store.uiState)
    .subscribe(uiState$ => {
      if (uiState$.authUser && uiState$.client) {
        this.client = uiState$.client;
        this.afs.collection('deliverSenseModules').snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe(dsModules$ => {
          this.dsModules = FirestoreUtilities.mapToType(dsModules$);
        })
      }
    });
  }
  runSearch() {
    this.dayCharges = null;
    const start = moment(this.startDate.value).startOf('day').toDate();
    const end = moment(this.endDate.value).endOf('day').toDate();
    this.afs.collection('clientDayCharges', ref => ref
      .where('client', '==', this.client.id)
      .where('chargeDate', '>=', start)
      .where('chargeDate', '<=', end)
      .orderBy('chargeDate', 'desc')
    )
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dayCharges$ => {
        this.dayCharges = FirestoreUtilities.mapToType(dayCharges$);
        const uniqueClientModuleIds = _.uniqBy(this.dayCharges, 'clientModule').map(dayCharge => dayCharge.clientModule);
        const clientModuleRequests = uniqueClientModuleIds.map(clientModuleId => {
          return this.afs.doc(`clientModules/${clientModuleId}`).snapshotChanges();
        });
        from(clientModuleRequests)
          .pipe(combineAll(), takeUntil(this.destroy$))
          .subscribe((clientModules$) => {
            const clientModules = FirestoreUtilities.mergeToType(clientModules$);
            this.dayCharges.forEach(dayCharge => {
              const dayChargeClientModule = clientModules.find(clientModule => clientModule.id === dayCharge.clientModule);
              if (dayChargeClientModule) {
                dayCharge.clientModule = dayChargeClientModule;
              }
              dayCharge.subTotal = +(dayCharge.subTotal.toFixed(2));
            });
            this.tableData = new MatTableDataSource(this.dayCharges);
            this.tableData.paginator = this.paginator;
            this.tableData.sort = this.sort;
            this.cdr.detectChanges();
          })
      });
  }
  getDeliverSenseModule(clientModule: ClientModule) {
    if (clientModule && this.dsModules) {
      const module = this.dsModules.find(dsModule => dsModule.id === clientModule.module);
      return module ? module.name : '';
    }
    return '';
  }
  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(3, 'months').toDate();
    }
  }

}
