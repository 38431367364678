import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { User } from 'firebase';
import { Client, ThirdPartyReport } from '@deliver-sense-librarian/data-schema';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { scrollbarOptions } from '../../../../shared/ds-constant';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-third-party-delivery-latest-reports',
  templateUrl: './third-party-delivery-latest-reports.component.html',
  styleUrls: ['./third-party-delivery-latest-reports.component.scss']
})
export class ThirdPartyDeliveryLatestReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  loadingWidgetData: boolean;
  thirdPartyDeliveryReports: ThirdPartyReport[];
  scrollbarOptions = scrollbarOptions;

  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private el: ElementRef) {

  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getRecentTPDReports();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngAfterViewInit() {

  }
  getRecentTPDReports() {
    this.loadingWidgetData = true;
    this.afs.collection('thirdPartyReports', ref => ref
    .where('client', '==', this.client.id)
    .orderBy('dateUpdated', 'desc')
    .limit(5))
    .snapshotChanges()
    .pipe(takeUntil(this.destroy$))
    .subscribe(clientInvoices$ => {
      this.thirdPartyDeliveryReports = <ThirdPartyReport[]>FirestoreUtilities.mapToType(clientInvoices$);
      this.loadingWidgetData = false;
    })
  }
}
