import { Component, OnInit } from '@angular/core';
import { HelpService } from "../../services/help.service";
import { trigger, style, transition, group, animate, state } from "@angular/animations";

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    animations: [
        trigger('windowAppear', [
            transition(':enter', [
                style({ transform: 'translateX(-100vw)', opacity: 0 }),
                group([
                    animate('0.3s 0.1s ease-in-out', style({
                        transform: 'translateX(0)'
                    })),
                    animate('0.3s ease', style({
                        opacity: 1
                    }))
                ])
            ]),
            transition(':leave', [
                group([
                    animate('0.3s ease-in-out', style({
                        transform: 'translateY(-150vh)',
                        height: 10
                    })),
                    animate('0.3s 0.2s ease', style({
                        opacity: 0
                    }))
                ])
            ])
        ])
    ]
})
export class HelpComponent implements OnInit {
    tip: string;

    constructor(private helpService: HelpService) {
    }

    ngOnInit() {
        this.helpService.getTip().subscribe(tip => {
            this.tip = tip;
        })
    }

    clearTip() {
        this.helpService.clearTip();
    }

}
