import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {
    public signup = false;
    public text: string;
    public determinateValue: any;
    public mode;

    constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.signup = data.signup;
            this.determinateValue = data.determinateValue;
            this.mode = data.determinateValue ? 'determinate' : 'indeterminate';
            this.text = data.text;
        }
    }

    ngOnInit() {
    }

  getProgressPrecent() {
      const progress = this.determinateValue.progress ?  +(this.determinateValue.progress).toFixed(0)  : 0;
      return progress > 99 ? 99 : progress;
  }
}
