import {Component, OnInit} from '@angular/core';
import {scrollbarOptions} from 'app/shared/ds-constant';

@Component({
    selector: 'app-terms-and-services-dialog',
    templateUrl: './terms-and-services-dialog.component.html',
    styleUrls: ['./terms-and-services-dialog.component.scss']
})
export class TermsAndServicesDialogComponent implements OnInit {
    scrollbarOptions = scrollbarOptions;

    constructor() {
    }

    ngOnInit() {
    }

}
