import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UploadDocumentService } from 'app/services/upload-document.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { User, AuditTrailDocument, Client, UserView } from '@deliver-sense-librarian/data-schema';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditTrailsService } from '../../services/audit-trails.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-audit-trail-ping-dialog',
  templateUrl: './audit-trail-ping-dialog.component.html',
  styleUrls: ['./audit-trail-ping-dialog.component.scss']
})
export class AuditTrailPingDialogComponent implements OnInit, OnDestroy {
  projectUsers: UserView[] = [];
  user: User;
  auditTrailDocument: AuditTrailDocument;
  client: Client;
  pingForm: FormGroup;
  destroy$ = new Subject();
  constructor(private snackBar: MatSnackBar,
    private atService: AuditTrailsService,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AuditTrailPingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { availableUsers, user, auditTrailDocument, client }) {
    this.user = data.user;
    this.auditTrailDocument = data.auditTrailDocument;
    this.client = data.client;
  }

  ngOnInit() {
    this.setupPinForm();
    this.atService
      .fetchProjectUsers(this.auditTrailDocument)
      .pipe(takeUntil(this.destroy$))
      .subscribe(projectUsers$ => {
        this.projectUsers = projectUsers$
      })
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  setupPinForm() {
    this.pingForm = this.fb.group({
      toUsers: new FormControl([], Validators.required),
      message: new FormControl('', Validators.required),
      auditTrailDocument: new FormControl(this.auditTrailDocument.id, Validators.required),
      fromUser: new FormControl(this.user.id, Validators.required),
      client: new FormControl(this.client.id, Validators.required)
    });
  }


  async sendPing() {
    if (this.pingForm.valid) {
      const newPing = this.pingForm.value;
      await this.afs.collection('auditTrailDocumentPings').add(newPing);
      this.snackBar.open(`A ping has been sent to the selected team members' emails`, 'Dismiss', {
        duration: 5000
      })
      this.dialogRef.close();
    } else {
      this.snackBar.open(`Please select one or more team members and provide a message.' emails`, 'Dismiss', {
        duration: 5000
      })
    }
  }

}
