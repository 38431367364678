<div fxLayout="column" class="policy-container">
    <div fxLayout="row" fxLayoutAlign="end end">
        <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxFlex class="terms-of-service" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
        <div [innerHTML]="policy | sanitizeHtml"></div>
    </div>
</div>
