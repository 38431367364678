import { FormControl, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FirebaseAuthService } from "../../../../auth/services/firebase-auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { ThirdParty, Location, LocationThirdParty, LocationThirdPartyItemRate, RateType, User, UserLocationRole, Client, ClientRole, usStates, Entity, UserRoles } from '@deliver-sense-librarian/data-schema';
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { combineAll, map, takeUntil, distinctUntilChanged, take } from 'rxjs/operators';
import { combineLatest, forkJoin, from, Subject, zip } from "rxjs";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from '@ngrx/store';
import { downloadDataAsFile, tableExpandAnimation } from "../../../../shared/ds-constant";
import { LocationUploadDialogComponent } from "../../../../dialogs/location-upload-dialog/location-upload-dialog.component";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import * as moment from "moment";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  animations: tableExpandAnimation
})
export class LocationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<Location>;
  public mutableTableData: MatTableDataSource<any> & MatTableDataSource<Location>;
  public displayedColumns: string[] = ['active', 'id', 'name', 'entityName', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'totalTaxRate', 'expandTrigger'];
  public isInEditMode = false;
  private locations: Location[] = [];
  expandedElement: any | null;
  private destroy$ = new Subject();
  public thirdParties: ThirdParty[] = [];
  public rateTypes: RateType[] = [];
  public uiState: UiState;
  filterState = new FormControl();
  locationSearchText = new FormControl();
  states = usStates;
  noLocations: boolean;
  onlyShowActive = false;
  rateToEdit: number;
  rateToEditPropertyName: string;
  rateToEditLocation: Location;
  activePopover: any;
  entities: Entity[] = [];
  constructor(private store: Store<any>,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog,
    private papa: Papa,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$),
      distinctUntilChanged((prev: UiState, curr: UiState) => {
        return prev.client === curr.client && prev.authUser === curr.authUser
      }))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.initializationTemplate()
        }
      });
  }
  private initializationTemplate() {
    this.fetchEntitiesAndLocations();
    this.fetchThirdPartiesAndRateTypes();
    this.listenForStateFilter();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  listenForStateFilter() {
    this.filterState.valueChanges.subscribe(() => {
      this.locationSearchText.reset();
      this.mergeDataForTable();
    })
  }
  setRateEditing(property: string, location: Location, activePopover) {
    this.rateToEdit = location[property];
    this.rateToEditPropertyName = property;
    this.rateToEditLocation = location;
    this.activePopover = activePopover;
  }
  async saveRateChanges(value) {
    if (+value && +value > 0 && +value < 100) {
      const updateData = {};
      updateData[this.rateToEditPropertyName] = +value;
      this.afs.doc(`locations/${this.rateToEditLocation.id}`).update(updateData);
      this.snackBar.open(`Rate value updated successfully.`, 'Dismiss', {
        duration: 5000
      });
      this.clearRatePopover();
    } else {
      this.snackBar.open('Rate must be a positive number between 0 and 100', 'Dismiss', { duration: 4000 });
    }
  }
  clearRatePopover() {
    this.rateToEdit = null;
    this.rateToEditPropertyName = null;
    this.rateToEditLocation = null;
    this.activePopover.hide();
    this.activePopover = null;
  }
  applyAllFilter(filterValue: string) {
    const tableFilters = [
      {
        id: 'locationId',
        value: filterValue
      },
      {
        id: 'name',
        value: filterValue
      },
      {
        id: 'entityName',
        value: filterValue
      },
      {
        id: 'addressLine1',
        value: filterValue
      },
      {
        id: 'addressLine2',
        value: filterValue
      },
      {
        id: 'addressCity',
        value: filterValue
      },
      {
        id: 'addressPostalCode',
        value: filterValue
      },
    ];
    this.tableData.filter = JSON.stringify(tableFilters);
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  toggleOnlyActive($event: MatSlideToggleChange) {
    if ($event.checked) {
      const tableFilters = [
        {
          id: 'active',
          value: true
        }
      ];
      this.tableData.filter = JSON.stringify(tableFilters);
      if (this.tableData.paginator) {
        this.tableData.paginator.firstPage();
      }
    } else {
      this.mergeDataForTable();
    }
  }
  private fetchEntitiesAndLocations() {
    const userInternal = this.uiState.authUser.internalRole >= 1;
    const entitiesRequest = userInternal ?
      this.afs.collection('entities', ref => ref.where('client', '==', this.uiState.client.id)).snapshotChanges() :
      FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin, UserRoles.contributor])
    const locationsRequest = userInternal ?
      this.afs.collection('locations', ref => ref.where('client', '==', this.uiState.client.id)).snapshotChanges() :
      FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState.locations, [UserRoles.admin, UserRoles.contributor])
    combineLatest([
     entitiesRequest,
     locationsRequest
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([entitiesResult$, locationsResult$]) => {
        this.entities = userInternal ? FirestoreUtilities.mapToType(entitiesResult$) : entitiesResult$;
        this.locations = userInternal ? FirestoreUtilities.mapToType(locationsResult$) : locationsResult$;
        // set entity for loading speed
        this.locations.forEach(location => {
          const locationEntity = this.entities.find(e => e.id === location.entity);
          location['entityName'] = locationEntity ? locationEntity.name : '';
        });
        this.mergeDataForTable();
      }, (e) => {
        debugger;
      })
  }

  private mergeDataForTable() {
    let locations = Object.assign(this.locations);
    if (this.filterState.value) {
      locations = this.locations.filter(_location => _location.addressState === this.filterState.value)
    }
    this.tableData = new MatTableDataSource<Location>(locations);
    this.mutableTableData = Object.assign(new MatTableDataSource(), this.tableData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filterPredicate =
      (data: Location, filtersJson: string) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);
        filters.forEach(filter => {
          const val = data[filter.id] === null ? '' : data[filter.id];
          if (filter.value === true) { // handle filter for active
            matchFilter.push(val === filter.value);
          } else {
            matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
          }
        });
        return matchFilter.indexOf(true) > -1;
      };
    this.cdr.detectChanges();
  }
  exportReport() {
    const results = this.papa.unparse(this.tableData.data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `${this.uiState.client.name}-locations-${moment().format('M.D.YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }
  private fetchThirdPartiesAndRateTypes() {
    const fetchThirdParties = this.afs.collection('thirdParties').snapshotChanges();
    const fetchRateTypes = this.afs.collection('rateTypes').snapshotChanges();
    combineLatest([
      fetchThirdParties,
      fetchRateTypes,
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([thirdParties$, rateTypes$]) => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$)
          .filter(rate => {
            return rate.name !== 'Beer' &&
              rate.name !== 'Non-Taxable' &&
              rate.name !== 'Wine' &&
              rate.name !== 'Liquor'
          })
          .sort((a) => {
            return a.name === 'Food & N/A Bev' ? -1 : 1;
          });

      })
  }

  getLocationThirdParties(location: Location) {
    return location['thirdParties'] ? location['thirdParties'] : []
  }

  isThirdPartyAvailableForLocation(location: any, thirdParty: ThirdParty) {
    const isDpAvailalbe = this.getLocationThirdParties(location).find(ldp => ldp.thirdParty === thirdParty.id);
    return isDpAvailalbe ? isDpAvailalbe.active : false;
  }

  getThirdPartyDeliveryFee(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp.deliveryFee;
    }
    return 0;
  }

  getThirdPartyMfRate(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp['marketFacilitatorRates'] && ldp['marketFacilitatorRates'][0] ? ldp['marketFacilitatorRates'][0].rate : 0;
    }
    return 0;
  }
  getThirdPartyMfEffectiveDate(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp['marketFacilitatorRates'] && ldp['marketFacilitatorRates'][0] && ldp['marketFacilitatorRates'][0].effectiveDate ?
        `Effective Date: ${moment(ldp['marketFacilitatorRates'][0].effectiveDate.toDate()).format('M/DD/YY')}` :
        null;
    }
    return null;
  }
  getTotalTaxRate(row: any) {
    return (+row.stateTaxRate + +row.cityTaxRate + +row.countyTaxRate + +row.specialTaxRate).toFixed(2);
  }

  openLocationUploadDialog() {
    this.dialog.open(LocationUploadDialogComponent, {
      panelClass: 'invisible-panel-dialog'
    });
  }

  async updateLocationActive($event: MatCheckboxChange, location: Location) {
    await this.afs.doc(`locations/${location.id}`).update({ active: $event.checked });
    const message = $event.checked ? 'Location activated successfully' : 'Location has been deactivated';
    this.snackBar.open(message, 'Dismiss', { duration: 50000 });
  }


}
