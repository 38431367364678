<div fxLayoutAlign="center center" fxLayout="column" class="loading-dialog-container">
    <ng-container *ngIf="signup">
        <div class="text-center">
            <h2>Creating Your Account</h2>
        </div>
    </ng-container>
    <ng-container *ngIf="text">
        <div class="text-center">
            <h2>{{text}}</h2>
        </div>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex class="text-center">
            <mat-progress-spinner [mode]="'indeterminate'" strokeWidth="4"
                                  [value]="determinateValue?.progress"></mat-progress-spinner>
        </div>
    </div>
  <ng-container *ngIf="determinateValue?.progress">
    <div class="text-center">
      <h2>{{getProgressPrecent()}}%</h2>
    </div>
  </ng-container>
    <ng-container *ngIf="signup">
        <div class="text-center">
            <h2>Please Don't Navigate Away or Refresh</h2>
        </div>
    </ng-container>
</div>
