<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>{{entity && entity.id ? ('Edit ' + entity.name) : 'Create Entity'}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="space-evenly start" *ngIf="entityForm">
      <form [formGroup]="entityForm">
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Entity Name</mat-label>
            <input matInput placeholder="Entity Name" formControlName="name">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h3>Address</h3>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Street Address 1</mat-label>
          <input matInput placeholder="Address Line 1" formControlName="addressLine1">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Street Addres 2</mat-label>
          <input matInput placeholder="Address Line 2" formControlName="addressLine2">
        </mat-form-field>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput placeholder="City" formControlName="addressCity">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <mat-select formControlName="addressState">
              <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Postal Code</mat-label>
          <input matInput placeholder="Postal Code" formControlName="addressPostalCode">
        </mat-form-field>
      </form>
      <div fxLayout="row">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <button mat-raised-button [routerLink]="'../'">Cancel</button>
        <button mat-raised-button color="warn" *ngIf="entity && entity.id" (click)="delete()">Delete</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
