import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-unsaved-changes',
    templateUrl: './confirm-leave-dialog.component.html',
    styleUrls: ['./confirm-leave-dialog.component.scss']
})
export class ConfirmLeaveDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ConfirmLeaveDialogComponent>,
                public snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

}
