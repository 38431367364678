<div fxLayout="row">
  <h4>
    {{section && section.id ? 'Edit ' + section.name : 'Create Section'}}
  </h4>
</div>
<form [formGroup]="sectionForm" fxLayout="column" fxLayoutGap="10px">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes"></textarea>
  </mat-form-field>
  <div fxLayout="row">
    <button mat-raised-button color="primary"
            (click)="saveDocument()">
      Save
      <mat-icon>save</mat-icon>
    </button>
  </div>
</form>
