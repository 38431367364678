import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-edit-field-dialog',
  templateUrl: './edit-field-dialog.component.html',
  styleUrls: ['./edit-field-dialog.component.scss']
})
export class EditFieldDialogComponent implements OnInit {
  public value = new FormControl();
  public type: any;
  public options: any;
  public label: any;
  public deleteable: any;


  constructor(public dialogRef: MatDialogRef<EditFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { value, type, options, label, deleteable }) {
    this.value.patchValue(data.value);
    this.type = data.type;
    this.options = data.options;
    this.label = data.label;
    this.deleteable = data.deleteable;
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close({value: this.value.value});
  }
  delete() {
    this.dialogRef.close({delete: true});
  }

}
