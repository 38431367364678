<div fxLayout="row" fxLayoutAlign="start end">
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div class="report-table">
  <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
    <ng-container *ngFor="let column of displayedColumns; let i = index">
      <ng-container *ngIf="column === 'remove'; else dataColumn">
        <ng-container matColumnDef="remove" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row" [class.row-to-remove]="isRowMarkedForRemoval(row.batchRow)">
            <ng-container *ngIf="isRowMarkedForRemoval(row.batchRow); else removeRowButton">
              <button [style.margin-left.px]="-20" mat-raised-button color="primary" (click)="addBackRow(row.batchRow)">Undo Row Removal</button>
            </ng-container>
            <ng-template #removeRowButton>
              <button [style.margin-left.px]="-20" mat-raised-button color="warn" (click)="markRowToDelete(row.batchRow)">Remove</button>
            </ng-template>
          </mat-cell>
        </ng-container>
      </ng-container>
      <ng-template #dataColumn>
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef mat-sort-header [class.error-text]="column === 'Missing Data'">{{column}}</mat-header-cell>
          <mat-cell *matCellDef="let row"  [class.error-text]="column === 'Missing Data'">
            <div [matTooltip]="row[column]">
              {{row[column]}}
            </div>
          </mat-cell>
        </ng-container>
      </ng-template>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let element; columns: displayedColumns;">
    </mat-row>
  </mat-table>
</div>
