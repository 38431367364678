<ng-container *ngIf="!tableData; else transactionMatchTable">
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="5px" class="drill-down-report-tools">
    <ng-container *ngIf="parametersForm">
      <form [formGroup]="parametersForm">
        <div [style.width.%]="100" fxLayout="row wrap">
          <div>
            <app-location-search [selectedLocations]="parametersForm.get('selectedLocation')" [multiple]="false"
              [locations]="locations">
            </app-location-search>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Third Party</mat-label>
            <mat-select formControlName="selectedThirdParty">
              <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty.id">
                {{thirdParty.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
            <mat-label>Start Date</mat-label>
            <input matInput formControlName="startDate" [min]="minDate" [matDatepicker]="datePicker" placeholder="Day">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
            <mat-label>End Date</mat-label>
            <input matInput formControlName="endDate" [max]="maxDate" [matDatepicker]="endDatePicker" placeholder="Day">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button (click)="runAnalysis()">
            Run Analysis
          </button>
        </div>
      </form>
    </ng-container>
  </div>
</ng-container>
<ng-template #transactionMatchTable>
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutAlign="center center" fxLayoutGap="10px"
    class="drill-down-report-tools">
    <div>
      <button mat-raised-button color="warn" (click)="clearResults()">
        Clear Results
      </button>
      <button mat-button (click)="exportReport()" color="accent">
        Export
        <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
      </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <b>Location: </b>{{getSelectedLocationName()}}
      </div>
      <div>
        <b>Third Party: </b>{{getSelectedThirdPartyName()}}
      </div>
    </div>

    <mat-form-field appearance="outline" class="report-drill-down-filter">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
  </div>
  <div class="report-table transaction-drill-down-table" [style.height.px]="getHeight() - 50"
    [style.width]="'100% !important'" [style.margin-top.px]="10">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows class="mat-elevation-z5">
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let day">
          {{day['date'] | date: 'shortDate' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="posTransactions">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Transactions</mat-header-cell>
        <mat-cell *matCellDef="let day" [class.cell-warn]="day.tpdTransactions !== day.posTransactions"
          [class.cell-attn]="day.tpdTransactions === day.posTransaction && day.tpdTransactionsTipOnly > 0">
          <u (click)="openLocationStatusTransactionsViewer(day.transactions.pos)" [style.pointer]="'cursor'">
            {{day.posTransactions}}
          </u>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tpdTransactions">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Transactions</mat-header-cell>
        <mat-cell *matCellDef="let day" [class.cell-warn]="day.tpdTransactions !== day.posTransactions"
          [class.cell-attn]="day.tpdTransactions === day.posTransaction && day.tpdTransactionsTipOnly > 0">
          <u (click)="openLocationStatusTransactionsViewer(day.transactions.tpd)" [style.pointer]="'cursor'">
            {{day.tpdTransactions}}
          </u>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tpdTransactionsTipOnly">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Transactions Tip Only</mat-header-cell>
        <mat-cell *matCellDef="let day" [class.cell-warn]="day.tpdTransactions !== day.posTransactions"
          [class.cell-attn]="day.tpdTransactions === day.posTransaction && day.tpdTransactionsTipOnly > 0">
          <u (click)="openLocationStatusTransactionsViewer(day.transactions.tipOnly)" [style.pointer]="'cursor'">
            {{day.tpdTransactionsTipOnly}}
          </u>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="posSales">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Sales</mat-header-cell>
        <mat-cell *matCellDef="let day">
          <div class="content-cell">
            {{day.posSales | accountingFormat}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tpdSales">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Sales</mat-header-cell>
        <mat-cell *matCellDef="let day">
          {{day.tpdSales | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="posTax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pos Tax</mat-header-cell>
        <mat-cell *matCellDef="let day">
          {{day.posTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tpdTax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Tax</mat-header-cell>
        <mat-cell *matCellDef="let day">
          {{day.tpdTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
</ng-template>
