import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Entity, Location, RateType, ThirdParty, User, UserRoles, usStates, LocationThirdParty } from '@deliver-sense-librarian/data-schema';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineLatest, from, Observable, of, Subject } from "rxjs";
import { combineAll, map, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { CustomValidators } from "ng2-validation";
import { markFields, scrollbarOptions } from "../../../../shared/ds-constant";
import * as moment from "moment";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import * as crypto from "crypto-js";

@Component({
  selector: 'app-create-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {
  @Input() location: Location = new Location();
  public entities: Entity[] = [];
  public states = usStates;
  public scrollbarOption = scrollbarOptions;
  public locationForm: FormGroup;
  public uiState: UiState;
  private user: User;
  private client: any;
  private destroy$ = new Subject();
  private thirdParties: ThirdParty[] = [];
  private rateTypes: RateType[] = [];
  public editLocationId = false;
  public locationIdAlreadyTaken = false;
  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = <UiState>uiState$;
          this.getRelatedResource();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete()
  }

  private getRelatedResource() {
    const userInternal = this.uiState.authUser.internalRole >= 1;
    const entitiesRequest = userInternal ?
      this.afs.collection('entities', ref => ref.where('client', '==', this.uiState.client.id)).snapshotChanges() :
      FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin, UserRoles.contributor])
    combineLatest([
      this.afs.collection('thirdParties').snapshotChanges(),
      this.afs.collection('rateTypes').snapshotChanges(),
      entitiesRequest
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([thirdParties$, rateTypes$, entities$]) => {
        this.entities = userInternal ? FirestoreUtilities.mapToType(entities$) : entities$;
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$) as ThirdParty[];
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$) as RateType[];
        this.rateTypes = this.rateTypes
          .filter(rateType => rateType.name !== 'Non-Taxable')
          .sort((a, b) => {
            return a.order < b.order ? -1 : 1;
          });
        this.activedRoute.params.subscribe(params$ => {
          if (params$['id'] && params$['id'] !== 'new') {
            this.fetchLocation(params$['id']);
          } else {
            this.setupLocationForm();
          }
        });
      })
  }

  private fetchLocation(locationId: string) {
    this.afs.doc(`locations/${locationId}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(location$ => {
        this.location = FirestoreUtilities.objectToType(location$) as Location;
        this.setupLocationForm();
      })
  }

  private setupLocationForm() {
    this.locationForm = this.fb.group({
      name: new FormControl(this.location.name, Validators.required),
      active: new FormControl(this.location.active),
      entity: new FormControl(this.location.entity, Validators.required),
      locationId: new FormControl(this.location.locationId, Validators.required, /*this.validateUniqueLocationId.bind(this)*/),
      primaryContact: new FormControl(this.location.primaryContact, CustomValidators.email),
      stateTaxRate: new FormControl(this.location.stateTaxRate ? this.location.stateTaxRate : 0, [Validators.min(0), Validators.max(100)]),
      countyTaxRate: new FormControl(this.location.countyTaxRate ? this.location.countyTaxRate : 0, [Validators.min(0), Validators.max(100)]),
      cityTaxRate: new FormControl(this.location.cityTaxRate ? this.location.cityTaxRate : 0, [Validators.min(0), Validators.max(100)]),
      specialTaxRate: new FormControl(this.location.specialTaxRate ? this.location.specialTaxRate : 0, [Validators.min(0), Validators.max(100)]),
      addressLine1: new FormControl(this.location.addressLine1, Validators.required),
      addressLine2: new FormControl(this.location.addressLine2),
      addressCity: new FormControl(this.location.addressCity, Validators.required),
      addressState: new FormControl(this.location.addressState, Validators.required),
      addressPostalCode: new FormControl(this.location.addressPostalCode, Validators.required),
      thirdParties: new FormArray([]),
    });
    if (this.location.id) {
      this.populateThirdPartyFormArrayFromExistingLocation();
    } else {
      this.populateThirdPartyFormArray();
    }
    markFields(this.locationForm);
  }
  validateUniqueLocationId(locationIdControl: FormControl): Observable<any> {
    if (this.locationForm) {

    const locationId = locationIdControl.value;
    const entityId = this.locationForm.get('entity').value;
    const clientId = this.uiState.client.id;
    if (!locationId || !entityId) {
      if (this.locationForm && this.locationForm.get('locationId')) {
        this.locationForm.get('locationId').setErrors(null);
      }
      return of();
    }
    return this.afs.collection('locations', ref => ref
      .where('locationId', '==', locationId)
      .where('client', '==', clientId)
      .where('entity', '==', entityId)
      .limit(1))
      .snapshotChanges().pipe(map(result$ => {
        const queryResult = FirestoreUtilities.mapToType(result$);
        const found = queryResult[0] && queryResult[0].locationId === locationId;
        debugger;

        if (!found) {
          locationIdControl.setErrors(null);
          this.locationIdAlreadyTaken = false;
          return null;
        } else {
          locationIdControl.setErrors({ takenLocationId: true });
          this.locationIdAlreadyTaken = true;
          return { takenLocationId: true }
        }
      }));
    }
    return of(null);
  }
  private populateThirdPartyFormArrayFromExistingLocation() {
    const thirdPartyFormArray = this.locationForm.get('thirdParties') as FormArray;
    this.location['thirdParties'].forEach((ltp: LocationThirdParty) => {
      const ltpFormGroup = new FormGroup({
        thirdParty: new FormControl(ltp.thirdParty, Validators.required),
        active: new FormControl(ltp.active),
        deliveryFee: new FormControl(ltp.deliveryFee),
        mfApplicable: new FormControl(!!ltp.mfApplicable),
        marketFacilitatorRates: new FormArray([])
      });
      thirdPartyFormArray.push(ltpFormGroup);
      const thirdPartyMfRatesFormArray = ltpFormGroup.get('marketFacilitatorRates') as FormArray;
      ltp['marketFacilitatorRates'].forEach(ltpMfTaxRate => {
        const ltpMfRateFormGroup = new FormGroup({
          effectiveDate: new FormControl(ltpMfTaxRate.effectiveDate ? ltpMfTaxRate.effectiveDate.toDate() : null, Validators.required),
          rate: new FormControl(ltpMfTaxRate.rate, Validators.required)
        });
        thirdPartyMfRatesFormArray.push(ltpMfRateFormGroup);
      });
    })
  }

  private populateThirdPartyFormArray() {
    const thirdPartyFormArray = this.locationForm.get('thirdParties') as FormArray;
    this.thirdParties.forEach(thirdParty => {
      const ltpFormGroup = new FormGroup({
        thirdParty: new FormControl(thirdParty.id, Validators.required),
        active: new FormControl(),
        deliveryFee: new FormControl(),
        mfApplicable: new FormControl(),
        marketFacilitatorRates: new FormArray([])
      });
      thirdPartyFormArray.push(ltpFormGroup);
    })
  }

  addMfRate(thirdPartyItemRateFormArray: FormArray) {
    thirdPartyItemRateFormArray.push(new FormGroup({
      effectiveDate: new FormControl(new Date()),
      rate: new FormControl(0, Validators.required)
    }));
  }

  async removeRate(thirdPartyRateFormArray: FormArray, rateIndex: number, thirdPartyId) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this rate?',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const formArray = thirdPartyRateFormArray as FormArray;
        const rateControl = thirdPartyRateFormArray[rateIndex].value;
        formArray.removeAt(rateIndex);
      }
    });
  }

  async save() {
    if (this.locationForm.valid) {
      this.loadingService.isLoading(true, 'Saving location...');
      const locationData = this.mapFormToLocation();
      try {
        if (this.location.id) {
          await this.updateLocation(locationData);
        } else {
          await this.createLocation(locationData);
        }
        await this.router.navigate(['/app/organization/locations'])
        this.loadingService.isLoading(false);
      } catch (e) {
        console.log(e.message)
        this.snackBar.open('Error saving location. Please refresh and try again.', 'Dismiss', {
          duration: 5000
        });
        this.loadingService.isLoading(false);
      }
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  private async updateLocation(locationData: Location) {
    const locationsBatch = this.afs.firestore.batch();
    locationsBatch.update(this.afs.doc(`locations/${this.location.id}`).ref, {
      name: locationData.name,
      active: locationData.active,
      locationId: locationData.locationId,
      entity: locationData.entity,
      primaryContact: locationData.primaryContact,
      addressLine1: locationData.addressLine1,
      addressLine2: locationData.addressLine2,
      addressCity: locationData.addressCity,
      addressState: locationData.addressState,
      addressPostalCode: locationData.addressPostalCode,
      cityTaxRate: locationData.cityTaxRate,
      countyTaxRate: locationData.countyTaxRate,
      stateTaxRate: locationData.stateTaxRate,
      specialTaxRate: locationData.specialTaxRate,
      thirdParties: locationData['thirdParties']
    });
    try {
      await locationsBatch.commit();
      this.snackBar.open('Updated location successfully!', 'Dismiss', {
        duration: 5000
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  private async createLocation(locationData: Location) {
    locationData.id = this.getLocationClientHash(locationData.locationId);
    locationData.client = this.client.id;
    await this.afs.doc(`users/${this.user.id}/clientRoles/${this.client.id}/organizationRoles/${locationData.id}`).set({
      resource: locationData.id,
      type: 'location',
      role: UserRoles.admin
    })
    await this.afs.doc(`locations/${locationData.id}`).set(locationData.toJSONObject())
    this.snackBar.open('Created location successfully!', 'Dismiss', {
      duration: 5000
    });
  }
  private getLocationClientHash(locationId) {
    const digest = crypto.algo.MD5.create();
    digest.update(locationId);
    digest.update(this.client.id);
    const progressiveHash = digest.finalize();
    return progressiveHash.toString(crypto.enc.Hex);
  }
  private mapFormToLocation() {
    const formValues = this.locationForm.value;
    const location = new Location();
    location.name = formValues.name;
    location.active = formValues.active;
    location.locationId = formValues.locationId;
    location.primaryContact = formValues.primaryContact;
    location.entity = formValues.entity;
    location.addressLine1 = formValues.addressLine1;
    location.addressLine2 = formValues.addressLine2;
    location.addressCity = formValues.addressCity;
    location.addressState = formValues.addressState;
    location.addressPostalCode = formValues.addressPostalCode;
    location.stateTaxRate = formValues.stateTaxRate;
    location.countyTaxRate = formValues.countyTaxRate;
    location.cityTaxRate = formValues.cityTaxRate;
    location.specialTaxRate = formValues.specialTaxRate;
    location['thirdParties'] = formValues.thirdParties;
    return location;
  }

  public async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this location? All rates associated with this location will also be deleted.',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`locations/${this.location.id}`).delete();
        this.loadingService.isLoading(true, 'Deleting location...');
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Location deleted successfully', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['/app/organization/locations'])
        }, 1000)
      }
    });
  }

  getThirdPartyName(id: any) {
    const tp = this.thirdParties.find(_tp => _tp.id === id);
    return tp ? tp.name : '';
  }
}
