import { ChangeDetectorRef, Component, OnDestroy, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  AuditTrailDocument,
  AuditTrailDocumentSection,
  AuditTrailDocumentSectionComment,
  AuditTrailDocumentSectionCommentStatuses,
  AuditTrailDocumentSectionCommentMessage,
  Client,
  User,
  UserView,
} from "@deliver-sense-librarian/data-schema";
import { Subject, of, Observable } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { Store } from '@ngrx/store';
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { scrollbarOptions, SlideInOutAnimation } from '../../../../shared/ds-constant';
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import * as moment from "moment";
import { AngularFireStorage } from '@angular/fire/storage';
import { AuditTrailPingDialogComponent } from '../../../../dialogs/audit-trail-ping-dialog/audit-trail-ping-dialog.component';
import { AuditTrailsService } from '../../../../services/audit-trails.service';
import { AuditTrailChecklistViewerDialogComponent } from '../../../../dialogs/audit-trail-checklist-viewer-dialog/audit-trail-checklist-viewer-dialog.component';


@Component({
  selector: 'app-audit-trail-viewer',
  templateUrl: './audit-trail-viewer.component.html',
  styleUrls: ['./audit-trail-viewer.component.scss'],
  animations: SlideInOutAnimation
})
export class AuditTrailViewerComponent implements OnInit, OnDestroy, OnChanges {
  public user: User;
  public auditTrailDocument = new AuditTrailDocument();
  public selectedSectionId: string;
  public selectedCommentId: string;
  public sections: AuditTrailDocumentSection[] = [];
  public creatingSectionComment = false;
  public addingSection = false;
  public newSectionName = new FormControl('', Validators.required);
  public newSectionNotes = new FormControl();
  public sectionsFilter: FormControl = new FormControl();
  public reviewDocumentTitle = new FormControl(this.auditTrailDocument.name, Validators.required);
  public reviewDocumentDescription = new FormControl(this.auditTrailDocument.description, Validators.required);
  public titleInEdit = false;
  public showFiles = false;
  public statusFilter = new FormControl([
    'untouched',
    AuditTrailDocumentSectionCommentStatuses.closed,
    AuditTrailDocumentSectionCommentStatuses.ready,
    AuditTrailDocumentSectionCommentStatuses.open
  ]);
  public statues = [
    { id: AuditTrailDocumentSectionCommentStatuses.closed, value: 'Closed' },
    { id: AuditTrailDocumentSectionCommentStatuses.ready, value: 'Ready For Review' },
    { id: AuditTrailDocumentSectionCommentStatuses.open, value: 'Open' }
  ]
  public commentCountTotals;
  public theme;
  public sidePanelOpened = true;
  public sectionPanelOpened = true;
  public selectedComment: any;
  public selectedCommentMessages: AuditTrailDocumentSectionCommentMessage[] = [];
  public client: Client;
  public selectedSection: any;
  private uiState: UiState;
  yScrollbarOptions = scrollbarOptions;
  scrollbarOptions = {
    axis: 'x',
    theme: 'dark',
    scrollButtons: { enable: true },
    advanced: {
      autoExpandHorizontalScroll: true,
      updateOnContentResize: true
    }
  };
  config: any = {};
  private destroy$ = new Subject();
  attachedDocumentDownloadUrl: string;
  sectionComments: AuditTrailDocumentSectionComment[] = [];
  preSelectionComplete: any;
  sectionSidePanelOpened = false;
  comments: AuditTrailDocumentSectionComment[] = [];
  liveComment: Observable<AuditTrailDocumentSectionComment> = of();
  constructor(
    public atService: AuditTrailsService,
    private store: Store<any>,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private storage: AngularFireStorage,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.activatedRoute.queryParams.subscribe(queryParams$ => {
            if (queryParams$['section']) {
              this.selectedSectionId = queryParams$['section'];
            }
            if (queryParams$['comment']) {
              this.selectedCommentId = queryParams$['comment'];
            }
            this.cdr.markForCheck();
          });
          this.activatedRoute.params.subscribe(params$ => {
            if (params$['id']) {
              this.fetchReviewDocument(params$['id']);
            }
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (!changes.auditTrailDocument.currentValue && !!changes.auditTrailDocument.previousValue) {
      this.snackBar.open('This document was deleted by a project admin.', 'Dismiss', {
        duration: 5000
      });
      setTimeout(() => {
        this.router.navigate(['/app/audit-trails/'])
      }, 500)
    }
  }

  setSelectedSection(sectionId) {
    if (this.selectedSectionId !== sectionId) {
      this.selectedSectionId = null;
      this.selectedCommentId = null;
      setTimeout(() => {
        this.selectedSectionId = sectionId;
      })
    }
  }
  setSelectedComment(commentId) {
    if (this.selectedCommentId !== commentId) {
      this.selectedCommentId = null;
      setTimeout(() => {
        this.selectedCommentId = commentId;
      })
    }
  }

  setCommentCountTotals(countTotals) {
    this.commentCountTotals = countTotals;
  }


  private fetchReviewDocument(id: any) {
    this.loadingService.isLoading(true, 'Loading document...');
    this.afs.doc(`auditTrailDocuments/${id}`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$), distinctUntilChanged((a$, b$) => {
        const current = FirestoreUtilities.objectToType(a$) as AuditTrailDocument;
        const prev = FirestoreUtilities.objectToType(b$) as AuditTrailDocument;
        const aMod = { name: current.name, description: current.description, checkListItems: current.checklistItems };
        const bMod = { name: prev.name, description: prev.description, checkListItems: prev.checklistItems };
        return JSON.stringify(aMod) === JSON.stringify(bMod);
      })).subscribe(async (auditTrailDocument$) => {
        this.auditTrailDocument = FirestoreUtilities.objectToType(auditTrailDocument$) as AuditTrailDocument;
        if (this.auditTrailDocument) {
          this.setReviewDocumentFormControlValues();
        } else {
          this.router.navigate(['/app/audit-trails/']);
          this.snackBar.open(`Unable to find that Audit Trail document. Make sure you have access to the Audit Trail's project.`)
        }
        if (this.auditTrailDocument && this.auditTrailDocument.filePath) {
          this.attachedDocumentDownloadUrl = await FirestoreUtilities.getDownloadUrlFromPath(this.storage, this.auditTrailDocument.filePath);
        } else {
          this.attachedDocumentDownloadUrl = null;
        }
        this.loadingService.isLoading(false);
      });
  }

  isUserProjectAdmin() {
    const userProjectRole = this.uiState && this.auditTrailDocument ? this.uiState.projects.find(project => project.id === this.auditTrailDocument.project) : undefined;
    return userProjectRole ? userProjectRole.role > 2 : false;
  }

  async saveTitleChanges() {
    if (this.reviewDocumentTitle.valid) {
      this.afs.doc(`auditTrailDocuments/${this.auditTrailDocument.id}`)
        .update({
          name: this.reviewDocumentTitle.value,
          description: this.reviewDocumentDescription.value ? this.reviewDocumentDescription.value : null,
          dateUpdated: moment().toDate()
        });
      this.snackBar.open('Review document title updated successfully', 'Dismiss', {
        duration: 5000
      });
      this.titleInEdit = false;
    } else {
      this.snackBar.open('You must set a name for the review document.', 'Dismiss', {
        duration: 5000
      });
    }
  }
  private setReviewDocumentFormControlValues() {
    this.reviewDocumentTitle.patchValue(this.auditTrailDocument.name);
    this.reviewDocumentTitle.updateValueAndValidity();
    this.reviewDocumentDescription.patchValue(this.auditTrailDocument.description);
    this.reviewDocumentDescription.updateValueAndValidity();
  }


  async deleteDocument() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Document',
        message: 'Are you sure you want delete this document? All section and comments for this document will be deleted as well.',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`auditTrailDocuments/${this.auditTrailDocument.id}`).delete();
        this.snackBar.open('Successfully deleted the document.', 'Dismiss', {
          duration: 5000
        });
        await this.router.navigate(['/app/audit-trails/'])
      }
    });
  }
  isChecklistComplete() {
    let complete = true;
    for (const item in this.auditTrailDocument.checklistItems) {
      if (!item) {
        complete = false;
        break;
      }
    }
    return complete;
  }
  remainingChecklistItems() {
    let count = 0;
    if (this.auditTrailDocument && this.auditTrailDocument.checklistItems) {
      this.auditTrailDocument.checklistItems.forEach(item => {
        if (!item.complete) {
          count++;
        }
      });
    }
    return count;
  }
  viewAndSetupChecklist(inSetup?: boolean) {
    this.dialog.open(AuditTrailChecklistViewerDialogComponent, {
      disableClose: true,
      data: {
        auditTrailDocument: this.auditTrailDocument,
        inSetup
      }
    })
  }




  addWrappedText({ text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10 }) {
    const textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    const pageHeight = doc.internal.pageSize.height;        // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
    doc.setFontType(fontType);
    doc.setFontSize(fontSize);
    let cursorY = initialYPosition;
    textLines.forEach(lineText => {
      if ((cursorY + 20) > pageHeight) { // Auto-paging with 20 bottom margin
        doc.addPage();
        cursorY = pageWrapInitialYPosition;
      }
      doc.fromHTML(lineText, xPosition, cursorY, {
        'width': 400,
        'elementHandlers': {
          '#editor': function (element, renderer) {
            return true;
          }
        }
      });
      cursorY += lineSpacing;
    })
    return cursorY;
  }

  sendPing() {
    this.dialog.open(AuditTrailPingDialogComponent, {
      disableClose: true,
      data: {
        user: this.user,
        client: this.client,
        auditTrailDocument: this.auditTrailDocument
      }
    })
  }




  deleteAttachment() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Attachment',
        message: 'Are you sure you want delete the attached document?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        FirestoreUtilities.deleteStorageFile(this.auditTrailDocument.filePath, this.storage);
        this.afs.doc(`auditTrailDocuments/${this.auditTrailDocument.id}`).update({
          filePath: null,
          fileSize: null,
          fileName: null,
          dateUpdated: moment().toDate()
        });
      }
    });
  }


}
