<div fxLayout="column" fxLayoutGap="10px" [style.padding.px]="12">
  <div>
    <b>Client Payment Id: </b> {{client.billingId}} <mat-icon color="primary" fontSet="fab" fontIcon="fa-stripe">
    </mat-icon>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field appearance="outline">
      <mat-label>Billing Email</mat-label>
      <input matInput #clientBillingEmail [value]="client.billingEmail" />
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" [disabled]="clientBillingEmail.value === client.billingEmail"
        (click)="updateBillingEmail(clientBillingEmail.value)">
        Update
      </button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <h3><b>Client Tax Rate: </b></h3>
    <ng-container *ngIf="loadingTaxRate; else taxRateLoaded">
      <mat-spinner diameter="12"></mat-spinner>
    </ng-container>
    <ng-template #taxRateLoaded>
      <ng-container *ngIf="clientTaxRate; else noTaxRateSet">
        <h4>
          {{clientTaxRate.display_name}} - {{clientTaxRate.percentage}}%
        </h4>
      </ng-container>
      <ng-template #noTaxRateSet>
        No Tax Rate Set.
      </ng-template>
      <mat-icon fontSet="fas" fontIcon="fa-info-circle"
        matTooltip="This is the tax rate that will be applied to your invoice total at the beginning of each month.">
      </mat-icon>
    </ng-template>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <h3>Auto Payments</h3>
  </div>
  <p>
    Setup auto pay by enabling "Auto Pay" and adding a credit or debit card to your account. The payment method
    you setup below will be charged
    automatically on the 1st of every month for the full amount of your invoice.
  </p>
  <div fxLayout="row" fxLayoutGap="20px">
    <mat-slide-toggle [formControl]="autoPayEnabled">Auto Pay {{client.autoPayEnabled ? 'Enabled' : 'Disabled'}}
    </mat-slide-toggle>
    <button mat-raised-button color="accent" *ngIf="autoPayEnabled.dirty" (click)="updateAutoPay()">
      Save
    </button>
  </div>
  <ng-container *ngIf="client.autoPayEnabled">
    <ng-container *ngIf="loadingPaymentMethod; else paymentInfo">
      <div fxLayout="row" fxLayout="center center">
        <h4>Loading Auto Payment Method</h4>
      </div>
      <div fxLayout="row" fxLayout="center center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-template #paymentInfo>
      <ng-container *ngIf="clientCurrentMethod && !addingNewSource; else changingSource">
        <div fxLayout="row" fxLayoutAlign="space-evenly start">
          Current Auto Pay Method:
          <div class="auto-payment-details">
            <mat-icon fontSet="fab" [fontIcon]="getMethodCCIcon()" color="accent"></mat-icon>
            xxxx-xxxx-xxxx-{{clientCurrentMethod.card?.last4}}
          </div>
          <button mat-raised-button *ngIf="!addingNewSource" color="primary" (click)="addingNewSource = true">Change
            Payment
            Method</button>
        </div>
      </ng-container>
      <ng-template #changingSource>
        <div fxLayout="row">
          <mat-tab-group>
            <mat-tab label="Add Card">
              <ng-template matTabContent>
                <app-stripe-card #newCard></app-stripe-card>
                <button (click)="createCardSource()" mat-raised-button color="accent">Save</button>
                <button (click)="addingNewSource = false" mat-raised-button color="warn">Cancel</button>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
</div>
