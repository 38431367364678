import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AuditTrailDocument,
  AuditTrailDocumentSection,
  AuditTrailDocumentSectionComment,
  AuditTrailDocumentSectionCommentStatuses,
  Client,
  User,
  UserView
} from "@deliver-sense-librarian/data-schema";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as moment from "moment";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { AuditTrailsService } from '../../../../services/audit-trails.service';
import _ from 'lodash';

@Component({
  selector: 'app-audit-trail-document-comment',
  templateUrl: './audit-trail-document-comment.component.html',
  styleUrls: ['./audit-trail-document-comment.component.scss']
})
export class AuditTrailDocumentCommentComponent implements OnInit, OnDestroy {
  @Input() comment: AuditTrailDocumentSectionComment;
  @Input() sectionId: string;
  @Input() documentId: string;
  @Input() projectId: string;
  @Output() saved = new EventEmitter();
  public availableAssignees: UserView[] = [];
  public uiState: UiState;
  public commentForm: FormGroup;
  public commentStatuses = [
    { name: 'Open', value: AuditTrailDocumentSectionCommentStatuses.open, disabled: true },
    { name: 'Ready for Review', value: AuditTrailDocumentSectionCommentStatuses.ready, disabled: true },
    { name: 'Closed', value: AuditTrailDocumentSectionCommentStatuses.closed, disabled: true },
    { name: 'Cancelled', value: AuditTrailDocumentSectionCommentStatuses.cancelled, disabled: true },
  ];
  private destroy$ = new Subject();
  private commentsPath: string; // DB path comment collection

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private atService: AuditTrailsService,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.commentsPath = `auditTrailDocuments/${this.documentId}/sections/${this.sectionId}/comments`
          this.fetchAvailableAssignees();
          this.filterCommentsBasedOnCreatorVsAssignee();
          this.setupCommentForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private fetchAvailableAssignees() {
    this.atService
      .fetchProjectUsers(new AuditTrailDocument({ project: this.projectId, id: this.documentId }))
      .pipe(takeUntil(this.destroy$))
      .subscribe(projectUsers$ => {
        this.availableAssignees = projectUsers$
      })
  }
  async saveSectionComment() {
    if (this.commentForm.valid) {
      try {
        const formValues = this.commentForm.value;
        if (this.comment.id) {
          this.updateComment(formValues)
        } else {
          this.createComment(formValues);
        }
        this.snackBar.open(`Comment ${this.comment.id ? 'Updated' : 'Created'}`, 'Dismiss', {
          duration: 5000
        });
        this.saved.emit(true);
      } catch (e) {
        console.log(e);
        this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please fill out required fields.', 'Dismiss', {
        duration: 5000
      });
    }
  }
  private async updateComment(formValues: any) {
    const approvedBy = formValues.status === AuditTrailDocumentSectionCommentStatuses.closed ? this.uiState.authUser.id : null;
    const approvedOn = formValues.status === AuditTrailDocumentSectionCommentStatuses.closed ? moment().toDate() : null;
    await this.afs.doc(`${this.commentsPath}/${this.comment.id}`)
      .update({
        comment: formValues.comment ? formValues.comment : null,
        assignee: formValues.assignee,
        dueDate: formValues.dueDate ? moment(formValues.dueDate).toDate() : null,
        status: formValues.status,
        approvedBy,
        approvedOn,
        dateUpdated: moment().toDate()
      });
  }
  private async createComment(formValues: any) {
    await this.afs.collection(`${this.commentsPath}`)
      .add({
        creator: this.comment.creator,
        auditTrailDocumentSection: this.comment.auditTrailDocumentSection,
        comment: formValues.comment ? formValues.comment : null,
        assignee: formValues.assignee,
        dueDate: formValues.dueDate ? moment(formValues.dueDate).toDate() : null,
        status: formValues.status,
        dateCreated: moment().toDate(),
        dateUpdated: moment().toDate()
      })
  }
  getCommentAssigneeName() {
    const userMatch = this.availableAssignees.find(userView => userView.id === this.comment.assignee);
    return userMatch ? `${userMatch.firstName} ${userMatch.lastName}` : '';
  }
  async deleteSectionComment() {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this comment? All messages in associated with this comment will also be deleted.',
        action: 'Yes, delete.'
      }
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.saved.emit(true);
        await this.afs.doc(`auditTrailDocuments/${this.documentId}/sections/${this.sectionId}/comments/${this.comment.id}`).delete();
        this.snackBar.open('Comment deleted successfully!', 'Dismiss', {
          duration: 5000
        });
      }
    });
  }

  private filterCommentsBasedOnCreatorVsAssignee() {
    if (this.documentId && this.comment.id) {
      if (this.comment.creator === this.uiState.authUser.id || this.comment.creator['id'] === this.uiState.authUser.id) {
        // Creators can set to approved to clear comment, cancelled to remove the need to do it, or pending for work to be completed on it.
        this.commentStatuses = [
          { name: 'Open', value: AuditTrailDocumentSectionCommentStatuses.open, disabled: false },
          { name: 'Ready For Review', value: AuditTrailDocumentSectionCommentStatuses.ready, disabled: true },
          { name: 'Closed', value: AuditTrailDocumentSectionCommentStatuses.closed, disabled: false },
          { name: 'Cancelled', value: AuditTrailDocumentSectionCommentStatuses.cancelled, disabled: false },
        ];
      } else {
        // Assignees can only update to completed or back to pending
        // @TODO change complete to ready for review
        // @TODO lock changes from assignee if set to approved
        this.commentStatuses = [
          { name: 'Open', value: AuditTrailDocumentSectionCommentStatuses.open, disabled: true },
          { name: 'Ready For Review', value: AuditTrailDocumentSectionCommentStatuses.ready, disabled: false },
          { name: 'Closed', value: AuditTrailDocumentSectionCommentStatuses.closed, disabled: true },
          { name: 'Cancelled', value: AuditTrailDocumentSectionCommentStatuses.cancelled, disabled: true },
        ];
      }
    } else {
      this.commentStatuses = [
        { name: 'Open', value: AuditTrailDocumentSectionCommentStatuses.open, disabled: false },
        { name: 'Ready For Review', value: AuditTrailDocumentSectionCommentStatuses.ready, disabled: false },
        { name: 'Closed', value: AuditTrailDocumentSectionCommentStatuses.closed, disabled: false },
        { name: 'Cancelled', value: AuditTrailDocumentSectionCommentStatuses.cancelled, disabled: false },
      ];
    }
  }
  getCommentDateFormatted(comment: AuditTrailDocumentSectionComment) {
    if (comment.dueDate) {
      return comment.dueDate.seconds ? moment(comment.dueDate.toDate()).format('M/D/YYYY') : moment(comment.dueDate).format('M/D/YYYY');
    }
  }
  private setupCommentForm() {
    const dueDate = this.comment.dueDate ? this.comment.dueDate.toDate() : '';
    this.commentForm = this.fb.group({
      comment: new FormControl(this.comment['comment'], Validators.required),
      dueDate: new FormControl(dueDate),
      assignee: new FormControl(this.comment.assignee),
      status: new FormControl(this.comment.status, Validators.required)
    })
  }
}
