<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="drill-down-report-tools">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="selectFields()" color="accent">
      Fields
      <mat-icon>menu_open</mat-icon>
    </button>
    <button mat-button (click)="exportReport()" color="accent">
      Export
      <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
    </button>
    <mat-slide-toggle [checked]="onlyShowErrors" (change)="toggleOnlyShowErrorLocations($event)">
      Only Errors
    </mat-slide-toggle>
  </div>
  <mat-form-field appearance="outline" class="report-drill-down-filter">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</div>
<ng-container *ngIf="dataFlattened && tableData && tableData.data.length > 0; else noData">
  <div class="report-table" [style.height.px]="getHeight()">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows class="mat-elevation-z5">
      <ng-container matColumnDef="Location Id" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header>Location Id</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div>{{row.locationId}}</div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.thirdPartyName}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Location Name" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="location-name-cell-text" [matTooltip]="row.locationName">
            {{row.locationName}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="State/Province">
        <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div>{{row.stateProvince}}</div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="MF Applicable" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header>MF Tax Applicable</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div>{{row.mfTaxApplicable}}</div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Gross Sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Gross Sales</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-sales">
          {{row.posGrossSales | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Sales Adjustments">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Sale Adjustments</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-sales">
          {{row.posSaleAdjustments | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Net Sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Net Sales</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-sales">
          {{row.posNetSales | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Gross Sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Gross Sales</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-sales">
          {{row.thirdPartyGrossSales | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Sales Adjustments">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Sale Adjustments</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-sales">
          {{row.thirdPartySalesAdjustments | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Net Sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Net Sales</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-sales">
          {{row.thirdPartyNetSales | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Sales Variance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Sales Variance
        </mat-header-cell>
        <mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="center center" [class.cell-warn]="row['salesErrors']">
              <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
                functions
              </mat-icon>
              <mat-menu #functionMenu="matMenu" class="info-menu">
                <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
                  fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'salesVariance')">
                </div>
              </mat-menu>
            <div class="variance" [class.difference]="row['Sales Variance'] !== 0">
              {{row.salesVariance | accountingFormat}}
            </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="POS Tips">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Tips</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-tips">
          {{row.posTips | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Tips">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Tips</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-tips">
          {{row.thirdPartyTips | accountingFormat}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="POS Other Charges">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Other Charges</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-other-charges">
          {{row.posOtherCharges| accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Other Charges">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Other Charges</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-other-charges">
          {{row.thirdPartyOtherCharges | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Other Revenue">
        <mat-header-cell *matHeaderCellDef mat-sort-header>POS Other Revenue</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-other-charges">
          {{row.posOtherRevenue | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Other Revenue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Other Revenue</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-other-charges">
          {{row.thirdPartyOtherRevenue | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Gross Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> POS Gross Tax</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-tax">
          {{row.posGrossTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Tax Adjustments">
        <mat-header-cell *matHeaderCellDef mat-sort-header> POS Tax Adjustments</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-tax-adjustments">
          {{row.posTaxAdjustments | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="POS Net Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> POS Net Tax</mat-header-cell>
        <mat-cell *matCellDef="let row" id="pos-net-tax">
          {{row.posNetTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Gross Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Gross Tax</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-tax">
          {{row.thirdPartyGrossTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Tax Adjustments">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Tax Adjustments</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-tax-adjustments">
          {{row.thirdPartyTaxAdjustments | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Net Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> 3PD Net Tax</mat-header-cell>
        <mat-cell *matCellDef="let row" id="third-party-net-tax">
          {{row.thirdPartyNetTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Tax Variance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tax Variance</mat-header-cell>
        <mat-cell *matCellDef="let row" [class.cell-warn]="row['taxErrors']">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'taxVariance')">
            </div>
          </mat-menu>
          <div class="variance" [class.difference]="row['Tax Variance'] !== 0">
            {{row.taxVariance | accountingFormat}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Location Tax Rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Location Tax Rate</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'locationTaxRate')">
            </div>
          </mat-menu>
          {{row.locationTaxRate ? getRoundedRate(row.locationTaxRate) : 0}}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="3PD Effective Tax Rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>3PD Effective Rate</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'thirdPartyEffectiveTaxRate')">
            </div>
          </mat-menu>
          {{row.thirdPartyEffectiveTaxRate ? getRoundedRate(row.thirdPartyEffectiveTaxRate) : 0 }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Tax Rate Variance">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tax Rate Variance</mat-header-cell>
        <mat-cell *matCellDef="let row" [class.cell-warn]="row['taxRateErrors']">
          {{row.taxRateVariance ? getRoundedRate(row.taxRateVariance): 0 }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Market Facilitator Tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Market Facilitator Tax</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.marketFacilitatorTax | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Effective MF Tax Rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Effective MF Tax Rate</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'effectiveMfTaxRate')">
            </div>
          </mat-menu>
          {{row.effectiveMfRate ? getRoundedRate(row.effectiveMfRate) : 0 }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Client Tax Responsibility">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Client Tax Responsibility</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'clientTaxResponsibility')">
            </div>
          </mat-menu>
          {{row.clientTaxResponsibility | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Client Tax Responsibility Rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Client Tax Responsibility Rate</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'clientTaxResponsibilityRate')">
            </div>
          </mat-menu>
          {{row.effectiveClientTaxResponsibilityRate ? getRoundedRate(row.effectiveClientTaxResponsibilityRate): 0 }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Suggested Tax Adjustment">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Suggested Tax Adjustment</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'suggestedTaxAdjustment')">
            </div>
          </mat-menu>
          {{row.suggestedTaxAdjustment | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Delivery Fees">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Fees</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.deliveryFees| accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Effective Delivery Fee Rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Effective Delivery Fee Rate</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'deliverFeeRate')">
            </div>
          </mat-menu>
          {{row.effectiveDeliveryFeeRate ? getRoundedRate(row.effectiveDeliveryFeeRate) : 0 }}%
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Other Fees">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Other Fees</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.otherFees | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Total Fees">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Total Fees</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.totalFees | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Expected Remittance">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Expected Remittance</mat-header-cell>
        <mat-cell *matCellDef="let row" id="location-tax">
          <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
            functions
          </mat-icon>
          <mat-menu #functionMenu="matMenu" class="info-menu">
            <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column" fxLayoutAlign="center center"
              fxLayoutWrap [innerHTML]="getFunctionDefinition(row, 'expectedRemittance')">
            </div>
          </mat-menu>
          {{row.expectedRemittance | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Actual Remittance">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Actual Remittance</mat-header-cell>
        <mat-cell *matCellDef="let row" [style.color]="row.color">
          {{row.actualRemittance | accountingFormat}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Remittance Variance">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Remittance Variance</mat-header-cell>
        <mat-cell *matCellDef="let row" [class.cell-warn]="row['remittanceErrors']">
          <div class="variance" [class.difference]="row['Remittance Variance'] !== 0">
            <mat-icon class="function-trigger" [matMenuTriggerFor]="functionMenu">
              functions
            </mat-icon>
            <mat-menu #functionMenu="matMenu" class="info-menu">
              <div (click)="$event.stopPropagation()" class="text-center" fxLayout="column"
                fxLayoutAlign="center center" fxLayoutWrap
                [innerHTML]="getFunctionDefinition(row, 'remittanceVariance')">
              </div>
            </mat-menu>
            {{row.remittanceVariance | accountingFormat}}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Sales Errors">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Sales Errors</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.salesErrors">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Tax Errors">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tax Errors</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.taxErrors">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Tax Rate Errors">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tax Rate Errors</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.taxRateErrors">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Remittance Errors">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Remittance Errors</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.remittanceErrors">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="selectedFields; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let element; columns: selectedFields;"></mat-row>
    </mat-table>
  </div>
</ng-container>
<ng-template #noData>
  <div class="no-report-overlay mat-elevation-z10" *ngIf="!!tableData && tableData.data.length === 0">
    <h2>No Transaction Data found matching those parameters</h2>
  </div>
</ng-template>
