<mat-sidenav-container>
  <mat-sidenav [mode]="'over'" [opened]="showFiles" position="end" (closedStart)="showFiles=false" autosize
    [style.max-width.px]="400">
    <ng-container *ngIf="auditTrailDocument  && sections && showFiles">
      <app-audit-trail-document-files [auditTrailDocument]="auditTrailDocument" [sections]="sections" [client]="client"
        [user]="user">
      </app-audit-trail-document-files>
    </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-card>
      <mat-card-header>
        <ng-container *ngIf="titleInEdit; else documentTitle">
          <div fxFlex fxLayout="column">
            <mat-form-field appearance="outline">
              <mat-label>Document Name</mat-label>
              <input matInput [formControl]="reviewDocumentTitle" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Description</mat-label>
              <textarea matInput [formControl]="reviewDocumentDescription"></textarea>
            </mat-form-field>
            <div fxLayout="row">
              <button mat-raised-button color="accent" (click)="saveTitleChanges()">
                <mat-icon>save</mat-icon>
                Save
              </button>
              <button mat-raised-button color="warn" (click)="titleInEdit = false">
                <mat-icon>cancel</mat-icon>
                Cancel
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #documentTitle>
          <div fxFlex="50" class="audit-trails-title-description-container">
            <h3 [matTooltip]="auditTrailDocument ? auditTrailDocument.name : ''" class="inner-text">
              <button mat-icon-button (click)="titleInEdit = true" color="primary">
                <mat-icon>edit</mat-icon>
              </button>
              <b>{{auditTrailDocument ? auditTrailDocument.name : ''}}</b>
            </h3>
            <div [style.height.px]="60">
              <perfect-scrollbar>
                {{auditTrailDocument ? auditTrailDocument.description : ''}}
              </perfect-scrollbar>
            </div>
          </div>
          <div fxFlex fxLayout="row" fxLayoutAlign="center start">
            <div fxLayout="column" fxLayoutGap="10px">
              <mat-form-field appearance="outline" *ngIf="auditTrailDocument">
                <mat-label>Filter Sections</mat-label>
                <mat-select [formControl]="statusFilter" multiple>
                  <mat-option *ngFor="let status of statues" [value]="status.id">{{status.value}}</mat-option>
                  <mat-option [value]="'untouched'">No Comments</mat-option>
                </mat-select>
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" *ngIf="commentCountTotals">
                <small class="mat-text-warn font-weight-bold">
                  Total Open: {{commentCountTotals[1]}}
                </small>
                <small class="mat-text-primary font-weight-bold">
                  Total Ready: {{commentCountTotals[0]}}
                </small>
                <small class="mat-text-accent font-weight-bold">
                  Total Closed: {{commentCountTotals[2]}}
                </small>
                <small class="mat-text-disabled font-weight-bold">
                  Total Cancelled: {{commentCountTotals[3]}}
                </small>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center start">
            <div fxFlex fxLayout="row" fxLayoutAlign="center start">
              <button mat-icon-button color="primary" [matMenuTriggerFor]="docMenu" *ngIf="isUserProjectAdmin()">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #docMenu="matMenu">
              <button mat-menu-item matTooltip="Send a ping" (click)="sendPing()">
                Ping <mat-icon fontSet="fas" fontIcon="fa-broadcast-tower"></mat-icon>
              </button>
              <button mat-menu-item color="primary" (click)="showFiles = true">
                File History<mat-icon fontSet="fas" fontIcon="fa-paperclip"></mat-icon>
              </button>
              <button *ngIf="auditTrailDocument && sections" mat-menu-item color="accent" (click)="atService.exportAuditTrail(auditTrailDocument)"
                matTooltip="Download a pdf of all sections, comments, and messages for this document.">
                Export <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item color="warn" (click)="deleteDocument()">
                <span class="mat-text-warn">Delete Audit Trail</span>
                <mat-icon color="warn">delete_forever</mat-icon>
              </button>
              <mat-divider></mat-divider>
            </mat-menu>
            <div fxFlex>
              <button mat-raised-button [color]="isChecklistComplete() ? 'accent' : 'warn'"
                (click)="viewAndSetupChecklist(false)"
                [matBadge]="remainingChecklistItems() > 0 ? remainingChecklistItems() : null" [matBadgeColor]="'warn'">
                <mat-icon fontSet="fas" fontIcon="fa-tasks"></mat-icon>
                Checklist
              </button>
            </div>
          </div>
        </ng-template>
      </mat-card-header>
    </mat-card>
    <div [@slideInOut] class="section-comment-container" *ngIf="auditTrailDocument">
      <mat-card class="mat-card-top pa-0" fxLayout="row" fxLayoutAlign="start start">
        <ng-container *ngIf="auditTrailDocument && auditTrailDocument.id">
          <div fxFlex="25">
            <app-audit-trail-viewer-sections [sectionsFilter]="statusFilter"
              [auditTrailDocumentId]="auditTrailDocument.id"
              (onCountTotalsUpdated)="setCommentCountTotals($event)" (onSectionSelected)="setSelectedSection($event)">
            </app-audit-trail-viewer-sections>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedSectionId">
          <div fxFlex="25">
            <app-audit-trail-viewer-comments [auditTrailDocumentId]="auditTrailDocument.id"
              [auditTrailDocumentSectionId]="selectedSectionId" (onCommentSelect)="setSelectedComment($event)">
            </app-audit-trail-viewer-comments>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedCommentId">
          <div fxFlex="50">
            <app-audit-trail-viewer-messages [auditTrailDocumentId]="auditTrailDocument.id"
              [auditTrailDocumentSectionId]="selectedSectionId"
              [auditTrailDocumentSectionCommentId]="selectedCommentId">
            </app-audit-trail-viewer-messages>
          </div>
        </ng-container>
      </mat-card>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
