import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadCenterComponent } from './document-upload-center.component';
import { DocumentUploadModule } from '../document-upload/document-upload.module';



@NgModule({
  declarations: [DocumentUploadCenterComponent],
  imports: [
    CommonModule,
    DocumentUploadModule
  ]
})
export class DocumentUploadCenterModule { }
