<ng-container *ngIf="initials || (pictureUrl | async)">
  <ng-container *ngIf="pictureUrl; else initialsTemplate">
    <img class="avatar-img" [src]="pictureUrl |async"/>
  </ng-container>
  <ng-template #initialsTemplate>
    <div class="avatar-circle" [style.background-color]="getUserViewInitialColor()">
      <span class="initials" matToolTip="getAvatarTooltip()">{{initials}}</span>
    </div>
  </ng-template>
</ng-container>
