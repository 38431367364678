<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>{{department && department.id ? ('Edit ' + department.name) : 'Create Department'}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="space-evenly start" *ngIf="departmentForm">
      <form [formGroup]="departmentForm">
        <div fxLayout="column">
          <mat-form-field appearance="outline" *ngIf="entities && entities.length > 0; else noEntities">
            <mat-label>Entity</mat-label>
            <mat-select placeholder="Entity" formControlName="entity">
              <mat-option *ngFor="let entity of entities" [value]="entity.id">
                {{entity.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #noEntities>
            <mat-error>You haven't created an entities yet. A department must belong to an entity.</mat-error>
          </ng-template>
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Department Name" formControlName="name"/>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </form>
      <div fxLayout="row">
        <button mat-raised-button color="primary" [disabled]="departmentForm.invalid" (click)="save()">Save</button>
        <button mat-raised-button [routerLink]="'../'">Cancel</button>
        <button mat-raised-button color="warn" *ngIf="department && department.id" (click)="delete()">Delete</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
