<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="location">
      <h2>{{location.id ? 'Edit ' : 'Create '}}
        {{location.name ? location.locationId + '-' + location.name : 'Location'}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <button mat-raised-button color="primary" [disabled]="!locationForm || locationForm.invalid" (click)="save()">Save
    </button>
    <button mat-raised-button [routerLink]="'../'">
      Cancel
    </button>
    <button mat-raised-button color="warn" *ngIf="location.id" [disabled]="!locationForm || locationForm.invalid"
      (click)="delete()">Delete
    </button>
  </mat-card-actions>
</mat-card>
<mat-card>
  <mat-card-content>
    <div appWindowHeightMatcher [heightCompensation]="240" *ngIf="locationForm" MalihuScrollbar
      [scrollbarOptions]="scrollbarOption">
      <form [formGroup]="locationForm" fxLayout="row wrap" fxLayoutGap="12px" fxLayoutAlign="space-between start"
        *ngIf="locationForm">
        <!-- START LOCATION INFO -->
        <div fxFlex="30" fxLayout="column">
          <h2>Location Info</h2>
          <div fxLayout="row">
            <mat-form-field appearance="outline">
              <mat-label>Location Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
          </div>
          <div fxLayout="row" [style.margin-bottom.px]="10">
            <mat-checkbox formControlName="active">Location Active</mat-checkbox>
            <mat-icon fontSet="fas" fontIcon="fa-info-circle"
              matTooltip="In order to run reports and upload data associated with a location it must be active. If you are using modules that bill based on number of locations on active locations will be charged.">
            </mat-icon>
          </div>
          <div fxLayout="row">
            <mat-form-field appearance="outline">
              <mat-label>Entity</mat-label>
              <mat-select formControlName="entity">
                <mat-option *ngFor="let entity of entities" [value]="entity.id">{{entity.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <ng-container *ngIf="!location.id || editLocationId; else staticLocationId">
              <mat-form-field appearance="outline">
                <mat-label>Location ID</mat-label>
                <input matInput formControlName="locationId" [disabled]="!locationForm.controls['entity'].valid">
              </mat-form-field>
            </ng-container>
            <ng-template #staticLocationId>
              <mat-form-field appearance="outline">
                <mat-label>Location ID</mat-label>
                <input matInput disabled [value]="location.locationId">
                <button mat-icon-button color="warn" (click)="editLocationId = true" matSuffix>
                  <mat-icon>edit</mat-icon>
                </button>
              </mat-form-field>
            </ng-template>
            <mat-icon color="accent" matTooltip="This is your company's unique identifier for the location"
              fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
          </div>
          <!-- <div
            *ngIf="locationForm.controls['locationId'].hasError('takenLocationId') &&  locationForm.controls['locationId'].touched"
            class="mat-text-warn">
            This location id already exists for this account
          </div> -->
          <div fxLayout="row">
            <mat-form-field appearance="outline">
              <mat-label>Primary Contact Email</mat-label>
              <input matInput type="email" formControlName="primaryContact">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="column">
              <h3>Address</h3>
              <mat-form-field appearance="outline">
                <mat-label>Street Address 1</mat-label>
                <input matInput formControlName="addressLine1">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Street Addres 2</mat-label>
                <input matInput formControlName="addressLine2">
              </mat-form-field>
              <div fxLayout="row wrap">
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="addressCity">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="addressState">
                    <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field appearance="outline">
                <mat-label>Postal Code</mat-label>
                <input matInput formControlName="addressPostalCode">
              </mat-form-field>
            </div>
            <div fxLayout="column">
              <div fxLayout="column">
                <h3>Location Tax Rates</h3>
                <mat-form-field appearance="outline">
                  <mat-label>State Tax Rate</mat-label>
                  <input matInput type="number" formControlName="stateTaxRate" min="0" max="100" step="1">
                  <i matSuffix>%</i>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>County Tax Rate</mat-label>
                  <input matInput type="number" formControlName="countyTaxRate" min="0" max="100" step="1">
                  <i matSuffix>%</i>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>City Tax Rate</mat-label>
                  <input matInput type="number" formControlName="cityTaxRate" min="0" max="100" step="1">
                  <i matSuffix>%</i>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Special Tax Rate</mat-label>
                  <input matInput type="number" formControlName="specialTaxRate" min="0" max="100" step="1">
                  <i matSuffix>%</i>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- END LOCATION INFO-->
        <!-- START 3PD FEES AND RATES -->
        <div fxFlex fxLayout="column">
          <h2>Third Party Fees and Rates</h2>
          <div formArrayName="thirdParties" *ngIf="locationForm.get('thirdParties')">
            <mat-tab-group>
              <mat-tab [label]="getThirdPartyName(thirdParty.get('thirdParty').value)"
                *ngFor="let thirdParty of locationForm.get('thirdParties')['controls']; let i = index">
                <!--3pd Start-->
                <div [formGroupName]="i" fxLayout="column" fxLayoutGap="20px">
                  <div fxLayout="row wrap" fxLayoutAlign="start start" [style.margin.px]="5" fxLayoutGap="50px">
                    <mat-checkbox formControlName="active">Active</mat-checkbox>
                    <mat-checkbox formControlName="mfApplicable">Market Facilitator Tax Applies</mat-checkbox>
                  </div>
                  <div fxLayout="row">
                    <mat-form-field appearance="outline" fxFlex="30">
                      <mat-label>Delivery Fee</mat-label>
                      <input matInput type="number" min="0" max="100" formControlName="deliveryFee" />
                      <i matSuffix>%</i>
                    </mat-form-field>
                  </div>
                  <!--3pd End-->
                  <ng-container *ngIf="!!thirdParty.get('mfApplicable')?.value">
                    <b>Market Facilitator Rates</b>
                    <!--Rates Start-->
                    <div formArrayName="marketFacilitatorRates" *ngIf="thirdParty.get('marketFacilitatorRates')"
                      fxLayout="column" fxLayoutGap="8px">
                      <div fxLayout="column">
                        <div fxLayout="row">
                          <button mat-raised-button color="accent"
                            (click)="addMfRate(thirdParty.get('marketFacilitatorRates')['controls'])">
                            <mat-icon>add</mat-icon>
                            Add New Rate
                          </button>
                        </div>
                      </div>
                      <div *ngFor="let mfRate of thirdParty.get('marketFacilitatorRates')['controls']; let j=index">
                        <div [formGroupName]="j" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
                          <div fxLayout="row">
                            <mat-form-field appearance="outline">
                              <mat-label>Rate</mat-label>
                              <input matInput type="number" min="0" max="100" step="1"
                                [formControl]="mfRate.get('rate')" />
                              <i matSuffix>%</i>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                              <mat-label>Effective Date</mat-label>
                              <input matInput [matDatepicker]="picker" [formControl]="mfRate.get('effectiveDate')">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <button mat-icon-button color="warn"
                              (click)="removeRate(thirdParty.get('marketFacilitatorRates')['controls'], j, thirdParty.value.thirdParty)">
                              <mat-icon>delete_forever</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!--Rates End-->
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <!-- END 3PD FEES AND RATES -->
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
