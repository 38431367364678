<div fxLayout="column">
    <nav fxFlex class="navbar navbar-default navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>

                <a class="navbar-brand" routerLink="/home"><img class="header_logo mat-elevation-z4"
                                                                [src]="'/assets/favicons/icon-512x512.png'"></a>
            </div>
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a routerLink="/home">
                            <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
                            Go Back
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="faqCard" appWindowHeightMatcher [heightCompensation]="70" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
        <app-faq-content></app-faq-content>
    </div>
</div>
