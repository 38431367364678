<div fxLayout="column" fxFlex="100" class="report-table">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutGap="12px">
      <mat-card-title>
        <h2>Invoices</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6></h6>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
      <ng-container matColumnDef="period">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Period</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.startDate?.toDate() | date:'shortDate'}} - {{row.endDate?.toDate() | date:'shortDate'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="mat-table-cell-text">
            {{row.stripeInvoice?.status}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getFormattedDate(row.stripeInvoice?.created)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="due">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Due Date</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getFormattedDate(row.stripeInvoice?.due_date)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subtotal">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.subtotal}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tax</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.tax}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="discount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Discount</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.discount}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amountDue">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Total Due</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.amount_due}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amountPaid">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Paid</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.amount_paid}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amountRemaining">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Remaining</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.stripeInvoice?.amount_remaining}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="payNow">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Pay Link</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.stripeInvoice?.hosted_invoice_url ">
            <a (click)="payNow(row)">
              Pay <mat-icon fontSet="fab" fontIcon="fa-stripe"></mat-icon>
            </a>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="download">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Download Invoice</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.stripeInvoice?.invoice_pdf">
            <a [href]="row.stripeInvoice?.invoice_pdf">
              <mat-icon color="accent" fontSet="fas" fontIcon="fa-download"></mat-icon>
            </a>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
