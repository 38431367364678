<ng-container *ngIf="tip">
    <p-sidebar [(visible)]="!!tip"
               position="bottom"
               [styleClass]="'help-window-container'"
               appendTo='body'
               [baseZIndex]="9999">
        <div fxLayout="column" class="chat mat-elevation-z3" [@windowAppear]>
            <div fxLayout="row">
                <mat-toolbar color="accent">
                    <i class="help-window-image fa fa-info-circle mat-elevation-z4"></i>
                    <span class="toolbar-spacer"></span>
                    <button mat-mini-fab color="warn" (click)="clearTip()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-toolbar>
            </div>
            <div fxLayout="row" class="chat-window-section">
                <span [innerHTML]="tip | sanitizeHtml"></span>
            </div>
        </div>
    </p-sidebar>
</ng-container>