<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="space-evenly center">
  <h1 mat-dialog-title>Change Transaction House Account</h1>
</div>
<div fxLayout="row">
  <mat-form-field appearance="outline">
    <mat-label>Select a house account</mat-label>
    <mat-select [formControl]="selectedAccount">
      <mat-option *ngFor="let account of (accounts |async)" [value]="account.id">
        {{account.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button fxFlex mat-raised-button color="accent" (click)="change()" [disabled]="selectedAccount.invalid">Change
  </button>
  <button fxFlex mat-raised-button color="warn" (click)="cancel()" tabindex="-1">Nevermind</button>
</div>
