import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client, DeliverSenseModule, ClientModule, DsModules, User, ClientThirdParty, ClientModuleBillingRate } from '@deliver-sense-librarian/data-schema';
import { StripeService } from 'app/services/stripe.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { first, takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { Subject, combineLatest } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from '../../../../dialogs/information-dialog/information-dialog.component';
import { ModuleInformationDialogComponent } from 'app/dialogs/module-information-dialog/module-information-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-client-modules',
  templateUrl: './client-modules.component.html',
  styleUrls: ['./client-modules.component.scss']
})
export class ClientModulesComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @Input() user: User;
  private destroy$ = new Subject();

  public availableModules: DeliverSenseModule[] = [];
  public clientModules: ClientModule[] = [];
  totalProjectUsers: number;
  totalActive3pds: number;
  totalActiveLocations: number;
  clientLocations: Location[] = [];
  clientThirdParties: ClientThirdParty[] = [];
  clientModuleBillingRates: ClientModuleBillingRate[] = [];

  constructor(private stripeService: StripeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.getClientModules();
    this.getAvailableModules();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  showModuleInfo(dsModule: DeliverSenseModule) {
    this.dialog.open(ModuleInformationDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        module: dsModule
      }
    })
  }
  isBilledByLocations(dsModule: DeliverSenseModule) {
    return dsModule.id === DsModules.ExemptSalesManagement || dsModule.id === DsModules.ThirdPartyDeliveryReporting;
  }
  isBilledByThirdPartyDeliverPartners(dsModule: DeliverSenseModule) {
    return dsModule.id === DsModules.ThirdPartyDeliveryReporting;
  }
  private getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientModules$ => {
        this.clientModules = FirestoreUtilities
          .mapToType(clientModules$)
          .filter(module => module.id !== DsModules.AuditTrails);
        this.getClientModuleMonthlyEstimate();
        this.getClientModuleBillingRates();
      });
  }
  private async getClientModuleBillingRates() {
    const clientModuleIds = this.clientModules.map(clientModule => clientModule.id);
    const queryResult$ = await this.afs.collection('clientModuleBillingRates', ref => ref
      .where('clientModule', 'in', clientModuleIds))
      .snapshotChanges()
      .pipe(first())
      .toPromise();
    this.clientModuleBillingRates = FirestoreUtilities.mapToType(queryResult$);
    this.clientModules.forEach((clientModule: ClientModule) => {
      const matchingBillingRate = this.clientModuleBillingRates.find((clientModuleBillingRate: ClientModuleBillingRate) => {
        return clientModuleBillingRate.clientModule === clientModule.id
      });
      if (matchingBillingRate) {
        clientModule['billingRate'] = matchingBillingRate;
      }
    })
  }
  getEstimatedMonthlyCost(dsModule: DeliverSenseModule) {
    const clientModule = this.getClientModule(dsModule);
    const discount = clientModule['billingRate'].discount ? clientModule['billingRate'].discount / 100 : 0;
    switch (clientModule['billingRate'].unit) {
      case 'locations':
        return (clientModule['billingRate'].unitMonthlyRate * this.clientLocations.length * (1 - discount)).toFixed(2);
      default:
        return '';
    }
  }
  private getClientModuleMonthlyEstimate() {
    combineLatest(
      [
        this.afs.collection('locations', ref => ref
          .where('client', '==', this.client.id)
          .where('active', '==', true))
          .snapshotChanges(),
        this.afs.collection('clientThirdParties', ref => ref
          .where('client', '==', this.client.id)
          .where('active', '==', true))
          .snapshotChanges(),
      ]
    ).pipe(takeUntil(this.destroy$)).subscribe(([locationResults$, clientThirdPartiesResult$]) => {
      this.clientLocations = <Location[]>FirestoreUtilities.mapToType(locationResults$);
      this.clientThirdParties = <ClientThirdParty[]>FirestoreUtilities.mapToType(clientThirdPartiesResult$);
    }, (e) => {
      console.log('error pulling third parties and locations');
    })
  }
  private getAvailableModules() {
    this.afs.collection('deliverSenseModules')
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dsModules$ => {
        this.availableModules = FirestoreUtilities.mapToType(dsModules$)
      });
  }
  deactivateModule(dsModule) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Confirm Deactivation of ${dsModule.name}`,
        message: `Are you sure you want to deactivate ${dsModule.name}? All your data for this module will be saved for when you reactivate the module. However, you will immediately
        lose access to the module and must reactivate the module in order to access ${dsModule.name} specific data.`,
        action: 'Yes, Deactivate Now'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const clientModule = this.getClientModule(dsModule);
        await this.afs.doc(`clientModules/${clientModule.id}`).delete();
        this.snackBar.open(`${dsModule.name} has been successfully deactivated.`, 'Dismiss', {
          duration: 5000
        });
      }
    });
  }
  async activateModule(dsModule) {
    const clientModule = new ClientModule();
    clientModule.module = dsModule.id;
    clientModule.client = this.client.id;
    clientModule.active = true;
    clientModule.userActivated = this.user.id;
    let successMessage;
    let successTitle;
    switch (dsModule.id) {
      case DsModules.AuditTrails:
        successMessage = `Audit Trails has been successfully activated! Before using Audit Trails make sure you have one or more projects created in your organization. Click "Audit Trails" in
        the side navigation and begin by manually creating an Audit Trail, or by uploading an excel or pdf document to auto generate an Audit Trail with sections matching the tabs/pages
        of the document.`
        successTitle = `Audit Trails Is Ready To Use!`
        clientModule.inTrial = false;
        clientModule.trialStart = moment().toDate();
        clientModule.trialEnd = moment().toDate();
        clientModule.trialRollOver = false;
        clientModule.inSetup = false;
        break;
      case DsModules.ExemptSalesManagement:
        successMessage = `Exempt Sales Management has been successfully activated! There are several steps needed to setup this module. You will not be charged for this module until
        setup is complete. Please check your email for next steps on how to get started using the module.`
        successTitle = `Exempt Sales Management Activated!`
        clientModule.inTrial = true;
        clientModule.trialStart = moment().toDate();
        clientModule.trialEnd = moment().add(30, 'days').toDate();
        clientModule.trialRollOver = true;
        clientModule.inSetup = true;
        break;
      case DsModules.ThirdPartyDeliveryReporting:
        successMessage = `Third Party Delivery Reporting has been successfully activated! There are several steps needed to setup this module. You will not be charged for this module until
        setup is complete. Please check your email for next steps on how to get started using the module.`
        successTitle = `Third Party Delivery Reporting Activated!`
        clientModule.inTrial = true;
        clientModule.trialStart = moment().toDate();
        clientModule.trialEnd = moment().add(30, 'days').toDate();
        clientModule.trialRollOver = true;
        clientModule.inSetup = true;
        break;
    }
    try {
      await this.afs.collection('clientModules').add(clientModule.toJSONObject());
      this.dialog.open(InformationDialogComponent, {
        data: {
          type: 'success',
          title: successTitle,
          message: successMessage
        }
      });
    } catch (e) {
      this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  async saveTrialRolloverChanges(dsModule, checked) {
    const clientModule = this.getClientModule(dsModule);
    await this.afs.doc(`clientModules/${clientModule.id}`).update({
      trialRollOver: !!checked
    });
    this.snackBar.open(`${dsModule.name}  ${checked ? 'will' : ' will not'} convert to a paid subscription at the end of the trial.`, 'Dismiss', {
      duration: 5000
    });
  }
  getClientModule(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule : undefined;
  }

  getBillingRates(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule['billingRates'] : undefined;
  }

  isClientModuleActive(dsModule: DeliverSenseModule) {
    if (dsModule) {
      const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
      return clientMatchingModule ? clientMatchingModule.active : false;
    }
    return false;
  }


  getClientMatchingModule(dsModule) {
    return this.clientModules.find(cm => cm.module.toString() === dsModule.id);
  }

  activatedInLast24Hours(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.getClientMatchingModule(dsModule);
    if (clientMatchingModule && clientMatchingModule.active && clientMatchingModule.dateUpdated) {
      return moment(clientMatchingModule.dateUpdated.toDate()).isSameOrAfter(moment().subtract(24, "hours"));
    } else {
      return false;
    }
  }

}
