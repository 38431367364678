<div fxLayout="row" fxLayoutAlign="end end">
  <button id="tutorial-sheet" class="dialog-close-button" mat-mini-fab color="accent">
    <mat-icon fontSet="fas" fontIcon="fa-info"></mat-icon>
  </button>
</div>
<div fxLayout="column">
  <ng-container *ngIf="tutorial; else noTutorialAvailable">
    <div fxLayout="row" fxLayoutAlign="center center">
      <h3 *ngIf="!(currentStep | async); else stepTitle">{{tutorial.title}}</h3>
      <ng-template #stepTitle>
        <h3>{{(currentStep | async).title}}</h3>
      </ng-template>
    </div>
    <ng-container *ngIf="!(currentStep | async); else currentStepInfo">
      <div class="tutorial-summary">{{tutorial.summary}}</div>
      <div fxLayout="row">
        <button mat-raised-button
                color="primary"
                *ngIf="tutorial.steps && tutorial.steps.length > 0; else finishButton"
                (click)="start()">
          Start!
        </button>
        <ng-template #finishButton>
          <button mat-raised-button color="primary" (click)="bottomSheetRef.dismiss()">
            Finished!
          </button>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #currentStepInfo>
      <div class="tutorial-summary">{{(currentStep | async).mainText}}</div>
      <ng-container *ngIf="(currentStep | async).mainImage">
        <img class="tutorial-step-image" (load)="imageLoaded = true;" [src]="(currentStep | async).mainImage"/>
      </ng-container>
      <div fxLayout="row">
        <button mat-raised-button color="warn" *ngIf="currentStepIndex > 0" (click)="previousStep()">Previous</button>
        <button mat-raised-button color="accent" *ngIf="currentStepIndex < (tutorial.steps.length - 1)"
                (click)="nextStep()">Next
        </button>
        <button mat-raised-button color="primary" *ngIf="currentStepIndex === (tutorial.steps.length - 1)"
                (click)="close()">Finished!
        </button>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #noTutorialAvailable>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h3>Tutorial for this page coming soon...</h3>
    </div>
  </ng-template>
</div>
