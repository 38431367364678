import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { User } from 'firebase';
import { Client, ClientModule } from '@deliver-sense-librarian/data-schema';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { ClientsModule } from 'app/components/organization/clients/clients.module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  public user: User;
  public client: Client;
  public uiState: UiState;
  private clientModules: ClientModule[] = [];
  private destroy$ = new Subject();
  thirdPartyReportingModule = 'vlBJZeknUiMFjkSqj6F6';
  auditTrailsModule = 'ru8bwRnug0X6Q6bLZwHX';
  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private el: ElementRef) {

  }

  ngOnInit() {
    this.store.select(store => store.uiState)
    .pipe(takeUntil(this.destroy$))
    .subscribe(uiState$ => {
      if (uiState$.authUser && uiState$.client) {
        this.user = uiState$.authUser;
        this.client = uiState$.client;
        this.uiState = uiState$;
        this.getClientModules();
      }
    });  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientModules$ => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
        if (this.clientModules.length > 0) {
          this.clientModules = this.clientModules.filter((clientModule: ClientModule) => {
            return clientModule.active;
          })
        }
      })
  }

  ngAfterViewInit() { }
  isModuleActive(moduleId) {
    const clientModule = this.clientModules.find(cm => cm.module === moduleId);
    return clientModule ? clientModule.active : false;
  }
}
