import {Component, OnInit} from '@angular/core';
import {scrollbarOptions} from 'app/shared/ds-constant';

@Component({
    selector: 'app-privacy-policy-dialog',
    templateUrl: './privacy-policy-dialog.component.html',
    styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent implements OnInit {
    scrollbarOptions = scrollbarOptions;

    constructor() {
    }

    ngOnInit() {
    }

}
