import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { takeUntil } from 'rxjs/operators';
import { ThirdPartyReport } from '@deliver-sense-librarian/data-schema';

@Component({
  selector: 'app-new-tpd-report',
  templateUrl: './new-tpd-report.component.html',
  styleUrls: ['./new-tpd-report.component.scss']
})
export class NewReportComponent implements OnInit {
  @Output() saveCompleted = new EventEmitter();
  private destroy$ = new Subject();
  uiState$: UiState;
  newReportForm: any;
  constructor(private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) { }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.setupNewReportForm();
        }
      });
  }
  private setupNewReportForm() {
    this.newReportForm = this.fb.group({
      name: new FormControl('', Validators.required),
      client: new FormControl(this.uiState$.client.id, Validators.required),
      creator: new FormControl(this.uiState$.authUser.id, Validators.required)
    })
  }
  public async save() {
    if (this.newReportForm.valid) {
      const formValues = this.newReportForm.value;
      const thirdPartyReport = new ThirdPartyReport();
      thirdPartyReport.client = formValues.client;
      thirdPartyReport.name = formValues.name;
      thirdPartyReport.creator = formValues.creator;
      const saveResult = await this.afs.collection('thirdPartyReports').add(thirdPartyReport.toJSONObject());
      this.snackBar.open('Successfully created third party report', 'Dismiss', {duration: 5000});
      this.saveCompleted.emit(saveResult.id);
    }
  }
}
