import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineAll, map, takeUntil, first } from "rxjs/operators";
import { from, Subject } from "rxjs";
import { PosTransaction, User, ExemptionCertificate, Client, Location } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from '@ngrx/store';
import { tableExpandAnimation } from "../../../shared/ds-constant";
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { LoadingDialogService } from '../../../services/loading-dialog.service';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { transition } from '@angular/animations';
import { UiState } from '../../../redux/custom-states/uiState/ui-state';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-exemption-certificate-management',
  templateUrl: './exemption-certificate-management.component.html',
  styleUrls: ['./exemption-certificate-management.component.scss'],
  animations: tableExpandAnimation
})
export class ExemptionCertificateManagementComponent implements OnInit, OnDestroy {
  public locations: Location[];
  private user: User;
  private destroy$ = new Subject();
  public transactions: PosTransaction[];
  public selectedLocations = new FormControl([], Validators.required);
  public onlyUndocumentedTransactions = new FormControl(false);
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public storagePath: any;
  private client: Client;
  noTransactions = false;
  private uiState$: UiState;
  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState$ = uiState$;
          this.getLocations();
          this.storagePath = `clients/${this.client.id}/exemptionCertificates/`;
          // this.fetchExemptOrders();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState$.locations, [3])
      .subscribe(locations$ => {
        this.locations = locations$.sort((a, b) => a.locationId < b.locationId ? -1 : 1);
      });
  }

  private fetchExemptOrders() {
    this.afs.collection('posTransactions', ref => ref
      .where('account', '==', 'ex')
      .where('client', '==', this.client.id)
      .limit(350))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(transactions$ => {
        this.transactions = FirestoreUtilities.mapToType(transactions$);
        this.fetchTransactionExemptionCertificates();
      });
  }

  getDownloadUrl(exemptionCertificate: ExemptionCertificate) {
  }


  runSearch() {
    this.transactions = null;
    const reportLocations = this.locations.filter(location => this.selectedLocations.value.find(locationId => locationId === location.locationId));
    const start = this.startDate.value;
    const end = this.endDate.value;
    const onlyUndocumented = !!this.onlyUndocumentedTransactions.value;
    const transactionQueries = reportLocations.map(location => {
      if (onlyUndocumented) {
        return this.afs.collection('posTransactions', ref => ref
          .where('location', '==', location.locationId)
          .where('account', '==', 'ex')
          .where('client', '==', this.client.id)
          .where('date', '<=', end)
          .where('date', '>=', start)
          .where('isDocumented', '==', false))
          .snapshotChanges();
      } else {
        return this.afs.collection('posTransactions', ref => ref
          .where('location', '==', location.locationId)
          .where('account', '==', 'ex')
          .where('client', '==', this.client.id)
          .where('date', '<=', end)
          .where('date', '>=', start))
          .snapshotChanges();
      }
    });
    this.loadingService.isLoading(true, 'Searching for tax exempt transactions.');
    from(transactionQueries)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(transactions$ => {
        this.transactions = FirestoreUtilities.mergeCollectionToType(transactions$);
        if (this.transactions.length > 0) {
          this.transactions.forEach(transaction => {
            const transactionLocation = this.locations.find(l => l.locationId === transaction.location);
            if (transactionLocation) {
              transaction['state'] = transactionLocation.addressState;
            }
          })
          this.fetchTransactionExemptionCertificates();
        } else {
          this.loadingService.isLoading(false);
          this.noTransactions = true;
        }
      })
  }
  private async fetchTransactionExemptionCertificates() {
    const exCertRequests = this.transactions.map(transaction => {
      return this.afs.collection('exemptionCertificates', ref => ref
        .where('posTransaction', '==', transaction.id))
        .snapshotChanges();
    });
    from(exCertRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe((exCerts$) => {
        const exemptionCertificates = FirestoreUtilities.mergeCollectionToType(exCerts$);
        exemptionCertificates.forEach((exc) => {
            this.storage.ref(exc.document).getDownloadURL().pipe(takeUntil(this.destroy$)).subscribe(downloadUrl => {
              exc['url'] = downloadUrl;
            });
        });
        this.transactions.forEach(transaction => {
          const matchingExCert = exemptionCertificates.find(exc => exc.posTransaction === transaction.id);
          transaction['exemptionCertificateExists'] = !!matchingExCert ? 'documented' : false;
          transaction['exemptionCertificate'] = matchingExCert;
        });
        this.loadingService.isLoading(false);
      });
  }


  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(3, 'month').toDate();
    }
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(this.startDate.value);

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate) ? null : {
        'date-minimum': {
          'date-minimum': validationDate.toDate(),
          'actual': controlDate.toDate()
        }
      };
    };
  }
}
