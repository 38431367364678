import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AuditTrailDocument,
  ThirdPartyReport,
  UserView
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { from, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { combineAll, takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { NewTpdReportDialogComponent } from '../../../../dialogs/new-tpd-report-dialog/new-tpd-report-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { UiState } from '../../../../redux/custom-states/uiState/ui-state';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<ThirdPartyReport>;
  public displayedColumns: string[] = ['name', 'creator', 'date', 'delete'];
  private destroy$ = new Subject();
  private creators: UserView[] = [];
  private reports: ThirdPartyReport[] = [];
  private uiState$: UiState;
  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.getReport();
        }
      });
  }

  public getCreatorInfo(doc$: AuditTrailDocument) {
    const creator = this.creators.find(user => user.id === doc$.creator);
    return creator ? creator.email ? creator.email : `${creator.firstName} ${creator.lastName}` : 'N/A';
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private getReport() {
    this.afs.collection('thirdPartyReports', ref => ref
      .where('client', '==', this.uiState$.client.id)
      .where('creator', '==', this.uiState$.authUser.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(reports$ => {
        this.reports = <ThirdPartyReport[]>FirestoreUtilities.mapToType(reports$)
          .sort((a: ThirdPartyReport, b: ThirdPartyReport) => {
            return moment(a.dateUpdated.toDate()).isSameOrBefore(b.dateUpdated.toDate()) ? 1 : -1;
          });
        this.tableData = new MatTableDataSource(this.reports);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this.getReportCreators();
      });
  }

  private getReportCreators() {
    const creatorRequests = this.reports.map((doc$: ThirdPartyReport) => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(userViews$ => {
        this.creators = FirestoreUtilities.mergeToType(userViews$).filter(item => !!item);
      })
  }

  async deleteReport(row: ThirdPartyReport) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this report?',
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`thirdPartyReports/${row.id}`).delete();
        this.snackBar.open('Report deleted successfully!', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }

  createReport() {
    const dialogRef = this.dialog.open(NewTpdReportDialogComponent, {
      panelClass: 'invisible-panel-dialog'
    });
    dialogRef.afterClosed().subscribe(newId$ => {
      this.router.navigate(['../', newId$], { relativeTo: this.route });
    })
  }
}
