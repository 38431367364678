import { takeUntil, combineAll } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Client, StripeCreditNote } from '@deliver-sense-librarian/data-schema';
import { StripeCardComponent } from 'app/components/stripe-card/stripe-card.component';
import { Subject, from } from 'rxjs';
import { StripeService } from 'app/services/stripe.service';
import { FormBuilder } from '@angular/forms';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-client-credit-balance',
  templateUrl: './client-credit-balance.component.html',
  styleUrls: ['./client-credit-balance.component.scss']
})
export class ClientCreditBalanceComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @ViewChild(StripeCardComponent) newCard: StripeCardComponent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['date', 'credit', 'debit', 'ending_balance', 'invoice', 'type'];
  public clientInvoiceCredits: any[] = [];
  loadingInvoiceCredits: boolean;
  private destroy$ = new Subject();

  constructor(private stripeService: StripeService,
    private afs: AngularFirestore,
    private loadingService: LoadingDialogService) { }

  ngOnInit() {
    this.getClientInvoiceCredits();

  }
  getFormatedCreatedDate(row) {
    return moment.unix(row.created).format("M/D/YYYY hh:mm:ss A");
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  async getClientInvoiceCredits() {
    this.loadingInvoiceCredits = true;
    try {
      const clientInvoiceCredits$ = await this.stripeService.getClientInvoiceCredits(this.client).pipe(first()).toPromise() as StripeCreditNote[];
      this.clientInvoiceCredits = clientInvoiceCredits$.sort((a, b) => a.created < b.created ? 1 : -1);
      this.getCreditInvoices();
      this.loadingInvoiceCredits = false;
      this.tableData = new MatTableDataSource(this.clientInvoiceCredits);
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
    } catch (e) {
      console.log(e.message);
      this.loadingInvoiceCredits = false;
    }
  }
  getCreditInvoices() {
    const invoiceAppliedCreditNotes = this.clientInvoiceCredits.filter(invoiceCredit => !!invoiceCredit.invoice)
    if (invoiceAppliedCreditNotes.length > 0) {
      const invoiceRequests = invoiceAppliedCreditNotes.map(creditNote => {
        return this.afs.collection('clientInvoices', ref => ref
        .where('client', '==', this.client.id)
        .where('processorInvoiceId', '==', creditNote.invoice))
        .snapshotChanges();
      });
      from(invoiceRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(clientInvoices$ => {
        const clientInvoices = FirestoreUtilities.mergeCollectionToType(clientInvoices$);
        invoiceAppliedCreditNotes.forEach(creditNote => {
          const invoiceMatch = clientInvoices.find(clientInvoice => clientInvoice.processorInvoiceId === creditNote.invoice);
          if (invoiceMatch) {
            creditNote.clientInvoice = invoiceMatch;
          }
        })
      })
    }
  }
}
