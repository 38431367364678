import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { LocationReport, ThirdPartyDeliveryAnalyticsEngine } from "../third-party-delivery-analytics-engine";
import { Location, ThirdParty } from "@deliver-sense-librarian/data-schema";
import { downloadDataAsFile, tableExpandAnimation, tablescrollbarOptions } from "../../../../shared/ds-constant";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { ChangeTransactionThirdPartyDialogComponent } from "../../../../dialogs/change-transaction-third-party-dialog/change-transaction-third-party-dialog.component";
import { WebDataRocksPivotComponent } from "../../../../webdatarocks/webdatarocks.angular4";
import { Papa } from 'ngx-papaparse';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-transaction-drill-down',
  templateUrl: './transaction-drill-down.component.html',
  styleUrls: ['./transaction-drill-down.component.scss'],
  animations: tableExpandAnimation
})
export class TransactionDrillDownComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() locationId: string;
  @Input() thirdPartyId: string;
  @Input() reportTitle: string;
  @Input() reportHeaderCard: ElementRef;
  @Input() locationReportData: LocationReport[];
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  @Input() minDate: any;
  @Input() maxDate: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public matchTransactions = false;
  public parametersForm: FormGroup;
  public selectedDrillDownThirdParty = new FormControl(null, Validators.required);
  public selectedDrillDownLocation = new FormControl(null, Validators.required);
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'Pos-Transaction-Date',
    'Pos-Transaction-Sale',
    'Pos-Transaction-Tax',
    'Match%',
    'ThirdParty-Transaction-Date',
    'ThirdParty-Transaction-Sale',
    'ThirdParty-Transaction-Tax',
  ];
  scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;
  private transactions: any = [];
  uiState$: any;
  destroy$ = new Subject();
  allTransactions: { posTransactions: any[], tpdTransactions: any[] };
  transactionsMatches = [];
  constructor(private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog) {
  }


  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.setupParametersForm();
        }
      });
  }

  ngOnDestroy(): void {

  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  setupParametersForm() {
    this.parametersForm = this.fb.group(
      {
        selectedLocation: new FormControl('', Validators.required),
        selectedThirdParty: new FormControl('', Validators.required),
        startDate: new FormControl(this.minDate ? this.minDate : '', Validators.required),
        endDate: new FormControl(this.maxDate ? this.maxDate : '', Validators.required),
        acceptableVariance: new FormControl(95, Validators.required)
      }
    )
  }
  runAnalysis() {
    if (this.parametersForm.valid) {
      this.loadingService.isLoading(true, 'Matching transactions...');
      const locationId = this.parametersForm.get('selectedLocation').value;
      const thirdPartyId = this.parametersForm.get('selectedThirdParty').value;
      const start = moment(this.parametersForm.get('startDate').value).startOf('day').toDate();
      const end = moment(this.parametersForm.get('endDate').value).endOf('day').toDate();
      const acceptableVariance = 0;
      this.analyticsEngine.fetchParameterizedReportData(locationId, thirdPartyId, start, end, this.uiState$.client, this.afs)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result$ => {
          this.loadingService.isLoading(false);
          this.allTransactions = result$;
          const matchingAnalysis: {matches, distances} = this.analyticsEngine.createDistanceMatrix(acceptableVariance, this.allTransactions);
          this.transactionsMatches = this.flattenTransactionMatches(matchingAnalysis);
          this.tableData = new MatTableDataSource(this.transactionsMatches);
          this.tableData.paginator = this.paginator;
          this.tableData.sort = this.sort;
          this.cdr.detectChanges();
        }, () => this.loadingService.isLoading(false))// @TODO needs error catch

    } else {
      this.snackBar.open('Please select a location, 3PD, start date, and end date', 'Dismiss', {
        duration: 5000
      })
    }
  }

  private flattenTransactionMatches(matchingAnalysis: {matches, distances}) {
    return matchingAnalysis.matches.map(match => {
      const posTransaction = this.getPosTransaction(match.posTransaction);
      const thirdPartyTransaction = this.getThirdPartyTransaction(match.thirdPartyTransaction);
      return {
        'Pos-Transaction-Date': posTransaction && posTransaction.date ? moment(posTransaction.date.toDate()).format('M/D/YYYY') : '',
        'Pos-Transaction-Sale': posTransaction ? posTransaction.sale : '',
        'Pos-Transaction-Tax': posTransaction ? posTransaction.tax : '',
        'Match%': `${+this.analyticsEngine.normalizeDistance(match.match, matchingAnalysis.distances)}`,
        'DeliverSense-ThirdParty-Transaction-Id': thirdPartyTransaction ? thirdPartyTransaction.id : '',
        'ThirdParty-Name': thirdPartyTransaction ? this.getThirdPartyName(thirdPartyTransaction.thirdParty) : '',
        'ThirdParty-Transaction-Date': thirdPartyTransaction && thirdPartyTransaction.date ? moment(thirdPartyTransaction.date.toDate()).format('M/D/YYYY') : '',
        'ThirdParty-Transaction-Sale': thirdPartyTransaction ? thirdPartyTransaction.sale : '',
        'ThirdParty-Transaction-Tax': thirdPartyTransaction ? thirdPartyTransaction.tax : '',
      }
    }).sort((a, b) => {
      return a['Match%'] < b['Match%'] ? 1 : -1;
    });
  }

  getThirdPartyName(thirdPartyId) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getPosTransaction(posTransactionId: string) {
    return this.allTransactions.posTransactions.find(t => t.id === posTransactionId);
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.allTransactions.tpdTransactions.find(t => t.id === thirdPartyTransactionId);
  }

  exportReport() {
    const results = this.papa.unparse(this.tableData.data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
      const fileName = `${this.reportTitle}_Transaction-Drill-Down_${moment().format('M/D/YY-HH:mm:ss')}`;
      downloadDataAsFile(results, fileName, 'csv');
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getSelectedLocationName() {
    const selectedLocation = this.parametersForm.get('selectedLocation').value;
    if (selectedLocation) {
      const location = this.locations.find(_l => _l.locationId === selectedLocation);
      return `${location.name}`
    }
    return '';
  }
  getSelectedThirdPartyName() {
    const selectedThirdParty = this.parametersForm.get('selectedThirdParty').value;
    if (selectedThirdParty) {
      const tpd = this.thirdParties.find(_tp => _tp.id === selectedThirdParty);
      return `${tpd.name}`
    }
    return '';
  }
  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (280 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }

  clearResults() {
    this.tableData = null;
  }
  changePosHouseAccount(posTransactionId: string) {
    const posTransaction = this.getPosTransaction(posTransactionId);
    const changeAccountDialogRef = this.dialog.open(ChangeTransactionThirdPartyDialogComponent, {
      data: {
        transaction: posTransaction
      }
    });
    changeAccountDialogRef.afterClosed().subscribe(changedAccount => {
      if (changedAccount) {
        this.afs.doc(`posTransactions/${posTransaction.id}`).update({
          account: changedAccount
        })
      }
    })
  }
}
