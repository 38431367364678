import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as screenfull from 'screenfull';
import { FirebaseAuthService } from "../../../auth/services/firebase-auth.service";
import { Store } from '@ngrx/store';
import { Client, User, UserNotification } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { FormControl } from "@angular/forms";
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Subject, of } from "rxjs";
import { combineAll, take, takeUntil, map, first } from 'rxjs/operators';
import { MatSelectChange } from "@angular/material/select";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { SetAccountClientAction, UnsetSelectedClientAction, ToggleShowDataUploadAction } from "../../../redux/custom-states/uiState/ui-state-actions";
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { ClearSfaAction } from '../../../redux/custom-states/uiState/ui-state-actions/authentication-actions';
import { environment } from 'environments/environment';
import { DataUploadDialogComponent } from '../../../dialogs/data-upload-dialog/data-upload-dialog.component';
import { DataUploadService } from '../../../services/data-upload.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleNotificationSidenav = new EventEmitter<void>();
  public user: User;
  public client: Client;
  public selectedClient = new FormControl();
  public availableClients: Client[] = [];
  public userNotifications: UserNotification[] = [];
  private destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private dataUploadService: DataUploadService,
    private loadingService: LoadingDialogService,
    private store: Store<any>) {
  }

  ngOnInit(): void {
    this.store.select(state => state.uiState).subscribe(uiState$ => {
      if (uiState$.authUser && uiState$.client) {
        this.user = uiState$.authUser;
        this.client = uiState$.client;
        if (this.selectedClient && !this.selectedClient.value) {
          this.selectedClient.patchValue(this.client.id);
          this.selectedClient.valueChanges.subscribe((value) => {
            this.changeSelectedClient(value);
          })
        }
        this.getAvailableClients();
        this.getNotifications();
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fullScreenToggle(): void {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  async logout() {
    await this.auth.signOut();
    this.router.navigate(['']);
  }

  public getClient(clientId) {
    const clientMatch = this.availableClients.find(_client => _client.id === clientId);
    return clientMatch;
  }
  public async clearClientSelection() {
    this.selectedClient = null;
    this.store.dispatch(new UnsetSelectedClientAction());
    const sfaToken = localStorage.getItem('sfaToken');
    if (sfaToken) {
      this.store.dispatch(new ClearSfaAction(sfaToken));
    }
    this.router.navigate(['/client-selection']);
  }
  private getAvailableClients() {
    this.afs.collection(`users/${this.user.id}/clientRoles`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientRoles$ => {
        const clientRoles = FirestoreUtilities.mapToType(clientRoles$);
        const clientRequests = clientRoles.map(clientRole => {
          if (clientRole.role) {
            return this.afs.doc(`clients/${clientRole.resource}`).snapshotChanges()
          }
        }).filter(req => !!req);
        if (clientRequests.length > 0) {
          from(clientRequests)
            .pipe(combineAll(), takeUntil(this.destroy$))
            .subscribe(clients$ => {
              this.availableClients = <Client[]>FirestoreUtilities.mergeToType(clients$);
              const clientLogos = this.availableClients.map(client => {
                if (client.logoPath) {
                  return this.storage.ref(client.logoPath).getDownloadURL().pipe(map(url => {
                    client.logo = url
                  }))
                } else {
                  return of();
                }
              });
              from(clientLogos)
                .pipe(takeUntil(this.destroy$), combineAll())
                .subscribe(clientLogoMappings$ => {

                })
            })
        }
      }, (e) => {
        throw new Error(e);
      });
  }

  changeSelectedClient(clientId) {
    const selectedClient = this.availableClients.find(client => client.id === clientId);
    if (selectedClient && selectedClient.id !== this.client.id) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Confirm Client Selection Change',
          message: 'Are you sure you want to change your client selection?',
          action: 'Yes.'
        }
      });
      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          const sfaToken = localStorage.getItem('sfaToken');
          if (sfaToken) {
            this.store.dispatch(new ClearSfaAction(sfaToken));
          }
          if (selectedClient.sfaRequired) {
            this.loadingService.isLoading(true, 'Changing client...');
            this.store.dispatch(new UnsetSelectedClientAction());
            this.router.navigate(['/sfa', selectedClient.id])
            // purely ux to let user know what is happening
            setTimeout(() => {
              this.loadingService.isLoading(false);
            })
          } else {
            this.store.dispatch(new SetAccountClientAction(selectedClient));
            this.loadingService.isLoading(true, 'Changing client...');
            this.router.navigate(['client-selection'])
            setTimeout(() => {
              window.location.reload();
              this.loadingService.isLoading(false);
            })
          }
        } else {
          this.selectedClient.patchValue(this.client.id);
        }
      });
    }
  }

  private getNotifications() {
    this.afs.collection('userNotifications', ref => ref
      .where('toUser', '==', this.user.id)
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(userNotifications$ => {
        this.userNotifications = <UserNotification[]>FirestoreUtilities.mapToType(userNotifications$);
      })
  }

  countUnreadNotifications() {
    return this.userNotifications.filter(notification => !notification.read).length;
  }
  openDataUploadWindow() {
    this.store.dispatch(new ToggleShowDataUploadAction(true));
  }

  uploadCompletions() {
    const errors = this.dataUploadService.completedUploads().errors
    const success = this.dataUploadService.completedUploads().success;
    return {
      errorsExist: errors > 0,
      total: errors + success
    }
  }
}
