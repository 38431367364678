import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientRole, Department, Entity, User, UserRoles} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit, OnDestroy {
  @Input() department: Department;
  @Output() complete = new EventEmitter();
  departmentForm: FormGroup;
  private user: User;
  private client: any;
  private entities: Entity[] = [];
  private clientRoles: ClientRole;
  private destory$ = new Subject();
  private uiState: UiState;

  constructor(private store: Store<any>,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private loadingService: LoadingDialogService,
              private router: Router,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          if (!this.department) {
            this.activedRoute.params.subscribe(params$ => {
              if (params$['id'] && params$['id'] !== 'new') {
                this.fetchDepartment(params$['id']);
              } else {
                this.department = new Department();
                this.setupDepartmentForm()
              }
            });
          } else {
            this.setupDepartmentForm();
          }
          this.getEntities()
        }
      });
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }

  private fetchDepartment(departmentId) {
    this.afs.doc(`departments/${departmentId}`)
      .snapshotChanges()
      .subscribe(department$ => {
        this.department = FirestoreUtilities.objectToType(department$);
        this.setupDepartmentForm();
      })
  }

  private setupDepartmentForm() {
    this.departmentForm = this.fb.group({
      name: new FormControl(this.department.name ? this.department.name : '', Validators.required),
      client: new FormControl(this.department.client ? this.department.client : this.client.id, Validators.required),
      entity: new FormControl(this.department.entity ? this.department.entity : '', Validators.required),
      description: new FormControl(this.department.description ? this.department.description : '')
    });
  }

  public save() {
    if (this.departmentForm.valid) {
      if (this.department && this.department.id) {
        const _department = this.mapFormToDepartment();
        this.afs.doc(`departments/${this.department.id}`)
          .update(_department.toJSONObject()).then(() => {
          this.snackBar.open('Successfully updated the department', 'Dismiss', {
            duration: 5000,
          });
          this.complete.emit(true);
          this.router.navigate(['/app/organization/departments']);
        })
      } else {
        const _department = this.mapFormToDepartment();
        this.afs.collection(`departments`)
          .add(_department.toJSONObject()).then((newDepartment$) => {
          this.afs.doc(`users/${this.user.id}/clientRoles/${this.client.id}/organizationRoles/${newDepartment$.id}`).set({
            resource: newDepartment$.id,
            type: 'department',
            role: UserRoles.admin
          }).then(() => {
            this.snackBar.open('Successfully created the department', 'Dismiss', {
              duration: 5000,
            });
            this.complete.emit(true);
            this.router.navigate(['/app/organization/departments']);
          });
        })
      }
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000,
      });
    }
  }


  private mapFormToDepartment() {
    const _department = new Department();
    const form = this.departmentForm.value;
    _department.name = form.name;
    _department.entity = form.entity;
    _department.client = this.client.id;
    _department.description = form.description;
    return _department;
  }

  private getEntities() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin])
      .pipe(takeUntil(this.destory$))
      .subscribe(entities$ => {
        this.entities = (entities$);
      });
  }

  delete() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this department? All projects within this department will also be deleted.',
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`departments/${this.department.id}`).delete();
        this.loadingService.isLoading(true, 'Deleting department...');
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Department deleted successfully!', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['/app/organization/departments'])
        }, 1000)
      }
    });
  }
}
