<div fxLayout="row" fxLayoutAlign="space-between start">
  <button id="export-tax-exempt-documentation" mat-raised-button color="accent" (click)="exportDocumentation()">
    Export <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
  </button>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Transactions">
  </mat-form-field>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
<div class="mat-elevation-z8 report-table">
  <mat-table [dataSource]="tableData" matSort class="mat-elevation-z5">
    <ng-container matColumnDef="location" sticky>
      <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.location}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date" sticky>
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.date.toDate() | date:'shortDate'}}
      </mat-cell>
    </ng-container>
    <ng-container *ngFor="let column of displayedColumns; let i = index">
      <ng-container [matColumnDef]="column" *ngIf="i > 1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row[column]}}
        </mat-cell>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let element; columns: displayedColumns;">
    </mat-row>
  </mat-table>
</div>
