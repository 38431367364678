<div class="app mat-typography" [dir]="options.dir"
  [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'compact-sidebar': options.compact, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <!-- <ngx-loading-bar color="#009688"></ngx-loading-bar> -->
  <app-header (toggleSidenav)="sidemenu.toggle()"></app-header>
  <mat-sidenav-container class="app-inner">
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'"
      [opened]="!isOver()" (openedStart)="sidePanelOpened = true" (closedStart)="sidePanelOpened = false"
      (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()" [perfectScrollbar]="config"
      [disabled]="mediaMatcher.matches">
      <app-sidebar (click)="updatePS()"></app-sidebar>
    </mat-sidenav>
    <mat-sidenav position="end" mode="over" [opened]="showDataUpload" disableClose="true" class="chat-panel">
      <app-data-uploads-list></app-data-uploads-list>
    </mat-sidenav>
    <ng-container *ngIf="user && client">
      <div [perfectScrollbar]="config" [disabled]="mediaMatcher.matches">
        <router-outlet></router-outlet>
      </div>
    </ng-container>

  </mat-sidenav-container>
</div>
