import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: '[appScrollListener]'
})
export class ScrollListenerDirective {
  @Output() setScroll = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  @HostListener('scroll', ['$event'])
  scrollIt() {
    if (this.el.nativeElement &&
      (this.el.nativeElement.scrollTop + this.el.nativeElement.offsetHeight) >= this.el.nativeElement.scrollHeight) {
      this.setScroll.emit(false);
    } else {
      this.setScroll.emit(true);
    }
  }

}
