<app-dashboard-widget [title]="'Daily Charges'"
[description]="'Most recent daily charges to your account.'"
[noResultsText]="'No Charges To Display'"
[widgetData]="clientDayCharges"
[loadingWidgetData]="loadingWidgetData">
<ng-template let-item>
  <h3 matLine>
    <a [routerLink]="'/app/client/'">{{item['moduleName']}} - ${{item.subTotal}}</a>
  </h3>
  <h6>{{item.unit}}</h6>
  <small matLine>Charged On: {{item.chargeDate?.toDate() | date:'shortDate'}} </small>
  <div fxLayout="row" class="list-item-divider">
    <mat-divider></mat-divider>
  </div>
</ng-template>
</app-dashboard-widget>
