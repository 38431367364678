<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <div class="text-xs-center pb-1">
            <img src="assets/images/logo-dark-vert.png" alt="" width="50%"/>
            <p class="mat-text-muted">Sign in to continue.</p>
          </div>
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div>
              <button mat-raised-button (click)="loginWithProvider('microsoft')" [style.width.px]="200">
                <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png"> Sign in with Microsoft
              </button>
            </div>
            <div>
              <button mat-raised-button (click)="loginWithProvider('google')" [style.width.px]="200">
                <img [style.height.px]="25" src="/assets/images/google-login-symbol.png"> Sign in with Google
              </button>
            </div>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div fxLayout="column" fxLayoutAlign="space-around">
              <div class="pb-1">
                <mat-form-field appearance="outline" style="width: 100%">
                  <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>
                <small *ngIf="loginForm.controls['email'].hasError('required') && loginForm.controls['email'].touched" class="mat-text-warn">Username
                  is required.</small>
              </div>
              <div class="pb-1">
                <mat-form-field appearance="outline" style="width: 100%">
                  <input matInput type="password" placeholder="Password" formControlName="password">
                </mat-form-field>
                <small *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched"
                       class="mat-text-warn">Password is required.</small>
              </div>
              <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid || submitted">Login</button>
            </div>
            <div class="pt-1 pb-1 text-xs-center">
              <a [routerLink]="['/forgot']">Forgot password?</a>&nbsp;&nbsp;&nbsp;&nbsp;
             <!-- <a [routerLink]="['/sign-up']">Sign up for a new account.</a> -->
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
