import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Client} from "@deliver-sense-librarian/data-schema";

@Component({
    selector: 'app-create-client-dialog',
    templateUrl: './create-client.dialog.component.html',
    styleUrls: ['./create-client.dialog.component.scss']
})
export class CreateClientDialogComponent implements OnInit {
    public client: Client;

    constructor(public dialogRef: MatDialogRef<CreateClientDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.client = data ? data.client : undefined;
    }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close();
    }
}

