import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/firestore';
import { Client, StripeTaxRate } from '@deliver-sense-librarian/data-schema';
import { Subject } from 'rxjs';
import { StripeService } from 'app/services/stripe.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { first } from 'rxjs/operators';
import { StripeCardComponent } from 'app/components/stripe-card/stripe-card.component';

@Component({
  selector: 'app-client-payment-details',
  templateUrl: './client-payment-details.component.html',
  styleUrls: ['./client-payment-details.component.scss']
})
export class ClientPaymentDetailsComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @ViewChild(StripeCardComponent) newCard: StripeCardComponent;

  private destroy$ = new Subject();

  clientCurrentMethod: any;
  addingNewSource = false;
  autoPayEnabled = new FormControl();
  newBankSourceForm: FormGroup;
  loadingPaymentMethod: boolean;
  clientTaxRate: StripeTaxRate;
  loadingTaxRate: boolean;
  constructor(private stripeService: StripeService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.getClientAutoPayMethod();
    this.setupNewBankSourceForm();
    this.getClientTaxRate();
    this.autoPayEnabled.patchValue(this.client.autoPayEnabled);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  setupNewBankSourceForm() {
    this.newBankSourceForm = this.fb.group({
      accountNumber: new FormControl('', Validators.required),
      routingNumber: new FormControl('', Validators.required),
      nameOnAccount: new FormControl('', Validators.required)
    });
  }
  public async getClientTaxRate() {
    if (this.client.defaultTaxRate) {
      this.loadingTaxRate = true;
      this.clientTaxRate = <StripeTaxRate>await this.stripeService.getTaxRate(this.client.id, this.client.defaultTaxRate).pipe(first()).toPromise();
      this.loadingTaxRate = false;
    }
  }
  async updateAutoPay() {
    await this.afs.doc(`clients/${this.client.id}`).update({
      autoPayEnabled: this.autoPayEnabled.value,
      dateUpdated: moment().toDate()
    });
    this.snackBar.open(`Auto Payments ${this.autoPayEnabled.value ? 'Enabled' : 'Disabled'}`, 'Dismiss', {
      duration: 5000
    });
  }
  async updateBillingEmail(newBillingEmail) {
    try {
      this.loadingService.isLoading(true, 'Updating your billing email...');
      await this.afs.doc(`clients/${this.client.id}`).update({
        billingEmail: newBillingEmail
      });
      await this.stripeService.updateClientEmail(this.client, newBillingEmail).pipe(first()).toPromise();
      this.loadingService.isLoading(false);
      this.snackBar.open('Client billing email updated successfully.', 'Dismiss', {
        duration: 5000
      })
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Oops... something went wrong. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  getMethodCCIcon() {
    switch (this.clientCurrentMethod.card.brand.toLowerCase()) {
      case 'visa':
        return 'fa-cc-visa';
      case 'american express':
        return 'fa-cc-amex';
      case 'diners club':
        return 'fa-cc-diners-club';
      case 'discover':
        return 'fa-cc-discover';
      case 'mastercard':
        return 'fa-cc-mastercard';
      case 'jcb':
        return 'fa-cc-jcb';
      default:
        return 'cc-stripe'

    }
  }
  async getClientAutoPayMethod() {
    if (this.client.autoPayMethod) {
      this.loadingPaymentMethod = true;
      const method = await this.stripeService.getClientAutoPayMethod(this.client).pipe(first()).toPromise();
      this.loadingPaymentMethod = false;
      this.clientCurrentMethod = method;
    } else {
      return null;
    }
  }


  public async createCardSource() {
    const cardData = this.newCard.getCard();
    try {
      const result = await this.newCard.stripe.createPaymentMethod({
        type: 'card',
        card: cardData,
      });
      const clientPaymentMethod = await this.stripeService.createStripePaymentMethod(result.paymentMethod).pipe(first()).toPromise();
      this.snackBar.open('Auto payment method updated', 'Dismiss', {
        duration: 5000
      });
      this.addingNewSource = false;
    } catch (e) {
      this.snackBar.open(`Error: ${e}`, 'Dismiss', {
        duration: 5000
      })
    }
  }
}
