import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Client, ClientRole, Department, Project, User, UserRoles} from "@deliver-sense-librarian/data-schema";
import {from, Subject} from "rxjs";
import {FirebaseApp} from "@angular/fire";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineAll, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<Project>;
  public displayedColumns: string[] = ['name', 'department', 'description'];
  private user: User;
  private destroy$ = new Subject();
  public client: Client;
  private projects: Project[] = [];
  private departments: Department[] = [];
  private uiState: UiState;

  constructor(private firebaseApp: FirebaseApp,
              private store: Store<any>,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getProjects();
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private getProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.contributor, UserRoles.viewer])
      .pipe(takeUntil(this.destroy$))
      .subscribe(projects$ => {
        this.projects = projects$ as Project[];
        if (this.projects && this.projects.length > 0) {
          this.projects = this.projects.filter(project => project.client === this.client.id);
          this.tableData = new MatTableDataSource(this.projects);
          this.getProjectDepartments();
        }
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private getProjectDepartments() {
    const departmentRequests = this.projects.map(project => {
      return this.afs.doc(`departments/${project.department}`).snapshotChanges()
    }).filter(req => !!req);
    from(departmentRequests)
      .pipe(combineAll(), takeUntil(this.destroy$))
      .subscribe(departments$ => {
        this.departments = FirestoreUtilities.mergeToType(departments$);
      })
  }

  public getDepartmentName(project: Project) {
    const department = this.departments.find(d => d.id === project.department);
    return department ? department.name : '';
  }

}
