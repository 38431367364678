import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TermsComponent} from './terms/terms.component';
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import {DsSharedModule} from "../../shared/ds-shared.module";

@NgModule({
    imports: [
        CommonModule,
        MalihuScrollbarModule.forRoot(),
        FlexLayoutModule,
        DsSharedModule
    ],
    declarations: [TermsComponent, PrivacyPolicyComponent],
    exports: [TermsComponent, PrivacyPolicyComponent]
})
export class TermsModule {
}
