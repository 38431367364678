<mat-card class="dialog-card">
  <mat-card-header class="action-card-header">
    <mat-card-title>
      <h2>3PD Report Field Selection</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutGap="10px">
    <mat-checkbox (change)="toggleSelectAll($event)">Select All</mat-checkbox>
    <div fxLayout="row" fxLayoutAlign="end space-evenly">
    <button mat-raised-button (click)="treeControl.expandAll();">
      Expand All
      <mat-icon fontSet="fas" fontIcon="fa-expand"></mat-icon>
    </button>
    <button mat-raised-button (click)="treeControl.collapseAll();">
      Collapse All
      <mat-icon fontSet="fas" fontIcon="fa-compress"></mat-icon>
    </button>
    </div>
    <div [style.height]="'65vh'" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="fieldlistSelection.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-form-field>
          <input matInput #itemValue placeholder="New item...">
        </mat-form-field>
        <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.filename">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)"
                      [indeterminate]="descendantsPartiallySelected(node)"
                      (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        <button *ngIf="dynamicFields" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
      </mat-tree-node>
    </mat-tree>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end space-evenly">
    <button mat-raised-button color="primary" (click)="apply()">Apply</button>
    <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
  </mat-card-actions>
</mat-card>
