import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent} from "./sidebar.component";
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AccordionModule} from "../menu-accordion/accordion.module";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        CommonModule,
        AccordionModule,
        RouterModule
    ]
})
export class SidebarModule {
}
