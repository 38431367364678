<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Saved Reports</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <button mat-raised-button color="primary" (click)="createReport()">New Report</button>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div class="mat-elevation-z8">
        <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a [routerLink]="'../' + row.id">
                {{row.name}}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.dateUpdated.toDate() | date:'short'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Creator</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{getCreatorInfo(row)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button [disabled]="uiState$.authUser.id !== row.creator" mat-icon-button color="warn" (click)="deleteReport(row)">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>


