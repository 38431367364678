<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="space-evenly center">
  <h1 mat-dialog-title>{{data.title | titleCase}}</h1>
</div>
<div mat-dialog-content fxLayoutAlign="row" fxLayoutAlign="space-around center">
  <p>{{data.message}}</p>
</div>
<ng-container *ngIf="data.typeConfirm">
  <div>
    To confirm deletion of {{data.title}} type: <b><u>{{data.typeConfirm}}</u></b> below.
  </div>
  <div fxLayout="row">
    <mat-form-field>
      <input matInput [placeholder]="data.typeConfirm" [formControl]="typeConfirmInput"/>
    </mat-form-field>
  </div>
</ng-container>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button fxFlex mat-raised-button [disabled]="!typingConfirmed" color="warn" [mat-dialog-close]="true"
          tabindex="2">{{data.action | titleCase}}</button>
  <button fxFlex mat-raised-button color="accent" (click)="dialogRef.close()" tabindex="-1">Nevermind</button>
</div>
