<mat-card-content appWindowHeightMatcher heightCompensation="440">
  <div #scrollContainer appScrollListener *ngIf="messages" (setScroll)="setUserScrolled($event)" [style.height.%]="100">
    <ng-container *ngFor="let message of messages; trackBy: trackMessage">
      <ng-container *ngIf="message.from.id !== this.user.id; else myMessage">
        <div *ngIf="noMessages" fxLayoutAlign="center center" fxLayout="row">
          <h3>No Messages For This Comment</h3>
        </div>
        <div class="chat-conversation-user them">
          <app-avatar *ngIf="message.from && message.from.id; else noUser" [userView]="message.from"></app-avatar>
          <ng-template #noUser>
            <app-avatar [name]="'?'" [toolTip]="'User has been removed from project'"></app-avatar>
          </ng-template>
          <div class="chat-conversation-message">
            <p>{{message.message}}
              <span class="chat-date">
                {{ message.dateCreated.toDate() | date: 'fullDate' }}
              </span>
            </p>
          </div>
        </div>
      </ng-container>
      <ng-template #myMessage>
        <div class="chat-conversation-user me" fxLayout="row">
          <div class="chat-conversation-message" [style.order]="0">
            <p>{{message.message}}
              <span class="chat-date">{{ message.dateCreated.toDate() | date: 'fullDate' }}
                <b>
                  <mat-icon (click)="editMessage(message)" fontSet="fas" fontIcon="fa-edit"></mat-icon>
                </b>
              </span>
            </p>
          </div>
          <app-avatar [style.float]="'right'" *ngIf="message.from && message.from.id; else noUser" [userView]="message.from">
          </app-avatar>
          <ng-template #noUser>
            <app-avatar [name]="'?'" [toolTip]="'User has been removed from project'"></app-avatar>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>
  </div>
</mat-card-content>
<div class="px-2 comment-message-new-message-container" *ngIf="messages">
  <div fxLayout="row" fxLayoutAlign="start start">
    <form fxFlex fxLayout="row" class="search-form" [style.padding-bottom.px]="2" fxLayoutAlign="start start" (ngSubmit)="createCommentMessage()" *ngIf="canUserMessage()">
      <button mat-icon-button [disabled]="messageInput.invalid" type="submit" color="accent">
        <mat-icon>send</mat-icon>
      </button>
      <mat-form-field class="mr-1 ml-1" fxFlex>
        <textarea [formControl]="messageInput" matInput placeholder="Send message"></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
