import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { LocationReport, ThirdPartyDeliveryAnalyticsEngine } from '../third-party-delivery-analytics-engine';
import { ThirdParty, Location } from '@deliver-sense-librarian/data-schema';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  @Input() locationId: string;
  @Input() thirdPartyId: string;
  @Input() reportHeaderCard: ElementRef;
  @Input() locationReportData: LocationReport[];
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  selectedLocations = new FormControl([], Validators.required);
  selectedThirdParty = new FormControl('', Validators.required);
  selectedVarianceType = new FormControl('', Validators.required);
  showChart = false;
  data: any
  varianceTypes = [
    'Sales',
    'Tax',
    'Remittance',
  ]
  label = '';
  self = this;
  public bubbleChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      position: 'top',
      text: ``
    },
    tooltips: {
      callbacks: {
        label: (chart, tooltipItem) => {
          const dataItem = tooltipItem.datasets[0].data[chart.index]
          const text = `Location: ${dataItem['x']} - ${this.getLocationName(dataItem['x'])} | Total Sales: $${dataItem['y']} | ${this.selectedVarianceType.value} Variance: $${this.getVariance(dataItem['x'])}`
          return text
        }
      }
    }
  };
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = false;

  public bubbleChartData: ChartDataSets[] = [
    {
      data: [
      ],
      label: this.label,
      backgroundColor: 'teal',
      borderColor: 'indigo',
      hoverBackgroundColor: 'indigo',
      hoverBorderColor: 'teal',
    },
  ];

  public bubbleChartColors: Color[] = [
    {
      backgroundColor: 'teal'
    }
  ];
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  getLocationName(locationId) {
    const location = this.locations.find(l => l.locationId == locationId);
    return location ? location.name : '';
  }
  private normalizeRadius(r, min, max) {
    r = Math.abs(r);
    let normalizedR;
    if (r !== 0) {
      normalizedR = +(r - min) / (max - min);
    }
    if (normalizedR === 0 || (normalizedR * 30 < 5)) {
      return 5;
    }
    return normalizedR * 30;
  }

  async updateChartData() {
    this.showChart = false;
    setTimeout(() => {
      if (this.selectedThirdParty.valid && this.selectedLocations.valid && this.selectedVarianceType.valid) {
        const locations = this.selectedLocations.value;
        const thirdParty = this.selectedThirdParty.value;
        const varianceType = this.selectedVarianceType.value;
        this.data = this.locationReportData
          .filter(row => this.locations
            .find(location => {
              return location['locationId'] === row.locationId
            })
          )
          .map(row => {
            if (row.thirdParty === thirdParty) {
              const x = +row.locationId;
              const y = row.posNetSales;
              let v;
              let r;
              switch (varianceType) {
                case 'Tax':
                  v = +(row.posNetTax - row.thirdPartyGrossTax).toFixed(2);
                  this.label = "Tax Variance by Location and Total POS Sales"
                  break;
                case 'Remittance':
                  v = +(row.expectedRemittance - row.actualRemittance).toFixed(2);
                  this.label = "Remittance Variance by Location and Total POS Sales"
                  break;
                default:
                  v = +(row.posNetSales - row.thirdPartyGrossSales).toFixed(2);
                  this.label = "Sales Variance by Location and Total POS Sales"
              }
              r = Math.abs(v);
              if (!!x && !!y && !!v && !!r) {
                return { x, y, v, r }
              }
            }
          }).filter(d => !!d);
        const maxSales = _.maxBy(this.data, (o) => o['y'])['y']
        const maxVariance = _.maxBy(this.data, (o) => o['r'])['r'];
        const minVariance = _.minBy(this.data, (o) => o['r'])['r'];
        this.data.forEach(set => {
          set.r = this.normalizeRadius(set.r, minVariance, maxVariance);
        })
        this.bubbleChartOptions['scales'] = { yAxes: [{ ticks: { max: (+maxSales * 2) } }] };
        this.bubbleChartOptions['title'].text = `${this.selectedVarianceType.value} Variance By Location and Total Sales`;
        this.bubbleChartData[0].data = this.data;
        this.showChart = true;
      } else {
        this.snackBar.open('Please select one or more locations, a third party, and a variance type.');
      }
    }, 300);
  }
  private getVariance(locationId) {
    const location = this.locationReportData.find(location => location.locationId.toString() === locationId.toString());
    switch (this.selectedVarianceType.value) {
      case 'Tax':
        return Math.abs(location.posNetTax - location.thirdPartyNetTax).toFixed(2);
      case 'Remittance':
        return Math.abs(location.expectedRemittance - location.actualRemittance).toFixed(2);
      default:
        return Math.abs(location.posNetSales - location.thirdPartyNetSales).toFixed(2);
    }
  }
}
