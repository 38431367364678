<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutGap="12px">
      <mat-card-title>
        <h2>Transaction Search
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6>Search transactions that have been uploaded to DeliverSense.</h6>
      </mat-card-subtitle>

      <div fxLayout="row wrap" fxLayoutGap="2px" fxLayoutAlign="space-between center">
        <app-location-search [locations]="locations" [selectedLocations]="selectedLocation"
          *ngIf="locations && locations.length > 0" [multiple]="false">
        </app-location-search>
        <div fxLayout="row wrap" fxLayoutGap="2px">
          <div fxLayout="row">
            <mat-form-field appearance="outline">
              <mat-label>Transaction Type</mat-label>
              <mat-select [formControl]="selectedTransactionType">
                <mat-option *ngFor="let transactionType of transactionTypes" [value]="transactionType">
                  {{transactionType}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" *ngIf="selectedTransactionType.valid && selectedTransactionType.value === 'POS'">
            <mat-form-field appearance="outline">
              <mat-label>POS System</mat-label>
              <mat-select [formControl]="selectedPos">
                <mat-option *ngFor="let posSystem of posSystems" [value]="posSystem">
                  {{posSystem.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row"
            *ngIf="selectedTransactionType.valid && (selectedTransactionType.value === '3PD' || selectedTransactionType.value === 'POS')">
            <mat-form-field appearance="outline">
              <mat-label>Account Type</mat-label>
              <mat-select [formControl]="selectedThirdParty">
                <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty">
                  {{thirdParty.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
            <mat-label>Start Date</mat-label>
            <input matInput [formControl]="startDate" [matDatepicker]="startPicker" placeholder="Start date">
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" id="select-end-date" class="report-parameter-form-field">
            <mat-label>End Date</mat-label>
            <input matInput [formControl]="endDate" [disabled]="startDate.invalid" [min]="startDate.value"
              [max]="getMaxEndDate()" [matDatepicker]="endPicker" placeholder="End date">
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div [style.margin-right.px]="20">
          <button mat-raised-button color="primary" (click)="runSearch()">
            Search
          </button>
        </div>
      </div>
    </mat-card-header>
  </mat-card>
  <ng-container *ngIf="transactions && transactions.length > 0; else noResults">
    <div>
      <app-transaction-lookup-report [transactions]="transactions" [transactionType]="selectedTransactionType.value">
      </app-transaction-lookup-report>
    </div>
  </ng-container>
  <ng-template #noResults>
    <mat-card *ngIf="noTransactions">
      <div fxLayout="row" fxLayoutAlign="center center">
        <h3><b>No Transactions Found.</b></h3>
      </div>
    </mat-card>
  </ng-template>
</div>
