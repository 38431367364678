import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, NG_ASYNC_VALIDATORS, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomValidators } from "ng2-validation";
import { Client, ClientRole, User, UserRoles, usStates } from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { verifyPasswordMatch } from "../../shared/validators";
import { take, takeUntil, first, map } from 'rxjs/operators';
import { Subject, of, Observable } from 'rxjs';
import { FirestoreUtilities } from '../../utilities/firestore-utilities';
import { PolicyDialogComponent } from '../../dialogs/policy-dialog/policy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { scrollbarOptions } from '../../shared/ds-constant';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy {
  referralCodeSuccess = false;
  signupFormGroup: FormGroup;
  clientInfoFormGroup: FormGroup;
  submitted = false;
  error = '';
  loading = false;
  showUserForm = true;
  private customTokenSet = new Subject();
  states = usStates;
  destroy$ = new Subject();
  provider: 'google' | 'microsoft';
  scrollbarOptions = scrollbarOptions;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private fbAuth: FirebaseAuthService,
    private afs: AngularFirestore,
    private router: Router,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    public snackBar: MatSnackBar) {
  }


  ngOnInit() {
    this.signupFormGroup = this.fb.group({
      email: new FormControl(null, [Validators.required, CustomValidators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, []),
      confirmPassword: new FormControl(null, Validators.required),
    }, { validator: verifyPasswordMatch });
    this.clientInfoFormGroup = this.fb.group({
      name: new FormControl(null, Validators.required),
      addressLine1: new FormControl(null, Validators.required),
      addressLine2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      stateProvince: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      billingEmail: new FormControl(null, [Validators.required, CustomValidators.email]),
      referralCode: new FormControl(null, null, this.validateReferralCode.bind(this)),
      teamMembers: new FormArray([]),
      agreeToTerms: new FormControl(null, Validators.required),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
  }
  addTeamMemberEmailControl() {
    const formArray = this.clientInfoFormGroup.get('teamMembers') as FormArray;
    const newGroup = new FormGroup({
      email: new FormControl('', [Validators.required, CustomValidators.email])
    });
    formArray.push(newGroup);
  }
  async removeTeamMemberEmail(index: number) {
    const formArray = this.clientInfoFormGroup.get('teamMembers') as FormArray;
    formArray.removeAt(index);
  }
  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validateReferralCode(control: FormControl): Observable<any> {
    const code = control.value;
    if (!code) {
      if (this.clientInfoFormGroup && this.clientInfoFormGroup.get('referralCode')) {
        this.clientInfoFormGroup.get('referralCode').setErrors(null);
      }
      return of();
    }
    return this.afs.collection('clientReferralCodes', ref => ref
      .where('code', '==', code)
      .limit(1))
      .snapshotChanges().pipe(map(result$ => {
        const queryResult = FirestoreUtilities.mapToType(result$);
        const found = queryResult[0] && queryResult[0].code === code;
        if (found) {
          control.setErrors(null);
          this.referralCodeSuccess = true;
          return null;
        } else {
          control.setErrors({ invalidCode: true });
          this.referralCodeSuccess = false;
          return { invalidCode: true }
        }
      }));
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.signupFormGroup.controls;
  }
  public registerWithProvider(provider: 'google' | 'microsoft') {
    this.signupFormGroup.get('email').patchValue('1@1.com');
    this.signupFormGroup.get('firstName').patchValue('placeholder');
    this.signupFormGroup.get('lastName').patchValue('placeholder');
    this.signupFormGroup.get('password').patchValue('placeholder');
    this.signupFormGroup.get('confirmPassword').patchValue('placeholder');
    this.signupFormGroup.get('email').updateValueAndValidity();
    this.signupFormGroup.get('firstName').updateValueAndValidity();
    this.signupFormGroup.get('lastName').updateValueAndValidity();
    this.signupFormGroup.get('password').updateValueAndValidity();
    this.signupFormGroup.get('confirmPassword').updateValueAndValidity();
    this.showUserForm = false;
    this.provider = provider;
  }

  /**
   * On submit form
   */
  async onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.markFields(this.signupFormGroup);
    this.markFields(this.clientInfoFormGroup);
    if (this.signupFormGroup.valid && this.clientInfoFormGroup.valid) {
      const password = this.signupFormGroup.value.password;
      const user = this.mapFormToUser(this.signupFormGroup);
      const client = this.mapFormToClient(this.clientInfoFormGroup);
      const referralCode = this.clientInfoFormGroup.get('referralCode').value;
      const teamMemberInvites = this.clientInfoFormGroup.get('teamMembers').value ? this.clientInfoFormGroup.get('teamMembers').value : null;
      if (this.provider) {
        try {
          const authUser$ = await this.fbAuth.authWithProvider(this.provider, client, null, referralCode, teamMemberInvites)
          this.snackBar.open('Registration Successful!', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['client-selection']);
        } catch (e) {
          this.alertRegistrationError(e);
        }
      } else {
        this.fbAuth.register(user, password, client, null, referralCode, teamMemberInvites).subscribe(authUser$ => {
          this.snackBar.open('Registration Successful!', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['client-selection']);
        }, (e) => {
          this.alertRegistrationError(e);
        });

      }
    } else {
      this.snackBar.open('Please fill out the required fields.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  private alertRegistrationError(e) {
    this.loadingService.isLoading(false);
    if (e.error.error === "The email address is already in use by another account.") {
      this.snackBar.open(e.error.error, 'Dismiss', {
        duration: 5000
      });
    } else {
      this.snackBar.open('Error creating your account. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }
  markFields(form) {
    Object.keys(form.controls).forEach(field => {
      form.get(field).markAsTouched({ onlySelf: true });
    });
  }

  private mapFormToUser(signupFormGroup: FormGroup) {
    const formValues = signupFormGroup.value;
    const user = new User();
    user.firstName = formValues.firstName;
    user.lastName = formValues.lastName;
    user.email = formValues.email;
    return user
  }
  openTermsOfServiceDialog() {
    this.afs.doc(`staticContents/terms-of-service`).snapshotChanges()
      .pipe(takeUntil(this.destroy$)).subscribe(policy$ => {
        const policy = FirestoreUtilities.objectToType(policy$);
        this.dialog.open(PolicyDialogComponent, {
          data: { policy: policy.value }
        })
      })
  }
  openPrivacyPolicyDialog() {
    this.afs.doc(`staticContents/privacy-policy`).snapshotChanges()
      .pipe(takeUntil(this.destroy$)).subscribe(policy$ => {
        const policy = FirestoreUtilities.objectToType(policy$);
        this.dialog.open(PolicyDialogComponent, {
          data: { policy: policy.value }
        })
      })
  }
  private mapFormToClient(clientInfoFormGroup: FormGroup) {
    const formValues = clientInfoFormGroup.value;
    const client = new Client();
    client.name = formValues.name;
    client.addressLine1 = formValues.addressLine1;
    client.addressLine2 = formValues.addressLine2;
    client.addressCity = formValues.city;
    client.addressState = formValues.stateProvince;
    client.addressPostalCode = formValues.postalCode;
    client.billingEmail = formValues.billingEmail;
    return client;
  }
  backToUserForm() {
    if (this.provider) {
      this.signupFormGroup.reset();
    }
    this.showUserForm = true;
    this.provider = null;
  }
}
