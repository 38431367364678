import { FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { UploadDocumentService } from '../../services/upload-document.service';
declare const pdfjsLib: any;
import * as JSZip from "jszip";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuditTrailDocumentSection } from '@deliver-sense-librarian/data-schema';
import * as _ from "lodash";
import { scrollbarOptions } from 'app/shared/ds-constant';
import { MatSelectionList } from '@angular/material/list';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-document-dialog',
  templateUrl: './attach-audit-trail-file-dialog.component.html',
  styleUrls: ['./attach-audit-trail-file-dialog.component.scss']
})
export class AttachAuditTrailFileDialogComponent implements OnInit, OnDestroy {
  @ViewChild('selectedNewSections') selectedNewSections: MatSelectionList;
  path: string;
  file: any;
  fileName = new FormControl();
  fileExtension: string;
  fileParsingComplete = false;
  newSections: string[] = [];
  scrollbarOptions = scrollbarOptions;
  private zipFile: any;
  private existingSections: string[] = [];

  constructor(private snackBar: MatSnackBar,
    private uploadDocumentService: UploadDocumentService,
    public dialogRef: MatDialogRef<AttachAuditTrailFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.path = data.path
    this.existingSections = data.sections ? data.sections.map((section: AuditTrailDocumentSection) => section.name) : [];
    this.zipFile = new JSZip();
  }

  ngOnInit() {

  }
  ngOnDestroy() {

  }
  async handleFileSelect(evt) {
    const files = evt.target.files;
    this.file = files[0];
    this.fileName.patchValue(this.file.name.substr(0, this.file.name.lastIndexOf('.')));
    const lastDot = this.file.name.lastIndexOf('.');
    this.fileExtension = this.file.name.substring(lastDot + 1);
    if (files && this.file && (this.fileExtension === 'xlsx' || this.fileExtension === 'xlsm')) {
      const reader = new FileReader();
      reader.onload = (readerEvt) => {
        const binaryString = readerEvt.target['result'] as any;
        this.getSections(btoa(binaryString));
      };
      reader.readAsBinaryString(this.file);
      this.fileParsingComplete = true;
    } else if (this.fileExtension === 'pdf') {
      const reader = new FileReader();
      reader.readAsBinaryString(this.file);
      reader.onloadend = () => {
        const result = reader['result'].toString();
        const count = result.match(/\/Type[\s]*\/Page[^s]/g).length;
        for (let i = 0; i < count; i++) {
          this.newSections.push(`Page ${i}`);
        }
        if (this.newSections.length > 0) {
          this.newSections = _.difference(this.newSections, this.existingSections);
        }
        this.fileParsingComplete = true;
      }
    } else {
      this.newSections = [];
      this.fileParsingComplete = true;
    }
  }
  private decodeHTMLEntity(value) {
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.value;
  }
  private async getSections(base64file) {
    let s, i, id;
    const zip = new JSZip();
    const zip$ = await zip.loadAsync(base64file, {
      base64: true
    });
    try {
      const t = zip$.file('xl/workbook.xml');
      if (!!t) {
        const data = await t.async('string');
        s = data;
        s = s.split('<sheet ');
        i = s.length;
        while (--i) {
          id = s[i].substr(s[i].indexOf('name="') + 6);
          let tabName = id.substring(0, id.indexOf('"'));
          tabName = this.decodeHTMLEntity(tabName);
          this.newSections.push(tabName);
        }
        if (this.newSections.length > 0 && this.fileName.value) {
          this.newSections = _.reverse(this.newSections);
          this.newSections = _.difference(this.newSections, this.existingSections);
        } else {
          this.snackBar.open(`This file doesn't have any tabs. Please upload an excel file with one or more tabs to create an Audit Trail Document.`, 'Dismiss', {
            duration: 5000
          })
        }
      }
    } catch (e) {
      this.snackBar.open('Error reading the file. The file must be an xlsx or xlsm file.', 'Dismiss', { duration: 5000 });
    }
  };

  async finishUpload() {
    const lastDot = this.file.name.lastIndexOf('.');
    const fileExtension = this.file.name.substring(lastDot + 1);
    const filePath = `${this.path}/${this.fileName.value.replace(/ /g, "_")}_${moment().unix().toString()}.${fileExtension}`;
    const uploadResult = await this.uploadDocumentService.uploadSingle(this.file, filePath);
    uploadResult['fileName'] = `${this.fileName.value}.${this.fileExtension}`;
    uploadResult['newSections'] = this.selectedNewSections ? this.selectedNewSections
      .selectedOptions
      .selected.map(selected => selected.value)
      .filter(value => !!value) : [];
    this.dialogRef.close(uploadResult);
  }

  clearUpload() {
    this.file = null;
    this.fileName.reset();
    this.fileExtension = null;
    this.newSections = [];
    this.fileParsingComplete = false;
  }
}
