<mat-card >
    <mat-card-header fxLayout="column" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="space-between center">
            <mat-card-title><h1>FAQs</h1></mat-card-title>
            <mat-card-subtitle><h3>You've Got Questions? We've Got Answers!</h3></mat-card-subtitle>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-evenly">
            <div fxFlex>
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2>Members</h2>
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let faq of faqs">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="faqHeader">
                                    {{faq.question}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-evenly center">
                                <div [innerHTML]="faq.answer | sanitizeHtml"></div>
                                <ng-container *ngIf="faq && (faq.path || faq.transcodingRecord)">
                                    <div fxFlex="50" class="faq-media-container">
                                        <ng-container *ngIf="faq.path && !faq.transcodingRecord">
                                            <img class="faq-image"
                                                 [src]="faq.image"/>
                                        </ng-container>
                                        <ng-container *ngIf="faq.transcodingRecord">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around center" class="faq-contact-button">
           <button mat-raised-button color="accent" (click)="showSupportDialog()">
                <h5>Contact Us</h5>
           </button>
        </div>
    </mat-card-content>
</mat-card>
