import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routing';
import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AdminLayoutModule } from './views/admin-layout/admin-layout.module';
import { LoadingDialogComponent } from "./dialogs/loading-dialog/loading-dialog.component";
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from "@angular/common/http";
import { FaqModule } from "./components/faq/faq.module";
import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { HelpModule } from "./components/help/help.module";
import { ServicesModule } from "./services/services.module";
import { AuthModule } from "./auth/auth.module";
import { DashboardModule } from "./components/dashboard/dashboard.module";
import { DsSharedModule } from "./shared/ds-shared.module";
import { NotFoundModule } from "./components/not-found/not-found.module";
import { FirebaseAuthService } from "./auth/services/firebase-auth.service";
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ThirdPartyDeliveryReportsModule } from "./components/ds-modules/3pd-reports/3pd-reports.module";
import { StoreDevtoolsModule, StoreDevtoolsOptions } from '@ngrx/store-devtools';
import { getArdConfig } from "./utilities/ard-config";
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationModule } from "./components/organization/organization.module";
import { DirectivesModule } from "./directives/directives.module";
import { TermsModule } from "./components/terms/terms.module";
import { UserProfileModule } from "./components/organization/user-profile/user-profile.module";
import { DocumentUploadModule } from "./components/document-upload/document-upload.module";
import { ExemptionCertificateManagementModule } from "./components/ds-modules/exemption-certificate-management/exemption-certificate-management.module";
import { AuditTrailModule } from "./components/ds-modules/audit-trails/audit-trail.module"
import { GuardsModule } from "./guards/guards.module";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { NotificationPanelModule } from "./components/structural/notification-panel/notification-panel.module";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DataUploadModule } from './components/data-upload/data-upload.module';
import { DialogsModule } from "./dialogs/dialogs.module"
import { ChatWindowModule } from './components/chat-window/chat-window.module';
import { TransactionLookupModule } from './components/ds-modules/transaction-lookup/transaction-lookup.module';
import { DocumentUploadCenterModule } from './components/document-upload-center/document-upload-center.module';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': { direction: Hammer.DIRECTION_ALL }
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    /**
     * FireStore & Firebase
     */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    /**
     * REDUX
     */
    StoreModule.forRoot(getArdConfig().customReducers),
    EffectsModule.forRoot(getArdConfig().effects),
    StoreDevtoolsModule.instrument(<StoreDevtoolsOptions>{ maxAge: 25 }),
    /**
     * Angular Base Modules
     */
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    MalihuScrollbarModule.forRoot(),
    FlexLayoutModule,
    ScrollToModule.forRoot(),
    /**
     * Views
     */
    AdminLayoutModule,
    /**
     * Components
     */
    ThirdPartyDeliveryReportsModule,
    OrganizationModule,
    DashboardModule,
    FaqModule,
    HelpModule,
    NotFoundModule,
    TermsModule,
    UserProfileModule,
    DocumentUploadModule,
    ExemptionCertificateManagementModule,
    AuditTrailModule,
    TransactionLookupModule,
    NotificationPanelModule,
    DataUploadModule,
    ChatWindowModule,
    DocumentUploadCenterModule,
    /**
     * Libraries
     */
    PerfectScrollbarModule,
    /**
     * Application Specific
     */
    AuthModule,
    DirectivesModule,
    DsSharedModule,
    ServicesModule,
    GuardsModule,
    PopoverModule.forRoot()
  ],
  providers: [
    Location,
    Title,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    MalihuScrollbarService,
    FirebaseAuthService,
  ],
  entryComponents: [
    LoadingDialogComponent,
  ],
  bootstrap:
    [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {

}
