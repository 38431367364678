<app-dashboard-widget [title]="'3PD Reconciliation Reports'"
  [description]="'Most recently updated 3PD reconciliation reports.'" [noResultsText]="'No 3PD Reports To Display'"
  [widgetData]="thirdPartyDeliveryReports" [loadingWidgetData]="loadingWidgetData">
  <ng-template let-item>
    <h3 matLine><a [routerLink]="'/app/3pd-reports/'+item.id">{{item.name}}</a></h3>
    <small matLine><b>Last Updated: </b>{{item.dateUpdated?.toDate() | date:'shortDate'}}</small>
    <div fxLayout="row" class="list-item-divider">
      <mat-divider></mat-divider>
    </div>
  </ng-template>
</app-dashboard-widget>
