import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentUploadComponent} from './document-upload.component';
import {MaterialModule} from "../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [DocumentUploadComponent],
    exports: [DocumentUploadComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class DocumentUploadModule {
}
