import { DataUploadErrorTypes } from '@deliver-sense-librarian/data-schema';
import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { scrollbarOptions } from '../../shared/ds-constant';
import * as _ from 'lodash';

export class ErrorGroup {
  title: string;
  message: string;
  errors: any[];
}
@Component({
  selector: 'app-data-upload-error-dialog',
  templateUrl: './data-upload-error-dialog.component.html',
  styleUrls: ['./data-upload-error-dialog.component.scss']
})
export class DataUploadErrorDialogComponent implements OnInit {
  type = 'error';
  scrollbarOptions = scrollbarOptions;
  rowsToRemove = [];
  missingFields: [];
  duplicates: [];
  invalidLocations: [];
  errorGroups: any;
  errorGroupKeys: string[];
  constructor(
    public dialogRef: MatDialogRef<DataUploadErrorDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.errorGroups = data.errors;
    this.errorGroupKeys = Object.keys(this.errorGroups);
  }

  ngOnInit() {
  }
  getTabLabel(key) {
   const typeLabel = DataUploadErrorTypes[key];
   return typeLabel;
  }
  closeAndRetry() {
    this.dialogRef.close({ retry: true, rowsToRemove: this.rowsToRemove })
  }
  setRowsToDelete(updatedRowsToDelete: number[]) {
    updatedRowsToDelete.forEach(rowNumber => {
      if (this.rowsToRemove.indexOf(rowNumber)) {

      }
    })
    this.rowsToRemove = updatedRowsToDelete;
  }

  addRemoveRow(row) {
    if (this.rowsToRemove.indexOf(row) < 0) {
      this.rowsToRemove.push(row);
    }
  }
  undoRemoveRow(row) {
    if (this.rowsToRemove.indexOf(row) >= 0) {
      this.rowsToRemove.splice(this.rowsToRemove.indexOf(row), 1);
    }
  }
  getMissingFieldsList(missingFields) {
    return _.uniq(missingFields);
  }
}
