import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-information-dialog-table',
  templateUrl: './information-dialog-table.component.html',
  styleUrls: ['./information-dialog-table.component.scss']
})
export class InformationDialogTableComponent implements OnInit {
  @Input() errorData: any[] = [];
  @Input() rowsToRemove: number[] = [];
  @Output() removeRow = new EventEmitter();
  @Output() undoRemoveRow = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public keys: string[] = []
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [];
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.displayedColumns = Object.keys(this.errorData[0]);
    // Batch Row always first. If table is for Missing Required Data make "Missing Data" column after batchRow
    this.displayedColumns.sort((a, b) => {
      return a === "Missing Data" ? -1 : a === 'batchRow' ? -1 : 0;
    });
    this.displayedColumns.unshift('remove');
    this.tableData = new MatTableDataSource(this.errorData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }
  markRowToDelete(batchRow: number) {
    this.removeRow.emit(batchRow);
  }
  addBackRow(batchRow: number) {
    this.undoRemoveRow.emit(batchRow);
  }
  isRowMarkedForRemoval(batchRow) {
    return this.rowsToRemove.indexOf(batchRow) !== -1;
  }
}
