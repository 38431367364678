import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingDialogService } from './loading-dialog.service';
import { HttpClient } from '@angular/common/http';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, from, of, Subject, Subscription } from 'rxjs';
import { DataUploadDialogComponent } from 'app/dialogs/data-upload-dialog/data-upload-dialog.component';
import { environment } from 'environments/environment';
import { concatMap, catchError, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { User, Client, DataUploadLog } from '@deliver-sense-librarian/data-schema';
import { UiState } from '../redux/custom-states/uiState/ui-state';
import { DataUploadDialogResponse } from '../dialogs/data-upload-dialog/data-upload-dialog.component';
import { DataUploadErrorDialogComponent } from '../dialogs/data-upload-error-dialog/data-upload-error-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DataUploadService {
  uploadQueue: DataUploadLog[] = []
  destroy$ = new Subject();
  user: User;
  client: Client;
  uiState: UiState;
  uploadInProgress = new BehaviorSubject(null);
  uploadSubscription: Subscription;
  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private titleService: Title,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private http: HttpClient,
    public auth: FirebaseAuthService,
    private afAuth: AngularFireAuth) {
    this.store.select(store$ => store$.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
        }
      });

    this.uploadInProgress.subscribe(dataUploadLog => {
      if (!dataUploadLog) {
        const nextDataUploadLog = this.uploadQueue.find(_dataUploadLog => _dataUploadLog.status === 'pending');
        if (nextDataUploadLog) {
          this.uploadInProgress.next(nextDataUploadLog);
        }
      } else {
        this.uploadData(dataUploadLog)
      }
    })
  }
  startNewUpload() {
    const uploadDialogRef = this.dialog.open(DataUploadDialogComponent, {
      panelClass: 'invisible-panel-dialog'
    })
    uploadDialogRef.afterClosed().subscribe(async (dialogResults: DataUploadDialogResponse) => {
      if (dialogResults) {
        const dataUploadLog = new DataUploadLog();
        dataUploadLog.dataToUpload = dialogResults.data;
        dataUploadLog.posSystem = dialogResults.posSystem;
        dataUploadLog.thirdParty = dialogResults.thirdParty;
        dataUploadLog.testMode = dialogResults.testMode;
        dataUploadLog.fileName = dialogResults.fileName;
        dataUploadLog.fileSize = dialogResults.fileSize;
        dataUploadLog.rowCount = dialogResults.data.length;
        dataUploadLog.status = 'pending';
        dataUploadLog.id = this.afs.createId();
        dataUploadLog.batchId = this.afs.createId();
        dataUploadLog.user = this.user.id;
        dataUploadLog.client = this.client.id;
        dataUploadLog.shortId = dataUploadLog.id.substr(0, 6);
        await this.saveDataUploadLog(dataUploadLog);
        this.uploadQueue.push(dataUploadLog);
        this.triggerQueue(dataUploadLog);
      }
    })
  }

  private async saveDataUploadLog(dataUploadLog: DataUploadLog) {
    const id = dataUploadLog.id;
    const raw = dataUploadLog.toJSONObject();
    if (dataUploadLog.dataToUpload) {
      delete raw['dataToUpload'];
    }
    await this.afs.doc(`dataUploadLogs/${id}`).set(raw);
  }

  private createRequestUrl(dataUploadLog: DataUploadLog) {
    let url = `${environment.apiUrl}clientTransactions?batchId=${dataUploadLog.batchId}&format=json&clientId=${this.client.id}`

    if (dataUploadLog.posSystem) {
      url = `${url}&transactionType=pos&pos=${dataUploadLog.posSystem}`;
    } else if (dataUploadLog.thirdParty) {
      url = `${url}&transactionType=thirdPartyDelivery&thirdParty=${dataUploadLog.thirdParty}`;
    }
    if (dataUploadLog.testMode) {
      url = `${url}&testMode=true`;
    }
    return url;

  }
  private async uploadData(dataUploadLog: DataUploadLog) {
    dataUploadLog.errorData = {};
    dataUploadLog.progress = 0;
    dataUploadLog.status = 'working';
    try {
      const progress = { progress: 0 };
      const url = this.createRequestUrl(dataUploadLog);
      const headerObj = await this.auth.getAuthHeader();
      headerObj.headers.set('Content-Type', 'text/plain')
      const requestData = _.chunk(dataUploadLog.dataToUpload, 25000); // create chunks of 25000 records
      const transactionRequests = requestData.map(data => {
        return this.http.post(url, JSON.stringify(data), headerObj);
      }); // create a request for each chunk
      let count = 0;
      const progressStep = 100 / (transactionRequests.length);
      this.uploadSubscription = from(transactionRequests)
        .pipe(concatMap(res => (res).pipe(catchError(async e => { // upload each chunk synchronously to prevent concurrent overwrites
          if (e.error && e.error.type === 'Validation Error') {
            Object.keys(e.error.data).forEach(errorTypeKey => {
              dataUploadLog.errorData[errorTypeKey] = dataUploadLog.errorData[errorTypeKey] ?
                [...dataUploadLog.errorData[errorTypeKey], ...e.error.data[errorTypeKey]] :
                [...e.error.data[errorTypeKey]];
            })
          } else if (e.status >= 400) {
            this.uploadSubscription.unsubscribe();
            dataUploadLog.status = 'errors'
            await this.updateDataUploadLog(dataUploadLog);
            this.uploadInProgress.next(null);
          }
          progress.progress = +(progress.progress + progressStep).toFixed(0);
          dataUploadLog.progress = progress.progress;
          count++;
          return of();
        }))))
        .subscribe(urlRes => {
          if (count === (transactionRequests.length)) {
            return urlRes;
          } else {
            count++;
            progress.progress = +(progress.progress + progressStep).toFixed(0);
            dataUploadLog.progress = progress.progress;
          }
        }, async (e) => {
          dataUploadLog.status = 'errors'
          await this.updateDataUploadLog(dataUploadLog);
          this.uploadInProgress.next(null);
        }, async () => {
          if (Object.keys(dataUploadLog.errorData).length > 0) {
            dataUploadLog.status = 'errors'
            await this.updateDataUploadLog(dataUploadLog);

            this.uploadInProgress.next(null);
          } else {
            dataUploadLog.status = 'success'
            await this.updateDataUploadLog(dataUploadLog);
            if (dataUploadLog.testMode) {
            } else {
              this.snackBar.open('Successfully uploaded transactions!', 'Dismiss', {
                duration: 5000
              });
            }
            this.uploadInProgress.next(null);
            return;
          }
        });
    } catch (e) {
      dataUploadLog.status = 'errors'
      this.snackBar.open('Error uploading transactions.', 'Dismiss', {
        duration: 5000
      });
      this.loadingService.isLoading(false);
    }
  }
  async updateDataUploadLog(dataUploadLog: DataUploadLog) {
    await this.afs.doc(`dataUploadLogs/${dataUploadLog.id}`).update({
      status: dataUploadLog.status,
      errorData: dataUploadLog.errorData ? dataUploadLog.errorData : null
    })
  }

  viewErrors(dataUploadLog: DataUploadLog) {
    const errorDialogRef = this.dialog.open(DataUploadErrorDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        errors: dataUploadLog.errorData
      }
    });
    errorDialogRef.afterClosed().pipe(take(1)).subscribe(retryData => {
      if (!!retryData && retryData.retry === true) {
        retryData.rowsToRemove.forEach(batchRow => {
          const row = dataUploadLog.dataToUpload.find(transaction => transaction.batchRow === batchRow);
          dataUploadLog.dataToUpload.splice(dataUploadLog.dataToUpload.indexOf(row), 1);
        });
        dataUploadLog.status = 'pending';
        this.uploadQueue.push(this.uploadQueue.splice(this.uploadQueue.indexOf(dataUploadLog), 1)[0]);
        this.triggerQueue(dataUploadLog);
      }
    })
  }
  private triggerQueue(dataUploadLog) {
    if (!this.uploadInProgress.value) {
      this.uploadInProgress.next(dataUploadLog);
    }
  }

  public getNumberOfErrors(upload: DataUploadLog) {
    if (upload.errorData) {
      return Object.keys(upload.errorData).reduce((errorCount, key) => {
        if (upload.errorData[key]) {
          errorCount += upload.errorData[key].length;
        }
        return errorCount;
      }, 0)
    } else {
      return 0;
    }

  }

  public clearUpload(upload) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Clear Upload',
        message: 'Are you sure you want to clear this upload? This will cancel any further progress.',
        action: 'Yes, Clear Upload'
      }
    });
    confirmDialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const uploadQueueIndex = this.uploadQueue.indexOf(upload);
        if (this.uploadInProgress.value === upload) {
          this.uploadSubscription.unsubscribe();
          this.uploadSubscription = null;
          this.uploadInProgress.next(false);
        }
        this.uploadQueue.splice(uploadQueueIndex, 1);
      }
    });
  }
  completedUploads() {
    return {
      errors: this.uploadQueue.reduce((errorCount, dataUploadLog) => {
        if (dataUploadLog.status === 'errors') {
          errorCount++
        }
        return errorCount;
      }, 0),
      success: this.uploadQueue.reduce((successCount, dataUploadLog) => {
        if (dataUploadLog.status === 'success') {
          successCount++
        }
        return successCount;
      }, 0),
    }
  }
  commitTestUpload(dataUploadLog: DataUploadLog) {
    dataUploadLog.status = 'pending';
    dataUploadLog.testMode = false;
    this.uploadQueue.push(this.uploadQueue.splice(this.uploadQueue.indexOf(dataUploadLog), 1)[0]);
    this.triggerQueue(dataUploadLog);
  }
}
