<div fxLayout="row" fxLayoutAlign="end end">
    <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div fxLayout="column" class="policy-container">
    <div fxLayout="row" fxLayoutAlign="end end">
        <ng-container *ngIf="client; else noClientTemplate">
            <app-create-client [client]="client" (formSavedSuccess)="closeDialog()"></app-create-client>
        </ng-container>
        <ng-template #noClientTemplate>
            <app-create-client (formSavedSuccess)="closeDialog()"></app-create-client>
        </ng-template>
    </div>
</div>
