import { UploadDocumentService } from './../../services/upload-document.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { IImage, ImageCompressService, ResizeOptions } from "ng2-image-compress";
import { finalize, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { ImageUploadUtilities } from "./document-upload.utilities";
import { FirebaseApp } from "@angular/fire/";
import * as firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  @Input() storagePath: string;
  @Input() compressFile = true;
  @Output() imageUploadComplete = new EventEmitter();
  private imageFileName;
  private resizeOptions = new ResizeOptions();
  private destroy$ = new Subject();
  private processedImages = [];
  private fileToUpload: any;
  private progress = { progress: 0 };
  private downloadURL: Observable<any>;
  private uploadPercent: Observable<number>;

  constructor(private loadingService: LoadingDialogService,
    private firebaseApp: FirebaseApp,
    private uploadDocumentService: UploadDocumentService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private snackBar: MatSnackBar,
    private imgCompressService: ImageCompressService) {
  }

  ngOnInit() {
  }

  async detectFiles(event) {
    debugger;
    if (event.target.files && event.target.files[0]) {
      this.loadingService.isLoading(true, 'Uploading File', this.progress);
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      this.imageFileName = this.afs.createId() + '.' + ext;
      if (ext === 'pdf' || !this.compressFile) {
        try {
          return await this.uploadFilesToStorage(event.target.files[0], this.progress);
        } catch (e) {
          console.log(`error: ${e.message}`);
          this.snackBar.open('Oops... something went wrong with the upload. Please refresh and try agin')
        }
      } else {
        // @ts-ignore
        // ImageUploadUtilities.getOrientation(event.target.files[0], (orientation) => {
        //   event.target.files[0]['orientation'] = orientation;
        // });
        return this.prepareImageForUpload(event.target.files);
      }
    }
  }

  private async prepareImageForUpload(files) {
    let image: IImage;
    const observableImages = await ImageCompressService.filesToCompressedImageSourceEx(files, this.resizeOptions);
    observableImages
      .subscribe((image$) => {
        image = image$.compressedImage;
        this.processedImages.push(image)
      }, (error) => {
        console.log("Error while converting");
      }, () => {
        this.loadingService.isLoading(false);
        const file = {};
        file['imageDataUrl'] = this.processedImages[0].imageDataUrl;
        file['imageObjectUrl'] = ImageUploadUtilities.dataURItoBlob(file['imageDataUrl']);
        file['name'] = files[0].name;
        this.uploadFilesToStorage(file);
      });
  }

  private async uploadFilesToStorage(file, progress?: { progress }) {
    const filePath = this.storagePath + "/" + this.imageFileName;
    const uploadResult = await this.uploadDocumentService.uploadSingle(file, filePath);
    uploadResult['fileName'] = `${file.name}`;
    this.imageUploadComplete.emit(uploadResult);
    return uploadResult;
  }
}
