import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Client, AuditTrailDocument, AuditTrailDocumentSection, User, Project, UserRoles } from "@deliver-sense-librarian/data-schema";
import { FirebaseApp } from "@angular/fire";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import * as JSZip from "jszip";
import { Store } from '@ngrx/store';
import { map, takeUntil, finalize, first } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { FirestoreUtilities } from "../../../../utilities/firestore-utilities";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { AngularFireStorage } from '@angular/fire/storage';
import * as moment from 'moment';
import { UploadDocumentService } from '../../../../services/upload-document.service';
import * as _ from 'lodash';
import { LoadingDialogService } from '../../../../services/loading-dialog.service';
declare const pdfjsLib: any;
@Component({
  selector: 'app-upload-review-document',
  templateUrl: './upload-review-document.component.html',
  styleUrls: ['./upload-review-document.component.scss']
})
export class UploadReviewDocumentComponent implements OnInit {
  @Output() documentCreated = new EventEmitter();
  private zipFile: any;
  private tabNames: string[] = [];
  private user: User;
  private fileName: string;
  private file: any;
  private client: Client;
  private destroy$ = new Subject();
  private selectedProject = new FormControl('', Validators.required);
  public includeFile = new FormControl(false);
  public projects: Project[];
  public activated = false;
  private uiState: UiState;
  fileExtension: any;

  constructor(private store: Store<any>,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private loadingSevice: LoadingDialogService,
    private storage: AngularFireStorage,
    private uploadDocumentService: UploadDocumentService,
    private cdr: ChangeDetectorRef) {
    this.zipFile = new JSZip();
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getAvailableProjects();
        }
      });
  }

  public async handleFileSelect(evt) {
    this.loadingSevice.isLoading(true, 'Scanning file...');
    const files = evt.target.files;
    this.file = files[0];
    this.fileName = this.file.name.substr(0, this.file.name.lastIndexOf('.'));
    const lastDot = this.file.name.lastIndexOf('.');
    this.fileExtension = this.file.name.substring(lastDot + 1);
    if (files && this.file && (this.fileExtension === 'xlsx' || this.fileExtension === 'xlsm')) {
      const reader = new FileReader();
      reader.onload = async (readerEvt) => {
        const binaryString = readerEvt.target['result'];
        try {
          //@ts-ignore
          await this.getTabs(btoa(binaryString));
          this.loadingSevice.isLoading(false);
        } catch (e) {
          this.loadingSevice.isLoading(false);
          this.snackBar.open('Error scanning document. Make sure the file ends in the extension .xlsx or .xlsm');
        }
      };
      reader.readAsBinaryString(this.file);
    } else if (this.fileExtension === 'pdf') {
      const reader = new FileReader();
      reader.readAsBinaryString(this.file);
      reader.onloadend = async () => {
        //@ts-ignore
        const count = reader['result'].match(/\/Type[\s]*\/Page[^s]/g).length;
        for (let i = 0; i < count; i++) {
          this.tabNames.push(`Page ${i}`);
        }
        if (this.tabNames.length > 0) {
          await this.createNewAuditTrailDocument();
        }
        this.loadingSevice.isLoading(false);
      }
    } else {
      this.loadingSevice.isLoading(false);
      this.snackBar.open('Invalid file. Document parsing is only available for Excel and PDF files at this time.', 'Dismiss', {
        duration: 7000
      })
    }
  }

  /**
   *
   * @param base64file
   */
  private async getTabs(base64file) {
    let s, i, id;
    const zip = new JSZip();
    const zip$ = await zip.loadAsync(base64file, {
      base64: true
    });
    try {
      const t = zip$.file('xl/workbook.xml');
      if (!!t) {
        const data = await t.async('string');
        s = data;
        s = s.split('<sheet ');
        i = s.length;
        while (--i) {
          id = s[i].substr(s[i].indexOf('name="') + 6);
          let tabName = id.substring(0, id.indexOf('"'));
          tabName = this.decodeHTMLEntity(tabName);
          this.tabNames.push(tabName);
        }
        if (this.tabNames.length > 0 && this.fileName && this.selectedProject.valid) {
          this.tabNames = _.reverse(this.tabNames);
          this.createNewAuditTrailDocument();
        } else {
          this.snackBar.open(`This file doesn't have any tabs. Please upload an excel file with one or more tabs to create an Audit Trail Document.`, 'Dismiss', {
            duration: 5000
          })
        }
      }
    } catch (e) {
      this.snackBar.open('Error reading the file. The file must be an xlsx or xlsm file.', 'Dismiss', { duration: 5000 });
    }
  };
  private decodeHTMLEntity(value) {
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.value;
  }
  private async createNewAuditTrailDocument() {
    this.loadingSevice.isLoading(true, 'Creating a new Audit Trail from upload...');
    const porDocument = new AuditTrailDocument();
    porDocument.id = this.afs.createId();
    porDocument.project = this.selectedProject.value;
    porDocument.creator = this.user.id;
    porDocument.name = this.fileName;
    const tabsCreationRequests = this.tabNames.map(tabName => {
      const porDocumentTab = new AuditTrailDocumentSection();
      porDocumentTab.auditTrailDocument = porDocument.id;
      porDocumentTab.name = tabName;
      porDocumentTab.order = this.tabNames.indexOf(tabName);
      return this.afs.collection(`auditTrailDocuments/${porDocument.id}/sections`).add(porDocumentTab.toJSONObject());
    });
    try {
      await this.afs.doc(`auditTrailDocuments/${porDocument.id}`).set(porDocument.toJSONObject());
      await Promise.all(tabsCreationRequests);
      if (!!this.includeFile.value) {
        const uploadResult = await this.uploadFilesToStorage();
        await this.afs.collection(`auditTrailDocuments/${porDocument.id}/files`).add({
          filePath: uploadResult['path'],
          fileSize: uploadResult['size'],
          fileName: uploadResult['fileName'],
          dateUpdated: moment().toDate(),
          dateCreated: moment().toDate()
        });
      }
      this.snackBar.open('Audit trail document created successfully.', 'Dismiss', {
        duration: 5000
      });
      this.selectedProject.reset();
      this.activated = false;
      this.tabNames = [];
      this.documentCreated.emit(true);
      this.loadingSevice.isLoading(false);
    } catch (e) {
      this.loadingSevice.isLoading(false);
      this.snackBar.open('Oops... something went wrong please try again.', 'Dismiss', {
        duration: 5000
      });
      this.tabNames = [];
    }
  }
  private async uploadFilesToStorage() {
    const filePath = `clients/${this.client.id}/audit-trail-documents/${this.fileName.replace(/ /g, "_")}_${moment().unix().toString()}.${this.fileExtension}`;
    const uploadResult = await this.uploadDocumentService.uploadSingle(this.file, filePath);
    uploadResult['fileName'] = `${this.fileName}.${this.fileExtension}`;
    return uploadResult;
  }
  private getAvailableProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.contributor])
      .pipe(takeUntil(this.destroy$))
      .subscribe(projects$ => {
        this.projects = (projects$) as Project[];
      })
  }
}
