import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { ToggleShowDataUploadAction } from '../../../redux/custom-states/uiState/ui-state-actions/application-state-actions';
import { UiState } from '../../../redux/custom-states/uiState/ui-state';
import { DataUploadService } from '../../../services/data-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataUploadLog } from '@deliver-sense-librarian/data-schema';
import { FirestoreUtilities } from '../../../utilities/firestore-utilities';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-data-uploads-list',
  templateUrl: './data-uploads-list.component.html',
  styleUrls: ['./data-uploads-list.component.scss']
})
export class DataUploadsListComponent implements OnInit {
  uploads: DataUploadLog[] = [];
  uiState: UiState;
  dataUploadLogs: DataUploadLog[] = [];
  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public dataUploadService: DataUploadService) { }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.uploads = this.dataUploadService.uploadQueue
          this.getUploadLogs();
        }
      });
  }
  getUploadLogs() {
    this.afs.collection('dataUploadLogs', ref => ref
      .where('client', '==', this.uiState.client.id)
      .orderBy('dateUpdated', 'desc')
      .limit(51))
      .snapshotChanges()
      .subscribe(results$ => {
        this.dataUploadLogs = FirestoreUtilities.mapToType(results$) as DataUploadLog[];
      }, (e) => {
        console.error(e.message)
      })
  }
  loadMoreLogs() {

  }
  // @TODO move to data-upload service for queueing
  deleteBatch(uploadLog: DataUploadLog) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Transactions',
        message: `Are you sure you want to delete this batch of ${uploadLog.rowCount} transactions? This action cannot be undone`,
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const collectionType = uploadLog.posSystem ? 'posTransactions' : 'thirdPartyTransactions';
        this.afs.collection(collectionType, ref => ref
          .where('batchId', '==', uploadLog.batchId))
          .snapshotChanges()
          .pipe(take(1))
          .subscribe(async (transactions$) => {
            const transactions = FirestoreUtilities.mapToType(transactions$)
            if (transactions.length > 0) {
              const chunks = _.chunk(transactions, 475);
              await Promise.all(chunks.map(async (chunk) => {
                const batch = this.afs.firestore.batch();
                chunk.forEach((transaction: any) => {
                  batch.delete(this.afs.doc(`thirdPartyTransactions/${transaction.id}`).ref)
                })
                await batch.commit();
                return;
              }));
              this.snackbar.open('Transactions deleted', 'Dismiss', { duration: 5000 });
              await this.afs.doc(`dataUploadLogs/${uploadLog.id}`).delete();
              this.snackbar.open('Transaction deleted successfully', 'Dismiss', { duration: 5000 })
            }
          });

      }
    });
  }
  closeSidenav() {
    this.store.dispatch(new ToggleShowDataUploadAction(false));
  }
  getUploadSizeMb(bytes) {
    return (bytes * 0.000001).toFixed(2);
  }
  commitTestUpload(upload) {
    this.dataUploadService.commitTestUpload(upload);
  }
}
