  <div class="mat-elevation-z8">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Client</mat-header-cell>
        <mat-cell *matCellDef="let row">
            {{row.name}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Client Role</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{getUserClientRoleText(row.userRole)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-raised-button color="warn" (click)="removeClientAccess(row)">Remove Client</button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expandTrigger">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b></b></mat-header-cell>
        <mat-cell *matCellDef="let row" [style.color]="row.color">
          <button mat-icon-button color='primary' (click)="expandedElement = expandedElement === row ? null : row">
            <mat-icon>expand</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="expanded-cell-data" fxLayout="row wrap" fxLayoutAlign="space-between start">

            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
