import { User } from '@deliver-sense-librarian/data-schema';
import { Store } from '@ngrx/store';
import { downloadDataAsFile, tableExpandAnimation } from '../../../../shared/ds-constant';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { PosTransaction, ExemptionCertificate, Client } from '@deliver-sense-librarian/data-schema';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-transaction-lookup-report',
  templateUrl: './transaction-lookup-report.component.html',
  styleUrls: ['./transaction-lookup-report.component.scss'],
  animations: tableExpandAnimation
})
export class TransactionLookupReportComponent implements OnInit, AfterViewInit {
  @Input() transactions: PosTransaction[] = [];
  @Input() transactionType: 'POS' | '3PD' | 'Remittance';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    // dynamically populated based off unique values in transaction array
  ];
    public expandedElement: any | null;
  private excNoteUpdateTimeout: any;
  private destroy$ = new Subject();
  private client: Client;
  private user: User;

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private papa: Papa,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
        }
      });
    this.setTableColumns();

    this.tableData = new MatTableDataSource(this.transactions);
    setTimeout(() => {
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
      this.cdr.detectChanges();
    });
  }
  ngAfterViewInit() {

  }
  private setTableColumns() {
    if (this.transactionType === 'POS') {
      this.displayedColumns = [
        'date',
        'location',
        'sale',
        'tax',
        'tip',
        'otherCharges',
        'otherRevenue',
        'workflowId',
        'status',
        'saleCorrection',
        'taxCorrection',
      ]
    } else if (this.transactionType === '3PD') {
      this.displayedColumns = [
        'date',
        'location',
        'sale',
        'tax',
        'tip',
        'otherCharges',
        'otherRevenue',
        'workflowId',
        'status',
        'saleCorrection',
        'taxCorrection',
        'taxRemitted',
        'totalRemitted',
        'promoFee',
        'deliveryFeeRate',
        'deliveryFeeTotal',
        'pickupFeeRate',
        'pickupFeeTotal',
        'cateringFee'
      ]
    }
  }
  applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }


  exportDocumentation() {
    const data = this.tableData.data.map(row => {
      const mutRow = Object.assign({}, row);
      if (row.date) {
        mutRow.date = mutRow.date.toDate();
      }
      return mutRow;
    })
    const results = this.papa.unparse(data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `transactions_lookup_${moment().format('M/D/YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }
}
