import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  User,
  UserRoles,
  TeamMemberInvitation,
  Client,
  Entity,
  Department,
  Location,
  Project,
  UserView,
  ClientRole, OrganizationRole
} from "@deliver-sense-librarian/data-schema";
import * as moment from "moment";
import { ConfirmDialogComponent } from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { map, take, takeUntil, first } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { scrollbarOptions } from 'app/shared/ds-constant';
import { LoadingDialogService } from "../../../../services/loading-dialog.service";
import { UiState } from "../../../../redux/custom-states/uiState/ui-state";
import { ActivatedRoute, Router } from "@angular/router";
import { MatOption } from '@angular/material/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';

export class TeamMemberClientRole {
  role: number;
  id: string;
  locations: OrganizationRole[] = [];
  entities: OrganizationRole[] = [];
}
/**
 * Need to filter other member roles by current user roles so not to have locations show that current user does not have access to
 */
@Component({
  selector: 'app-create-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent2 implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('locationAllSelected') private locationsAllSelected: MatOption;
  @ViewChild('locationPaginator', { static: true }) locationPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) locationSort: MatSort;
  public locationDisplayColumns: string[] = ['select', 'location', 'role', 'entity'];
  public locationSelection = new SelectionModel<OrganizationRole>(true, []);
  public locationTableData: MatTableDataSource<any>;
  public locations: Location[] = [];
  public availableLocations: Location[] = [];
  public selectedLocations = new FormControl();
  public locationPage: PageEvent = { pageIndex: 0, pageSize: 5, length: 0 };
  locationSearchText = new FormControl('')
  public defaultLocationRole = new FormControl('');


  @ViewChild('entitiesAllSelected') private entitiesAllSelected: MatOption;
  @ViewChild('entitiesPaginator', { static: true }) entitiesPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) entitiesSort: MatSort;
  public entitiesDisplayColumns: string[] = ['select', 'entity', 'role'];
  public entitiesSelection = new SelectionModel<OrganizationRole>(true, []);
  public entitiesTableData: MatTableDataSource<any>;
  public selectedEntities = new FormControl();
  public entities: Entity[] = [];
  entitySearchText = new FormControl('')


  public teamMember: UserView;
  public teamMemberId: string;
  public teamMemberClientRoleControl = new FormControl();
  private teamMemberClientRoles: TeamMemberClientRole;
  public emailToSendTo = new FormControl('', [Validators.required, Validators.email]);

  public roles = [
    { name: 'admin', value: UserRoles.admin },
    { name: 'contributor', value: UserRoles.contributor },
    { name: 'viewer', value: UserRoles.viewer },
    { name: 'none', value: '' }
  ];
  public filteredLocations: Location[] = [];
  public scrollbarOptions = scrollbarOptions;
  public uiState: UiState;
  private destroy$ = new Subject();
  organizationRoles: any;

  constructor(private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingDialogService,
    private store: Store<any>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.loadingService.isLoading(true, `Loading User Organization Roles...`)
          this.activatedRoute.params.pipe(take(1)).subscribe(async params$ => {
            await this.getUserOrgResources();
            this.teamMemberId = params$['id'];
            if (this.teamMemberId && this.teamMemberId !== 'new') {
              await this.fetchTeamMemberAndResources();
            } else {
              this.teamMemberClientRoles = new TeamMemberClientRole();
              this.setupTables();
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
  }
  private async getUserOrgResources() {
    if (this.uiState.authUser.internalRole > 1) {
      await this.getAllOrganizationResources();
    } else {
      await this.getAccessibleOrganizationResources();
    }
  }

  private async getAllOrganizationResources() {
    return Promise.all([
      this.locations = FirestoreUtilities.mapToType(await this.afs.collection('locations', ref => ref
        .where('client', '==', this.uiState.client.id))
        .snapshotChanges().pipe(first()).toPromise()),
      this.entities = FirestoreUtilities.mapToType(await this.afs.collection('entities', ref => ref
        .where('client', '==', this.uiState.client.id))
        .snapshotChanges().pipe(first()).toPromise())
    ]);
  }

  private async getAccessibleOrganizationResources() {
    return Promise.all([
      this.entities = await FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin]).pipe(first()).toPromise(),
      this.locations = await FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState.locations, [UserRoles.admin]).pipe(first()).toPromise()
    ])
  }

  private async fetchTeamMemberAndResources() {
    this.teamMember = FirestoreUtilities.objectToType(await this.afs.doc(`userViews/${this.teamMemberId}`)
      .snapshotChanges()
      .pipe(first()).toPromise());
    await this.fetchTeamMemberClientRoles();
  }

  public updateAvailableLocations() {
    const entitiesRoles = this.entitiesTableData.data.filter(entityRole => entityRole.role >= 1);
    this.availableLocations = this.locations.filter(location => {
      return !!entitiesRoles.find(entityRole => entityRole.resource === location.entity);
    });
    this.populateLocationTableData();
  }

  private async fetchTeamMemberClientRoles() {
    const teamMemberRoleResult = await Promise.all([
      this.afs.doc(`users/${this.teamMember.id}/clientRoles/${this.uiState.client.id}`)
        .snapshotChanges().pipe(first()).toPromise(),
      this.afs.collection(`users/${this.teamMember.id}/clientRoles/${this.uiState.client.id}/organizationRoles`)
        .snapshotChanges().pipe(first()).toPromise()
    ])
    const teamMemberClientRole = FirestoreUtilities.objectToType(teamMemberRoleResult[0]);
    this.organizationRoles = FirestoreUtilities.mapToType(teamMemberRoleResult[1]);
    this.teamMemberClientRoles = new TeamMemberClientRole();
    this.teamMemberClientRoles.role = teamMemberClientRole.role;
    this.teamMemberClientRoles.id = teamMemberClientRole.resource;
    this.teamMemberClientRoleControl.patchValue(teamMemberClientRole.role);
    this.teamMemberClientRoleControl.updateValueAndValidity();
    this.teamMemberClientRoles.entities = this.organizationRoles ? this.organizationRoles.filter((orgRole: OrganizationRole) => orgRole.type === 'entity' && this.entities.find(e => e.id === orgRole.id)) : [];
    this.teamMemberClientRoles.locations = this.organizationRoles ? this.organizationRoles.filter((orgRole: OrganizationRole) => orgRole.type === 'location' && this.locations.find(l => l.id === orgRole.id)) : [];
    this.setupTables();
  }
  setupTables() {
    this.loadingService.isLoading(false);
    this.populateEntityTableData();
    this.updateAvailableLocations();
  }
  populateEntityTableData() {
    this.entitiesTableData = new MatTableDataSource(this.getTableData('entities'));
    this.entitiesTableData.paginator = this.entitiesPaginator;
    this.entitiesTableData.sort = this.entitiesSort;
  }

  populateLocationTableData() {
    const tableData = this.getTableData('locations');
    // persist changes to roles even when location availability changes
    if (this.locationTableData?.data) {
      tableData.forEach(locationRole => {
        const existingRole = this.locationTableData.data.find(existingRole => existingRole.resource === locationRole.resource);
        if (existingRole) {
          locationRole.role = existingRole.role;
        }
      })
    }
    this.locationTableData = new MatTableDataSource(tableData);
    this.locationTableData.paginator = this.locationPaginator;
    this.locationTableData.sort = this.locationSort;
  }

  private getTableData(type: 'locations' | 'entities') {
    const resourceArray = type === 'locations' ? this.availableLocations : this.entities;
    return resourceArray.map((resource: any) => {
      const resourceRole = this.teamMemberClientRoles[type].find(role => role.id === resource.id);
      let parentName = type === 'locations' ? this.entities.find(entity => entity.id === resource.entity)?.name : ''
      return {
        role: resourceRole ? resourceRole.role : '',
        name: type === 'locations' ? `${resource.locationId}-${resource.name}` : resource.name,
        resource: resourceRole ? resourceRole.resource : resource.id,
        parent: parentName
      }
    }).sort((a, b) => {
      return a.role > b.role ? -1 : 1
    });
  }

  public async save() {
    if (this.teamMemberClientRoleControl.valid) {
      if (this.teamMember && this.teamMember.id) {
        this.updateTeamMember();
      } else {
        this.sendInvitation();
      }
    }
  }

  private async sendInvitation() {
    if (this.emailToSendTo.valid) {
      const invitation = this.mapValuesToInvitation();
      this.loadingService.isLoading(true, 'Sending Invite...');
      await this.afs.collection('teamMemberInvitations').add(invitation.toJSONObject());
      this.loadingService.isLoading(false);
      this.snackBar.open('Email sent to team member.', 'Dismiss', {
        duration: 5000
      });
      this.router.navigate(['/app/organization/team']);
    } else {
      this.snackBar.open('Please provide an email to send the invitation to.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private async updateTeamMember() {
    const userClientRoleRef = this.afs.doc(`users/${this.teamMember.id}/clientRoles/${this.uiState.client.id}`);
    const userOrgRolesRef = this.afs.collection(`users/${this.teamMember.id}/clientRoles/${this.uiState.client.id}/organizationRoles`);
    const updatedRoles = this.getUpdatedOrgRolesFromForm();
    this.loadingService.isLoading(true, 'Updating team member roles');
    try {
      await userClientRoleRef.set({
        role: updatedRoles.role,
        resource: this.uiState.client.id
      });
      const existingOrgRoleChunks = _.chunk(this.organizationRoles, 499);
      await Promise.all(existingOrgRoleChunks.map(async (chunk) => {
        const batch = this.afs.firestore.batch();
        chunk.forEach((orgRole: OrganizationRole) => {
          batch.delete(userOrgRolesRef.doc(`${orgRole.id}`).ref);
        })
        return batch.commit();
      }));
      const newOrgRoleChunks = _.chunk([...updatedRoles.entities, ...updatedRoles.locations], 499);
      await Promise.all(newOrgRoleChunks.map(async (chunk) => {
        const batch = this.afs.firestore.batch();
        chunk.forEach((orgRole: OrganizationRole) => {
          batch.set(userOrgRolesRef.doc(orgRole.resource).ref, {
            resource: orgRole.resource,
            role: orgRole.role,
            type: orgRole.type
          });
        })
        return batch.commit();
      }));
      this.loadingService.isLoading(false);
      this.snackBar.open('Successfully updated team member roles.', 'Dismiss', {
        duration: 5000
      });
      this.router.navigate(['/app/organization/team']);
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Something went wrong updating your team members roles. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private mapValuesToInvitation() {
    const invitation = new TeamMemberInvitation();
    invitation.client = this.uiState.client.id;
    invitation.from = this.uiState.authUser.id;
    invitation.email = this.emailToSendTo.value;
    invitation.clientRole = this.getUpdatedOrgRolesFromForm();
    invitation.status = 'pending';
    invitation.expiration = moment().add(72, 'hours').toDate();
    return invitation;
  }

  private getUpdatedOrgRolesFromForm() {
    const newClientRole = {
      role: this.teamMemberClientRoleControl.value,
      entities: [],
      locations: []
    };
    this.entitiesTableData.data
      .filter(entityRole => entityRole.role >= 1)
      .forEach(entityRole => {
        const role = new OrganizationRole();
        role.resource = entityRole.resource;
        role.type = 'entity';
        role.role = entityRole.role;
        newClientRole.entities.push(role.toJSONObject());
      });
    this.locationTableData.data
      .filter(entityRole => entityRole.role >= 1)
      .forEach(locationRole => {
        const role = new OrganizationRole();
        role.resource = locationRole.resource;
        role.type = 'location';
        role.role = locationRole.role;
        newClientRole.locations.push(role.toJSONObject());
      });
    return newClientRole;
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(discardChanges => {
      if (discardChanges) {
        this.router.navigate(['../']);
      }
    });
  }

  //LOCATIONS TABLE OPERATIONS
  public applyLocationFilter(filterValue: string) {
    this.locationTableData.filter = filterValue.trim().toLowerCase();
    if (this.locationTableData.paginator) {
      this.locationTableData.paginator.firstPage();
    }
  }
  isAllLocationsSelected() {
    const numSelected = this.locationSelection.selected.length;
    const numRows = this.locationTableData.data.length;
    return numSelected === numRows;
  }

  masterLocationsToggle() {
    this.isAllLocationsSelected() ?
      this.locationSelection.clear() :
      this.locationTableData.data.forEach(row => {
        this.locationSelection.select(row)
      });
  }

  applyRoleToSelection() {
    const role = this.defaultLocationRole.value;
    this.defaultLocationRole.reset();
    const selectedLocations = this.locationSelection.selected;
    selectedLocations.forEach(location => {
      location.role = role;
    })
    this.locationSelection.clear();
  }
  //ENTITIES TABLE OPERATIONS
  public applyEntityFilter(filterValue: string) {
    this.entitiesTableData.filter = filterValue.trim().toLowerCase();
    if (this.entitiesTableData.paginator) {
      this.entitiesTableData.paginator.firstPage();
    }
  }
  isAllEntitiesSelected() {
    const numSelected = this.locationSelection.selected.length;
    const numRows = this.locationTableData.data.length;
    return numSelected === numRows;
  }

  masterEntitiesToggle() {
    this.isAllLocationsSelected() ?
      this.locationSelection.clear() :
      this.locationTableData.data.forEach(row => this.locationSelection.select(row));
  }

  // @TODO NEED?
  private getToken(token): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }
}
