import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    termsOfService: string;

    constructor(private afs: AngularFirestore) {
    }

    ngOnInit() {
        this.afs.doc('staticContents/0').snapshotChanges().subscribe(staticContents$ => {
            this.termsOfService = FirestoreUtilities.objectToType(staticContents$).termsOfService;
        })
    }
}
