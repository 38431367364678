<div fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Upload Your Locations</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <a [href]="locationUploadTemplateLocation" download>
          Click here to download and fill out this spreadsheet with your company's location information.</a>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <div *ngIf="client && entities && entities.length > 0">
          <mat-form-field appearance="outline">
            <mat-label>Select Entity</mat-label>
            <mat-select [formControl]="selectedEntity" placeholder="Select the Entity to add locations to">
              <mat-option [value]="entity" *ngFor="let entity of entities">
                {{entity.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="entities && entities.length < 1">
          <div>
            <p>This client doesn't have any entities. <a [routerLink]="'/app/organization/entities/new'">Click here to create one.</a> </p>
          </div>
        </div>
        <div *ngIf="selectedEntity.value">
          <input type="file"
                 id="location-form-upload-button-partial"
                 fxHide
                 (change)="detectFiles($event)"
                 [multiple]="false"
                 accept=".csv">
          <label for="location-form-upload-button-partial"
                 #imageUploadLabel>
            <button mat-raised-button color="primary" (click)="imageUploadLabel.click()">
              <mat-icon>attachment</mat-icon> Upload location information sheet
            </button>
          </label>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
