import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {PasswordresetComponent} from './passwordreset/passwordreset.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {DsSharedModule} from "../shared/ds-shared.module";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "../material.module";
import {ClientSelectorComponent} from "./client-selector/client-selector.component";
import {FlexModule} from "@angular/flex-layout";
import {TeamMemberSignupComponent} from "./team-member-signup/team-member-signup.component";
import {ClientsModule} from "../components/organization/clients/clients.module";
import { SfaComponent } from './sfa/sfa.component';
import { SsoComponent } from './sso/sso.component';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    PasswordresetComponent,
    ClientSelectorComponent,
    TeamMemberSignupComponent,
    SfaComponent,
    SsoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(), // Keep in mind the "forRoot"-magic nuances!
    MalihuScrollbarModule.forRoot(),
    RecaptchaFormsModule,
    DsSharedModule,
    NgbAlertModule,
    RouterModule,
    MaterialModule,
    MatCardModule,
    FormsModule,
    FlexModule,
    ClientsModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcfNbkUAAAAAEz20Rw2LMS8NAjPXA9eG-SZ89DF',
      } as RecaptchaSettings,
    },
  ]
})
export class AuthModule {
}
