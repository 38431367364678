import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject } from "rxjs";
import * as firebase from 'firebase';
import { AngularFirestore } from "@angular/fire/firestore";
import { FirebaseAuthService } from "../auth/services/firebase-auth.service";

@Injectable()
export class PushMessagingService {
    messaging = firebase.messaging();
    currentMessage = new BehaviorSubject(null);

    constructor(private db: AngularFireDatabase, private afs: AngularFirestore, private afAuth: AngularFireAuth, private auth: FirebaseAuthService) {
    }


    updateToken(token) {
        this.auth.authUser.subscribe(user => {
            if (!user) {
                return;
            }
            if (token) {
                const data = { token: token };
                if (data && user) {
                    this.afs.doc(`fcmTokens/${user.id}`).set(data).then(() => {
                        console.log('token updated');
                    });
                }
            }
        })
    }

    getPermission() {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (!iOS) {
            this.messaging.requestPermission()
                .then(() => {
                    console.log('UserNotification permission granted.');
                    return this.messaging.getToken()
                }, err => {
                    console.log(err);
                })
                .then(token => {
                    console.log(token)
                    this.updateToken(token)
                })
                .catch((err) => {
                    console.log(err + ':Unable to get permission to notify.');
                });
        }
    }

    receiveMessage() {
        this.messaging.onMessage((payload) => {
            console.log("Message received. ", payload);
            this.currentMessage.next(payload)
        });

    }
}
