<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="column" fxLayoutGap="12px">
  <h2>Ping Your Team</h2>
  <p class="mat-text-muted">Let your team members know that you have made changes to:<br>
    <b>{{auditTrailDocument.name}}</b>.</p>
  <form ngSubmit="sendPing()" [formGroup]="pingForm" fxLayout="column" fxLayoutGap="12px">
    <mat-form-field appearance="outline">
      <mat-label>To Users</mat-label>
      <mat-select multiple formControlName="toUsers">
        <mat-option *ngFor="let user of projectUsers" [value]="user.id">
          <div [matTooltip]="user.email">{{user.firstName}} {{user.lastName}}
            <small class="mat-text-muted">{{user.email}}</small></div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" rows="6"
        placeholder="Tell your team what you have done..."></textarea>
    </mat-form-field>
    <div>
      <button mat-raised-button color="accent" (click)="sendPing()">
        Send Ping
      </button>
    </div>
  </form>
</div>
