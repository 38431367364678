import { NgModule } from '@angular/core';
import { ConfirmLeaveGuard } from "./confirm-leave.guard";
import { AuthGuard } from "./auth.guard";
import { UnauthenticatedGuard } from "./unauthenticated.guard";
import { ClientSelectedGuard } from "./client-selected.guard";
import { SecondFactorAuthenticationGuard } from './sfa.guard';
import { NoSfaGuard } from './no-sfa.guard';


@NgModule({
  providers: [
    ConfirmLeaveGuard,
    AuthGuard,
    UnauthenticatedGuard,
    ClientSelectedGuard,
    SecondFactorAuthenticationGuard,
    NoSfaGuard
  ]
})

export class GuardsModule {
}
