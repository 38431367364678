<mat-toolbar color="accent">
  <h2>Uploads</h2>
  <button mat-raised-button color="accent" (click)="dataUploadService.startNewUpload()">
    <mat-icon>add</mat-icon>
    New
  </button>
  <span fxFlex></span>
  <button mat-mini-fab color="warn" (click)="closeSidenav()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group>
  <mat-tab label="Current">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center" [style.margin-bottom.px]='80'>
      <div fxLayout="row" *ngFor="let upload of uploads">
          <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
            <ng-container [ngSwitch]="upload.status">
              <mat-icon *ngSwitchCase="'success'" fontSet="fas" fontIcon="fa-check-circle" color="accent">
              </mat-icon>
              <button mat-icon-button (click)="dataUploadService.viewErrors(upload)" *ngSwitchCase="'errors'"
                color="warn">
                <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle"></mat-icon>
              </button>
              <mat-icon *ngSwitchCase="'pending'" fontSet="fas" fontIcon="fa-clock" color="primary"></mat-icon>
              <div *ngSwitchCase="'working'" fxLayout="column" fxLayoutAlign="center center">
                <div>
                  <mat-spinner diameter="50" strokeWidth="8"></mat-spinner>
                </div>
                <div>{{upload.progress}}%</div>
              </div>
            </ng-container>
          </div>
          <div fxLayout="column">
            <div fxLayout="row" *ngIf="upload.testMode">
              <div class="mat-text-primary">
                *TEST UPLOAD* <mat-icon fontSet="fas" fontIcon="fa-info-circle"
                  matTooltip="Data from upload tests is not saved. To save the data from this upload click the 'Commit' button below.">
                </mat-icon>
              </div>
            </div>
            <div fxLayout="row" *ngIf="upload.testMode && upload.status === 'success'">
              <div class="mat-text-primary">
                <button mat-button color="primary" (click)="commitTestUpload(upload)">
                  Commit
                </button>
              </div>
            </div>
            <div fxLayout="row">
              <div [class.mat-text-warn]="upload.status === 'errors'">
                <b>{{upload.fileName}}</b> - {{getUploadSizeMb(upload.fileSize)}} MB
              </div>
            </div>
            <div>
              <div>
                <b>Total Rows:</b> {{upload.rowCount}}
              </div>
            </div>
            <ng-container *ngIf="dataUploadService.getNumberOfErrors(upload) > 0">
              <div class="mat-text-warn">
                Error Count: {{dataUploadService.getNumberOfErrors(upload)}}
              </div>
            </ng-container>
          </div>
          <div fxFlex="20">
            <button mat-icon-button color="warn" (click)="dataUploadService.clearUpload(upload)">
              <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row">
          <mat-divider></mat-divider>
        </div>
    </div>
  </mat-tab>
  <mat-tab label="History">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center" [style.margin-bottom.px]='80'>
      <div fxLayout="row" *ngFor="let uploadLog of dataUploadLogs">
          <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
            <ng-container [ngSwitch]="uploadLog.status">
              <mat-icon *ngSwitchCase="'success'" fontSet="fas" fontIcon="fa-check-circle" color="accent">
              </mat-icon>
              <button mat-icon-button (click)="dataUploadService.viewErrors(uploadLog)" *ngSwitchCase="'errors'"
                color="warn">
                <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle"></mat-icon>
              </button>
              <mat-icon *ngSwitchCase="'pending'" fontSet="fas" fontIcon="fa-clock" color="primary"></mat-icon>
              <div *ngSwitchCase="'working'" fxLayout="column" fxLayoutAlign="center center">
                <div>
                  <mat-spinner diameter="50" strokeWidth="8"></mat-spinner>
                </div>
              </div>
            </ng-container>
          </div>
          <div fxLayout="column">
            <div fxLayout="row">
              <div><b>Upload Id: {{uploadLog.shortId}} </b></div>
            </div>
            <div fxLayout="row">
              <div><b> Type: </b>{{uploadLog.posSystem ? 'POS' : '3PD'}}</div>
              <div><b> Account: </b>{{uploadLog.posSystem ? uploadLog.posSystem : uploadLog.thirdParty}}</div>
            </div>
            <div fxLayout="row">
              <div [class.mat-text-warn]="uploadLog.status === 'errors'">
                <b>File: {{uploadLog.fileName}}</b> - {{getUploadSizeMb(uploadLog.fileSize)}} MB
              </div>
            </div>
            <div>
              <div>
                <b>Records:</b> {{uploadLog.rowCount}}
              </div>
            </div>
            <ng-container *ngIf="dataUploadService.getNumberOfErrors(uploadLog) > 0">
              <div class="mat-text-warn">
                Error Count: {{dataUploadService.getNumberOfErrors(uploadLog)}}
              </div>
            </ng-container>
            <div>
              <button mat-raised-button (click)="deleteBatch(uploadLog)" color="warn">
                Delete <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
  </mat-tab>
</mat-tab-group>
