<mat-card>
  <mat-tab-group>
    <mat-tab [label]="'Payment Info'">
      <ng-template matTabContent>
        <app-client-payment-details [client]="client"></app-client-payment-details>
      </ng-template>
    </mat-tab>
    <mat-tab label="modules">
      <ng-template matTabContent>
        <app-client-modules [client]="client" [user]="user"></app-client-modules>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Day Charges'">
      <app-client-day-charges [client]="client"></app-client-day-charges>
    </mat-tab>
    <mat-tab [label]="'Invoices'">
      <app-client-invoices [client]="client"></app-client-invoices>
    </mat-tab>
    <mat-tab [label]="'Credits'">
      <app-client-credit-balance [client]="client"></app-client-credit-balance>
    </mat-tab>
  </mat-tab-group>
</mat-card>
