import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { DeliverSenseModule, DsModules } from '@deliver-sense-librarian/data-schema';
import { scrollbarOptions } from '../../shared/ds-constant';

@Component({
  selector: 'app-module-information-dialog',
  templateUrl: './module-information-dialog.component.html',
  styleUrls: ['./module-information-dialog.component.scss']
})
export class ModuleInformationDialogComponent implements OnInit {
  dsModule: DeliverSenseModule;
  title: string;
  howItWorks: string;
  whoIsItFor: string;
  whatIsIt: string;
  tagLine: string;
  scrollbarOptions = scrollbarOptions;
  constructor(public dialogRef: MatDialogRef<ModuleInformationDialogComponent>,
    private afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dsModule = data.module as DeliverSenseModule;
  }

  ngOnInit() {
    if (this.dsModule) {
      switch (this.dsModule.id) {
        case DsModules.AuditTrails:
          this.title = "Audit Trails";
          this.tagLine = "Redefining the review process!"
          this.whatIsIt = ``
          break;
        case DsModules.ThirdPartyDeliveryReporting:
          break;
        case DsModules.ExemptSalesManagement:
          break;
        case DsModules.PropertyTaxManager:
          break;
      }
    }
  }

}
