import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client/client.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../../material.module";
import { DocumentUploadModule } from "../../document-upload/document-upload.module";
import { ClientDayChargesComponent } from './client-day-charges/client-day-charges.component';
import { ClientBillingComponent } from './client-billing/client-billing.component';
import { ClientInvoicesComponent } from './client-invoices/client-invoices.component';
import { StripeCardModule } from 'app/components/stripe-card/stripe-card.module';
import { ClientCreditBalanceComponent } from './client-credit-balance/client-credit-balance.component';
import { ClientModulesComponent } from './client-modules/client-modules.component';
import { ClientPaymentDetailsComponent } from './client-payment-details/client-payment-details.component';

@NgModule({
  declarations: [
    ClientComponent,
    ClientDayChargesComponent,
    ClientBillingComponent,
    ClientInvoicesComponent,
    ClientCreditBalanceComponent,
    ClientModulesComponent,
    ClientPaymentDetailsComponent
  ],
  exports: [
    ClientComponent,
    ClientDayChargesComponent,
    ClientBillingComponent,
    ClientInvoicesComponent,
    ClientCreditBalanceComponent,
    ClientPaymentDetailsComponent,
    ClientModulesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DocumentUploadModule,
    StripeCardModule
  ]
})
export class ClientsModule {
}
