import {Action} from '@ngrx/store';
import {Client} from "@deliver-sense-librarian/data-schema";

export const LOADED_CLIENT = 'LOADED_CLIENT';

export class LoadedClientAction implements Action {
    readonly type = LOADED_CLIENT;
    constructor(public payload?: Client) {
    }
}

