<mat-card>
  <mat-card-header>
    <mat-card-title>
      <ng-container *ngIf="teamMember && teamMember.id; else newTeamMember">
        Update <i>{{teamMember.firstName}} {{teamMember.lastName}}</i> Roles for <b>{{uiState?.client ? uiState.client.name : ''}}</b>
      </ng-container>
      <ng-template #newTeamMember>
        <h2>Invite a new team member</h2>
      </ng-template>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="outline" *ngIf="!teamMember">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="emailToSendTo" placeholder="Team member's email" />
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div>
        <h4>Client Role</h4>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <mat-select [formControl]="teamMemberClientRoleControl" placeholder="Role">
          <mat-option *ngFor="let _role of roles" [value]="_role.value">{{_role.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--ENTITIES START -->
    <mat-accordion fxLayout="column" fxLayoutGap="10px" [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h4>Entities</h4>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline">
            <mat-label>Filter Locations</mat-label>
            <input [formControl]="entitySearchText" matInput (keyup)="applyEntityFilter($event.target.value)"
              placeholder="Filter Entities">
            <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
          </mat-form-field>
          <mat-paginator #entitiesPaginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
          </mat-paginator>
        </div>
        <div class="mat-elevation-z8">
          <table mat-table  [dataSource]="entitiesTableData" class="report-table" matSort>
            <tr mat-header-row *matHeaderRowDef="entitiesDisplayColumns; sticky"></tr>
            <tr  mat-row *matRowDef="let row; let i = index; columns: entitiesDisplayColumns;"></tr>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterEntitiesToggle() : null"
                  [checked]="entitiesSelection.hasValue() && isAllEntitiesSelected()"
                  [indeterminate]="entitiesSelection.hasValue() && !isAllEntitiesSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? entitiesSelection.toggle(row) : null"
                  [checked]="entitiesSelection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="entity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Entity</th>
              <td mat-cell *matCellDef="let row; let i = index">
                {{row.name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
              <td mat-cell *matCellDef="let row; let j = index">
                <mat-radio-group [(ngModel)]="row.role" fxLayout="row" (change)="updateAvailableLocations()" fxLayoutAlign="start center" fxLayoutGap="12px">
                  <mat-radio-button fxFlex class="example-radio-button" *ngFor="let role of roles" [value]="role.value">
                    {{role.name}}
                  </mat-radio-button>
                </mat-radio-group>
              </td>
            </ng-container>
            <ng-container matColumnDef="remove">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Remove</th>
              <td mat-cell *matCellDef="let row; let j = index">
                <mat-radio-group [(ngModel)]="row.role" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                  <mat-radio-button fxFlex class="example-radio-button" *ngFor="let role of roles" [value]="role.value">
                    {{role.name}}
                  </mat-radio-button>
                </mat-radio-group>
              </td>
            </ng-container>
          </table>
        </div>
      </mat-expansion-panel>
      <!--ENTITIES END-->
      <mat-divider></mat-divider>
      <!--LOCATIONS START -->
      <div fxLayout="row">
        <div fxHide.gt-md="false" fxHide="true" fxFlex="10" fxLayoutAlign="end start">
          <mat-icon>subdirectory_arrow_right</mat-icon>
        </div>
        <mat-expansion-panel fxFlex class="resource-expansion-panel">
          <mat-expansion-panel-header>
            <h4>Locations</h4>
          </mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutGap="20px" [style.margin.px]="14" *ngIf="locationSelection.selected.length > 0">
            <div fxLayout="column">
            <div>
              <h5>Update Selected Location Roles</h5>
            </div>
            <mat-radio-group [formControl]="defaultLocationRole" fxLayout="column">
              <mat-radio-button *ngFor="let _role of roles" [value]="_role.value">{{_role.name}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-raised-button color="primary" (click)="applyRoleToSelection()">
              Apply
            </button>
          </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline">
              <mat-label>Search Locations</mat-label>
              <input [formControl]="locationSearchText" matInput (keyup)="applyLocationFilter($event.target.value)"
                placeholder="Search Locations">
              <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
            </mat-form-field>
            <mat-paginator #locationPaginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [pageSize]="5">
            </mat-paginator>
          </div>
          <div class="mat-elevation-z8" >
            <table mat-table [dataSource]="locationTableData" class="report-table" matSort>
              <tr mat-header-row *matHeaderRowDef="locationDisplayColumns; sticky"></tr>
              <tr mat-row *matRowDef="let row; let i = index; columns: locationDisplayColumns;"></tr>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterLocationsToggle() : null"
                    [checked]="locationSelection.hasValue() && isAllLocationsSelected()"
                    [indeterminate]="locationSelection.hasValue() && !isAllLocationsSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? locationSelection.toggle(row) : null"
                    [checked]="locationSelection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  {{row.name}}
                </td>
              </ng-container>
              <ng-container matColumnDef="entity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Entity</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  {{row.parent}}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let row; let j = index">
                  <mat-radio-group [(ngModel)]="row.role" fxLayout="row" fxLayoutAlign="start center"
                    fxLayoutGap="12px">
                    <mat-radio-button fxFlex class="example-radio-button" *ngFor="let role of roles"
                      [value]="role.value">
                      {{role.name}}
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
              </ng-container>
            </table>
          </div>

        </mat-expansion-panel>
      </div>
    </mat-accordion>


  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button color="primary" (click)="save()">
      <ng-container *ngIf="teamMember && teamMember.id; else newTeamMember">
        Update
      </ng-container>
      <ng-template #newTeamMember>
        Send Invite
      </ng-template>
    </button>
    <button *ngIf="teamMember && teamMember.id" mat-raised-button color="accent" >
      Reset Role Changes
    </button>
    <button mat-raised-button [routerLink]="'../'">Cancel</button>
    <!-- <button mat-raised-button color="warn" *ngIf="teamMember && teamMember.id && uiState.clientRole === 3" (click)="delete()">Delete</button> -->
  </mat-card-actions>
</mat-card>
