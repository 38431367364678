<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Locations</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h6>View and edit your locations</h6>
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
      <button mat-button (click)="exportReport()" color="accent">
        Export
        <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
      </button>
      <mat-slide-toggle [checked]="onlyShowActive" (change)="toggleOnlyActive($event)">
        Only Show Active
      </mat-slide-toggle>
      <mat-form-field appearance="outline">
        <mat-label>Search Locations</mat-label>
        <input [formControl]="locationSearchText" matInput (keyup)="applyAllFilter($event.target.value)"
          placeholder="Search Locations">
        <mat-icon matSuffix fontSet="fas" fontIcon="fa-search"></mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Filter By State</mat-label>
        <mat-select [formControl]="filterState" placeholder="Filter By State">
          <mat-option [value]="null">Clear State Filter</mat-option>
          <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" [routerLink]="'new'">
        Create Location
        <mat-icon>add</mat-icon>
      </button>
      <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button color="primary" [routerLink]="'upload'">
          Bulk Upload Locations Data
          <mat-icon>cloud_upload</mat-icon>
        </button>
      </div> -->
    </mat-card-header>
    <mat-card-content>
      <div class="mat-elevation-z8" id="location-table">
        <mat-table [dataSource]="isInEditMode ? mutableTableData : tableData" matSort multiTemplateDataRows>
          <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef> Active</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [checked]="row.active" (change)="updateLocationActive($event, row)"></mat-checkbox>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Location Number</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <a [routerLink]="'./' +row.id">
                {{row.locationId}}
                <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.name}}
            </mat-cell>
          </ng-container>
           <ng-container matColumnDef="entityName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Entity</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.entityName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="addressLine1">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Address 1</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.addressLine1}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="addressLine2">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Address 2</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.addressLine2}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="city">
            <mat-header-cell *matHeaderCellDef mat-sort-header> City</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.addressCity}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef> State</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.addressState}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="zip">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Postal Code</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.addressPostalCode}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="totalTaxRate">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b>Total Tax Rate</b></mat-header-cell>
            <mat-cell *matCellDef="let row" [style.color]="row.color">
              <b>
                {{getTotalTaxRate(row)}}
                <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
              </b>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandTrigger">
            <mat-header-cell *matHeaderCellDef mat-sort-header><b></b></mat-header-cell>
            <mat-cell *matCellDef="let row" [style.color]="row.color">
              <button mat-icon-button color='primary' (click)="expandedElement = expandedElement === row ? null : row">
                <mat-icon>expand</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="example-element-detail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="expanded-cell-data" fxLayout="row wrap" fxLayoutAlign="space-between start">
                  <table class="delivery-partner-rate-sub-table">
                    <thead>
                      <th>Location Tax Rates</th>
                    </thead>
                    <tbody>
                      <ng-template #editRateTemplate>
                        <div fxLayout="column">
                          <mat-form-field appearance="outline">
                            <mat-label>Rate</mat-label>
                            <input matInput min="0" max="100" [value]="rateToEdit" #rateInput type="number">
                            <mat-icon fontSet="fas" fontIcon="fa-percent" matSuffix></mat-icon>
                          </mat-form-field>
                          <div fxLayout="row">
                            <button mat-raised-button color="accent"
                              (click)="saveRateChanges(rateInput.value)">Save</button>
                            <button mat-raised-button color="warn" (click)="clearRatePopover()">Cancel</button>
                          </div>
                        </div>
                      </ng-template>
                      <tr>
                        <td class="location-rate-cell" [popover]="editRateTemplate"
                          (click)="setRateEditing('stateTaxRate', element, stateRatePop)"
                          [popoverTitle]="'Edit '+element.name+ ' State Tax Rate'" #stateRatePop="bs-popover">
                          State: {{element.stateTaxRate}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>

                        </td>
                      </tr>
                      <tr>
                        <td class="location-rate-cell" [popover]="editRateTemplate"
                          (click)="setRateEditing('countyTaxRate', element, countyRatePop)"
                          [popoverTitle]="'Edit '+element.name+ ' County Tax Rate'" #countyRatePop="bs-popover">
                          County: {{element.countyTaxRate}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
                        </td>
                      </tr>
                      <tr>
                        <td class="location-rate-cell" [popover]="editRateTemplate"
                          (click)="setRateEditing('cityTaxRate', element, cityRatePop)"
                          [popoverTitle]="'Edit '+element.name+ ' City Tax Rate'" #cityRatePop="bs-popover">
                          City: {{element.cityTaxRate}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
                        </td>
                      </tr>
                      <tr>
                        <td class="location-rate-cell" [popover]="editRateTemplate"
                          (click)="setRateEditing('specialTaxRate', element, specialRatePop)"
                          [popoverTitle]="'Edit '+element.name+ ' Special Tax Rate'" #specialRatePop="bs-popover">
                          Special:
                          {{element.specialTaxRate}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="delivery-partner-rate-sub-table">
                    <thead>
                      <th>Delivery Partner</th>
                      <th>Active</th>
                      <th>Delivery Fee</th>
                      <th>
                        MF Tax Rate
                        <mat-icon [matTooltip]="'The Current Market Facilitator Tax Rate'" fontSet="fas"
                          fontIcon="fa-info-circle">
                        </mat-icon>
                      </th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let thirdParty of thirdParties">
                        <td>{{thirdParty.name}}</td>
                        <td>
                          <ng-container
                            *ngIf="isThirdPartyAvailableForLocation(element, thirdParty); else notAvailableIcon">
                            <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
                          </ng-container>
                          <ng-template #notAvailableIcon>
                            <mat-icon fontSet="fas" fontIcon="fa-times-circle" color="warn"></mat-icon>
                          </ng-template>
                        </td>
                        <td>
                          {{getThirdPartyDeliveryFee(thirdParty, element)}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
                        </td>
                        <td [matTooltip]="getThirdPartyMfEffectiveDate(thirdParty, element)">
                          {{getThirdPartyMfRate(thirdParty, element)}}
                          <mat-icon fontSet="fas" fontIcon="fa-percent"></mat-icon>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element">
          </mat-row>
          <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card-content>

  </mat-card>

</div>
