<div fxLayout="row" fxLayoutAlign="end end">
    <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-card>
    <mat-card-header>
        <mat-card-title><h2>{{title}}</h2></mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>{{placeholder}}</mat-label>
                <textarea placeholder="{{placeholder}}" matInput [formControl]="reason" rows="5"></textarea>
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div fxLayout="row" fxLayoutWrap>
            <button mat-raised-button color="accent" (click)="submitReason();">Submit</button>
            <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
        </div>
    </mat-card-actions>
</mat-card>
