import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadingDialogService } from './loading-dialog.service';
import { map, combineAll, first, switchMap, audit } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, from, Subscription, Observer } from 'rxjs';
import {
  AuditTrailDocumentSection,
  AuditTrailDocument,
  AuditTrailDocumentSectionComment,
  AuditTrailDocumentSectionCommentMessage,
  AuditTrailDocumentSectionCommentStatuses, UserView
} from '@deliver-sense-librarian/data-schema';
import { UiState } from '../redux/custom-states/uiState/ui-state';
import { FirestoreUtilities } from '../utilities/firestore-utilities';
import * as bluebird from 'bluebird';
import * as moment from "moment";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class AuditTrailsService {
  uiState: UiState;
  constructor(
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar) {
    this.store.select($store => $store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.uiState = uiState$;
        }
      });
  }

  public fetchProjectUsers(auditTrailDocument: AuditTrailDocument) {
    let availableAssignees: UserView[] = [];
    return this.afs.collection('userViews', ref => ref
      .where('clients', 'array-contains', this.uiState.client.id))
      .snapshotChanges()
      .pipe(switchMap(clientUsers$ => {
        const clientUsers = FirestoreUtilities.mapToType(clientUsers$) as UserView[];
        if (this.uiState.clientRole > 2) {
          const projectRoleRequests = clientUsers.map(user => {
            return this.afs.doc(`users/${user.id}/clientRoles/${this.uiState.client.id}/organizationRoles/${auditTrailDocument.project}`)
              .snapshotChanges();
          });
          return from(projectRoleRequests)
            .pipe(combineAll(),  map(projectRoles$ => {
              projectRoles$
                .filter(childDoc => childDoc.payload.exists)
                .forEach(childDoc => {
                  const pathSegments = childDoc.payload.ref.path.split('/');
                  const parentCollectionIndex = pathSegments.indexOf('users');
                  const projectRoleUserId = pathSegments[parentCollectionIndex + 1];
                  if (projectRoleUserId && projectRoleUserId !== this.uiState.authUser.id) {
                    const clientUser = clientUsers.find(user => user.id === projectRoleUserId);
                    availableAssignees.push(clientUser);
                  }
                });
              availableAssignees = _.uniqBy(availableAssignees, 'id').filter(user => !!user);
              this.loadingService.isLoading(false);
              return availableAssignees;
            }, () => {
              this.loadingService.isLoading(false)
              return availableAssignees
            }));
        } else {
          availableAssignees = [];
          this.loadingService.isLoading(false);
          return availableAssignees;
        }
      }, () => {
        this.loadingService.isLoading(false)
        return availableAssignees
      }))
  }
  async exportAuditTrail(auditTrailDocument: AuditTrailDocument) {
    const user = this.uiState.authUser;
    this.loadingService.isLoading(true, 'Generating Audit Trail Document...');
    const dsLogoUrl = await this.getBase64ImageFromURL('/assets/images/ds-audit-trails.png').pipe(first()).toPromise();
    const docName = auditTrailDocument.name.replace(/\s/g, '_');
    try {
      const dd = {
        info: {
          title: `Audit_Trail-${docName}.pdf`,
          author: `${user.firstName} ${user.lastName}`,
          subject: 'Audit Trails documentation export.',
        },
        header: {
          margin: [18, 18, 18, 30],
          alignment: 'justify',
          columns: [
            {
              image: `data:image/jpeg;base64,${dsLogoUrl}`,
              width: 100
            },
            {
              text: `Generated: ${moment().toString()}`,
              alignment: 'right'
            }
          ]
        },
        footer: function (currentPage, pageCount) {
          return { text: `Page: ${currentPage}/${pageCount}`, alignment: 'right', margin: [20, 5, 20, 5] };
        },
        content: [],
        styles: {
          header: {
            fontSize: 18,
            bold: true
          },
          subheader: {
            fontSize: 15,
            bold: true
          },
          quote: {
            italics: true
          },
          small: {
            fontSize: 8
          }
        }
      };
      dd.content.push({
        text: auditTrailDocument.name,
        style: 'header',
        margin: [0, 10, 0, 10]
      });
      dd.content.push({
        text: `Description: ${auditTrailDocument.description ? auditTrailDocument.description : ''}`,
        style: 'subheader',
        bold: true
      });
      dd.content.push({
        text: 'Sections',
        style: 'subheader',
        alignment: 'center'
      });

      const sectionsList = { ul: [] }
      const sections: AuditTrailDocumentSection[] = FirestoreUtilities.mapToType(
        await this.afs.collection(`auditTrailDocuments/${auditTrailDocument.id}/sections`)
          .snapshotChanges().pipe(first()).toPromise()
      );
      sections.forEach(section => {
        sectionsList.ul.push({
          text: [
            `${section.name} - page: `,
            {
              pageReference: section.name,
              color: 'blue',
              bold: true,
              decoration: 'underline',
            }
          ],
          margin: [0, 10, 0, 10]
        });
      });
      sectionsList.ul.push({
        text: [
          `Checklist`,
          {
            pageReference: `${auditTrailDocument.name} Checklist`,
            color: 'blue',
            bold: true,
            decoration: 'underline',
          }
        ],
        margin: [0, 10, 0, 10]
      })
      dd.content.push(
        { ul: sectionsList.ul, pageBreak: 'after' },
      );
      dd.content.push()
      await bluebird.mapSeries(sections, async (section: AuditTrailDocumentSection) => {
        await this.addSectionText(dd, auditTrailDocument, section)
      });
      await this.addChecklistSummary(dd, auditTrailDocument);
      pdfMake.createPdf(dd).open();
      // pdfMake.createPdf(dd).open();
      this.loadingService.isLoading(false);
    } catch (e) {
      this.loadingService.isLoading(false);
      console.log(e);
      this.snackBar.open('Oops... something went wrong. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  private async addSectionText(dd,document: AuditTrailDocument, section: AuditTrailDocumentSection) {
    const sectionCommentsQuery = await this.afs.collection(`auditTrailDocuments/${document.id}/sections/${section.id}/comments`).snapshotChanges().pipe(first()).toPromise();
    section['comments'] = FirestoreUtilities.mapToType(sectionCommentsQuery);
    dd.content.push({
      text: `${section.name}`,
      style: 'header',
      alignment: 'center',
      id: section.name,
      bold: true,
      decoration: 'underline',
      color: '#3949AB'
    });
    dd.content.push({
      columns: [
        {
          text: `Open: ${section.totalOpen}`,
          color: '#D32F2F'
        },
        {
          text: `Ready: ${section.totalReady}`,
          color: '#3949AB'
        },
        {
          text: `Closed: ${section.totalClosed}`,
          color: '#009688'
        },
        {
          text: `Cancelled: ${section.totalCancelled}`,
          color: '#757575'
        },
      ],
      alignment: 'center',
      margin: [0, 10, 0, 20]
    })
    if (section['comments'].length > 0) {
      await bluebird.mapSeries(section['comments'], async (comment: AuditTrailDocumentSectionComment) => {
        const messages = await this.mapSectionCommentMessages(document, section, comment).pipe(first()).toPromise();
        dd.content.push({
          text: [{ text: 'Comment:', fontSize: 15, bold: true }, ` ${comment.comment}`],
          margin: [0, 10, 0, 20]
        });
        const commentStatus = await this.getCommentStatus(comment);
        const commentAssigneeName = await this.getCommentAssigneeName(comment);
        const commentCreatorName = await this.getCommentCreatorName(comment);
        const commentApprovalInfo = await this.getCommentApprovalInfo(comment);
        dd.content.push({
          ul: [
            { text: [{ text: `Status: `, fontSize: 13, italics: true }, `${commentStatus}`] },
            { text: [{ text: `Assigned To: `, fontSize: 13, italics: true }, `${commentAssigneeName}`] },
            { text: [{ text: `Created By: `, fontSize: 13, italics: true }, `${commentCreatorName}`] },
            { text: [{ text: `${commentApprovalInfo ? 'Approved By: ' : ''}`, fontSize: 13, italics: true }, `${commentApprovalInfo ? commentApprovalInfo : ''}`] },
          ],
          margin: [20, 5, 0, 5]
        })
        if (messages.length > 0) {
          dd.content.push({
            text: `Messages`,
            style: 'subheader',
            alignment: 'center',
            decoration: 'underline',
          });
          messages.forEach((message: AuditTrailDocumentSectionCommentMessage) => {
            const messageInfo = [
              { text: [{ text: 'Message:', fontSize: 13, italics: true }, ` ${message.message}`] },
              { text: [{ text: 'From:', fontSize: 13, italics: true }, ` ${message.from['firstName']} ${message.from['lastName']}`] },
              { text: [{ text: 'Date:', fontSize: 13, italics: true }, ` ${message.dateCreated.toDate()}`] }
            ]
            dd.content.push(...messageInfo);
          });
          dd.content.push({
            canvas: [
              {
                type: 'line',
                x1: 0, y1: 1,
                x2: 500, y2: 1,
                lineColor: '#6D6E71',
              }
            ],
            margin: [0, 20, 0, 20]
          })
        }
      })
    } else {
      dd.content.push({
        text: `No Comments Available For This Section`,
        style: 'subheader',
        alignment: 'center'
      });
    }
    // if (this.sections.indexOf(section) !== (this.sections.length - 1)) {
    dd.content.push({
      text: '',
      pageBreak: 'after'
    });
    // }

  }
  private mapSectionCommentMessages(document: AuditTrailDocument, section: AuditTrailDocumentSection, comment: AuditTrailDocumentSectionComment) {
    return this.afs.collection(`auditTrailDocuments/${document.id}/sections/${section.id}/comments/${comment.id}/messages`)
      .snapshotChanges()
      .pipe(first(), switchMap(messages$ => {
        const selectedCommentMessages = FirestoreUtilities.mapToType(messages$).sort((a, b) => {
          return moment(a.dateCreated.toDate()).isBefore(b.dateCreated.toDate()) ? -1 : 1;
        });
        const messageUserViews = selectedCommentMessages.map(message => {
          return this.afs.doc(`userViews/${message.from}`).snapshotChanges();
        });
        if (messageUserViews.length > 0) {
          return from(messageUserViews)
            .pipe(combineAll(),
              map(userViews$ => {
                const userViews = FirestoreUtilities.mergeToType(userViews$);
                selectedCommentMessages.forEach(message => {
                  const fromUser = userViews.find(userView => userView.id === message.from);
                  if (fromUser) {
                    message.from = fromUser;
                  }
                });
                return selectedCommentMessages;
              }));
        } else {
          return of([]);
        }
      }));
  }
  private async addChecklistSummary(dd, document: AuditTrailDocument) {
    dd.content.push({
      text: `${document.name} Checklist`,
      style: 'header',
      alignment: 'center',
      id: `${document.name} Checklist`,
      bold: true,
      decoration: 'underline',
      color: '#3949AB'
    });
    const checklistItemList = { ul: [] };
    if (document.checklistItems) {
      await Promise.all(document.checklistItems.map(async (checklistItem) => {
        let userView: UserView;
        if (checklistItem.complete && checklistItem.completedBy) {
          userView = await this.afs.doc(`userViews/${checklistItem.completedBy}`).valueChanges().pipe(first()).toPromise() as UserView;
        }
        const color = checklistItem.complete ? '#009836' : '#f44336';
        checklistItemList.ul.push({
          text: [{
            text: `${checklistItem.name} - ${checklistItem.complete ? 'COMPLETED' : 'INCOMPLETE'}
            ${checklistItem.complete ? ` by ${userView.firstName} ${userView.lastName} on ${moment(checklistItem.completedOn.toDate()).format("llll")}` : ''}`,
            color: color,
            bold: false,
          }],
          margin: [0, 5, 0, 5]
        });
        return;
      }));
      dd.content.push(checklistItemList)
    }
  }
  getCommentStatusIcon(comment) {
    if (comment) {
      switch (comment.status) {
        case AuditTrailDocumentSectionCommentStatuses.closed:
          return `<i class="material-icons mat-text-accent">check_circle</i>`;
        case AuditTrailDocumentSectionCommentStatuses.cancelled:
          return `<i class="material-icons mat-text-disabled"">cancel</i>`;
        case AuditTrailDocumentSectionCommentStatuses.ready:
          return `<i class="material-icons mat-text-primary">rate_review</i>`;
        case AuditTrailDocumentSectionCommentStatuses.open:
          return `<i class="material-icons mat-text-warn">warning</i>`;
      }
    }
  }
  async getCommentAssigneeName(comment: AuditTrailDocumentSectionComment) {
    if (comment.assignee) {
      const userView = await this.afs.doc(`userViews/${comment.assignee}`).valueChanges().pipe(first()).toPromise() as UserView;
      return userView ? `${userView.firstName} ${userView.lastName}` : '';
    } else {
      return '';
    }
  }

  async getCommentApprovalInfo(comment: AuditTrailDocumentSectionComment) {
    if (comment.approvedBy) {
      const userView = await this.afs.doc(`userViews/${comment.approvedBy}`).valueChanges().pipe(first()).toPromise() as UserView;
      return userView ?
        `${userView.firstName} ${userView.lastName} on ${comment.approvedOn ? moment(comment.approvedOn.toDate()).format('M/D/YY') : ''}`
        : '';
    } else {
      return '';
    }
  }

  async getCommentCreatorName(comment: AuditTrailDocumentSectionComment) {
    if (comment.creator) {
      const userView = await this.afs.doc(`userViews/${comment.creator}`).valueChanges().pipe(first()).toPromise() as UserView;
      return userView ? `${userView.firstName} ${userView.lastName}` : '';
    } else {
      return '';
    }
  }
  getCommentStatus(comment) {
    if (comment) {
      switch (comment.status) {
        case AuditTrailDocumentSectionCommentStatuses.closed:
          return 'Closed';
        case AuditTrailDocumentSectionCommentStatuses.open:
          return 'Open';
        case AuditTrailDocumentSectionCommentStatuses.cancelled:
          return 'Cancelled';
        case AuditTrailDocumentSectionCommentStatuses.ready:
          return 'Ready for Review';
      }
    }
  }
}
