import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuditTrailDocumentListComponent} from './audit-trail-document-list/audit-trail-document-list.component';
import {AuditTrailDocumentComponent} from './audit-trail-document/audit-trail-document.component';
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {UploadReviewDocumentComponent} from './upload-audit-trail-document/upload-review-document.component';
import {AuditTrailDocumentCommentComponent} from './audit-trail-document-comment/audit-trail-document-comment.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {DsSharedModule} from "../../../shared/ds-shared.module";
import {AvatarModule} from "../../avatar/avatar.module";
import {DirectivesModule} from "../../../directives/directives.module";
import {TabGroupModule} from "../../tab-group/tab-group.module";
import { AuditTrailDocumentSectionComponent } from './audit-trail-document-section/audit-trail-document-section.component';
import {AuditTrailViewerComponent} from "./audit-trail-viewer/audit-trail-viewer.component";
import { AuditTrailDocumentFilesComponent } from './audit-trail-document-files/audit-trail-document-files.component';
import { NgxSmoothDnDModule } from 'ngx-smooth-dnd';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { AuditTrailChecklistTemplateComponent } from './audit-trail-checklist-template/audit-trail-checklist-template.component';
import { AuditTrailChecklistTemplateListComponent } from './audit-trail-checklist-template-list/audit-trail-checklist-template-list.component';
import { AuditTrailsUserSettingsComponent } from './audit-trails-user-settings/audit-trails-user-settings.component';
import { DialogModule } from 'primeng/primeng';
import { AuditTrailViewerCommentsComponent } from './audit-trail-viewer/comments/audit-trail-viewer-comments.component';
import { AuditTrailViewerMessagesComponent } from './audit-trail-viewer/messages/audit-trail-viewer-messages.component';
import { AuditTrailViewerSectionsComponent } from './audit-trail-viewer/sections/audit-trail-viewer-sections.component';
import { MessagesWindowComponent } from './audit-trail-viewer/messages/messages-window/messages-window.component';

@NgModule({
  declarations: [
    AuditTrailDocumentListComponent,
    AuditTrailDocumentComponent,
    UploadReviewDocumentComponent,
    AuditTrailDocumentCommentComponent,
    MessagesWindowComponent,
    AuditTrailDocumentSectionComponent,
    AuditTrailDocumentFilesComponent,
    AuditTrailChecklistTemplateComponent,
    AuditTrailChecklistTemplateListComponent,
    AuditTrailsUserSettingsComponent,

    AuditTrailViewerComponent,
    AuditTrailViewerSectionsComponent,
    AuditTrailViewerCommentsComponent,
    AuditTrailViewerMessagesComponent
  ],
  exports: [
    UploadReviewDocumentComponent,
    AuditTrailDocumentCommentComponent,
    AuditTrailDocumentSectionComponent,
    AuditTrailDocumentComponent,
    AuditTrailDocumentFilesComponent,
    AuditTrailChecklistTemplateComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    DragDropModule,
    CdkScrollableModule,
    MalihuScrollbarModule.forRoot(),
    PerfectScrollbarModule,
    DsSharedModule,
    DirectivesModule,
    AvatarModule,
    TabGroupModule,
    NgxSmoothDnDModule,
    DialogModule
  ]
})
export class AuditTrailModule {
}
