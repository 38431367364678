import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWindowComponent } from './chat-window.component';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { MaterialModule } from 'app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AvatarModule } from '../avatar/avatar.module';
import { ChatWindowMessagesComponent } from './chat-window-messages/chat-window-messages.component';



@NgModule({
  declarations: [ChatWindowComponent, ChatWindowMessagesComponent],
  exports: [ChatWindowComponent, ChatWindowMessagesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MalihuScrollbarModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AvatarModule
  ]
})
export class ChatWindowModule { }
