import { AuditTrailChecklistTemplateDialogComponent } from './../../../../dialogs/audit-trail-checklist-template-dialog/audit-trail-checklist-template-dialog.component';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Pipe } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuditTrailDocumentChecklistTemplate, Project, UserRoles, UserView } from '@deliver-sense-librarian/data-schema';
import { Subject, from } from 'rxjs';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeUntil, distinctUntilChanged, combineAll } from 'rxjs/operators';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { ConfirmDialogComponent } from '../../../../dialogs/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { tableExpandAnimation } from 'app/shared/ds-constant';
import { scrollbarOptions } from '../../../../shared/ds-constant';
import * as _ from 'lodash';

@Component({
  selector: 'app-audit-trail-checklist-template-list',
  templateUrl: './audit-trail-checklist-template-list.component.html',
  styleUrls: ['./audit-trail-checklist-template-list.component.scss'],
  animations: tableExpandAnimation
})
export class AuditTrailChecklistTemplateListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<AuditTrailDocumentChecklistTemplate>;
  public displayedColumns: string[] = ['name', 'description', 'createdBy', 'updated', 'copy', 'expandTrigger', 'delete'];
  public expandedElement: any | null;
  public uiState: UiState;
  public pageSizeOptions = [5, 10, 25, 50];
  public auditTrailDocumentChecklistTemplates: AuditTrailDocumentChecklistTemplate[] = [];
  public projects: Project[] = [];
  public selectedProject = new FormControl('');
  public userViews: UserView[] = [];
  public scrollbarOptions = scrollbarOptions;
  private destroy$ = new Subject();

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState = uiState$;
          this.getUserProjects();
          this.selectedProject.valueChanges.subscribe(projectId$ => {
            if (!!projectId$) {
              this.getProjectChecklists(projectId$);
            }
          })
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getUserProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.viewer, UserRoles.contributor])
      .pipe(takeUntil(this.destroy$))
      .subscribe(projects$ => {
        this.projects = (projects$) as Project[];
        this.projects.sort((a, b) => {
          return moment(a.dateUpdated.toDate()).isAfter(b.dateUpdated.toDate()) ? -1 : 1;
        });
        if (this.projects.length > 0 && !this.selectedProject.value) {
          this.selectedProject.patchValue(this.projects[0].id);
          this.selectedProject.updateValueAndValidity();
        }
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  public getProjectChecklists(projectId) {
    this.afs.collection('auditTrailDocumentChecklistTemplates', ref => ref
    .where('project', '==', projectId)
    .where('client', '==', this.uiState.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(atChecklistTemplates$ => {
        this.auditTrailDocumentChecklistTemplates = FirestoreUtilities.mapToType(atChecklistTemplates$);
        this.auditTrailDocumentChecklistTemplates.sort((a, b) => {
          return moment(a.dateUpdated.toDate()).isAfter(moment(b.dateUpdated.toDate)) ? -1 : 1;
        })
        this.tableData = new MatTableDataSource(this.auditTrailDocumentChecklistTemplates);
        this.getCreatorUserViews();
      }, (e) => {
        console.log(e.message);
      })
  }

  private getCreatorUserViews() {
    const uniqueCreatorTemplates = _.uniqBy(this.auditTrailDocumentChecklistTemplates, 'creator');
    const userViewRequests = uniqueCreatorTemplates.map(template => {
      return this.afs.doc(`userViews/${template.user}`).snapshotChanges()
    })
    from(userViewRequests)
    .pipe(combineAll(), takeUntil(this.destroy$))
    .subscribe(userViews$ => {
      this.userViews = FirestoreUtilities.mergeToType(userViews$);
    })
  }

  getCreatorName(template) {
    const userView = this.userViews.find(userView => userView.id === template.user);
    if (userView) {
      return `${userView.firstName} ${userView.lastName}`;
    }
    return '';
  }

  public editAtChecklistTemplate(atChecklistTemplate: AuditTrailDocumentChecklistTemplate) {
    this.dialog.open(AuditTrailChecklistTemplateDialogComponent, {
      disableClose: true,
      data: {
        auditTrailDocumentChecklistTemplate: atChecklistTemplate
      }
    })
  }

  public createAtChecklistTemplate() {
    this.dialog.open(AuditTrailChecklistTemplateDialogComponent, {
      data: {
        auditTrailDocumentChecklistTemplate: new AuditTrailDocumentChecklistTemplate(),
      }
    })
  }

  public copyTemplateToOtherProject(atChecklistTemplate: AuditTrailDocumentChecklistTemplate) {
    this.dialog.open(AuditTrailChecklistTemplateDialogComponent, {
      data: {
        auditTrailDocumentChecklistTemplate: atChecklistTemplate,
        copy: true
      }
    })
  }
  public deleteAtChecklistTemplate(atChecklistTemplate: AuditTrailDocumentChecklistTemplate) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete Checklist Template',
        message: 'Are you sure you want delete this template? This will not affect any Audit Trails that used the template to create a template.',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(true, 'Deleting template...')
        await this.afs.doc(`auditTrailDocumentChecklistTemplates/${atChecklistTemplate.id}`).delete();
        this.loadingService.isLoading(false);
        this.snackBar.open('Successfully deleted the document.', 'Dismiss', {
          duration: 5000
        });
      }
    });
  }
}
