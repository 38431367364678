import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reason-prompt-dialog',
    templateUrl: './reason-prompt-dialog.component.html',
    styleUrls: ['./reason-prompt-dialog.component.scss']
})
export class ReasonPromptDialogComponent implements OnInit {
    reason = new FormControl();
    title: string;
    placeholder: string;
    constructor(public dialogRef: MatDialogRef<ReasonPromptDialogComponent>,
                private afs: AngularFirestore,
                public snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.placeholder = data.placeholder;
        if (data.defaultValue) {
            this.reason.patchValue(data.defaultValue);
        }
    }

    ngOnInit() {
    }

    submitReason() {
        if (this.reason) {
            this.dialogRef.close(this.reason.value);
        } else {
            this.snackBar.open('Please provide a reason.', 'Dismiss', {
                duration: 5000
            })
        }
    }
}
