import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-3pd-report-export-dialog',
  templateUrl: './3pd-report-export-dialog.component.html',
  styleUrls: ['./3pd-report-export-dialog.component.scss']
})
export class ThirdPartyReportExportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ThirdPartyReportExportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
