import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportComponent} from './report/report.component';
import {ReportsComponent} from "./reports/reports.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../../material.module";
import {TransactionDrillDownComponent} from './transaction-drill-down/transaction-drill-down.component';
import {ReconciliationTableComponent} from './reconciliation-table/reconciliation-table.component';
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {DsSharedModule} from "../../../shared/ds-shared.module";
import {RouterModule} from "@angular/router";
import {TransactionDataUploadComponent} from './transaction-data-upload/transaction-data-upload.component';
import {WebDataRocksModule} from "../../../webdatarocks/webdatarocks.module";
import {LocationSearchModule} from "../../location-search/location-search.module";
import { ThirdPartyReportSettingsComponent } from './third-party-report-settings/third-party-report-settings.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ChartsModule } from 'ng2-charts';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { ErrorSummaryComponent } from './error-summary/error-summary.component';
import { NewReportComponent } from './new-report/new-tpd-report.component';
import { DailyDrillDownComponent } from './daily-drill-down/daily-drill-down.component';
import { StatusDrillDownComponent } from './status-drill-down/status-drill-down.component';

@NgModule({
  declarations: [
    ReportsComponent,
    ReportComponent,
    TransactionDrillDownComponent,
    ReconciliationTableComponent,
    TransactionDataUploadComponent,
    ThirdPartyReportSettingsComponent,
    AnalyticsComponent,
    ErrorSummaryComponent,
    NewReportComponent,
    DailyDrillDownComponent,
    StatusDrillDownComponent,
  ],
  exports: [
    TransactionDrillDownComponent,
    ReconciliationTableComponent,
    AnalyticsComponent,
    ErrorSummaryComponent,
    NewReportComponent,
    StatusDrillDownComponent,
  ],
  imports: [
    ReactiveFormsModule,
    ChartsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    MatSelectModule,
    MatTableModule,
    MatTreeModule,
    MatButtonModule,
    MatCardModule,
    MatSliderModule,
    MatInputModule,
    CommonModule,
    MalihuScrollbarModule.forRoot(),
    DsSharedModule,
    RouterModule,
    WebDataRocksModule,
    LocationSearchModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class ThirdPartyDeliveryReportsModule {
}
