import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { downloadDataAsFile, tableExpandAnimation, tablescrollbarOptions } from "../../../../shared/ds-constant";
import { MatDialog } from "@angular/material/dialog";
import { TpdReportFieldSelectionDialogComponent } from "../../../../dialogs/tpd-report-field-selection-dialog/tpd-report-field-selection-dialog.component";
import * as moment from "moment";
import * as _ from "lodash";
import { Papa } from 'ngx-papaparse';
import { Client3pdConfiguration, reconciliationTableFields, ThirdPartyReconciliationLocationData } from '@deliver-sense-librarian/data-schema';
import { FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { filter } from 'rxjs/operators';
import { Row } from 'primeng/primeng';

@Component({
  selector: 'app-reconciliation-drill-down',
  templateUrl: './reconciliation-table.component.html',
  styleUrls: ['./reconciliation-table.component.scss'],
  animations: tableExpandAnimation,
  encapsulation: ViewEncapsulation.None,
})
export class ReconciliationTableComponent implements OnInit {
  @Input() locationReportData: ThirdPartyReconciliationLocationData[];
  @Input() thirdParties: any;
  @Input() locations: any;
  @Input() reportHeaderCard: ElementRef;
  @Input() client3pdConfiguration: Client3pdConfiguration;
  @Input() reportTitle: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public onlyShowErrors = false;
  public dataFlattened = false;
  public possibleFields = reconciliationTableFields;
  public fixedFields = [
    'Location Id',
    '3PD'
  ];
  @Input() selectedFields = [
    ...this.fixedFields,
    'State/Province',
    'Sales Variance',
    'Tax Variance',
    'Suggested Tax Adjustment',
    'Total Fees',
    'Remittance Variance',
  ];
  public scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;
  private flattenedData: any[] = [];

  constructor(private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private papa: Papa
  ) {
  }

  ngOnInit() {
    this.initializeTable();
  }
  private async initializeTable() {
    this.setInitialFieldSelection();
    // this.flattenedData = this.flattenReportData();
    this.dataFlattened = true;
    this.tableData = new MatTableDataSource(this.locationReportData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filterPredicate =
      (data: Location, filtersJson: string) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);
        filters.forEach(filter => {
          const val = data[filter.id] === null || data[filter.id] === undefined ? '' : data[filter.id];
          if (filter.value === true) { // handle filter for active
            matchFilter.push(val === filter.value);
          } else if (val === 0) {
            matchFilter.push(val === filter.value);
          } else if (+val) {
            matchFilter.push(val === filter.value);
          } else {
            matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
          }
        });
        return matchFilter.indexOf(true) > -1;
      };
    this.cdr.detectChanges();

  }
  private setInitialFieldSelection() {
    if (this.client3pdConfiguration && this.client3pdConfiguration.defaultFields && this.client3pdConfiguration.defaultFields.length > 0) {
      this.selectedFields = this.client3pdConfiguration.defaultFields;
    }
  }

  private flattenReportData() {
    return this.locationReportData.map((row: ThirdPartyReconciliationLocationData) => {
      const location = this.locations.find(_l => _l.locationId === row.locationId);
      return {
        'Location Id': row.locationId,
        '3PD': row.thirdPartyName,
        'Location Name': row.locationName,
        'State/Province': row.stateProvince,
        'MF Applicable': row.mfTaxApplicable,
        'POS Gross Sales': row.posGrossSales,
        'POS Sales Adjustments': row.posSalesAdjustments,
        'POS Net Sales': row.posNetSales,
        '3PD Gross Sales': row.thirdPartyGrossSales,
        '3PD Sales Adjustments': row.thirdPartySalesAdjustments,
        '3PD Net Sales': row.thirdPartyNetSales,
        'Sales Variance': row.salesVariance,
        'POS Tips': row.posTips,
        '3PD Tips': row.thirdPartyTips,
        'POS Other Charges': row.posOtherCharges,
        'POS Other Revenue': row.posOtherRevenue,
        '3PD Other Charges': row.thirdPartyOtherCharges,
        '3PD Other Revenue': row.thirdPartyOtherRevenue,
        'POS Gross Tax': row.posGrossTax,
        'POS Tax Adjustments': row.posTaxAdjustments,
        'POS Net Tax': row.posNetTax,
        '3PD Gross Tax': row.thirdPartyGrossTax,
        '3PD Tax Adjustments': row.thirdPartyTaxAdjustments,
        '3PD Net Tax': row.thirdPartyNetTax,
        'Tax Variance': row.taxVariance,
        'Location Tax Rate': row.locationTaxRate,
        '3PD Effective Tax Rate': row.thirdPartyEffectiveTaxRate,
        'Tax Rate Variance': row.taxRateVariance,
        'Market Facilitator Tax': row.marketFacilitatorTax,
        'Effective MF Tax Rate': row.effectiveMfRate,
        'Client Tax Responsibility': row.clientTaxResponsibility,
        'Client Tax Responsibility Rate': row.effectiveClientTaxResponsibilityRate,
        'Suggested Tax Adjustment': row.suggestedTaxAdjustment,
        'Total Fees': row.totalFees,
        'Delivery Fees': row.deliveryFees,
        'Effective Delivery Fee Rate': row.effectiveDeliveryFeeRate,
        'Other Fees': row.otherFees,
        'Expected Remittance': row.expectedRemittance,
        'Actual Remittance': row.actualRemittance,
        'Remittance Variance': row.remittanceVariance,
        'Sales Errors': !!row['salesErrors'],
        'Tax Errors': !!row['taxErrors'],
        'Tax Rate Errors': !!row['taxRateErrors'],
        'Remittance Errors': !!row['remittanceErrors']
      }
    });
  }

  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }

  getThirdPartyName(thirdPartyId: string) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  exportReport() {
    const filteredData = this.flattenReportData().map(row => {
      const filteredFieldsObject = {}
      this.selectedFields.forEach(fieldName => {
        filteredFieldsObject[fieldName] = row[fieldName];
      });
      return filteredFieldsObject;
    })
    const results = this.papa.unparse(filteredData,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `${this.reportTitle}-Reconciliation-${moment().format('M.D.YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }

  public applyFilter(filterValue: string) {
    const tableFilters = [
      { id: 'Location Id', value: filterValue },
      { id: '3PD', value: filterValue },
      { id: 'Location Name', value: filterValue },
      { id: 'State/Province', value: filterValue },
      { id: 'POS Sales', value: filterValue },
      { id: '3PD Sales', value: filterValue },
      { id: 'Sales Variance', value: filterValue },
      { id: 'POS Tips', value: filterValue },
      { id: '3PD Tips', value: filterValue },
      { id: 'POS Other Charges', value: filterValue },
      { id: '3PD Other Charges', value: filterValue },
      { id: 'POS Tax', value: filterValue },
      { id: '3PD Tax', value: filterValue },
      { id: 'Tax Variance', value: filterValue },
      { id: 'Location Tax Rate', value: filterValue },
      { id: '3PD Effective Tax Rate', value: filterValue },
      { id: 'Tax Rate Variance', value: filterValue },
      { id: 'Market Facilitator Tax', value: filterValue },
      { id: 'Client Tax Responsibility', value: filterValue },
      { id: 'Suggested Tax Adjustment', value: filterValue },
      { id: 'Total Fees', value: filterValue },
      { id: 'Delivery Fees', value: filterValue },
      { id: 'Other Fees', value: filterValue },
      { id: 'Expected Remittance', value: filterValue },
      { id: 'Actual Remittance', value: filterValue },
      { id: 'Remittance Variance', value: filterValue }
    ];
    this.tableData.filter = JSON.stringify(tableFilters);
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  public toggleOnlyShowErrorLocations(event: MatSlideToggleChange) {
    const tableFilters = [
      {
        id: 'taxErrors',
        value: true
      },
      {
        id: 'salesErrors',
        value: true
      },
      {
        id: 'taxRateErrors',
        value: true
      },
      {
        id: 'remittanceErrors',
        value: true
      }
    ];
    if (event.checked) {
      this.tableData.filter = JSON.stringify(tableFilters);
      if (this.tableData.paginator) {
        this.tableData.paginator.firstPage();
      }
    } else {
      this.tableData.filter = '';
      if (this.tableData.paginator) {
        this.tableData.paginator.firstPage();
      }
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (350 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }
  getRoundedRate(rate) {
    return ((+rate ? (+rate * 100) : 0)).toFixed(2);
  }
  selectFields() {
    const fieldSelectDialogRef = this.dialog.open(TpdReportFieldSelectionDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        selectedFields: this.selectedFields
      }
    });
    fieldSelectDialogRef.afterClosed().subscribe(updatedFieldSelection$ => {
      if (updatedFieldSelection$) {
        const updateFieldSelection = [
          ...this.fixedFields,
          ...updatedFieldSelection$
            .map(field => field.item)
            .filter(fieldName => this.possibleFields.indexOf(fieldName) > -1)
        ];
        this.selectedFields = _.sortBy(updateFieldSelection, (item) => {
          return this.possibleFields.indexOf(item);
        });
      }
    });
  }

  getFunctionDefinition(row: ThirdPartyReconciliationLocationData, functionName: string) {
    const location = this.locations.find(l => l.locationId === row.locationId);
    if (location) {
      switch (functionName) {
        case 'salesVariance':
          return `
        <h4>Sales Variance Calculation</h4>
        <div class="function-def">
          POS Net Sales - 3PD Net Sales = Sales Variance
        </div>
        <div class="function-def">
          (${row.posNetSales} - ${row.thirdPartyNetSales} = ${row.salesVariance})
        </div>
        `
          break;
        case 'taxVariance':
          return `
        <h4>Tax Variance Calculation</h4>
        <div class="function-def">
          POS Net Tax - 3PD Net Tax = Tax_Variance
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.thirdPartyNetTax} = ${row.taxVariance})
        </div>
        `
          break;
        case 'locationTaxRate':
          return `
        <h4>Location Tax Rate Calculation</h4>
        <div class="function-def">
          State_Tax_Rate + County_Tax_Rate + City_Tax_Rate + Special_Tax_Rate = Location_Tax_Rate
        </div>
        <div class="function-def">
          (${location.stateTaxRate}% + ${location.countyTaxRate}% + ${location.cityTaxRate}% + ${location.specialTaxRate}% = ${row.locationTaxRate * 100}%)
        </div>
        `
        case 'thirdPartyEffectiveTaxRate':
          return `
        <h4>Third Party Effective Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Net Tax ÷ 3PD Net Sales = 3PD Effective Tax Rate
        </div>
        <div class="function-def">
        (${row.thirdPartyNetTax} ÷ ${row.thirdPartyNetSales} = ${row.thirdPartyEffectiveTaxRate})
        </div>
        `
        case 'effectiveMfTaxRate':
          return `
        <h4>Effective Market Facilitator Tax Rate Calculation</h4>
        <div class="function-def">
          3PD Market Facilitator Tax ÷ 3PD Net Sales = Effective MF Tax Rate
        </div>
        <div class="function-def">
        (${row.marketFacilitatorTax} ÷ ${row.thirdPartyNetSales} = ${row.effectiveMfRate})
        </div>
        `
        case 'clientTaxResponsibility':
          return `
        <h4>Client Tax Responsibility Calculation</h4>
        <div class="function-def">
          3PD Net Tax + Market Facilitator Tax = Client Tax Responsibility
        </div>
        <div class="function-def">
          (${row.thirdPartyNetTax} + ${row.marketFacilitatorTax} = ${row.clientTaxResponsibility})
        </div>
        `
        case 'clientTaxResponsibilityRate':
          return `
        <h4>Client Tax Responsibility Rate Calculation</h4>
        <div class="function-def">
          Client Tax Responsibility ÷ 3PD Net Sales = Client Tax Responsibility Rate
        </div>
        <div class="function-def">
        (${row.clientTaxResponsibility} ÷ ${row.thirdPartyNetSales} = ${row.effectiveClientTaxResponsibilityRate})
        </div>
        `
        case 'suggestedTaxAdjustment':
          return `
        <h4>Suggested Tax Adjustment</h4>
        <div class="function-def">
          POS Net Tax - Client Tax Responsibility = Suggested Tax Adjustment
        </div>
        <div class="function-def">
          (${row.posNetTax} - ${row.clientTaxResponsibility} = ${row.suggestedTaxAdjustment})
        </div>
        `
        case 'expectedRemittance':
          const locationReport = this.locationReportData.find(lrd => lrd.reportId === row.reportId);
          return `
        <h4>Expected Remittance Calculation</h4>
        <div class="function-def">
          POS Net Sales + POS Net Tax + POS Tips + POS Other Revenue - (Delivery Fees + 3PD MF Tax + Other Fees + 3PD Other Charges) = Expected Remittance
        </div>
        <div class="function-def">
          ${row.posNetSales} + ${row.posNetTax} + ${row.posTips} + ${row.posOtherRevenue} - (${row.deliveryFees} + ${row.marketFacilitatorTax} + ${row.otherFees} + ${row.thirdPartyOtherCharges}) = ${row.expectedRemittance}
        </div>
        `
        case 'deliverFeeRate':
          return `
        <h4>Effective Delivery Fee Rate Calculation</h4>
        <div class="function-def">
          Delivery Fees Total ÷ 3PD Net Sales = Effective Delivery Fee Rate
        </div>
        <div class="function-def">
        (${row.deliveryFees} ÷ ${row.thirdPartyNetSales} = ${row.effectiveDeliveryFeeRate})
        </div>
        `
        case 'remittanceVariance':
          return `
        <h4>Remittance Variance Calculation</h4>
        <div class="function-def">
        Expected Remittance - Actual Remittance = Remittance Variance
        </div>
        <div class="function-def">
          (${row.expectedRemittance} - ${row.actualRemittance} = ${row.remittanceVariance})
        </div>
        `
      }
    }
  }
}
