import {Component, Inject, OnInit} from '@angular/core';
import {AuditTrailDocument, AuditTrailDocumentSection} from "@deliver-sense-librarian/data-schema";
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-audit-trail-document-section-dialog',
  templateUrl: './audit-trail-document-section-dialog.component.html',
  styleUrls: ['./audit-trail-document-section-dialog.component.scss']
})
export class AuditTrailDocumentSectionDialogComponent implements OnInit {
  public section: AuditTrailDocumentSection;
  public documentId: string;

  constructor(public dialogRef: MatDialogRef<AuditTrailDocumentSectionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.section = data.section;
    this.documentId = data.documentId;
  }

  ngOnInit() {
  }


}
