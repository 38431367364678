<mat-card>
  <mat-card-header>
    <mat-card-title><h2>Unsaved Changes</h2></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      You have unsaved changes. Are you sure you want to leave?
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row" fxLayoutWrap>
      <button mat-raised-button color="accent" (click)="dialogRef.close(true)">Discard Changes</button>
      <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    </div>
  </mat-card-actions>
</mat-card>
