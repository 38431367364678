<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div>
  <h2>Select Attachment</h2>
  <input type="file"
             id="image-upload-button-partial"
             fxHide
             (change)="handleFileSelect($event)"/>
      <label for="image-upload-button-partial"
             #documentUploadLabel>
        <button mat-raised-button color="primary" (click)="documentUploadLabel.click()">
          Select File
          <mat-icon>attachment</mat-icon>
        </button>
      </label>
</div>
