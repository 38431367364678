import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataUploadsListComponent } from './data-uploads-list/data-uploads-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/material.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { DsSharedModule } from 'app/shared/ds-shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DataUploadsListComponent
  ],
  exports: [
    DataUploadsListComponent
  ],
  imports: [
    ReactiveFormsModule,
    ChartsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    MalihuScrollbarModule.forRoot(),
    DsSharedModule,
    RouterModule,
  ]
})
export class DataUploadModule { }
