import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DocumentUploadModule} from "../../document-upload/document-upload.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LocationSearchModule } from '../../location-search/location-search.module';
import { TransactionLookupReportComponent } from './transaction-lookup-report/transaction-lookup-report.component';
import { TransactionLookupComponent } from './transaction-lookup.component';

@NgModule({
    declarations: [TransactionLookupReportComponent, TransactionLookupComponent],
    exports: [TransactionLookupReportComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        DocumentUploadModule,
        LocationSearchModule
    ]
})
export class TransactionLookupModule {
}
