  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-card *ngIf="configForm" fxFlex>
      <mat-card-header>
        <mat-card-title>
          <h2>3PD Report Configurations</h2>
        </mat-card-title>
        <mat-card-subtitle>
          <h6>Configure your thresholds for variances and other in 3PD reconciliation report settings.</h6>
        </mat-card-subtitle>
      </mat-card-header>
      <form [formGroup]="configForm" fxLayout="column">
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Tax Variance Threshold</mat-label>
            <mat-icon matPrefix fontSet="fas" fontIcon="fa-dollar-sign"></mat-icon>
            <input type="number" matInput step="100" min="0" formControlName="taxVarianceThreshold">
          </mat-form-field>
          <!-- <div>
        <mat-slider formControlName="taxVarianceThreshold" thumbLabel [displayWith]="formatLabel" tickInterval="100"
          min="1" max="10000"></mat-slider>
      </div> -->
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Sales Variance Threshold</mat-label>
            <mat-icon matPrefix fontSet="fas" fontIcon="fa-dollar-sign"></mat-icon>
            <input type="number" matInput step="100" min="0" formControlName="salesVarianceThreshold">
          </mat-form-field>
          <!-- <div>
        <mat-slider formControlName="salesVarianceThreshold" thumbLabel [displayWith]="formatLabel" tickInterval="100"
          min="1" max="10000"></mat-slider>
      </div> -->
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Remittance Variance Threshold</mat-label>
            <mat-icon matPrefix fontSet="fas" fontIcon="fa-dollar-sign"></mat-icon>
            <input type="number" matInput step="100" min="0" formControlName="remittanceVarianceThreshold">
          </mat-form-field>
          <!-- <div>
        <mat-slider formControlName="salesVarianceThreshold" thumbLabel [displayWith]="formatLabel" tickInterval="100"
          min="1" max="10000"></mat-slider>
      </div> -->
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Tax Rate Variance Threshold</mat-label>
            <input type="number" matInput step="100" min="0" formControlName="taxRateVarianceThreshold">
            <mat-icon matPrefix fontSet="fas" fontIcon="fa-percent"></mat-icon>
          </mat-form-field>
          <!-- <div>
        <mat-slider formControlName="defaultFields" thumbLabel [displayWith]="formatLabelPercent" tickInterval="100" min="1"
          max="10000"></mat-slider>
      </div> -->
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Default Fields</mat-label>
          <mat-select multiple formControlName="defaultFields">
            <mat-option *ngFor="let field of fields" [value]="field"
              [disabled]="field === 'Location Id' || field === '3PD'">{{field}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button mat-raised-button color="accent" type="submit" (click)="save()">
            Save
          </button>
        </div>
      </form>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">

    <mat-card *ngIf="configForm">
      <mat-card-header>
        <mat-card-title>
          <h2>3PD Status Configurations</h2>
        </mat-card-title>
        <mat-card-subtitle>
          <h6>Use these status settings to configure which transaction status types should be excluded from the 3PD to
            POS
            reconciliation report. You will still be able to view transactions with the excluded statuses in the
            statuses
            report. </h6>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group>
          <mat-tab *ngFor="let clientThirdParty of clientThirdParties; let i = index">
            <ng-template matTabLabel>
              <span>{{clientThirdParty.name}}</span>
            </ng-template>
            <ng-template matTabContent>
              <div fxLayout="row">
                <button mat-raised-button color="primary"
                  (click)="addFormArrayGroup(clientThirdParty['clientStatusConfigForm'], clientThirdParty.id)">
                  Add Status
                </button>
                <button mat-raised-button color="accent"
                  (click)="saveClientThirdPartyTransactionStatuses(clientThirdParty['clientStatusConfigForm'])">
                  Save
                </button>
              </div>
              <div MalihuScrollbar [scrollbarOptions]="scrollbarOptions" [style.max-height.px]="500">
                <form *ngIf="clientThirdParty['clientStatusConfigForm']"
                  [formGroup]="clientThirdParty['clientStatusConfigForm']" fxLayout="column">
                  <div formArrayName="statusConfigs" fxLayout="column">
                    <div
                      *ngFor="let statusConfig of clientThirdParty['clientStatusConfigForm'].get('statusConfigs')['controls']; let i = index">
                      <div [formGroupName]="i" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                        <button mat-mini-fab color="warn"
                          (click)="removeFormArrayGroup(i, clientThirdParty['clientStatusConfigForm'])">
                          <mat-icon>delete_forever</mat-icon>
                        </button>
                        <mat-form-field appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select formControlName="status">
                            <mat-option
                              *ngFor="let transactionStatus of clientThirdParty['thirdPartyTransactionStatuses']"
                              [value]="transactionStatus.id">{{transactionStatus.status}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>POS System</mat-label>
                          <mat-select formControlName="posSystem">
                            <mat-option *ngFor="let posSystem of clientPosSystems" [value]="posSystem.id">
                              {{posSystem.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div fxLayout="row">
                          <mat-checkbox class="example-margin" formControlName="inPos">Include in Rec</mat-checkbox>
                          <mat-icon
                            matTooltip="Check this box if this status should be included in the total 3PD sales in the reconciliation report."
                            fontSet="fas" fontIcon="fa-info-circle" color="accent"></mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
