import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { LocationReport, ThirdPartyDeliveryAnalyticsEngine } from "../third-party-delivery-analytics-engine";
import { Location, ThirdParty, ThirdPartyTransaction, TransactionI, PosTransaction } from '@deliver-sense-librarian/data-schema';
import { downloadDataAsFile, tablescrollbarOptions, clientSettingTutorial } from '../../../../shared/ds-constant';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { Papa } from 'ngx-papaparse';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingDialogService } from '../../../../services/loading-dialog.service';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import bluebird from 'bluebird';
import _ from 'lodash';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { first, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { uiState } from '../../../../redux/custom-states/uiState/uiStateReducer';
import { combineLatest, Subject } from 'rxjs';
import { LocationTransactionsByStatusDialogComponent } from 'app/dialogs/location-transactions-by-status-dialog/location-transactions-by-status-dialog.component';

@Component({
  selector: 'app-daily-drill-down',
  templateUrl: './daily-drill-down.component.html',
  styleUrls: ['./daily-drill-down.component.scss']
})
export class DailyDrillDownComponent implements OnInit, OnDestroy {
  @Input() locationId: string;
  @Input() thirdPartyId: string;
  @Input() reportTitle: string;
  @Input() acceptableVariance: number;
  @Input() reportHeaderCard: ElementRef;
  @Input() locationReportData: LocationReport[];
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  @Input() minDate: any;
  @Input() maxDate: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public parametersForm: FormGroup;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'date',
    'posTransactions',
    'tpdTransactions',
    'tpdTransactionsTipOnly',
    'posSales',
    'tpdSales',
    'posTax',
    'tpdTax',
  ];
  public selectedDrillDownThirdParty = new FormControl('', Validators.required);
  public selectedDrillDownLocation = new FormControl('', Validators.required);
  public selectedDate = new FormControl('', Validators.required);
  public scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;
  private transactions: any = [];
  uiState$: any;
  destroy$ = new Subject();

  constructor(private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.setupParametersForm();
        }
      });
  }

  ngOnDestroy(): void {

  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  setupParametersForm() {
    this.parametersForm = this.fb.group(
      {
        selectedLocation: new FormControl('', Validators.required),
        selectedThirdParty: new FormControl('', Validators.required),
        startDate: new FormControl(this.minDate ? this.minDate : '', Validators.required),
        endDate: new FormControl(this.maxDate ? this.maxDate : '', Validators.required)
      }
    )
  }
  runAnalysis() {
    if (this.parametersForm.valid) {
      this.fetchParameterizedReportData()
    } else {
      this.snackBar.open('Please select all required parameters', 'Dismiss', {
        duration: 5000
      })
    }
  }

  getThirdPartyName(thirdPartyId) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getPosTransaction(posTransactionId: string) {
    return this.analyticsEngine.posTransactions.find(t => t.id === posTransactionId);
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.analyticsEngine.thirdPartyTransactions.find(t => t.id === thirdPartyTransactionId);
  }

  exportReport() {
    const results = this.papa.unparse(this.tableData.data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `${this.reportTitle}_Daily-Drill-Down_${moment().format('M/D/YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }

  private async fetchParameterizedReportData() {
    const locationId = this.parametersForm.get('selectedLocation').value;
    const thirdPartyId = this.parametersForm.get('selectedThirdParty').value;
    const start = moment(this.parametersForm.get('startDate').value).startOf('day').toDate();
    const end = moment(this.parametersForm.get('endDate').value).endOf('day').toDate();
    this.loadingService.isLoading(true, `Fetching Data...`);
    this.analyticsEngine.fetchParameterizedReportData(locationId, thirdPartyId, start, end, this.uiState$.client, this.afs)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allTransactions => {
        // group by date
        const dates = this.analyticsEngine.enumerateDaysBetweenDates(start, end);
        const dateGroups = dates.map(date => {
          const startOfDay = moment(date).startOf('day');
          const endOfDay = moment(date).endOf('day');
          const tpdTransactions = allTransactions.tpdTransactions.filter(transaction => {
            const isInDay = moment(transaction.date.toDate()).isSameOrAfter(startOfDay) && moment(transaction.date.toDate()).isSameOrBefore(endOfDay);
            return isInDay;
          });
          const posTransactions = allTransactions.posTransactions.filter(transaction => {
            const isInDay = moment(transaction.date.toDate()).isSameOrAfter(startOfDay) && moment(transaction.date.toDate()).isSameOrBefore(endOfDay);
            return isInDay;
          });
          const tpdDaySales = tpdTransactions.reduce((sum, transaction) => {
            return sum += transaction.sale;
          }, 0)

          const tpdDayTax = tpdTransactions.reduce((sum, transaction) => {
            return sum += transaction.tax;
          }, 0)

          const posDaySales = posTransactions.reduce((sum, transaction) => {
            return sum += transaction.sale;
          }, 0)

          const posDayTax = posTransactions.reduce((sum, transaction) => {
            return sum += transaction.tax;
          }, 0)
          const tpdTransactionsTipOnly = this.getTipOnlyTransactions(tpdTransactions);
          return {
            date: startOfDay.toDate(),
            tpdSales: tpdDaySales.toFixed(2),
            tpdTax: tpdDayTax.toFixed(2),
            posSales: posDaySales.toFixed(2),
            posTax: posDayTax.toFixed(2),
            tpdTransactions: tpdTransactions.length,
            tpdTransactionsTipOnly: tpdTransactionsTipOnly.length,
            posTransactions: posTransactions.length,
            transactions: {pos: posTransactions, tpd: tpdTransactions, tipOnly: tpdTransactionsTipOnly}
          }
        })// End Day Transaction Grouping and totaling
        this.loadingService.isLoading(false);
        this.tableData = new MatTableDataSource(dateGroups);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this.loadingService.isLoading(false);
      }, () => {
        this.loadingService.isLoading(false);
        this.snackBar.open('Oops... something went wrong. Please refresh and try again');
      });
  }
  private getTipOnlyTransactions(thirdPartyTransactions: ThirdPartyTransaction[]) {
    return thirdPartyTransactions.filter(transaction => {
      return transaction.tip > 0 && (
        !transaction.sale &&
        !transaction.saleCorrection &&
        !transaction.tax &&
        !transaction.taxCorrection &&
        !transaction.otherCharges &&
        !transaction.otherRevenue &&
        !transaction.promoFee &&
        !transaction.deliveryFeeTotal &&
        !transaction.pickupFeeTotal
      )
    })
  }
  openLocationStatusTransactionsViewer(transactions) {
    this.dialog.open(LocationTransactionsByStatusDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        transactions,
        locationId: this.selectedDrillDownLocation.value,
      }
    })
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  getSelectedLocationName() {
    const selectedLocation = this.parametersForm.get('selectedLocation').value;
    if (selectedLocation) {
      const location = this.locations.find(_l => _l.locationId === selectedLocation);
      return `${location.name}`
    }
    return '';
  }
  getSelectedThirdPartyName() {
    const selectedThirdParty = this.parametersForm.get('selectedThirdParty').value;
    if (selectedThirdParty) {
      const tpd = this.thirdParties.find(_tp => _tp.id === selectedThirdParty);
      return `${tpd.name}`
    }
    return '';
  }
  clearResults() {
    this.tableData = null;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (280 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }


}
