<div >
    <h1 mat-dialog-title>Please fill out the form below to contact support.</h1>
    <small>
    </small>
    <div mat-dialog-content class="login-content">
        <div fxLayout="row" fxLayoutWrap>
            <form [formGroup]="supportForm" fxLayout="column" fxLayoutWrap fxLayoutAlign="space-around">
                <div fxLayout="row" fxLayoutWrap>
                    <mat-form-field appearance="outline" fxFlex>
                        <input matInput placeholder="Your Email" formControlName="email">
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                        <mat-select placeholder="Contact Reason" formControlName="type">
                            <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
                        </mat-select>
                    </div>
                <div fxLayout="row" fxLayoutWrap>
                    <mat-form-field appearance="outline" fxFlex>
                        <textarea matInput placeholder="Message" rows="3" type="password" formControlName="message"></textarea>
                    </mat-form-field>
                </div>
                <!-- <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center">
                    <re-captcha formControlName="recaptchaReactive" name="captcha"></re-captcha>
                </div> -->
                <div fxLayout="row" fxLayoutWrap fxLayoutAlign="space-around">
                    <div fxFlex="40%">
                        <button mat-raised-button color="primary" (click)="sendMessage()">Send
                        </button>
                    </div>
                    <div fxFlex="40%">
                        <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
