<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>{{project && project.id ? ('Edit ' + project.name) : 'Create Project'}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="space-evenly start" *ngIf="projectForm">
      <form [formGroup]="projectForm">
        <div fxLayout="row">
          <mat-form-field>
            <mat-label>Entity</mat-label>
            <mat-select placeholder="Entity" [formControl]="selectedEntity">
              <mat-option *ngFor="let entity of entities" [value]="entity">
                {{entity.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf="filteredDepartments.length > 0">
          <mat-form-field>
            <mat-label>Department</mat-label>
            <mat-select placeholder="Department" formControlName="department">
              <mat-option *ngFor="let department of filteredDepartments" [value]="department.id">
                {{department.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Project Name</mat-label>
            <input matInput placeholder="Project Name" formControlName="name">
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </form>
      <div fxLayout="row">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <button mat-raised-button [routerLink]="'../'">Cancel</button>
        <button mat-raised-button color="warn" *ngIf="project && project.id" (click)="delete()">Delete</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
