<div fxLayout="row" fxLayoutAlign="space-between start">
  <button id="export-tax-exempt-documentation" mat-raised-button color="accent" (click)="exportDocumentation()">
    Export <mat-icon fontSet="fas" fontIcon="fa-file-export"></mat-icon>
  </button>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Transactions">
  </mat-form-field>
</div>
<mat-card-content>
  <div class="mat-elevation-z8 report-table">
    <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
      <ng-container matColumnDef="documented">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Documented?</mat-header-cell>
        <mat-cell *matCellDef="let row" id="documented-status">
          <ng-container *ngIf="row.exemptionCertificateExists; else noExcert">
            <mat-icon color="accent">check_circle</mat-icon>
          </ng-container>
          <ng-template #noExcert>
            <mat-icon color="warn">warning</mat-icon>
          </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction #</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="mat-table-cell-text">
            {{row.id}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Location</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="exempt-transaction-id-cell">{{row.location}}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>{{row.state}}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.date ? (row.date.toDate() | date:'shortDate') : ''}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sale">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Sale</mat-header-cell>
        <mat-cell *matCellDef="let row">
          ${{row.sale}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tax">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tax</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span [id]="row.tax > 0 ? 'error-tax' : ''" [class.error-text]="row.tax > 0">${{row.tax}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="expandTrigger">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b></b></mat-header-cell>
        <mat-cell *matCellDef="let row" [style.color]="row.color">
          <button mat-icon-button id="expand-exemption-details" color='primary'
            (click)="this.expandedElement = this.expandedElement === row ? null : row">
            <mat-icon>expand</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="expanded-cell-data" fxLayout="row" fxLayoutAlign="space-between start">
              <ng-container *ngIf="element.exemptionCertificateExists; else uploadNewExcemptionCert">
                <div fxFlex fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <h4><b>Exemption Certificate:</b></h4>
                  </div>
                  <div fxLayout="row">
                    <a *ngIf="element.exemptionCertificate.url" [href]="element.exemptionCertificate.url" download
                      [target]="'_blank'">
                      <mat-icon>cloud_download</mat-icon>
                      {{element.exemptionCertificate.fileName}}
                    </a>
                    <button [style.margin-left.px]="5" mat-icon-button color="warn"
                      (click)="deleteExemptionCertificate(element.exemptionCertificate)">
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </div>
                </div>
                <div fxLayout="column" fxFlex>
                <mat-form-field appearance="outline">
                  <mat-label>Notes</mat-label>
                  <textarea matInput maxlength="300"
                    #exemptionCertificateNotes
                    [value]="element.exemptionCertificate.notes ? element.exemptionCertificate.notes : ''"
                    placeholder="Notes">
                  </textarea>
                  <mat-hint>{{exemptionCertificateNotes.value.length}}/300</mat-hint>
                </mat-form-field>
                <ng-container *ngIf="exemptionCertificateNotes.value !== element.exemptionCertificate.notes">
                  <button mat-raised-button color="accent" (click)="updateExemptionCertificateNotes(element.exemptionCertificate, exemptionCertificateNotes.value)">Save</button>
                </ng-container>
              </div>
              </ng-container>
              <ng-template #uploadNewExcemptionCert>
                <div fxLayout="column">
                  <h5>Attach an exemption certificate:</h5>
                  <app-document-upload [storagePath]="storagePath" [compressFile]="true"
                    (imageUploadComplete)="addExemptionCert($event)">
                  </app-document-upload>
                </div>
              </ng-template>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</mat-card-content>
