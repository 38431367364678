<mat-card *ngIf="parametersForm">
  <div #reportHeaderCard>
    <mat-card-header fxLayout="column" fxLayoutAlign="center">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="14px">
        <ng-container *ngIf="existingReport && existingReport.id; else newReportTitle">
          <ng-container *ngIf="!editingName; else editNameTemplate">
            <div fxLayout="column">
              <div fxLayout="row">
                <h2>
                  <u>
                    {{existingReport.name}}
                  </u>
                </h2>
                <button *ngIf="isUserAccessRestricted()" mat-icon-button color="primary" (click)="editingName = true">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <div *ngIf="existingReport && existingReport.lastRun">
                <small>
                  <i>Last Run: {{existingReport.lastRun.toDate() | date: 'short'}}</i>
                </small>
                <ng-container *ngIf="isReportOutOfDate()">
                  <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle" color="warn"
                    matTooltip="3PD report configurations have changed since this report was last run."></mat-icon>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-template #editNameTemplate>
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>
                  Report Name
                </mat-label>
                <input matInput [formControl]="reportName" />
              </mat-form-field>
              <button mat-mini-fab color="accent" (click)="saveReportName(); editingName = false">
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #newReportTitle>
          <h2>Create 3PD Reconciliation Report:</h2>
        </ng-template>

      </div>
      <mat-accordion>
        <ng-container *ngIf="parametersForm">
          <mat-expansion-panel #parameterPanel (expandedChange)="parameterPanel.expanded = $event" [expanded]="true">
            <mat-expansion-panel-header fxLayout="row wrap" fxLayoutAlign="space-between">
              <mat-panel-title fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div>
                    <h3>
                      <b>Parameters:</b>
                    </h3>
                  </div>

                </div>
                <ng-container *ngIf="!parameterPanel.expanded">
                  <div fxFlex="20" fxLayout="column" class="parameter-container">
                    <div><b>Locations</b></div>
                    <div class="parameter-text">
                      {{getLocationName(parametersForm.get('selectedLocations').value[0])}}
                      <ng-container *ngIf="parametersForm.get('selectedLocations').value.length > 1">
                        +{{parametersForm.get('selectedLocations').value.length - 1}} Others
                      </ng-container>
                    </div>
                  </div>
                  <div fxFlex="20" fxLayout="column" class="parameter-container">
                    <div><b>3PDs</b></div>
                    <ng-container
                      *ngFor="let thirdPartyId of parametersForm.get('selectedThirdParties').value; let i = index">
                      {{getThirdPartyName(thirdPartyId)}}{{i !==
                      (parametersForm.get('selectedThirdParties').value.length - 1) ? ', ' : ''}}
                    </ng-container>
                  </div>
                  <div fxFlex="20" fxLayout="column" class="parameter-container">
                    <div><b>Start</b></div>
                    <div class="parameter-text">
                      {{parametersForm.get('startDate').value | date:'shortDate'}}
                    </div>
                  </div>
                  <div fxFlex="20" fxLayout="column" class="parameter-container">
                    <div><b>End</b></div>
                    <div class="parameter-text">
                      {{parametersForm.get('endDate').value | date:'shortDate'}}
                    </div>
                  </div>
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="parametersForm">
              <div fxLayout="row wrap" fxLayoutGap="2px" fxLayoutAlign="space-between center">
                <app-location-search *ngIf="locations.length > 0" [locations]="locations"
                  [selectedLocations]="parametersForm.get('selectedLocations')" [multiple]="true"></app-location-search>
                <mat-form-field appearance="outline" id="select-third-parties" class="report-parameter-form-field">
                  <mat-label>Delivery Partners</mat-label>
                  <mat-select formControlName="selectedThirdParties" multiple>
                    <mat-select-trigger>
                      <ng-container
                        *ngIf="parametersForm.get('selectedThirdParties').value && parametersForm.get('selectedThirdParties').value[0] === 0; else thirdPartiesSelectedText">
                        All 3PD's
                      </ng-container>
                      <ng-template #thirdPartiesSelectedText>
                        {{parametersForm.get('selectedThirdParties').value ?
                        getThirdPartyName(parametersForm.get('selectedThirdParties').value[0]) : ''}}
                        <div *ngIf="parametersForm.get('selectedThirdParties').value?.length > 1"
                          class="example-additional-selection">
                          (+{{parametersForm.get('selectedThirdParties').value.length - 1}} others)
                        </div>
                      </ng-template>
                    </mat-select-trigger>
                    <mat-option (click)="selectAllThirdParties()" [value]="0">Select All</mat-option>
                    <mat-option *ngFor="let thirdParty of thirdParties" [value]="thirdParty.id">{{thirdParty.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div fxLayout="row wrap" fxLayoutGap="2px">
                  <mat-form-field appearance="outline" id="select-start-date" class="report-parameter-form-field">
                    <mat-label>Start Date</mat-label>
                    <input matInput formControlName="startDate" [matDatepicker]="startPicker" placeholder="Start date">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field appearance="outline" id="select-end-date" class="report-parameter-form-field">
                    <mat-label>End Date</mat-label>
                    <input matInput formControlName="endDate" [disabled]="parametersForm.get('startDate').invalid"
                      [min]="parametersForm.get('startDate').value" [max]="getMaxEndDate()" [matDatepicker]="endPicker"
                      placeholder="End date">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <div [style.margin-right.px]="20">
                  <button *ngIf="isUserAccessRestricted()" mat-raised-button color="primary"
                    id="run-third-party-report-button" (click)="runReport(); parameterPanel.close()">
                    <ng-container *ngIf="existingReport.reportData; else newRunText">
                      Refresh Report
                    </ng-container>
                    <ng-template #newRunText>
                      Run Report
                    </ng-template>
                  </button>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </mat-card-header>
  </div>
</mat-card>
<ng-container *ngIf="reportFragments.length > 0 && reportFragments.length === completeFragments.length; else noData">
  <mat-card [style.min-height.px]="200">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div matBadgeSize="medium" matBadgeColor="warn"
            [matBadge]="reconciliationTable && totalErrors > 0 ? totalErrors: null">
            Reconciliation
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-reconciliation-drill-down #reconciliationTable [reportTitle]="existingReport.name"
            [reportHeaderCard]="reportHeaderCard" [locationReportData]="reportData" [locations]="locations"
            [client3pdConfiguration]="client3pdConfiguration" [thirdParties]="thirdParties">
          </app-reconciliation-drill-down>
        </ng-template>
      </mat-tab>
      <mat-tab label="Daily Drill Down">
        <ng-template matTabContent>
          <app-daily-drill-down #dailyDrillDownTable [locationReportData]="reportData"
            [reportTitle]="existingReport.name" [locations]="getReportLocations()"
            [thirdParties]="getReportThirdParties()" [analyticsEngine]="analyticsEngine"
            [client3pdConfiguration]="client3pdConfiguration" [minDate]="getReportMinDate()"
            [maxDate]="getReportMaxDate()">
          </app-daily-drill-down>
        </ng-template>
      </mat-tab>
      <mat-tab label="Transaction Matching">
        <ng-template matTabContent>
          <app-transaction-drill-down [locationReportData]="reportData" [locations]="getReportLocations()"
            [analyticsEngine]="analyticsEngine" [reportHeaderCard]="reportHeaderCard"
            [reportTitle]="existingReport.name" [thirdParties]="getReportThirdParties()" [minDate]="getReportMinDate()"
            [maxDate]="getReportMaxDate()">
          </app-transaction-drill-down>
        </ng-template>
      </mat-tab>
      <mat-tab label="Status Drill Down">
        <ng-template matTabContent>
          <app-status-drill-down [locationReportData]="reportData" [locations]="getReportLocations()"
            [analyticsEngine]="analyticsEngine" [reportHeaderCard]="reportHeaderCard"
            [reportTitle]="existingReport.name" [thirdParties]="getReportThirdParties()" [minDate]="getReportMinDate()"
            [maxDate]="getReportMaxDate()"></app-status-drill-down>
        </ng-template>
      </mat-tab>
      <mat-tab label="Variance Analytics">
        <ng-template matTabContent>
          <app-analytics [locationReportData]="reportData" [locations]="getReportLocations()"
            [thirdParties]="getReportThirdParties()" [analyticsEngine]="analyticsEngine"></app-analytics>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-container>
<ng-template #noData>
  <mat-card [style.min-height.px]="200">
    <div class="no-report-overlay mat-elevation-z10">
      <ng-container *ngIf="reportFragments.length > 0; else selectParamsPrompt">
        <h2>THE RECONCILIATION IS RUNNING. YOU CAN LEAVE THIS PAGE AND CHECK BACK LATER.</h2>
        <mat-progress-bar color="accent" mode="determinate" [value]="getReportProgressPercentage()"></mat-progress-bar>
        <h5>{{getReportProgressPercentage()}}%</h5>
        <ng-container *ngIf="errorReconcilingData">
          <h4 class="mat-text-warn">An error occurred running this reconciliation. Please re-run the report. If this
            problem persists please contact support.</h4>
        </ng-container>
      </ng-container>
      <ng-template #selectParamsPrompt>
        <ng-container *ngIf="!loadingReportFragments; else loadingReport">
          <h2>PLEASE SELECT VALUES TO CREATE REPORT</h2>
        </ng-container>
        <ng-template #loadingReport>
          <mat-spinner color="accent"></mat-spinner>
        </ng-template>
      </ng-template>
    </div>
  </mat-card>
</ng-template>
