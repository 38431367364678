<app-dashboard-widget [title]="'Invoices'" [description]="'Most recent invoices.'"
  [noResultsText]="'No Invoices To Display'" [widgetData]="clientInvoices" [loadingWidgetData]="loadingWidgetData">
  <ng-template let-item>
    <h3 matLine> <a>Created: {{getFormattedDate(item.stripeInvoice?.created)}} - {{item.stripeInvoice?.status}}
        <mat-icon fontSet="fas" fontIcon="fa-external-link-alt"></mat-icon></a></h3>
    <small matLine> <b>Total Due:</b> ${{item.stripeInvoice?.amount_due/100}}</small>
    <div fxLayout="row" class="list-item-divider">
      <mat-divider></mat-divider>
    </div>
  </ng-template>
</app-dashboard-widget>
