<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div>
  <h2>Select Attachment</h2>
  <input type="file" id="image-upload-button-partial" fxHide (change)="handleFileSelect($event)" />
  <label for="image-upload-button-partial" #documentUploadLabel>
    <button mat-raised-button color="primary" (click)="documentUploadLabel.click()">
      Select File
      <mat-icon>attachment</mat-icon>
    </button>
  </label>
  <ng-container *ngIf="fileParsingComplete">
    <div fxLayout="column">
      <div *ngIf="newSections.length > 0; else noNewSections" [style.margin-bottom.px]="12">
        <h3>New Sections Detected:</h3>
        <h5>Please select the sections you would like added to the audit trail document.</h5>
        <div [style.max-height.px]="300" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
          <mat-selection-list #selectedNewSections dense>
            <mat-list-option *ngFor="let section of newSections" [value]="section">
              {{section}}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <ng-template #noNewSections>
        <h5>No New Sections Detected</h5>
      </ng-template>
      <div fxLayout="row">
        <mat-form-field [style.width.%]="100" appearance="outline">
          <mat-label>Set File Name</mat-label>
          <input matInput [formControl]="fileName" />
          <span class="mat-text-muted" matSuffix>.{{fileExtension}}</span>
        </mat-form-field>
      </div>
      <div>
        <button mat-raised-button color="accent" (click)="finishUpload()">
          Attach {{fileName.value}} Now.
        </button>
        <button mat-raised-button color="warn" (click)="clearUpload()">
          Restart
        </button>
      </div>
    </div>
  </ng-container>

</div>
