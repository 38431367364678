<div fxLayout="column" fxFlex="100">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h2>Audit Trail Documents</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <!--        <h6>Manage document review comments and export audit .</h6>-->
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="projects">
      <div id="upload-audit-trail-button">
        <app-upload-review-document (documentCreated)="getInitialSet()"></app-upload-review-document>
      </div>
      <button mat-raised-button color="accent" (click)="createNewDocument()" id="create-new-audit-trail-button">
        <mat-icon>add</mat-icon>
        Create New Trail
      </button>
      <mat-form-field appearance="outline">
        <mat-label>Select Project</mat-label>
        <mat-select [formControl]="selectedProject">
          <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Documents">
      </mat-form-field>
    </div>
    <mat-card-content>
      <div class="mat-elevation-z8 report-table">
        <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="content-cell"><a [routerLink]="'../' + row.id">
                <div [matTooltip]="row.name">
                  {{row.name}}
                </div>
                </a>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="project">
            <mat-header-cell *matHeaderCellDef>Project</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{getProjectName(row)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="num_sections">
            <mat-header-cell *matHeaderCellDef># Sections</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{getDocumentSections(row).length}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef>Creator</mat-header-cell>
            <mat-cell *matCellDef="let row" [matTooltip]="getCreatorInfo(row)">
              <div class="content-cell">{{getCreatorInfo(row)}}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="open_comments">
            <mat-header-cell *matHeaderCellDef>Open Comments</mat-header-cell>
            <mat-cell *matCellDef="let row" id="open-comments-cell">
              {{getNumberOfComments(getDocumentSections(row))}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="approved_comments">
            <mat-header-cell *matHeaderCellDef> Approved Comments</mat-header-cell>
            <mat-cell *matCellDef="let row" id="approved-comments-cell">
              {{getNumberOfComments(getDocumentSections(row), false)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="completed">
            <mat-header-cell *matHeaderCellDef> Completed</mat-header-cell>
            <mat-cell *matCellDef="let row" id="completed-status-cell">
              <ng-container *ngIf="isDocumentReviewCompleted(row); else incompleteReview">
                <mat-icon fontSet="fas" fontIcon="fa-check-circle" color="accent"></mat-icon>
              </ng-container>
              <ng-template #incompleteReview>
                <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" color="warn"></mat-icon>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef> Created On</mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.dateCreated ? (row.dateCreated.toDate() | date:'shortDate') : ''}}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="numberOfProjectDocuments"
          (page)="pageChange($event)"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
