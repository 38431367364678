import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AuditTrailDocument, AuditTrailDocumentSection, AuditTrailDocumentSectionComment, AuditTrailDocumentSectionCommentMessage, User, UserView } from "@deliver-sense-librarian/data-schema";
import { AngularFirestore } from '@angular/fire/firestore';
import { MalihuScrollbarService } from "ngx-malihu-scrollbar";
import { BehaviorSubject, Subject, from, of } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil, switchMap, combineAll, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import * as _ from 'lodash';
import { scrollbarOptions } from 'app/shared/ds-constant';
import { EditFieldDialogComponent } from 'app/dialogs/edit-field-dialog/edit-field-dialog.component';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { AuditTrailsService } from 'app/services/audit-trails.service';

@Component({
  selector: 'app-messages-window',
  templateUrl: './messages-window.component.html',
  styleUrls: ['./messages-window.component.scss']
})
export class MessagesWindowComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {
  @ViewChild('scrollContainer', { read: ElementRef }) scrollContainer: ElementRef;
  @Input() user: User;
  @Input() comment: AuditTrailDocumentSectionComment;
  @Input() section: AuditTrailDocumentSection;
  @Input() document: AuditTrailDocument;
  public messages: AuditTrailDocumentSectionCommentMessage[] = [];
  messagesInit = false;
  scrolledToBottom = false;
  userScrolled = false;
  newMessageSent = false;
  messageInput = new FormControl('', [Validators.required, Validators.minLength(1)]);
  readyToScroll = new BehaviorSubject(false);
  initialLoadingComplete = false;
  scrollbarOptions = scrollbarOptions;
  scrollInit = false;
  destroy$ = new Subject();
  uiState: UiState;
  noMessages = false;
  constructor(
    public atService: AuditTrailsService,
    private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public malihuService: MalihuScrollbarService,
  ) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.uiState = uiState$;
          this.mapSectionCommentMessages();
        }
      });
  }

  ngAfterViewChecked(): void {
    if (!this.messagesInit && this.scrollContainer) {
      this.malihuService.initScrollbar(this.scrollContainer.nativeElement, {
        axis: 'y',
        theme: 'minimal-dark',
        callbacks: {
          // onTotalScrollBack: () => {
          //   this.retrieveOlderMessages();
          // },
          onScroll: () => {
            this.onWindowScroll();
          },
          onTotalScroll: () => {
            this.scrolledToBottom = true;
            this.userScrolled = false;
          }
        }
      });
      this.messagesInit = true;
      this.scrollToBottomMessage(true);
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages && changes.messages.currentValue !== changes.messages.previousValue) {
      this.messagesInit = false;
    }
    if (changes.comment && changes.comment.currentValue !== changes.comment.previousValue) {
      this.mapSectionCommentMessages();
    }
  }
  private mapSectionCommentMessages() {
    if (this.comment) {
      this.afs.collection(`auditTrailDocuments/${this.document.id}/sections/${this.section.id}/comments/${this.comment.id}/messages`)
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$)).subscribe(messages$ => {
          let selectedCommentMessages = FirestoreUtilities.mapToType(messages$);
          selectedCommentMessages = selectedCommentMessages.sort((a, b) => {
            return moment(a.dateCreated.toDate()).isBefore(b.dateCreated.toDate()) ? -1 : 1;
          });
          const uniqueUserMessages = _.uniqBy(selectedCommentMessages, 'from');
          const messageUserViews = uniqueUserMessages.map(message => {
            return this.afs.doc(`userViews/${message['from']}`).snapshotChanges();
          });
          if (messageUserViews.length > 0) {
            return from(messageUserViews)
              .pipe(combineAll(), takeUntil(this.destroy$))
              .subscribe(userViews$ => {
                const userViews = FirestoreUtilities.mergeToType(userViews$).filter(userView => !!userView);
                selectedCommentMessages.forEach(message => {
                  const fromUser = userViews.find(userView => userView.id === message.from);
                  if (fromUser) {
                    message.from = fromUser;
                  }
                });
                this.noMessages = false;
                this.messages = selectedCommentMessages;
                this.messagesInit = false;
              });
          } else {
            this.messages = [];
            this.noMessages = true;
            this.messagesInit = false;
          }
        });

    }
  }
  scrollToBottomMessage(override = false) {
    if (override ||
      (!this.userScrolled && this.scrollContainer && (!this.scrolledToBottom || this.newMessageSent))
    ) {
      try {
        this.malihuService.scrollTo('#messageContainer', 'bottom', { scrollInertia: 0 });
      } catch (e) {
        console.log('error scrolling to bottom of message container: ' + e.message);
      }
    }
  }

  onWindowScroll() {
    const scrollContainer = this.scrollContainer.nativeElement.children[0].children[0];
    const top = Math.abs(scrollContainer.offsetTop);
    const height = scrollContainer.offsetHeight;
    const oldHeight = this.scrollContainer.nativeElement.offsetHeight;
    const userScrollAmount = height - oldHeight - top;
    if (userScrollAmount > oldHeight * 0.50) {
      this.userScrolled = true;
    } else {
      this.userScrolled = false;
    }
  }

  setUserScrolled(event) {
    if (this.scrollInit) {
      this.userScrolled = event;
    }
  }

  async createCommentMessage() {
    if (this.messageInput.valid) {
      const newMessage = new AuditTrailDocumentSectionCommentMessage();
      newMessage.message = this.messageInput.value;
      newMessage.from = this.user.id;
      await this.afs.collection(`auditTrailDocuments/${this.document.id}/sections/${this.section.id}/comments/${this.comment.id}/messages`)
        .add(newMessage.toJSONObject());
      this.newMessageSent = true;
      this.messageInput.setValue(null);
      this.messageInput.markAsPristine();
      this.messageInput.updateValueAndValidity();
    }
  }

  async editMessage(message: AuditTrailDocumentSectionCommentMessage) {
    const dialogRef = this.dialog.open(EditFieldDialogComponent, {
      data: {
        type: 'textarea',
        value: message.message,
        label: 'Message',
        deleteable: true
      }
    });
    dialogRef.afterClosed().subscribe(async (newValue) => {
      if (newValue && newValue.value) {
        await this.afs.doc(`auditTrailDocuments/${this.document.id}/sections/${this.section.id}/comments/${this.comment.id}/messages/${message.id}`)
          .update({
            message: newValue.value,
            dateUpdated: moment().toDate()
          });
        this.snackBar.open('Message updated successfully.', 'Dismiss', {
          duration: 5000
        })
      } else if (newValue && newValue.delete) {
        this.deleteMessage(message);
      }
    })
  }

  private deleteMessage(message) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this message?',
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`auditTrailDocuments/${this.document.id}/sections/${this.section.id}/comments/${this.comment.id}/messages/${message.id}`).delete();
        this.snackBar.open('Report deleted successfully!', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }

  canUserMessage() {
    return this.uiState.projects.find(project => project.id === this.document.project).role > 1;
  }
  trackMessage(index: number, message: AuditTrailDocumentSectionCommentMessage) {
    return message ? message.id : null
  }
}
