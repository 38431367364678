import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAuthService } from "../services/firebase-auth.service";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  loading = false;
  private destroy$ = new Subject();

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private fbAuth: FirebaseAuthService) {
  }

  ngOnInit() {
    this.store.select(state => state.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser) {
          this.router.navigate(['client-selection']);
        }
      });
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

  get form() {
    return this.loginForm.controls;
  }

  async login() {
    if (!this.submitted) {
      this.submitted = true;
      if (this.loginForm.invalid) {
        return;
      }
      // this.loadingService.isLoading(true);
      try {
        await this.fbAuth.login(this.form.email.value, this.form.password.value);
        await this.router.navigate(['client-selection']);
      } catch (e) {
        this.submitted = false;
        this.snackBar.open('Invalid email and/or password', 'Dismiss', {
          duration: 5000
        });
      }
    }
  }
  async loginWithProvider(provider: 'microsoft' | 'google') {
    await this.fbAuth.authWithProvider(provider);
    await this.router.navigate(['client-selection']);
  }
}
