import {FirebaseAuthService} from '../auth/services/firebase-auth.service';
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private afAuth: AngularFireAuth, private router: Router) {
    };

    canActivate() {
        const user_data = JSON.parse(localStorage.getItem('user'));
        if (user_data) {
                return true;
        } else {
            this.router.navigate(['']);
        }
    }
}
