<div fxLayout="row" fxLayoutAlign="end end">
  <button class="dialog-close-button" mat-mini-fab color="warn" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="edit-dialog-content" fxLayout="column">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'input'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{label}}
        </mat-label>
        <input matInput type="text" [formControl]="value" [placeholder]="label" />
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{label}}
        </mat-label>
        <textarea matInput type="text" [formControl]="value" [placeholder]="label"></textarea>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{label}}
        </mat-label>
        <mat-select [formControl]="value" [placeholder]="label">
          <mat-option *ngFor="let option of options" [value]="option.value">{{option.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
      <mat-form-field appearance="outline">
        <mat-checkbox [formControl]="value">{{label}}</mat-checkbox>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <mat-form-field appearance="outline">
        <mat-label>
          {{label}}
        </mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="value">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <div fxLayout="row">
    <button mat-raised-button color="accent" (click)="save()">Save</button>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <button mat-raised-button *ngIf="deleteable" color="warn"  (click)="delete()">Delete</button>
  </div>
</div>
