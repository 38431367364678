import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { LocationReport, ThirdPartyDeliveryAnalyticsEngine } from "../third-party-delivery-analytics-engine";
import { Location, ThirdParty, ThirdPartyTransaction } from '@deliver-sense-librarian/data-schema';
import { downloadDataAsFile, tablescrollbarOptions, clientSettingTutorial } from '../../../../shared/ds-constant';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { Papa } from 'ngx-papaparse';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingDialogService } from '../../../../services/loading-dialog.service';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import * as bluebird from 'bluebird'
import _ from 'lodash';
import { UiState } from 'app/redux/custom-states/uiState/ui-state';
import { combineAll, first, takeUntil, concatMap, concatAll } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { uiState } from '../../../../redux/custom-states/uiState/uiStateReducer';
import { combineLatest, forkJoin, from, scheduled, Subject } from 'rxjs';
import { LocationTransactionsByStatusDialogComponent } from '../../../../dialogs/location-transactions-by-status-dialog/location-transactions-by-status-dialog.component';

@Component({
  selector: 'app-status-drill-down',
  templateUrl: './status-drill-down.component.html',
  styleUrls: ['./status-drill-down.component.scss']
})
export class StatusDrillDownComponent implements OnInit, OnDestroy {
  @Input() locationId: string;
  @Input() thirdPartyId: string;
  @Input() reportTitle: string;
  @Input() reportHeaderCard: ElementRef;
  @Input() locationReportData: LocationReport[];
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  @Input() minDate: any;
  @Input() maxDate: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public parametersForm: FormGroup;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    // dynamically populated based off unique values in transaction array
  ];
  public selectedThirdParty = new FormControl('', Validators.required);
  public selectedLocations = new FormControl([], Validators.required);
  public selectedDate = new FormControl('', Validators.required);
  public scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;
  private transactions: any = [];
  uiState$: any;
  destroy$ = new Subject();
  statusGroups: _.Dictionary<any[]>;
  locationGroups: any;

  constructor(private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private papa: Papa,
    private store: Store<any>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.setupParametersForm();
        }
      });
  }

  ngOnDestroy(): void {

  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  setupParametersForm() {
    this.parametersForm = this.fb.group(
      {
        selectedLocations: new FormControl([], Validators.required),
        selectedThirdParty: new FormControl('', Validators.required),
        startDate: new FormControl(this.minDate ? this.minDate : '', Validators.required),
        endDate: new FormControl(this.maxDate ? this.maxDate : '', Validators.required)
      }
    )
  }
  runAnalysis() {
    if (this.parametersForm.valid) {
      this.fetchParameterizedReportData()
    } else {
      this.snackBar.open('Please select all required parameters', 'Dismiss', {
        duration: 5000
      })
    }
  }

  getThirdPartyName(thirdPartyId) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getPosTransaction(posTransactionId: string) {
    return this.analyticsEngine.posTransactions.find(t => t.id === posTransactionId);
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.analyticsEngine.thirdPartyTransactions.find(t => t.id === thirdPartyTransactionId);
  }

  exportReport() {
    const data = Object.assign(this.tableData.data);
    const results = this.papa.unparse(data,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
      });
    const fileName = `${this.reportTitle}_Status-Drill-Down_${moment().format('M/D/YY-HH:mm:ss')}`;
    downloadDataAsFile(results, fileName, 'csv');
  }

  private async fetchParameterizedReportData() {
    this.transactions = [];
    const locationIds = this.parametersForm.get('selectedLocations').value;
    const thirdPartyId = this.parametersForm.get('selectedThirdParty').value;
    const start = moment(this.parametersForm.get('startDate').value).startOf('day').toDate();
    const end = moment(this.parametersForm.get('endDate').value).endOf('day').toDate();
    this.loadingService.isLoading(true, `Fetching Transactions...`);
    const transactionRequests = await bluebird.mapSeries(locationIds, async (locationId) => {
      return await this.afs.collection('thirdPartyTransactions', ref => ref
        .where('location', '==', locationId)
        .where('thirdParty', '==', thirdPartyId)
        .where('client', '==', this.uiState$.client.id)
        .where('date', '>=', start)
        .where('date', '<=', end)).snapshotChanges().pipe(first()).toPromise();;
    })
    const allTransactions = _.flatten(transactionRequests);
    this.transactions = FirestoreUtilities.mapToType(allTransactions);
    this.statusGroups = _.groupBy(this.transactions, 'status');
    this.locationGroups = _.groupBy(this.transactions, 'location')
    this.displayedColumns = ['location', ...Object.keys(this.statusGroups)];
    const locationStatusSalesRows = this.getLocationStatusSalesRows()
    this.tableData = new MatTableDataSource(locationStatusSalesRows);
    setTimeout(() => {
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
      this.cdr.detectChanges();
    });
    this.loadingService.isLoading(false);
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  getLocationStatusSalesRows() {
    const locationStatusRows = [];
    for (let locationId in this.locationGroups) {
      const locationStatusRow = {
        location: locationId
      }
      for (let statusText in this.statusGroups) {
        const transactionsAtLocationInStatus = this.locationGroups[locationId].filter(transaction => transaction.status === statusText)
        const totalSalesAtStatus = transactionsAtLocationInStatus.reduce((base: number, transaction: ThirdPartyTransaction) => {
          return base += +transaction.sale;
        }, 0);
        locationStatusRow[statusText] = +(totalSalesAtStatus.toFixed(2));
      }
      locationStatusRows.push(locationStatusRow)
    }
    return locationStatusRows
  }
  getSelectedLocationName() {
    const selectedLocation = this.parametersForm.get('selectedLocation').value;
    if (selectedLocation) {
      const location = this.locations.find(_l => _l.locationId === selectedLocation);
      return `${location.name}`
    }
    return '';
  }
  clearResults() {
    this.tableData = null;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (375 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }
  openLocationStatusTransactionsViewer(locationId: number, statusText: string) {
    const transactions = this.locationGroups[locationId].filter(transaction => transaction.status === statusText)
    this.dialog.open(LocationTransactionsByStatusDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        transactions,
        locationId,
        statusText
      }
    })
  }
}
