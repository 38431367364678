import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { Faq } from "@deliver-sense-librarian/data-schema";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import {takeUntil} from "rxjs/operators";
import {scrollbarOptions} from "../../../shared/ds-constant";
import {Subject} from "rxjs";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
    memberFaqs: Faq[] = [];
    professionalFaqs: Faq[] = [];
    enableLogin = true;
    destroy$ = new Subject();
    scrollbarOptions = scrollbarOptions;

    constructor(private afs: AngularFirestore) {
    }

    ngOnInit() {
        this.afs.collection('faqs').snapshotChanges().pipe(takeUntil(this.destroy$)).subscribe(faqs$ => {
            const allFaqs = FirestoreUtilities.mapToType(faqs$);
            this.memberFaqs = allFaqs.filter(faq$ => faq$.type === 'member');
            this.memberFaqs.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                } else {
                    return -1;
                }
            });
            this.professionalFaqs = allFaqs.filter(faq$ => faq$.type === 'professional');
            this.professionalFaqs.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                } else {
                    return -1;
                }
            });
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
