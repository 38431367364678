import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { SupportDialogComponent } from "../../../dialogs/support-dialog/support-dialog.component";
import { User, Client, ClientModule, ClientRole } from "@deliver-sense-librarian/data-schema";
import { Store } from '@ngrx/store';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import {
  auditTrailsModule,
  exemptSalesManagementModule,
  thirdPartyReportingModule
} from "../../../shared/ds-constant";
import { UiState } from "../../../redux/custom-states/uiState/ui-state";
import { ToggleShowDataUploadAction } from '../../../redux/custom-states/uiState/ui-state-actions/application-state-actions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuitems = [
    {
      state: 'dashboard',
      name: 'DASHBOARD',
      id: 'dashboard-link',
      type: 'link',
      icon: 'tachometer-alt'
    },
    {
      state: 'organization',
      name: 'ORGANIZATION',
      id: 'org-nav-link',
      type: 'sub',
      icon: 'sitemap',
      children: [
        { state: 'team', icon: 'users', name: 'TEAM MEMBERS' },
        { state: 'entities', icon: 'city', name: 'ENTITIES' },
        { state: 'locations', icon: 'globe', name: 'LOCATIONS' },
        // { state: 'departments', icon: 'building', name: 'DEPARTMENTS' },
        // { state: 'projects', icon: 'tasks', name: 'PROJECTS' },
      ]
    }
  ];
  public user: User;
  public client: Client;
  public uiState: UiState;
  private destroy$ = new Subject();
  private clientModules: ClientModule[] = [];

  constructor(private store: Store<any>,
    private afs: AngularFirestore,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.getClientModules();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showSupportDialog() {
    this.dialog.open(SupportDialogComponent);
  }
  openDataUploadWindow() {
    this.store.dispatch(new ToggleShowDataUploadAction(true));
  }
  private getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientModules$ => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
        if (this.clientModules.length > 0) {
          this.clientModules = this.clientModules.filter((clientModule: ClientModule) => {
            return clientModule.active;
          })
        }
        this.addActiveClientModuleLinks();
      })
  }

  private addActiveClientModuleLinks() {
    this.menuitems.splice(2, 3);
    this.clientModules.forEach((clientModule: ClientModule) => {
      switch (clientModule.module.toString()) {
        case thirdPartyReportingModule:
          const children = [
            { state: 'list', icon: null, name: 'ALL REPORTS' },
          ];
          // If the authUser is internal or has an admin+ client role they can modify report settings and search all transactions
          if (this.uiState.authUser.internalRole >= 1 || this.uiState.clientRole >= 3) {
            children.push(
              { state: 'settings', icon: null, name: 'REPORT SETTINGS' },
              { state: 'transactions', icon: null, name: 'TRANSACTION SEARCH' }
              )
          }
          this.menuitems.splice(3, 0, {
            state: '3pd-reports',
            name: '3PD REPORTS',
            id: 'third-party-report-module-link',
            type: 'sub',
            icon: 'chart-bar',
            children: children
          });
          break;
        case exemptSalesManagementModule:
          this.menuitems.splice(4, 0, {
            state: 'exemption-certificates',
            id: 'exemption-certificate-module-link',
            name: 'EXEMPTION CERTIFICATES',
            type: 'link',
            icon: 'certificate'
          });
          break;
        case auditTrailsModule:
          this.menuitems.splice(5, 0, {
            state: 'audit-trails',
            id: 'audit-trails-module-link',
            name: 'AUDIT TRAILS',
            type: 'sub',
            icon: 'thumbs-up',
            children: [
              { state: 'list', icon: null, name: 'ALL AUDIT TRAILS' },
              { state: 'check-list-templates', icon: null, name: 'CHECK LISTS' },
              // { state: 'user-settings', icon: null, name: 'SETTINGS' },
            ]
          });
          break;
      }
    })
  }

  isUserClientAdmin() {
    return this.uiState.clientRole > 2;
  }
}
