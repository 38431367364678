<div class="session mat-indigo">
  <div class="session-content">
    <!-- <div class="session-wrapper"> -->
    <button mat-raised-button color="accent" class="logout-button" (click)="signout()">
      Logout
      <mat-icon>exit_to_app</mat-icon>
    </button>
    <div fxLayout="row" [style.margin-bottom.px]="100" fxLayoutAlign="center center">
      <img [style.width.px]="200" [src]="'/assets/images/logo-light.png'" />
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1>Welcome {{user ? user.firstName: ''}}! Please Select A Client To Continue</h1>
    </div>
    <div fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px"
      *ngIf="!noClients; else createClientTemplate">
      <mat-card class="mat-elevation-z4 client-selector-card" *ngFor="let client of clients"
        (click)="setClient(client)">
        <div fxLayout="row">
          <h3 *ngIf="client">{{client.name}} </h3> <div><img [style.width.px]="30" *ngIf="client && client.logo" [src]="client.logo" /></div>
        </div>
        <div>
          <h5 [style.color]="'grey'">Role: <i>{{getUserClientRole(client)}}</i></h5>
        </div>
      </mat-card>
    </div>
    <ng-template #createClientTemplate>
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card>
          <h1>You are not part of any client teams.</h1>
          <!-- <h5>Use the form below to create one. If an account for your company already exists please ask your
              company's DeliverSense admin to add you to the team.</h5> -->
        </mat-card>
      </div>
    </ng-template>
    <!-- </div> -->
  </div>
</div>
