// import { LocationReport } from './third-party-delivery-analytics-engine';
import { Client, Client3pdTransactionStatusConfiguration, Entity, LocationThirdParty, PosTransaction, ThirdPartyTransaction, Client3pdConfiguration } from '@deliver-sense-librarian/data-schema';
import * as moment from "moment";
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import * as _ from 'lodash';
import { combineLatest } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FirestoreUtilities } from "app/utilities/firestore-utilities";
// @TODO needs to be service with dependency injection streamline operations and component data communication ; DEBT = 5
export class LocationReport {
  reportId: string;
  locationId: string;
  startDate: any;
  endDate: any;
  name: string;
  entity: string | Entity;
  client: string | Client;
  primaryContact: string;
  stateTaxRate: number;
  countyTaxRate: number;
  cityTaxRate: number;
  specialTaxRate: number;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  addressCountry: string;
  thirdParties: any[] | LocationThirdParty[];

  // Report Specific
  thirdParty: string;
  posTransactions: any[];
  thirdPartyTransactions: any[];

  /**
   * Sales Remittance Values
   */
  thirdPartyGrossSales: number;
  thirdPartySalesAdjustments: number; //new
  thirdPartyNetSales: number; //new
  thirdPartyTips: number;
  thirdPartyOtherCharges: number;
  thirdPartyOtherRevenue: number;
  deliveryFees: number;
  otherFees: number;
  expectedRemittance: number;
  actualRemittance: number;
  posGrossSales: number;
  posSalesAdjustments: number;
  posNetSales: number;
  posTips: number;
  posOtherCharges: number;
  posOtherRevenue: number;
  /**
   * Tax Reporting Values
   */
  thirdPartyGrossTax: number;
  thirdPartyTaxAdjustments: number; //new
  thirdPartyNetTax: number //new
  thirdPartyEffectiveTaxRate: number;
  thirdPartyRemitted: number;
  effectiveMfRate: number; //new
  posGrossTax: number;
  posTaxAdjustments: number;
  posNetTax: number;
  locationTaxRate: number;
  expectedDeliveryFee: number;
  effectiveDeliveryFeeRate: number; //new
  expectedMfTax: number;
  marketFacilitatorTax: number;
  taxResponsibility: number; // 3pdTax - 3pdTaxRemitted
  effectiveClientTaxResponsibilityRate: number;
  taxAdjustment: number // = posTax - taxResponsibility
}
export class ThirdPartyDeliveryAnalyticsEngine {
  public posTransactions: PosTransaction[] = [];
  public thirdPartyTransactions: ThirdPartyTransaction[] = [];
  public matchingComplete = false;
  private locationReportData: LocationReport[] = [];
  private _client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] = [];

  public async setLocationReportData(locationReportData) {
    this.locationReportData = locationReportData;
    this.triggerAnalysis();
  }

  public set client3pdTransactionStatusConfigurations(configs) {
    this._client3pdTransactionStatusConfigurations = configs
  }
  public get client3pdTransactionStatusConfigurations() {
    return this._client3pdTransactionStatusConfigurations;
  }
  constructor(data?: LocationReport[]) {
    this.locationReportData = data;
    this.triggerAnalysis();
  }

  private triggerAnalysis() {
    if (this.locationReportData && this.locationReportData.length > 0) {
      this.analyzeResults();
    }
  }

  /***************************************
   * Calculation Functions *
   ****************************************/
  private analyzeResults() {
    this.locationReportData.forEach((locationReport: LocationReport) => {
      /** fixed order ***/
      try {
        // 3PD Calculations
        this.calculateThirdPartyGrossSales(locationReport);
        this.calculateThirdPartySalesAdjustments(locationReport);
        this.calculateThirdPartyNetSales(locationReport);
        this.calculateThirdPartyTips(locationReport);
        this.calculateThirdPartyOtherCharges(locationReport);
        this.calculateThirdPartyGrossTax(locationReport);
        this.calculateThirdPartyTaxAdjustments(locationReport);
        this.calculateThirdPartyNetTax(locationReport);
        this.calculateThirdPartyEffectiveRate(locationReport);
        this.calculateDeliverFeeTotal(locationReport);
        this.calculateEffectiveDeliveryRate(locationReport);
        this.calculateOtherFeeTotal(locationReport);
        this.calculateThirdPartyOtherRevenue(locationReport);
        this.calculateActualRemittance(locationReport);

        // POS Calculations
        this.calculatePosGrossSales(locationReport);
        this.calculatePosSaleAdjustments(locationReport);
        this.calculatePosNetSales(locationReport);
        this.calculatePosTips(locationReport);
        this.calculatePosOtherCharges(locationReport);
        this.calculatePosOtherRevenue(locationReport);
        this.calculatePosGrossTax(locationReport);
        this.calculatePosTaxAdjustments(locationReport);
        this.calculatePosNetTax(locationReport);
        this.calculateLocationTaxRatesTotal(locationReport);

        this.calculateMarketFacilitatorTaxTotal(locationReport);
        this.calculateEffectiveMfRate(locationReport);
        this.calculateExpectedRemittance(locationReport); // after 3pdSales and delvieryFee and 3pd tax and mfTax and other fees

        this.calculateTaxResponsibility(locationReport); // after 3pdTax and market facilitator
        this.calculateTaxResponsibilityRate(locationReport);
        this.calculateTaxAdjustment(locationReport); // after tax responsibility
      } catch (e) {
        console.log('Error running report: ', e);
      }
    });
  }
  private calculateThirdPartyGrossSales(locationReport: LocationReport) {
    locationReport.thirdPartyGrossSales = locationReport.thirdPartyTransactions.reduce((prev: number, transaction: ThirdPartyTransaction) => {
      if (+transaction.sale) {
        prev += transaction.sale;
      }
      return +(prev).toFixed(2);
    }, 0);
  } private calculateThirdPartySalesAdjustments(locationReport: LocationReport) {
    locationReport.thirdPartySalesAdjustments = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        console.log(transaction.saleCorrection);
        if (+transaction.saleCorrection) {
          prev += transaction.saleCorrection; // Sales corrections should be negative numbers in 3PD reports
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyNetSales(locationReport: LocationReport) {
    locationReport.thirdPartyNetSales = +(locationReport.thirdPartyGrossSales + locationReport.thirdPartySalesAdjustments).toFixed(2);
  }
  private calculateThirdPartyTips(locationReport: LocationReport) {
    locationReport.thirdPartyTips = locationReport['thirdPartyTransactions'].reduce((prev: number, transaction: ThirdPartyTransaction) => {
      if (+transaction.tip) {
        prev += transaction.tip;
      }
      return +(prev).toFixed(2);
    }, 0);
  }
  private calculateThirdPartyOtherCharges(locationReport: LocationReport) {
    locationReport.thirdPartyOtherCharges = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.otherRevenue) {
          prev += transaction.otherRevenue;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyOtherRevenue(locationReport: LocationReport) {
    locationReport.thirdPartyOtherRevenue = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.otherCharges) {
          prev += transaction.otherCharges;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyGrossTax(locationReport: LocationReport) {
    locationReport.thirdPartyNetTax = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.tax) {
          prev += transaction.tax;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyTaxAdjustments(locationReport: LocationReport) {
    locationReport.thirdPartyTaxAdjustments = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.taxCorrection) {
          prev += transaction.taxCorrection;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyNetTax(locationReport: LocationReport) {
    locationReport.thirdPartyNetTax = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.tax) {
          prev += transaction.tax;
        }
        if (+transaction.taxCorrection) {
          prev += transaction.taxCorrection;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateThirdPartyEffectiveRate(locationReport: LocationReport) {
    locationReport.thirdPartyEffectiveTaxRate = locationReport.thirdPartyNetTax === 0 ? 0 :
      Math.abs(+(+locationReport.thirdPartyNetTax / +locationReport.thirdPartyNetSales).toFixed(4));
  }
  private calculateEffectiveMfRate(locationReport: LocationReport) {
    locationReport.effectiveMfRate = locationReport.marketFacilitatorTax === 0 ? 0 :
      Math.abs(+(+locationReport.marketFacilitatorTax / +locationReport.thirdPartyNetSales).toFixed(4));
  }
  private calculateTaxResponsibilityRate(locationReport: LocationReport) {
    locationReport.effectiveClientTaxResponsibilityRate = locationReport.taxResponsibility === 0 ? 0 :
      Math.abs(+(+locationReport.taxResponsibility / +locationReport.thirdPartyNetSales).toFixed(4));
  }
  private calculatePosGrossSales(locationReport: LocationReport) {
    locationReport.posGrossSales = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.sale) {
          prev += transaction.sale;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculatePosSaleAdjustments(locationReport: LocationReport) {
    locationReport.posSalesAdjustments = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.saleCorrection) {
          prev += transaction.saleCorrection;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculatePosNetSales(locationReport: LocationReport) {
    locationReport.posNetSales = locationReport.posGrossSales + locationReport.posSalesAdjustments;
  }
  private calculatePosTips(locationReport: LocationReport) {
    locationReport.posTips = locationReport.posTransactions.reduce((prev: number, transaction: PosTransaction) => {
      if (+transaction.tip) {
        prev += transaction.tip;
      }
      return +(prev).toFixed(2);
    }, 0);
  }
  private calculatePosOtherCharges(locationReport: LocationReport) {
    locationReport.posOtherCharges = locationReport.posTransactions.reduce((prev: number, transaction: PosTransaction) => {
      if (+transaction.otherCharges) {
        prev += transaction.otherCharges;
      }
      return +(prev).toFixed(2);
    }, 0);
  }
  private calculatePosOtherRevenue(locationReport: LocationReport) {
    locationReport.posOtherRevenue = locationReport.posTransactions.reduce((prev: number, transaction: PosTransaction) => {
      if (+transaction.otherRevenue) {
        prev += transaction.otherRevenue;
      }
      return +(prev).toFixed(2);
    }, 0);
  }
  private calculatePosGrossTax(locationReport: LocationReport) {
    locationReport.posGrossTax = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.tax) {
          prev += transaction.tax;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculatePosTaxAdjustments(locationReport: LocationReport) {
    locationReport.posTaxAdjustments = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.taxCorrection) {
          prev += transaction.taxCorrection;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculatePosNetTax(locationReport: LocationReport) {
    locationReport.posNetTax = locationReport.posGrossTax + locationReport.posTaxAdjustments;
  }
  private calculateLocationTaxRatesTotal(locationReport: LocationReport) {
    locationReport.locationTaxRate = +(
      +locationReport.stateTaxRate +
      +locationReport.countyTaxRate +
      +locationReport.cityTaxRate +
      +locationReport.specialTaxRate
    ).toFixed(2) / 100;
  }

  private calculateDeliverFeeTotal(locationReport: LocationReport) {
    locationReport.deliveryFees = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.deliveryFeeTotal) {
          prev += transaction.deliveryFeeTotal;
        }
        return +(prev).toFixed(2);
      }, 0);
  }
  private calculateEffectiveDeliveryRate(locationReport: LocationReport) {
    locationReport.effectiveDeliveryFeeRate = locationReport.deliveryFees === 0 ? 0 :
      Math.abs(+(+locationReport.deliveryFees / +locationReport.thirdPartyNetSales).toFixed(4));
  }
  private calculateOtherFeeTotal(locationReport: LocationReport) {
    locationReport.otherFees = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.promoFee) {
          prev += transaction.promoFee;
        }
        if (+transaction.cateringFee) {
          prev += +transaction.cateringFee
        }
        if (+transaction.cateringFee) {
          prev += +transaction.cateringFee
        }
        if (+transaction.pickupFeeTotal) {
          prev += +transaction.pickupFeeTotal
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateActualRemittance(locationReport: LocationReport) {
    locationReport.actualRemittance = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.totalRemitted) {
          prev += transaction.totalRemitted;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateMarketFacilitatorTaxTotal(locationReport: LocationReport) {
    locationReport.marketFacilitatorTax = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.taxRemitted) {
          prev += transaction.taxRemitted;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateExpectedRemittance(locationReport: LocationReport) {
    // let expectedRemittance = 0;
    const thirdPartyRates = locationReport.thirdParties.find(tp => tp.thirdParty === locationReport.thirdParty);
    const mfRates = thirdPartyRates ? thirdPartyRates['marketFacilitatorRates'] : [];
    let dateRelevantMfRate = 0;
    if (mfRates.length > 0) {
      for (const index in mfRates) {
        if (mfRates[index]) {
          const mfRate = mfRates[index];
          const rateEffectiveDate = mfRate.effectiveDate.toDate();
          if (moment(rateEffectiveDate).isSameOrBefore(moment(locationReport.startDate))) {
            dateRelevantMfRate = mfRate.rate;
            break;
          }
        }
      }
    }
    locationReport.expectedDeliveryFee = +(thirdPartyRates.deliveryFee / 100 * locationReport.posNetSales).toFixed(2);
    locationReport.expectedMfTax = dateRelevantMfRate ? +((dateRelevantMfRate / 100) * locationReport.posNetSales).toFixed(2) : 0;
    // expectedRemittance = +(locationReport.posNetSales + locationReport.posTips + locationReport.posNetTax - (locationReport.expectedDeliveryFee + locationReport.expectedMfTax + locationReport.otherFees)).toFixed(2);
    // locationReport.expectedRemittance = expectedRemittance;
    locationReport.expectedRemittance = +(locationReport.posNetSales + locationReport.posTips + locationReport.posNetTax + locationReport.posOtherRevenue - (Math.abs(locationReport.marketFacilitatorTax) + Math.abs(locationReport.otherFees) + Math.abs(locationReport.deliveryFees) + Math.abs(locationReport.thirdPartyOtherCharges))).toFixed(2)
  }

  private calculateTaxResponsibility(locationReport: LocationReport) {
    locationReport.taxResponsibility = +(locationReport.thirdPartyNetTax + locationReport.marketFacilitatorTax).toFixed(2);
  }

  private calculateTaxAdjustment(locationReport: LocationReport) {
    locationReport.taxAdjustment = +(locationReport.posNetTax - locationReport.taxResponsibility).toFixed(2);
  }


  /***************************************
   * Transaction Mapping Functions *
   ****************************************/

  public runTransactionMatches(locationId: string, thirdPartyId: string) {
    const distances = [];
    const matches = [];
    const locationData = this.locationReportData.filter(transactionInfo => transactionInfo.locationId === locationId && transactionInfo.thirdParty === thirdPartyId);
    this.posTransactions = locationData.reduce((arr, curr) => {
      return [...arr, ...curr.posTransactions]
    }, []);
    this.thirdPartyTransactions = locationData.reduce((arr, curr) => {
      return [...arr, ...curr.thirdPartyTransactions];
    }, []);
    _.uniqBy(this.posTransactions, 'id');
    _.uniqBy(this.thirdPartyTransactions, 'id');
    this.posTransactions.forEach((posTransaction: PosTransaction) => {
      posTransaction.date = new Timestamp(posTransaction.date.seconds, posTransaction.date.nanoseconds);
    });
    this.thirdPartyTransactions.forEach((thirdPartyTransaction: ThirdPartyTransaction) => {
      thirdPartyTransaction.date = new Timestamp(thirdPartyTransaction.date.seconds, thirdPartyTransaction.date.nanoseconds);
    });
    try {
      // this.createDistanceMatrix(acceptableVariance);
    } catch (e) {
      console.error(e);
    }
  }

  public createDistanceMatrix(acceptableVariance, allTransactions: { posTransactions: any[], tpdTransactions: any[] }) {
    const distances = [];
    const matches = [];
    const matrix = {};
    let unMatchedCount = 0;
    allTransactions.posTransactions.forEach((posTransaction: PosTransaction) => {
      matrix[posTransaction.id] = [];
      const posTransactionPoint: { subTotal?, tax?, date?} = {};
      posTransactionPoint.subTotal = posTransaction.sale ? posTransaction.sale : 0;
      posTransactionPoint.tax = posTransaction.tax ? posTransaction.tax : 0;
      posTransactionPoint.date = posTransaction.date ? moment(moment(posTransaction.date.toDate()).format('M/D/YY')).unix() : 0;
      allTransactions.tpdTransactions.forEach((thirdPartyTransaction: ThirdPartyTransaction) => {
        const thirdPartyTransactionPoint: { subTotal?, tax?, date?} = {};
        thirdPartyTransactionPoint.subTotal = thirdPartyTransaction.sale ? thirdPartyTransaction.sale : 0;
        thirdPartyTransactionPoint.tax = thirdPartyTransaction.tax ? thirdPartyTransaction.tax : 0;
        thirdPartyTransactionPoint.date = thirdPartyTransaction.date ? moment(moment(thirdPartyTransaction.date.toDate()).format('M/D/YY')).unix() : 0;
        const distance = this.getEuclideanDistance(posTransactionPoint, thirdPartyTransactionPoint);
        const relativeDistanceObject = {
          thirdPartyTransaction: thirdPartyTransaction.id,
          distance
        };
        matrix[posTransaction.id].push(relativeDistanceObject);
      });
      matrix[posTransaction.id].sort((a, b) => {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
      if (matrix[posTransaction.id][0]) {
        const lowestDistanceMatch = matrix[posTransaction.id][0].distance;
        distances.push(lowestDistanceMatch);
      }
    });
    distances.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });
    allTransactions.posTransactions.forEach(posTransaction => {
      if (matrix[posTransaction.id][0]) {
        const lowestDistanceMatch = matrix[posTransaction.id][0].distance;
        const lowestDistanceThirdPartyTransactionId = matrix[posTransaction.id][0].thirdPartyTransaction;
        const normalizedDistance = this.normalizeDistance(lowestDistanceMatch, distances,);
        if (normalizedDistance > acceptableVariance) {
          matches.push({
            posTransaction: posTransaction.id,
            thirdPartyTransaction: lowestDistanceThirdPartyTransactionId,
            match: lowestDistanceMatch
          });
        } else {
          matches.push({
            posTransaction: posTransaction.id,
            match: 1 // 1-1 = 0 --> no match
          });
        }
      }
    });
    allTransactions.tpdTransactions.forEach(thirdPartyTransaction => {
      const matched = matches.find(match => match.thirdPartyTransaction === thirdPartyTransaction.id);
      if (!matched) {
        unMatchedCount++;
        matches.push({
          thirdPartyTransaction: thirdPartyTransaction.id,
          match: 1 // 1-1 = 0 --> no match
        });
      }
    });
    matches.filter(match => match.posTransaction && match.thirdPartyTransaction)
      .forEach(match => {
        if (match.posTransaction && match.thirdPartyTransaction) { // if the entry has a match
          const duplicate = matches.find(otherMatch => {
            return otherMatch.posTransaction !== match.posTransaction && match.thirdPartyTransaction === otherMatch.thirdPartyTransaction;
          });
          if (duplicate) {
            const loser = this.normalizeDistance(match.match, distances) > this.normalizeDistance(duplicate.match, distances) ? duplicate : match;
            loser.thirdPartyTransaction = null;
            loser.match = 1;
          }
        }
      });
    return { matches, distances }
  }


  public normalizeDistance(x, distances) {
    const minDistance = distances[0];
    const maxDistance = distances[distances.length - 1];
    if (x === 1) {
      return 0;
    }
    if (x !== 0) {
      return +((1 - (x - minDistance) / (maxDistance - minDistance)) * 100).toFixed(2);
    } else {
      return 100;
    }
  }

  private getEuclideanDistance(p1, p2) {
    const xdiff = Math.pow((p1[Object.keys(p1)[0]] - p2[Object.keys(p1)[0]]), 2);
    const ydiff = Math.pow((p1[Object.keys(p1)[1]] - p2[Object.keys(p2)[1]]), 2);
    const zdiff = Math.pow((p1[Object.keys(p1)[2]] - p2[Object.keys(p2)[2]]), 2);
    return Math.sqrt(xdiff + ydiff + zdiff)
  }

  public perc2color(perc) {
    let r, g = 0;
    const b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    const hexColor = '#' + ('000000' + h.toString(16)).slice(-6);
    const rgb = this.hexToRgb(hexColor);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`;
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  public fetchParameterizedReportData(locationId, thirdPartyId, startDate, endDate, client: Client, afs: AngularFirestore) {
    const allTransactions = {
      posTransactions: [],
      tpdTransactions: []
    };
    return combineLatest([
      afs.collection('thirdPartyTransactions', ref => ref
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .where('thirdParty', '==', thirdPartyId)
        .where('location', '==', locationId)
        .where('client', '==', client.id)
        .orderBy('date')).snapshotChanges(),
      afs.collection('posTransactions', ref => ref
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .where('account', '==', thirdPartyId)
        .where('location', '==', locationId)
        .where('client', '==', client.id)
        .orderBy('date')).snapshotChanges()
    ]).pipe(map((([thirdPartyTransactions$, posTransactions$]) => {
      allTransactions.posTransactions = posTransactions$.map(transaction => FirestoreUtilities.objectToType(transaction))
      allTransactions.tpdTransactions = thirdPartyTransactions$.map(transaction => FirestoreUtilities.objectToType(transaction))
      return allTransactions
    })));
  }

  public enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    return dates;
  };
}
