<div class="session mat-indigo">
    <div class="session-wrapper">
      <mat-card>
        <mat-card-content>
          <div class="text-xs-center pb-1">
            <img src="assets/images/logo-dark-vert.png" alt="" width="30%" />
            <p class="mat-text-muted">Create an Account to Continue.</p>
          </div>
          <div class="text-xs-left pb-1">
            <small>
              If your company is already using DeliverSense please have your admin invite you to
              the team.
            </small>
          </div>
          <div [style.height]="'65vh'" MalihuScrollbar [scrollbarOptions]="scrollbarOptions">
            <form [formGroup]="signupFormGroup" *ngIf="showUserForm; else clientInfoForm">
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div>
                  <button mat-raised-button (click)="registerWithProvider('microsoft')" [style.width.px]="200">
                    <img [style.width.px]="25" src="/assets/images/ms-login-symbol.png"> Add to my organization
                  </button>
                </div>
                <div>
                  <button mat-raised-button (click)="registerWithProvider('google')" [style.width.px]="200">
                    <img [style.height.px]="25" src="/assets/images/google-login-symbol.png"> Register with Google
                  </button>
                </div>
              </div>
              <div fxLayout="column" fxLayoutAlign="space-around">
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Email address" type="email"
                      [formControl]="signupFormGroup.controls['email']">
                  </mat-form-field>
                  <small
                    *ngIf="signupFormGroup.controls['email'].hasError('required') && signupFormGroup.controls['email'].touched"
                    class="mat-text-warn">You
                    must include an email address.
                  </small>
                  <small
                    *ngIf="signupFormGroup.controls['email'].errors?.email && signupFormGroup.controls['email'].touched"
                    class="mat-text-warn">You
                    must include a valid email address.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="First Name" [formControl]="signupFormGroup.controls['firstName']">
                  </mat-form-field>
                  <small
                    *ngIf="signupFormGroup.controls['firstName'].hasError('required') && signupFormGroup.controls['firstName'].touched"
                    class="mat-text-warn">
                    You must provide your first name.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Last Name" [formControl]="signupFormGroup.controls['lastName']">
                  </mat-form-field>
                  <small
                    *ngIf="signupFormGroup.controls['lastName'].hasError('required') && signupFormGroup.controls['lastName'].touched"
                    class="mat-text-warn">
                    You must provide your last name.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput type="password" placeholder="Password"
                      [formControl]="signupFormGroup.controls['password']">
                  </mat-form-field>
                  <small
                    *ngIf="signupFormGroup.controls['password'].hasError('required') && signupFormGroup.controls['password'].touched"
                    class="mat-text-warn">You must include password.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Confirm Password"
                      [formControl]="signupFormGroup.controls['confirmPassword']" type="password">
                  </mat-form-field>
                  <small
                    *ngIf="signupFormGroup.controls['confirmPassword'].hasError('required') && signupFormGroup.controls['confirmPassword'].touched"
                    class="mat-text-warn">You must confirm your password.
                  </small>
                </div>
                <small *ngIf="signupFormGroup.errors?.notSame" class="mat-text-warn">
                  Passwords do not match.
                </small>
                <button mat-raised-button color="primary" class="btn-block" (click)="showUserForm = false;"
                  [disabled]="!signupFormGroup.valid">Next
                </button>
              </div>
            </form>
            <ng-template #clientInfoForm>
              <form [formGroup]="clientInfoFormGroup">
                <div class="text-xs-center pb-1">
                  <p class="mat-text-muted">Company Information</p>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Company Name" type="text"
                      [formControl]="clientInfoFormGroup.controls['name']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['name'].hasError('required') && clientInfoFormGroup.controls['name'].touched"
                    class="mat-text-warn">
                    Please provide your company's name.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Your Title (i.e. Director of Tax)" type="text"
                      [formControl]="clientInfoFormGroup.controls['title']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['title'].hasError('required') && clientInfoFormGroup.controls['title'].touched"
                    class="mat-text-warn">
                    Please provide your position with the company.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Address Line 1" type="text"
                      [formControl]="clientInfoFormGroup.controls['addressLine1']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['addressLine1'].hasError('required') && clientInfoFormGroup.controls['addressLine1'].touched"
                    class="mat-text-warn">
                    Please provide a street address.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Address Line 2" type="text"
                      [formControl]="clientInfoFormGroup.controls['addressLine2']">
                  </mat-form-field>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="City" type="text" [formControl]="clientInfoFormGroup.controls['city']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['city'].hasError('required') && clientInfoFormGroup.controls['city'].touched"
                    class="mat-text-warn">Please provide a city.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>State</mat-label>
                    <mat-select [formControl]="clientInfoFormGroup.controls['stateProvince']">
                      <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['stateProvince'].hasError('required') && clientInfoFormGroup.controls['stateProvince'].touched"
                    class="mat-text-warn">Please provide a state or province.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Postal Code" type="text"
                      [formControl]="clientInfoFormGroup.controls['postalCode']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['postalCode'].hasError('required') && clientInfoFormGroup.controls['postalCode'].touched"
                    class="mat-text-warn">Please provide a postal code.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <input matInput placeholder="Billing Email" type="text"
                      [formControl]="clientInfoFormGroup.controls['billingEmail']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['billingEmail'].hasError('required') && clientInfoFormGroup.controls['billingEmail'].touched"
                    class="mat-text-warn">Please set a billing email address.
                  </small>
                </div>
                <div class="pb-1">
                  <mat-form-field appearance="outline" style="width: 50%">
                    <mat-label>Referral Code</mat-label>
                    <input matInput id="referralCode" [formControl]="clientInfoFormGroup.controls['referralCode']">
                  </mat-form-field>
                  <small
                    *ngIf="clientInfoFormGroup.controls['referralCode'].hasError('invalidCode') && !clientInfoFormGroup.controls['referralCode'].touched"
                    class="mat-text-warn">
                    Invalid Code
                  </small>
                  <small *ngIf="referralCodeSuccess" class="mat-text-accent">
                    Success!
                  </small>
                </div>
                <div class="pb-1">
                  <div formArrayName="teamMembers">
                    <div *ngFor="let teamMember of clientInfoFormGroup.get('teamMembers')['controls']; let i = index">
                      <div [formGroupName]="i" fxLayout="row">
                        <mat-form-field appearance="outline">
                          <mat-label>Team Member Email</mat-label>
                          <input matInput formControlName="email" placeholder="Team Member Email">
                        </mat-form-field>
                        <button mat-icon-button color="warn" (click)="removeTeamMemberEmail(i)">
                          <mat-icon>delete_forever</mat-icon>
                        </button>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="clientInfoFormGroup.get('teamMembers').value.length < 3; else onlyThreeToStart">
                      <button mat-raised-button (click)="addTeamMemberEmailControl()">Add Team Member Email</button>
                    </ng-container>
                    <ng-template #onlyThreeToStart>
                      <small class="mat-text-warn">You can add more after you complete registration.</small>
                    </ng-template>
                    <div>
                    </div>
                  </div>
                </div>
                <div class="pb-1">
                  <re-captcha formControlName="recaptchaReactive" name="captcha" id="signupCaptcha"></re-captcha>
                </div>
                <div class="pb-1">
                  <mat-checkbox formControlName="agreeToTerms"></mat-checkbox>
                  I have read and agree to the <a (click)="openTermsOfServiceDialog()">terms of service.</a> and the <a
                    (click)="openPrivacyPolicyDialog()">privacy policy</a>
                </div>
                <button mat-raised-button color="warn" class="btn-block" (click)="backToUserForm()">
                  Previous
                </button>
                <button mat-raised-button color="primary" class="btn-block" (click)="onSubmit()"
                  [disabled]="!signupFormGroup.valid || !clientInfoFormGroup.valid">
                  Create your account
                </button>
              </form>
            </ng-template>
            <div class="pt-1 pb-1 text-xs-center">
              <a [routerLink]="['/forgot']">Forgot password?</a>&nbsp;&nbsp;&nbsp;&nbsp;
              <a [routerLink]="['/login']">Sign In.</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
