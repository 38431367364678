<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="client">
      <h2>Your {{client.name}} Team</h2>
    </mat-card-title>
    <mat-card-subtitle>
      <h6>View and edit your team.</h6>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between start">

    <button *ngIf="!teamMemberInEdit && uiState && uiState.clientRole > 2" mat-raised-button color="primary"
      [routerLink]="'./new'">
      Invite New Team Member
    </button>
  </div>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Team Members">
  </mat-form-field>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <mat-table [dataSource]="tableData" class="report-table" matSort multiTemplateDataRows>

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.firstName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.lastName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="inner-text">
              {{row.email}}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="role">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{getClientRole(row)}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="remove">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Remove</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.id !== user.id  && !row.internalRole">
              <button mat-raised-button color="warn" (click)="removeTeamMember(row)">Remove</button>
            </ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Edit</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.id !== user.id && !row.internalRole">
              <a [routerLink]="row.id">
                <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
              </a>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div *ngIf="pendingInvites.length > 0">
      <mat-list>
        <h3 mat-subheader>Pending Invites</h3>
        <mat-list-item *ngFor="let invite of pendingInvites">
          <div mat-line fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <div><b>To: </b> {{invite.email}}</div>
            <div><i>Sent at: {{invite.dateUpdated.toDate() | date:'short'}}</i></div>
            <ng-container *ngIf="isInviteExpired(invite)">
              <div>
                <mat-error>EXPIRED
                  <mat-icon>warning</mat-icon>
                </mat-error>
              </div>
            </ng-container>
            <div>
              <button mat-raised-button color="accent" *ngIf="invite.from === user.id"
                (click)="resendInvite(invite)">Resend</button>
              <button mat-raised-button color="warn" *ngIf="invite.from === user.id"
                (click)="deleteInvite(invite)">Delete</button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
