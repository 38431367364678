<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="client">
      <h2>Your {{client.name}} Entities</h2>
    </mat-card-title>
    <mat-card-subtitle>
      <h6>View and edit client entities.</h6>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
<mat-card>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button color="primary" [routerLink]="'./new'">Create an entity</button>
  </div>
  <mat-form-field appearance="outline">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Entities">
  </mat-form-field>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <mat-table [dataSource]="tableData" matSort multiTemplateDataRows>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a [routerLink]="row.id">{{row.name}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Address</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div fxLayout="column">
              <div>{{row.addressLine1}}</div>
              <div *ngIf="row.addressLine2">{{row.addressLine2}}</div>
              <div>{{row.addressCity}}, {{row.addressState}} {{row.addressPostalCode}}</div>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
