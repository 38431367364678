import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { UiState } from '../../../../redux/custom-states/uiState/ui-state';
import { Subject, combineLatest } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Client3pdConfiguration, Client3pdTransactionStatusConfiguration, PosSystem, reconciliationTableFields, ThirdParty } from '@deliver-sense-librarian/data-schema';
import * as moment from 'moment';
import { scrollbarOptions } from '../../../../shared/ds-constant';
// @TODO need to split to two separate components - COST = 3
@Component({
  selector: 'app-third-party-report-settings',
  templateUrl: './third-party-report-settings.component.html',
  styleUrls: ['./third-party-report-settings.component.scss']
})
export class ThirdPartyReportSettingsComponent implements OnInit, OnDestroy {
  public uiState$: UiState;
  private destroy$ = new Subject();
  private client3pdConfiguration = new Client3pdConfiguration();
  public configForm: FormGroup;
  public fields = reconciliationTableFields;
  client3pdTransactionStatusConfigurations: Client3pdTransactionStatusConfiguration[] = [];
  clientThirdParties: ThirdParty[] = [];
  clientPosSystems: PosSystem[] = [];
  scrollbarOptions = scrollbarOptions;
  formatLabel(value: number) {
    if (value >= 0) {
      return `$${Math.round(value / 1000)}`;
    }
    return value;
  }
  formatLabelPercent(value: number) {
    if (value >= 0) {
      return `${Math.round(value / 1000)}%`;
    }
    return value;
  }

  constructor(private store: Store<any>,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((uiState$: UiState) => {
        if (uiState$.authUser && uiState$.client) {
          this.uiState$ = uiState$;
          this.clientThirdParties = this.uiState$.clientThirdParties.map(clientTpd=> clientTpd.thirdParty) as ThirdParty[];
          this.clientPosSystems  = this.uiState$.clientPosSystems.map(clientPos => clientPos.posSystem) as ThirdParty[];
          this.getClient3pdConfiguration();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private getClient3pdConfiguration() {
    combineLatest([
      this.afs.collection('client3pdConfigurations', ref => ref
        .where('client', '==', this.uiState$.client.id))
        .snapshotChanges(),
      this.afs.collection('client3pdTransactionStatusConfigurations', ref => ref
        .where('client', '==', this.uiState$.client.id))
        .snapshotChanges(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([client3pdConfiguration$, client3pdTransactionStatusConfigurations$]) => {
        if (client3pdConfiguration$.length > 0) {
          this.client3pdConfiguration = FirestoreUtilities.mapToType(client3pdConfiguration$)[0] as Client3pdConfiguration;
        }
        if (client3pdTransactionStatusConfigurations$.length > 0) {
          this.client3pdTransactionStatusConfigurations = FirestoreUtilities.mapToType(client3pdTransactionStatusConfigurations$) as Client3pdTransactionStatusConfiguration[];
        }
        this.populateStatusConfigurationArrays();
        this.setupConfigurationForm();
      })
  }
  setupConfigurationForm() {
    this.configForm = this.fb.group({
      taxVarianceThreshold: new FormControl(this.client3pdConfiguration.taxVarianceThreshold ? this.client3pdConfiguration.taxVarianceThreshold : 500, Validators.required),
      salesVarianceThreshold: new FormControl(this.client3pdConfiguration.salesVarianceThreshold ? this.client3pdConfiguration.salesVarianceThreshold : 500, Validators.required),
      remittanceVarianceThreshold: new FormControl(this.client3pdConfiguration.remittanceVarianceThreshold ? this.client3pdConfiguration.remittanceVarianceThreshold : 500, Validators.required),
      taxRateVarianceThreshold: new FormControl(this.client3pdConfiguration.taxRateVarianceThreshold ? this.client3pdConfiguration.taxRateVarianceThreshold : 2, Validators.required),
      defaultFields: new FormControl(this.client3pdConfiguration.defaultFields ? this.client3pdConfiguration.defaultFields : ['Location Id', '3PD'], Validators.required),
      clientThirdPartyTransactionStatusConfigurations: new FormArray([])
    });
  }

  private async populateStatusConfigurationArrays() {
    this.clientThirdParties.forEach(async (clientThirdParty) => {
      let thirdPartyTransactionStatuses = await this.afs.collection('thirdPartyTransactionStatuses', ref => ref.where('thirdParty', '==', clientThirdParty.id)).snapshotChanges().pipe(first()).toPromise();
      thirdPartyTransactionStatuses = FirestoreUtilities.mapToType(thirdPartyTransactionStatuses);
      clientThirdParty['thirdPartyTransactionStatuses'] = thirdPartyTransactionStatuses;
      const clientStatusConfigs = this.client3pdTransactionStatusConfigurations.filter(config => config.thirdParty === clientThirdParty.id);
      clientThirdParty['clientStatusConfigForm'] = this.fb.group({
        statusConfigs: new FormArray([])
      });
      const relativeStatusConfigs = clientThirdParty['clientStatusConfigForm'].get('statusConfigs') as FormArray;
      clientStatusConfigs.forEach(statusConfig => {
        relativeStatusConfigs.push(
          new FormGroup({
            status: new FormControl(statusConfig.status, Validators.required),
            inPos: new FormControl(statusConfig.inPos, Validators.required),
            posSystem: new FormControl(statusConfig.posSystem, Validators.required),
            client: new FormControl(statusConfig.client, Validators.required),
            id: new FormControl(statusConfig.id, Validators.required),
            thirdParty: new FormControl(statusConfig.thirdParty, Validators.required)
          })
        )
      })
    })
    // const statusConfigurationArray = this.configForm.get('clientThirdPartyTransactionStatusConfigurations') as FormArray;
  }
  public addFormArrayGroup(formGroup, thirdPartyId) {
    const statusFormArray = formGroup.get('statusConfigs') as FormArray;
    statusFormArray.push(new FormGroup({
      status: new FormControl('', Validators.required),
      inPos: new FormControl(true),
      posSystem: new FormControl('', Validators.required),
      client: new FormControl(this.uiState$.client.id, Validators.required),
      id: new FormControl(this.afs.createId(), Validators.required),
      thirdParty: new FormControl(thirdPartyId, Validators.required)
    }));
  }

  public removeFormArrayGroup(index, formGroup) {
    const methodParameters = formGroup.get('statusConfigs') as FormArray;
    methodParameters.removeAt(index);
  }
  public async saveClientThirdPartyTransactionStatuses(clientThirdPartyStatusForm) {
    if (clientThirdPartyStatusForm.valid) {
      this.loadingService.isLoading(true, `Saving transaction configurations`)
      const statusConfigArray = clientThirdPartyStatusForm.get('statusConfigs').value;
      const statusConfigSaveRequests = statusConfigArray.map((arrayGroup: { status, id, thirdParty, posSystem, inPos, client }) => {
        const statusConfig = new Client3pdTransactionStatusConfiguration();
        statusConfig.thirdParty = arrayGroup.thirdParty;
        statusConfig.status = arrayGroup.status;
        statusConfig.client = arrayGroup.client;
        statusConfig.inPos = arrayGroup.inPos;
        statusConfig.posSystem = arrayGroup.posSystem;
        return this.afs.doc(`client3pdTransactionStatusConfigurations/${arrayGroup.id}`).set(statusConfig.toJSONObject());
      });
      try {
        await Promise.all(statusConfigSaveRequests);
        this.snackBar.open('Status configurations saved successfully!', 'Dismiss', { duration: 5000 });
      } catch (e) {
        this.snackBar.open('Oops... something went wrong. Please refresh and try again', 'Dismiss', { duration: 5000 });
      } finally {
        this.loadingService.isLoading(false)
      }
    } else {
      this.snackBar.open('Please fill out all required fields when creating a new third party transaction status configuration.', 'Dismiss', {
        duration: 5000
      })
    }
  }
  save() {
    if (this.configForm.valid) {
      const formValues = this.configForm.value;
      try {
        if (this.client3pdConfiguration.id) {
          this.updateClient3pdConfiguration(formValues);
        } else {
          this.createClient3pdConfiguration(formValues);
        }
        const errSnackbar = this.snackBar.open('Successfully updated your 3PD Report Configurations.', 'Dismiss', {
          duration: 5000
        });
      } catch (e) {
        const errSnackbar = this.snackBar.open('Oops... something went wrong. Please refresh and try again', 'Refresh Now', {
          duration: 5000
        });
        errSnackbar.onAction().subscribe(() => {
          window.location.reload();
        })
      }
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      })
    }
  }
  private async updateClient3pdConfiguration(form) {
    await this.afs.doc(`client3pdConfigurations/${this.client3pdConfiguration.id}`).update({
      taxVarianceThreshold: form.taxVarianceThreshold,
      salesVarianceThreshold: form.salesVarianceThreshold,
      taxRateVarianceThreshold: form.taxRateVarianceThreshold,
      remittanceVarianceThreshold: form.remittanceVarianceThreshold,
      defaultFields: form.defaultFields,
      dateUpdated: moment().toDate()
    });
  }

  private createClient3pdConfiguration(form) {
    this.afs.collection(`client3pdConfigurations`).add({
      taxVarianceThreshold: form.taxVarianceThreshold,
      salesVarianceThreshold: form.salesVarianceThreshold,
      taxRateVarianceThreshold: form.taxRateVarianceThreshold,
      remittanceVarianceThreshold: form.remittanceVarianceThreshold,
      defaultFields: form.defaultFields,
      client: this.uiState$.client.id,
      dateUpdated: moment().toDate(),
      dateCreated: moment().toDate()
    });
  }
}
